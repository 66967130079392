import * as React from 'react';
import { Modal , Form, Container , Row, FloatingLabel, Col, Button, Alert } from 'react-bootstrap';
import { useBranding } from '../Branding';
import { useHref, useLocation, useNavigate } from 'react-router-dom';
import profileapi from "../../io/profile";
import networkapi from '../../io/network';
import SearchResultsModal from './SearchResultsModal';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { useAuth } from '../Auth';
import { useRefreshEmitter } from '../RefreshEmitter';


function AddtoNetworkModal() {

	const branding = useBranding();
	const { hash } = useLocation();
	const href = useHref();
	const auth = useAuth();
	const navigate = useNavigate();
	const emitter = useRefreshEmitter();
	
	const [ showaddtonetwork , setAddtonetwork ] = React.useState(false);
	const [ search_type , setSearchType ] = React.useState(false);
	const [ searchresults , setSearchresults ] = React.useState(false);
	const [ showSearchResultsModal , setShowSearchModal ] = React.useState(false);

	const [ externalinvitesent , setExternalinvitesent ] = React.useState(false);
	const [ externalinviteemail , setExternalinviteEmail ] = React.useState(false);
	const [ notfounderror , setNotFoundError ] = React.useState(false);
	const [ selfwarning , setSelfWarning ] = React.useState(false);
	const [ invalidemailwarning , setInvalidemailwarning ] = React.useState(false);

	async function handleFormSubmit(event)
	{
		event.preventDefault();

		setNotFoundError(false);
		setSelfWarning(false);

		let formData = new FormData(event.currentTarget);

		let search_type = formData.get('search_type');
		let search_shield_id = formData.get('shieldid');
		let email = formData.get('emailaddress');

		if(search_type === 'shieldid')
		{
			if(search_shield_id === auth.user.certification_number)
			{
				setSelfWarning(true);
				return;
			}

			let response = await profileapi.search( search_shield_id );
			if(response.success && response.searchresults?.firstname !== undefined && response.searchresults?.firstname !== null)
			{
				setSearchresults(response.searchresults);
				setShowSearchModal(true);
				setAddtonetwork(false);
			}
			else
			{
				setNotFoundError(true);
			}
		}
		else if(search_type === 'email')
		{
			if(email === auth.user.emailaddress)
			{
				setSelfWarning(true);
				return;
			}

			if( !email.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z0-9]{2,24}$/) )
			{
				setInvalidemailwarning(true);
				return;
			}

			let response = await profileapi.search( email );
			if(response.success && response.searchresults?.firstname !== undefined && response.searchresults?.firstname !== null)
			{
				setSearchresults(response.searchresults);
				setShowSearchModal(true);
				setAddtonetwork(false);
			}
			else if(response.success)
			{
				let externalresponse = await networkapi.sendexternalinvite( email );
				if(externalresponse.success)
				{
					emitter.Emit('networkupdate');
					setExternalinviteEmail(email);
					setExternalinvitesent(true);
				}
			}
		}
	}

	function DismissModals()
	{
		setExternalinvitesent(false);
		setSearchType(false);
		setAddtonetwork(false);
		setShowSearchModal(false);
		setNotFoundError(false);
		setSelfWarning(false);
		navigate(href);
	}

	React.useEffect( () => {

		if(hash.match(/#addtomynetwork/))
		{
			setAddtonetwork(true);
		}
	},[ hash ]);	

	return (
		<>
			<Modal show={showaddtonetwork} onHide={DismissModals}>
				<Modal.Header style={{border:"0px"}} closeButton>
				</Modal.Header>
			
				<Modal.Body className="pt-0">

					<Col md={12}>
						<h3 style={{textAlign:'center'}}>Invite User</h3>
						<h6 style={{color:branding.PrimaryColor,textAlign:'center'}}>Send an invite to a user using their ShieldID<sup>TM</sup> or Email. <br/>Their ShieldScore<sup>TM</sup> will become available once they<br/>accept your network invite.</h6>
					</Col>
		
					<Form className="pt-3" onSubmit={handleFormSubmit} autoComplete='off'>
						<Container>
							<Row style={{cursor:'pointer'}} onClick={() => {
								setSearchType('shieldid');
							}}>
								<Col className='mb-5' >
									<Form.Check>
										<Form.Check.Input name="search_type" type="radio" value="shieldid" checked={search_type === 'shieldid'} onChange={() => {}}/>
										<Form.Check.Label>
											<b>Search from ShieldID (or ABC#)</b>
											<p>Add a user to your network from a provided ShieldID/ABC#</p>
										</Form.Check.Label>
									</Form.Check>
									<FloatingLabel controlId="shieldid" label="SHIELD ID">
										<Form.Control name="shieldid" type="text" placeholder='SHIELD ID' disabled={search_type !== 'shieldid'}/>
									</FloatingLabel>
								</Col>
							</Row>
							<Row>
								<Col className='mb-5' style={{cursor:'pointer'}} onClick={() => {
									setSearchType('email');
								}}>
									<Form.Check>
										<Form.Check.Input type="radio" name="search_type" value="email" checked={search_type === 'email'} onChange={() => {}}/>
										<Form.Check.Label>
											<b>Invite from email</b>
											<p>Send an email to a new user for them to create and share their ShieldID</p>
										</Form.Check.Label>
									</Form.Check>
									<FloatingLabel controlId="emailaddress" label="EMAIL">
										<Form.Control name="emailaddress" type="text" placeholder='EMAIL' disabled={search_type !== 'email'}/>
									</FloatingLabel>
								</Col>
							</Row>
							<Row>
								<Col style={{textAlign:'center'}}>
									{!externalinvitesent && <Button type="submit" size="lg" style={{width:'100%',marginBottom:'20px'}} disabled={(search_type === false)}>Continue</Button>}
									{externalinvitesent && <Alert variant='success'>Invite sent to {externalinviteemail}</Alert>}
									{notfounderror && <Alert variant='danger'><ExclamationTriangleFill /> Error: Shield ID Not Found</Alert>}
									{selfwarning && <Alert variant='warning'> That&apos;s your Shield ID / Email address </Alert>}
									{invalidemailwarning && <Alert variant='danger'><ExclamationTriangleFill /> Error: Invalid Email Address</Alert>}
								</Col>
							</Row>
						</Container>
					</Form>
				</Modal.Body>
			</Modal>
			<SearchResultsModal 
				searchresults={searchresults}
				DismissSearchModal={DismissModals}
				showSearchResultsModal={showSearchResultsModal}
			/>
		</>
	);
}

export default AddtoNetworkModal;