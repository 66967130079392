import * as api from './apibase';

async function listinvitations( )
{
	return await api.get('/network/listinvitations');
}

async function sendinternalinvite( userid )
{
	return await api.post('/network/newinvite', { touser : userid });
}

async function sendexternalinvite( emailaddress )
{
	return await api.post('/network/newinvite', { touser : emailaddress });
}

async function resendinvite( inviteid )
{
	return await api.post('/network/resendinvite', { inviteid });
}

async function cancelinvite( inviteid )
{
	return await api.post('/network/cancelinvite', { inviteid });
}

async function acceptinvite( inviteid )
{
	return await api.post('/network/acceptinvite', { inviteid });
}

async function ignoreinvite( inviteid )
{
	return await api.post('/network/ignoreinvite', { inviteid });
}

async function listconnections()
{
	return await api.get('/network/listconnections');
}

async function setconnectionactive( connectionid , active )
{
	return await api.post('/network/setconnectionactive', { connectionid, active });
}

async function deleteconnection( connectionid , connectiontype )
{
	return await api.post('/network/deleteconnection', { connectionid, connectiontype });
}

async function setsharedetails( connectionid, reportshared )
{
	return await api.post('/network/setsharedetails', { connectionid, reportshared });
}

async function acceptteaminvite( invitecode )
{
	return await api.post('/network/acceptteaminvite', { invitecode });
}
async function listalerts( fromdate , todate )
{
	return await api.post('/network/listalerts',{ fromdate , todate });
}
async function getalertprofile( userid )
{
	return await api.post('/network/getalertprofile', { userid });
}
async function savealertconfig( alertconfig )
{
	return await api.post('/network/savealertconfig', { alertconfig });
}

async function pendinginvites( invitecode )
{
	return await api.post('/network/listpendinginvites', { invitecode });
}

async function acceptpendinginvites( invitecode , invites , profile )
{
	return await api.post('/network/acceptpendinginvites', { invitecode, invites , profile });
}

async function downloadreport( connectionid )
{
	return await api.post('/network/downloadreport', { connectionid });
}

async function requestrenewal( connectionid )
{
	return await api.post('/network/requestrenewal', { connectionid });
}

export default { 
	listinvitations , 
	sendinternalinvite , 
	sendexternalinvite, 
	resendinvite ,
	cancelinvite ,
	acceptinvite ,
	ignoreinvite ,
	listconnections ,
	setconnectionactive,
	deleteconnection,
	setsharedetails,
	acceptteaminvite,
	listalerts,
	getalertprofile,
	savealertconfig,
	pendinginvites,
	acceptpendinginvites,
	downloadreport,
	requestrenewal,
};