import * as React from "react";
import { Card, Collapse , Button, Row , Col, Container, Alert} from "react-bootstrap";
import { CheckCircleFill, ChevronDown, ChevronRight, ExclamationCircleFill, XCircleFill } from "react-bootstrap-icons";
import ICScore from '../../ICScore';
import { useLocation } from "react-router-dom";
import { format as dateFormat , parseJSON as dateParseJSON , isValid as dateValid , isFuture as dateInFuture , isToday as dateIsToday , isPast as dateInPast  } from 'date-fns';
import api from '../../../io/admin';
import { useRefreshEmitter } from "../../RefreshEmitter";
import { useBranding } from "../../Branding";


function ShieldScore()
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const branding = useBranding();
	const [ shieldscores , setShieldScores ] = React.useState([]);
	const [ panelshow , setPanelShow ] = React.useState(true);

	async function LoadCurrentScore( userid )
	{
		let response = await api.getcurrentscore( userid );

		if(response.success === true)
		{

			let scores = response.scores.map( (record) => {

				if(record.effective_dts && !dateValid(record.effective_dts) && record?.effective_dts?.length > 0)
				{
					try{
						record.effective_dts = dateParseJSON(record.effective_dts);
					}
					catch(e)
					{
						console.warn(e);
						record.effective_dts = null;
					}
				}
				if(record.last_update_dts && !dateValid(record.last_update_dts) && record?.last_update_dts?.length > 0)
				{
					try{
						record.last_update_dts = dateParseJSON(record.last_update_dts);
					}
					catch(e)
					{
						console.warn(e);
						record.last_update_dts = null;
					}
				}
				if(record.expiration_dts && !dateValid(record.expiration_dts) && record?.expiration_dts?.length > 0)
				{
					try{
						record.expiration_dts = dateParseJSON(record.expiration_dts);
					}
					catch(e)
					{
						console.warn(e);
						record.expiration_dts = null;
					}
				}
				return record;
			})

			setShieldScores(scores);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setShieldScores([
				{
					userid : 1000,
					legalfirstname : 'Jonathan',
					legallastname : 'Smith',
					cra_profilenumber : '2024081366024937',
					currenticstatus : 'IC01',
					effective_dts : new Date(),
					last_update_dts : new Date(),
					expiration_dts : new Date('2024-01-10 10:00:00'),
					active : 'YES',
				},
				{
					userid : 1000,
					legalfirstname : 'Jonathan',
					legallastname : 'Smith',
					cra_profilenumber : '2024081366024937',
					currenticstatus : 'IC01',
					effective_dts : new Date(),
					last_update_dts : new Date(),
					expiration_dts : new Date('2024-01-10 10:00:00'),
					active : 'NO',
				}
			]);
		}
		else
		{
			console.warn(response);
			setShieldScores([]);
		}
	
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setShieldScores([]);

		( async () => {
			await LoadCurrentScore( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadCurrentScore( userid );
			})();
		}
	},[emitter.eventname,hash]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;ShieldScore</Button>


			<Collapse in={panelshow}>
				<Container style={{textAlign:'center'}} id="admin-score-block">
					<Row style={{color:branding.PrimaryColor,textTransform:'uppercase'}}>
						<Col>Legal Name</Col>
						<Col xs={2}>Current Score</Col>
						<Col>CRA ID</Col>
						<Col xs={2}>Effective Date</Col>
						<Col>Last Update</Col>
						<Col>Expiration Date</Col>
					</Row>
					{shieldscores && shieldscores.length > 0 && shieldscores.map( (userprofile,index) => {
						return (
							<Row className="pt-3" key={index} style={{backgroundColor: userprofile.active === 'NO' ? '#EEEEEE' : null , color : userprofile.active === 'NO' ? '#AAAAAA' : null }} >
								<Col style={{paddingTop:'16px'}}>{userprofile.legalfirstname} {userprofile.legallastname}</Col>
								<Col xs={2} style={{paddingTop:'10px'}} className='score-column'><ICScore score={userprofile.currenticstatus}/></Col>
								<Col style={{paddingTop:'16px'}}>{userprofile.cra_profilenumber}</Col>
								<Col xs={2} style={{paddingTop:'16px'}}>{ (userprofile.effective_dts && dateValid(userprofile.effective_dts) ? dateFormat(userprofile.effective_dts,"MM/dd/yyyy") :  null )}</Col>
								<Col style={{paddingTop:'16px'}}>{ (userprofile.last_update_dts && dateValid(userprofile.last_update_dts) ? dateFormat(userprofile.last_update_dts,"MM/dd/yyyy hh:mm a") : null )}</Col>
								<>
									{ (userprofile.expiration_dts && dateValid(userprofile.expiration_dts) && dateInFuture(userprofile.expiration_dts) ) ? <Col><Alert variant="success"><CheckCircleFill /> {dateFormat(userprofile.expiration_dts,"MM/dd/yyyy")} </Alert></Col> : null }
									{ (userprofile.expiration_dts && dateValid(userprofile.expiration_dts) && dateIsToday(userprofile.expiration_dts) ) ? <Col><Alert variant="warning"><ExclamationCircleFill /> {dateFormat(userprofile.expiration_dts,"MM/dd/yyyy")}</Alert></Col> : null }
									{ (userprofile.expiration_dts && dateValid(userprofile.expiration_dts) && dateInPast(userprofile.expiration_dts) && !dateIsToday(userprofile.expiration_dts) ) ? <Col><Alert variant="danger"><XCircleFill /> {dateFormat(userprofile.expiration_dts,"MM/dd/yyyy")}</Alert></Col> : null }

									{ ( userprofile.expiration_dts === null || userprofile.expiration_dts === undefined) && <Col></Col>}
								</>
							</Row>
						);
					})
					}
				</Container>
			</Collapse>
		</Card>

	);

}
export default ShieldScore;