import * as React from "react";
import { Button, Row , Col, Spinner , Image, Alert } from "react-bootstrap";
import { LicenseManager } from 'dynamsoft-license';
import { CoreModule } from "dynamsoft-core";
import "dynamsoft-document-normalizer";
import 'dynamsoft-label-recognizer';
import { CameraEnhancer, CameraView , DrawingStyleManager } from "dynamsoft-camera-enhancer";
import { CaptureVisionRouter } from "dynamsoft-capture-vision-router";
import { MultiFrameResultCrossFilter , ImageManager } from "dynamsoft-utility";
import api from '../../../io/workflow';
import { useGeolocated } from "react-geolocated";
import { CaretRightFill, ChevronDoubleLeft, LightbulbFill, LightbulbOffFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

LicenseManager.initLicense('DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAzMDcxNjUzLVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAzMDcxNjUzIiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2Rscy5keW5hbXNvZnRvbmxpbmUuY29tIiwiY2hlY2tDb2RlIjotODgzMjQxOTA4fQ==');

// function dataURLtoBlob(dataurl)
// {
// 	//https://gist.github.com/wuchengwei/b7e1820d39445f431aeaa9c786753d8e
// 	var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
// 		bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
// 	while(n--){
// 		u8arr[n] = bstr.charCodeAt(n);
// 	}
// 	return new Blob([u8arr], {type:mime});
// }

function CaptureID( props )
{
	if(process.env.REACT_APP_SAMPLEDATA ? true : false)
	{
		CoreModule.engineResourcePaths = {
			core: 'https://cdn.jsdelivr.net/npm/dynamsoft-core@3.2.30/dist/',
			license: 'https://cdn.jsdelivr.net/npm/dynamsoft-license@3.2.21/dist/',
			ddn: "https://cdn.jsdelivr.net/npm/dynamsoft-document-normalizer@2.2.10/dist/",
			cvr: 'https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router@2.2.30/dist/',
			dce: 'http://localhost:3000/dynamsoft/dynamsoft-camera-enhancer/dist/',
			std: 'https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-std@1.2.10/dist/',
			dip: 'https://cdn.jsdelivr.net/npm/dynamsoft-image-processing@2.2.30/dist/',
			dbr: 'https://cdn.jsdelivr.net/npm/dynamsoft-barcode-reader@10.2.10/dist/',
			dlr: "https://cdn.jsdelivr.net/npm/dynamsoft-label-recognizer@3.2.30/dist/",
			dnn: 'https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-dnn@1.0.20/dist/',
			dlrData: 'https://cdn.jsdelivr.net/npm/dynamsoft-label-recognizer-data@1.0.10/dist/',
		};
	}
	else if(process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN)
	{
		CoreModule.engineResourcePaths = {
			core : `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-core/dist/`,
			license: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-license/dist/`,
			ddn: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-document-normalizer/dist/`,
			cvr: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-capture-vision-router/dist/`,
			dce: '/dynamsoft/dynamsoft-camera-enhancer/dist/',
			std: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-capture-vision-std/dist/`,
			dip: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-image-processing/dist/`,
			dbr: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-barcode-reader/dist/`,
			dlr: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-label-recognizer/dist/`,
			dnn: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-capture-vision-dnn/dist/`,
			dlrData: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-label-recognizer-data/dist/`,
		};		
	}
	else
	{
		CoreModule.engineResourcePaths = {
			core : 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-core/dist/',
			license: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-license/dist/',
			ddn: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-document-normalizer/dist/',
			cvr: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-capture-vision-router/dist/',
			dce: '/dynamsoft/dynamsoft-camera-enhancer/dist/',
			std: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-capture-vision-std/dist/',
			dip: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-image-processing/dist/',
			dbr: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-barcode-reader/dist/',
			dlr: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-label-recognizer/dist/',
			dnn: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-capture-vision-dnn/dist/',
			dlrData: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-label-recognizer-data/dist/',
		};
	}

	const navigate = useNavigate();
	const capturecontainer = React.createRef(null);
	const fullscreencontainer = React.createRef(null);

	var CameraViewInstance, CameraEnhancerInstance, CVRouter , ImageManagerInstance;

	var MobileViewerTopPadding = document.requestFullscreen ? '1px' : '50px';
	var MobileViewerBottomPadding = document.requestFullscreen ? '1px' : '100px';

	// const { GeoCoords, isGeolocationAvailable, isGeolocationEnabled }
	const { GeoCoords } = useGeolocated({positionOptions:{ enableHighAccuracy: true, },userDecisionTimeout: 5000, isOptimisticGeolocationEnabled : false });

	const [ CameraTorchState , setCameraTorchState ] = React.useState(false);
	const [ userReady , setUserReady ] = React.useState(false);
	const [ capturedImage , setCapturedImage ] = React.useState(false);
	const [ capturedImageUrl , setCapturedImageUrl ] = React.useState(false);
	const [ captureInProgress , setCaptureInProgress ] = React.useState(false);
	const [ iddetectionresults , setIdDetectionResults ] = React.useState(null);
	const [ iddetectionmessages , setIdDetectionMessages ] = React.useState(['We could not detect the front of your ID. Please retake the photo']);


	async function verify()
	{
		setCaptureInProgress(true);

		let presignedurl = await api.startcaptureid(props.side);

		if(presignedurl.success)
		{
			let s3uploadresponse = await api.uploadcapture( presignedurl.posturl , capturedImage );
			if(s3uploadresponse.success)
			{
				let verifyresponse = await api.completecapture(props.side,'success' , GeoCoords );
				if(verifyresponse.success)
				{
					setIdDetectionResults(verifyresponse.idvalidation);
					if(verifyresponse.idvalidation === true && verifyresponse.idvalidationmessages.length === 0)
					{
						if(verifyresponse.documenttype === 'passport')
						{
							let verificationdataresults = await api.verificationdata('id');
							if(verificationdataresults.success)
							{
								if(verificationdataresults.idverificationresults === false && verificationdataresults.idfailuremessages.length > 0)
								{
									setIdDetectionResults(verificationdataresults.idverificationresults);
									setIdDetectionMessages(verificationdataresults.idfailuremessages);
								}
								else
								{
									navigate('/user/idverify/capturevideo');
								}
							}
							else
							{
								console.error(verificationdataresults);
							}
						}
						else
						{
							navigate('/user/idverify/captureback');
						}

						setCaptureInProgress(false);
					}
					else
					{
						if(verifyresponse.idvalidationmessages.length > 0)
						{
							setIdDetectionMessages(verifyresponse.idvalidationmessages);
						}

						setCaptureInProgress(false);
					}
				}
			}
		}

	}

	async function DocumentCapture( result )
	{
		if(result.detectedQuadResultItems.length === 0)
		{
			if(parseInt(result.errorCode) !== 0)
			{
				console.log({ errorString : result.errorString , errorCode : result.errorCode });
			}

			return;
		}

		let total_area = parseInt(result.originalImageTag.currentHeight) * parseInt(result.originalImageTag.currentWidth);
		let mostly_covered_area = parseInt(total_area * 0.15);

		// console.log(result);

		if( [8,16].indexOf(parseInt(result.detectedQuadResultItems[0].type)) !== -1 )
		{
			if(parseInt(result.detectedQuadResultItems[0].location.area) < mostly_covered_area )
			{
				// console.log(result.items[0].location.area,100000);
				return;
			}
		}
		else
		{
			console.log('wrong result type');
			console.log(result.detectedQuadResultItems[0].type);
			return;
		}

		// console.log(result);

		if(capturedImage === false)
		{
			CameraEnhancerInstance.setPixelFormat( 7 | 11);
			CameraEnhancerInstance.clearBuffer();

			let current_image = CameraEnhancerInstance.fetchImage();

			let textlineresults = await CVRouter.capture(current_image,'RecognizeTextLines_Default');

			if(textlineresults?.textLineResultItems && textlineresults.textLineResultItems.length > 3)
			{
				let imagedata = await ImageManagerInstance.saveToFile(current_image,'download');

				setCapturedImageUrl(URL.createObjectURL(imagedata));
				setCapturedImage(imagedata);

				ResetCamera();
			}
			else
			{
				try{
					CameraViewInstance.setTipVisible(true);
					CameraViewInstance.updateTipMessage('Image does not appear clear enough, please retry');
				}
				catch
				{
					console.log('tip did not get rendered');
				}

				CameraEnhancerInstance.clearBuffer();
				return;
			}
		}
	}

	async function Init()
	{
		if( !(CameraViewInstance && !CameraViewInstance?.getUIElement) )
		{
			CameraViewInstance = await CameraView.createInstance();
		}
		if(!CameraEnhancerInstance || !CVRouter)
		{
			CameraEnhancerInstance = await CameraEnhancer.createInstance(CameraViewInstance);
			CameraViewInstance.setTipConfig({
				topLeftPoint: {x: 50, y:50},
				width: 200,
				duration: 3000
			});
			CameraViewInstance.setTipVisible(false);
			CameraEnhancerInstance.setMaxImageCount(1);
			DrawingStyleManager.updateDrawingStyle(1,{
				fillStyle: "rgba(0, 0, 0, 0)",
				lineWidth: 1,
				paintMode : 'stroke',
				strokeStyle: "rgba(0, 0, 0, 0)"
			});
			// DrawingStyleManager.updateDrawingStyle(9,{
			// 	fillStyle: "rgba(0, 0, 0, 0)",
			// 	lineWidth: 1,
			// 	paintMode : 'stroke',
			// 	strokeStyle: "rgba(0, 0, 0, 0)"
			// });

			CVRouter = await CaptureVisionRouter.createInstance();
			CVRouter.setInput(CameraEnhancerInstance);
			CVRouter.addResultReceiver({
				onDetectedQuadsReceived : DocumentCapture,
			});
	
			let filter = new MultiFrameResultCrossFilter();
			filter.enableResultCrossVerification('barcode',false);
			filter.enableResultCrossVerification('detected_quad',true);
			await CVRouter.addResultFilter(filter);
		}

		if(capturecontainer !== null && capturecontainer.current !== null)
		{
			capturecontainer?.current?.replaceChildren( CameraViewInstance.getUIElement() );
			await CameraEnhancerInstance.open();
			let settings = await CVRouter.getSimplifiedSettings("DetectDocumentBoundaries_Default");
			settings.minImageCaptureInterval = 30;
			CVRouter.updateSettings('DetectDocumentBoundaries_Default', settings );
			await CVRouter.startCapturing('DetectDocumentBoundaries_Default');

			ImageManagerInstance = new ImageManager();
	
			if(props.isMobile === true && props.screenOrientation === 'landscape')
			{
				if(props.isMobile === true && fullscreencontainer?.current?.requestFullscreen )
				{
					await fullscreencontainer.current.requestFullscreen({ navigationUI: 'hide' });
				}
				else
				{
					fullscreencontainer.current.scrollIntoView({behavior:'instant',block:'start',inline:'nearest'});
				}
			}
		}		


	}

	function ResetCamera()
	{
		CameraEnhancerInstance?.clearBuffer();
	
		CVRouter?.stopCapturing('DetectDocumentBoundaries_Default');

		CVRouter?.stopCapturing();

		try{
			CameraEnhancerInstance?.turnOffTorch();
		}
		catch
		{
			//ignore
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		if( props.isMobile === false || (props.isMobile === true && props.screenOrientation === 'landscape') )
		{
			( async () => {
				await Init();
			} )();
		}


	}, [props.isMobile,props.screenOrientation, capturecontainer,capturecontainer.current,CameraViewInstance, CameraViewInstance?.getUIElement ]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (

		<>
			{userReady === true && capturedImage !== false && <>
				<Row className="pb-5 justify-content-center">
					<Col xs={12} md={8} lg={4}>
						<img src={capturedImageUrl} style={{width:'100%'}}/>
					</Col>
				</Row>
				<Row className="pb-3">
					<Col className="text-center">
						<h5>Make sure your details are clear and unobstructed</h5>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xs={12} md={8} lg={4}>
						<Button variant="outline-primary" style={{width:'100%'}} onClick={() => { setCapturedImage(false); setCapturedImageUrl(false); }}>Retake photo</Button>
					</Col>
				</Row>
				<Row className="mt-1 justify-content-center" >
					<Col xs={12} md={8} lg={4}>
						<Button variant="primary" style={{width:'100%'}} onClick={verify} disabled={(captureInProgress === true)} >Continue { captureInProgress === true && <Spinner size="sm" /> }</Button>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xs={12} md={8} lg={4}>
						<Alert variant="danger" className="mt-3" show={ iddetectionresults === false }>Error: {iddetectionmessages && iddetectionmessages.map( (item) => {
							return ( <>{item}<br /></> );
						})}</Alert>
					</Col>
				</Row>
			</>}
			{userReady === true && capturedImage === false && document.exitFullscreen && props.isMobile === true && props.screenOrientation === 'landscape' && 
				<>
					<div ref={fullscreencontainer}>
						<Button onClick={() => {
							if( document.exitFullscreen )
							{
								document.exitFullscreen();
							}

							ResetCamera();
							setUserReady(false);
						}} variant="light" style={{zIndex:'1001',position:'absolute',top:'0px',right:'10px'}}><ChevronDoubleLeft /></Button>
						{CameraTorchState === false && 
							<Button onClick={() => {  CameraEnhancerInstance.turnOnTorch(); setCameraTorchState(true);  }} variant="light" style={{zIndex:'1001',position:'absolute',top:'0px',right:'60px'}}><LightbulbFill /></Button>
						}
						{CameraTorchState === true && 
							<Button onClick={() => {  CameraEnhancerInstance.turnOffTorch(); setCameraTorchState(false);  }} variant="light" style={{zIndex:'1001',position:'absolute',top:'0px',right:'60px'}}><LightbulbOffFill /></Button>
						}
						<div ref={capturecontainer} className="div-ui-container" style={{border:'5px solid pink',width:'100%',height:'100%',zIndex:'1000' }}></div>
					</div>
				</>
			}
			{userReady === true && capturedImage === false && !(document.exitFullscreen) && ( props.isMobile === true && props.screenOrientation === 'landscape' ) &&
				<>
					<div ref={fullscreencontainer}>
						<Button onClick={() => { 
							ResetCamera();
							setUserReady(false);
						}} variant="light" style={{zIndex:'1001',position:'absolute',top:'0px',right:'10px'}}><ChevronDoubleLeft /></Button>
						{CameraTorchState === false && 
							<Button onClick={() => {  CameraEnhancerInstance.turnOnTorch(); setCameraTorchState(true);  }} variant="light" style={{zIndex:'1001',position:'absolute',top:'0px',right:'60px'}}><LightbulbFill /></Button>
						}
						{CameraTorchState === true && 
							<Button onClick={() => {  CameraEnhancerInstance.turnOffTorch(); setCameraTorchState(false);  }} variant="light" style={{zIndex:'1001',position:'absolute',top:'0px',right:'60px'}}><LightbulbOffFill /></Button>
						}
						<div ref={capturecontainer} className="div-ui-container" style={{border:'5px solid green',position:'absolute',top:'0px',left:'0px',width:'100%',height:'540px',zIndex:'1000',margin:'5px',paddingTop: MobileViewerTopPadding ,paddingBottom: MobileViewerBottomPadding }}></div>
					</div>
				</>
			}
			{userReady === true && capturedImage === false && props.isMobile === false &&
				<>
					<Button onClick={() => { ResetCamera(); setUserReady(false);  }} variant="light" style={{zIndex:'1001',position:'absolute',top:'0px',right:'10px'}}><ChevronDoubleLeft /></Button>
					<div ref={capturecontainer} className="div-ui-container" style={{height:'540px'}}></div>
				</>
			}
			{userReady === true && capturedImage === false && props.isMobile === true && props.screenOrientation === 'portrait' && 
			<>
				<Row>
					<Col className="text-center" xs={12}>
						<h2>For best results, please rotate your device to landscape mode</h2>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xs={8}>
						<Image src={`${process.env.PUBLIC_URL}/images/landscape_rotate_istockphoto-1144546554-1024x1024.jpg`} style={{width:'100%'}}/>
					</Col>
				</Row>
			</>
			}
			{ userReady === false && capturedImage === false && 
				<>
					<Row className="justify-content-center">
						<Col xs={12} lg={8} className="text-center">
							<h3>Capture ID Front</h3>
							<p>When ready, click below. Your camera will open, and begin detecting your ID. When your ID is detected, and in focus the camera will automattically capture the image.</p>
							<Button onClick={ () => { setUserReady(true); }} >I&apos;m ready <CaretRightFill /></Button>
						</Col>
					</Row>
				</>
			}
		</>


	);

}
export default CaptureID;
