import * as React from "react";
import { Button, Card , Row , Col } from "react-bootstrap";
import { PlayFill } from "react-bootstrap-icons";



function PurchaseBackgroundCheck() {

	return (
		<>
			<Card className="p-1">
				<Card.Body>
					<Row>
						<Col xs={12} md={5}>
							<h4>My Background Checks</h4>
							<p className="small">You currently do not have any background checks.</p>
						</Col>
						<Col sx={12} md={7} className="">
							<Button href="/user/purchase" className="btn-primary btn-lg p-3 purchase-button" style={{minWidth:'242px', width:'100%',fontSize: '16px'}}>Purchase Background Check&nbsp;<PlayFill /></Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
}
export default PurchaseBackgroundCheck;