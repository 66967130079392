import * as React from "react";
import { Badge, Button, Card, Col, Collapse, Container, Form, Row , InputGroup , Table } from "react-bootstrap";
import { ChevronDown, ChevronRight, Search, TrashFill } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import api from '../../../io/admin-enterprise';
import { useBranding } from "../../Branding";
import { useRefreshEmitter } from "../../RefreshEmitter";


function CRAPackages( props )
{
	const [ panelshow , setPanelShow ] = React.useState(true);
	const { hash } = useLocation();
	const [ enterpriseprofile , setEnterpriseProfile ] = React.useState({});
	const [ packageslist , setPackageList ] = React.useState();
	const [ showAddPackageSearch , setshowAddPackageSearch ] = React.useState(false);
	const [ searchpackages , setSearchpackages ] = React.useState([]);

	const emitter = useRefreshEmitter();
	const branding = useBranding();

	const searchpackagefieldRef = React.createRef();

	async function LoadProfile( userid )
	{
		let response = await api.getenterpriseprofile( userid );
		if(response.success === true)
		{
			setEnterpriseProfile({
				companyid : response.profile.companyid,
			});

			response.profile.packagelist = response.profile.packagelist.map( (item) => {

				if(item.selection_criteria === 'state=NY')
				{
					item.new_york_only = true;
					item.not_new_york = false;
				}
				if(item.exclusion_criteria === 'state!=NY')
				{
					item.new_york_only = false;
					item.not_new_york = true;
				}

				return item;
			});

			setPackageList(response.profile.packagelist);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setEnterpriseProfile({
				companyid : 1,
			});

			setPackageList([
				{
					id : 1000,
					default_package : 'YES',
					crapackageid : 'SLRT00001',
					full_package_descrip : 'Roof Marketplace 1 Year Non-NY Package with Continous Monitoring',
					craid : 10,
					craname : 'ClearStar',
					customerid : 'ASPN0003',
					selection_criteria : null,
					exclusion_criteria : 'state!=NY',
					new_york_only : true,
					not_new_york : null,
					base_price : 50,
					renewalterm : 1,
					enabled : true,
					paid : 'NO',
				},
				{
					id : 1001,
					default_package : 'NO',
					crapackageid : 'SLRT0002',
					full_package_descrip : 'Roof Marketplace 1 Year NY Package with Continous Monitoring',
					craid : 10,
					craname : 'ClearStar',
					customerid : 'ASPN0003',
					selection_criteria : 'state=NY',
					exclusion_criteria : null,
					new_york_only : false,
					not_new_york : true,
					base_price : 150,
					renewalterm : 1,
					enabled : true,
					paid : 'NO',
				}
			]);
		}
		else
		{
			console.warn(response);
			setPackageList([]);
		}

	}

	async function SaveProfile( )
	{

		let packagesupdate = [ ...packageslist];

		packagesupdate = packagesupdate.map( (item) => {

			return {
				id : item.id,
				craid : item.craid,
				crapackageid : item.crapackageid,
				enabled : item.enabled,
				delete_flag : ( item.delete_flag ? true : false),
			};

		});

		let response = await api.saveenterprisepackageselections( enterpriseprofile.companyid , packagesupdate );
		if(response.success === true)
		{
			props.setFormDirtyFunction(false);
		}
		else
		{
			console.warn(response);
		}
	}

	async function UpdatePackage( packageid , fieldname , value )
	{
		let packagelistupdate = [ ...packageslist ];

		packagelistupdate = packagelistupdate.map( (item) => {

			if(item.id === packageid)
			{
				item[fieldname] = value;
			}
			return item;
		});

		setPackageList(packagelistupdate);

		props.setFormDirtyFunction(true);
	}

	async function SearchPackages( event )
	{
		let searchterm = event.currentTarget.value;

		if(!searchterm || searchterm.length < 3)
		{
			setSearchpackages([]);
			return;
		}

		let packageresults = await api.packagesearch({ searchterm , companyid : enterpriseprofile.companyid });
		if(packageresults.success === true)
		{
			packageresults.packagelist = packageresults.packagelist.map( (item) => {

				if(item.selection_criteria === 'state=NY')
				{
					item.new_york_only = true;
					item.not_new_york = false;
				}
				if(item.exclusion_criteria === 'state!=NY')
				{
					item.new_york_only = false;
					item.not_new_york = true;
				}

				return item;
			});

			setSearchpackages(packageresults.packagelist);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setSearchpackages([
				{
					id : 2000,
					default_package : 'NO',
					crapackageid : 'SLRT_11509',
					full_package_descrip : 'RoofMarketplace | 1-Year Testing Package with Continous Monitoring',
					craid : 2,
					craname : 'ClearStar',
					customerid : 'ASPN_00003',
					selection_criteria : null,
					exclusion_criteria : 'state!=NY',
					new_york_only : true,
					not_new_york : null,
					base_price : 50,
					renewalterm : 1,
					enabled : true,
					paid : 'NO',
				},
				{
					id : 2001,
					default_package : 'NO',
					crapackageid : 'SLRT_11510',
					full_package_descrip : 'RoofMarketplace | 2-Year Testing Package with Continous Monitoring',
					craid : 2,
					craname : 'ClearStar',
					customerid : 'ASPN_00003',
					selection_criteria : 'state=NY',
					exclusion_criteria : null,
					new_york_only : false,
					not_new_york : true,
					base_price : 150,
					renewalterm : 2,
					enabled : true,
					paid : 'NO',
				}
			]);
		}

	}

	function AddPackage( packageid )
	{
		let packagelistupdate = [ ...packageslist ];

		let newpackage = searchpackages.filter( (item) => {
			return item.id === packageid;
		});

		newpackage[0].enabled = true;

		packagelistupdate.push(newpackage[0]);

		setPackageList(packagelistupdate);

		setSearchpackages([]);
		setshowAddPackageSearch(false);
		props.setFormDirtyFunction(true);

		searchpackagefieldRef.current.value = '';
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');

		( async () => {
			await LoadProfile( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		if( ['admin:saveenterprise'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await SaveProfile();
			})();
		}

	},[emitter.eventname]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow )}}>
				{panelshow && <ChevronDown />}
				{!panelshow && <ChevronRight />}
				&nbsp;CRA Packages</Button>
			<Collapse in={panelshow}>
				<Form autoComplete="off">
					<Row className="feature-row" style={{paddingLeft:'35px',borderTop:'solid 1px #B1B1B1',borderBottom:'0px'}}>
						<Col>Manage this organization&apos;s CRA packages. <b>Enabled</b> packages will be available for purchase by users on this organization&apos;s subdomain. <b>Paid packages</b> are hidden from users by default.</Col>
					</Row>
					<Row style={{paddingLeft:'35px'}}>
						<Col className="p-0">
							<Row className="p-2" style={{fontSize:'11pt'}}>
								<Col xs={2} style={{minWidth:'250px'}}>PACKAGE</Col>
								<Col xs={1}>CRA</Col>
								<Col xs={1} style={{minWidth:'120px',textAlign:'center'}}>CUSTOMER ID</Col>
								<Col xs={1} style={{fontSize:'10pt',textAlign:'center'}}>NEW YORK</Col>
								<Col xs={2} style={{textAlign:'center'}}>BASE PRICE</Col>
								<Col xs={1} style={{textAlign:'center'}}>TERM (Yr)</Col>
								<Col xs={1} style={{textAlign:'center',width:'88px'}}>ENABLED</Col>
								<Col xs={1} style={{textAlign:'center'}}>PAID</Col>
								<Col xs={1} style={{maxWidth:'25px'}}></Col>
							</Row>
							{packageslist && packageslist.map( (item) => {

								return (
									<Collapse key={item.id} in={ !(item.delete_flag && item.delete_flag === true ) }>
										<Container className="p-0" style={{fontSize:'11pt',borderBottom:'1px solid #B1B1B1'}}>
											<Row className="p-2 pt-4">
												<Col xs={2} style={{minWidth:'250px'}}>
													{item.crapackageid}
													{item.default_package === 'YES' && <>&nbsp;<Badge bg='primary' color={branding.PrimaryButtonBackground} style={{fontSize:'7pt'}}>DEFAULT PACKAGE</Badge></> }
												</Col>
												<Col xs={1}>{item.craname}</Col>
												<Col xs={1} style={{minWidth:'120px',textAlign:'center'}}>{item.customerid}</Col>
												<Col xs={1} style={{textAlign:'center'}}>
													{item.new_york_only && <>Y</>}
													{item.not_new_york && <>N</>}
												</Col>
												<Col xs={2} style={{textAlign:'center'}}>${item.base_price}</Col>
												<Col xs={1} style={{textAlign:'center'}}>{item.renewalterm}</Col>
												<Col xs={1} style={{textAlign:'center',width:'88px'}}>
													<Form.Check
														type="switch"
														style={{maxWidth:'50px',marginLeft:'auto',marginRight:'auto',transform:"scale(1.3)"}}
														checked={item.enabled === true}
														name="access"
														value="YES"
														onChange={() => {
															UpdatePackage(item.id,'enabled',!item.enabled);
														}}
													/>												
												</Col>
												<Col xs={1} style={{textAlign:'center'}}>{item.paid}</Col>
												<Col xs={1} style={{textAlign:'center',maxWidth:'25px'}}>
													{item.default_package !== 'YES' && <Button variant="link" className="p-0" onClick={() => UpdatePackage(item.id,'delete_flag',true)}><TrashFill color="#BE2026" size={19} /></Button>}
												</Col>
											</Row>
											<Row className="ps-2 pb-4">
												<Col xs={12}>{item.full_package_descrip}</Col>
											</Row>
										</Container>
									</Collapse>
								);
							})}
						</Col>
					</Row>
					{showAddPackageSearch && <>
						<Row style={{paddingTop:'10px',paddingLeft:'35px'}}>
							<Col xs={4}>
								<InputGroup>
									<Form.Control 
										ref={searchpackagefieldRef}
										type="text"
										onKeyUp={SearchPackages}
									/>
									<InputGroup.Text><Search /></InputGroup.Text>
								</InputGroup>
							</Col>
						</Row>
						{searchpackages && searchpackages.length > 0 && <Row style={{paddingLeft:'35px'}}>
							<Col xs={6}>
								<Table id='admin_package_search_results' style={{maxWidth:'50%',border:'1px solid #DEE2E6',position:'absolute'}}>
									<tbody>
										{searchpackages.map( (item) => {

											return (
												<tr className='result_row' key={item.id} onClick={() => { AddPackage(item.id); }}>
													<td>{item.crapackageid}</td>
													<td>{item.full_package_descrip}</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							</Col>
						</Row>}
					</>}
					<Row className="p-2">
						<Col xs={{offset:10,span:2}}><Button onClick={() => setshowAddPackageSearch(true) }> + Add Package</Button></Col>
					</Row>
				</Form>
			</Collapse>
		</Card>
		
		
	);
}
export default CRAPackages;