import * as React from "react";
import { Card, Collapse , Button  } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import api from '../../../io/admin';
import { AgGridReact } from "ag-grid-react";
import { format as dateFormat , parseISO as dateParseISO } from 'date-fns';
import { useRefreshEmitter } from "../../RefreshEmitter";



function TermsAndConditions()
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ acceptTermsHistory , setAcceptTermsHistory ] = React.useState([]);

	const TermsConditionColDef = [
		{ field : 'dts' , headerName : 'Acceptance Date' , minWidth : 180, initialWidth : 180, filter : 'agDateColumnFilter' , filterParams : { buttons : [ 'reset' ] } , cellRenderer : (props) => {
			return <>{props.data.dts_formatted}</>;
		}},
		{ field : 'terms_version' , headerName : 'Version' , initialWidth : 100,  filter : true , filterParams : { buttons : [ 'reset' ] } },
		{ field : '' , headerName : 'IP Address' , initialWidth : 150, cellRenderer : (props) => {
			return <>{props.data?.metadata?.ipaddress}</>;
		}},
		{ field : 'terms_content' , headerName : 'Terms' , wrapText : true, autoHeight : true, initialWidth : 580 },
		{ field : '' , headerName : '' , cellRenderer : () => {
			return <Button disabled>Download</Button>;
		}}
	];

	async function LoadTermsHistory( userid )
	{

		let response = await api.gettermshistory( userid );

		if(response.historyitems)
		{
			let historyitems = response.historyitems.map( (historyitem) => {
				if(historyitem.dts)
				{
					try{
						historyitem.dts = dateParseISO(historyitem.dts);
					}
					catch(e){
						console.warn(e);
						historyitem.dts = null;
					}
					historyitem.dts_formatted = dateFormat(historyitem.dts,"MM/dd/yyyy hh:mm a")
				}
				return historyitem;
			});

			setAcceptTermsHistory(historyitems);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setAcceptTermsHistory([
				{
					id : '1000',
					terms_version: '1',
					metadata : {
						ipaddress : '888.888.888.888'
					},
					dts : new Date(),
					dts_formatted : dateFormat(new Date(), 'MM/dd/yyyy hh:mm a'),
					terms_content : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
				}
			])
		}
		else
		{
			console.warn(response);
			setAcceptTermsHistory([]);
		}


	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setAcceptTermsHistory([]);

		( async () => {
			await LoadTermsHistory( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');

		if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadTermsHistory( userid );
			})();
		}
	},[emitter.eventname,hash]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;Terms &amp; Conditions</Button>


			<Collapse in={panelshow}>
				<>
					<div className="ag-theme-quartz" style={{height:'500px'}} >
						<AgGridReact
							columnDefs={TermsConditionColDef}
							rowData={acceptTermsHistory}
							pagination={true}
						/>
					</div>
				</>
			</Collapse>
		</Card>

	);

}
export default TermsAndConditions;