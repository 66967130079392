import * as React from "react";
import { Modal , Form , FloatingLabel, Button , Container , Row , Col , Alert } from "react-bootstrap";
import { useHref, useLocation, useNavigate } from "react-router-dom";

import api from '../../io/user.js'


function ShareMyShieldScore(){

	const { hash } = useLocation();
	const href = useHref();
	const navigate = useNavigate();
	const [ showsharemodal , setSharedmodal ] = React.useState(false);
	const [ sharedbuttonenable , setSharedButtonEnable ] = React.useState(false);
	const [ sharesent , setShareSent ] = React.useState(false);

	const formref = React.createRef(null);

	function handleEmailField( event )
	{
		if(event.currentTarget.value.match(/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,24}/) )
		{
			setSharedButtonEnable(true);
		}
		else
		{
			setSharedButtonEnable(false);
		}
	}

	async function handleSubmit( event )
	{

		event.preventDefault();

		let formData = new FormData(formref.current);
		let email = formData.get('share_to_emailaddress');

		let response = await api.shareshieldid( email );

		if(response.success)
		{
			setShareSent(email);

			setTimeout( () => {
				DismissModals();
			},15000);
		}

	}

	function DismissModals()
	{
		setSharedmodal(false);
		setShareSent(false);
		navigate(href);
	}

	React.useEffect( () => {

		if(hash.match(/#sharemyshieldid/))
		{
			setSharedmodal(true);
		}
	},[ hash ]);	

	return (
		<Modal show={showsharemodal} onHide={DismissModals} centered>
			<Modal.Header style={{border:"0px"}} closeButton></Modal.Header>
			<Modal.Body className="pt-0" style={{textAlign:'center'}}>
				<h3>Share My ShieldScore</h3>
				<h6>Easily share your ShieldScore with others.</h6>

				<Form className="pt-3" autoComplete='off' onSubmit={handleSubmit} ref={formref}>
					<Container>
						<Row>
							<Col>
								<FloatingLabel controlId="share_to_emailaddress" label="EMAIL">
									<Form.Control 
										name="share_to_emailaddress" 
										type="text" 
										placeholder='EMAIL' 
										onChange={handleEmailField}
									/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row className="p-3">
							<Col>
								{!(sharesent) &&  <Button type="submit" style={{width:'100%',marginBottom:'20px'}} disabled={!(sharedbuttonenable)}>Share</Button>}
								{sharesent && <Alert variant='success'>ShieldID Shared to {sharesent}</Alert>}

							</Col>
						</Row>
					</Container>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
export default ShareMyShieldScore;