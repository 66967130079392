import * as React from "react";
import { Stack , Row , Col, Form , Button, Container, Spinner } from "react-bootstrap";
import Logo from '../assets/shieldhub-logo.png'
import { Helmet } from "react-helmet-async";
import { useBranding } from "../components/Branding";
import { useParams , Link} from "react-router-dom";
import api from '../io/network';
import { ChevronDoubleRight } from "react-bootstrap-icons";
import {format as dateFormat , parseJSON as dateParseJSON } from "date-fns";

function HardRedirect(params) {

	window.location = params.to;

	return (<></>);
}


function PendingInvites(){

	const branding = useBranding();
	let params = useParams();

	const labelstyle = {
		color:branding.PrimaryColor,
		textTransform: "uppercase",
		fontSize:"14px"
	};

	const [ pendinginvites , setPendingInvites ] = React.useState(false);
	const [ countselected , setCountSelected ] = React.useState(0);
	const [ inviteacceptsuccess , setInviteAcceptSuccess ] = React.useState(null);
	const [ profile , setProfile ] = React.useState({});

	async function GetAlerts ()
	{
		let response = await api.pendinginvites( params.invitecode );
		if(response.success)
		{
			response.invites = response.invites.map( (item) => {
				item.invite_dts = dateFormat(dateParseJSON(item.invite_dts),'MM/dd/y');
				return item;
			});

			let selectedinvites = response.invites.filter( (item) => {
				return item.accepted === 'YES';
			});
	
			setCountSelected(selectedinvites.length);

			setPendingInvites(response.invites);

			setProfile(response.profile);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			let testinvites = [
				{
					inviteid : 1,
					firstname:'First',
					lastname : 'Last',
					businessname:'Business',
					invite_dts:'06/01/2024',
					accepted : 'YES'
				}
			];

			//testinvites = [];

			let selectedinvites = testinvites.filter( (item) => {
				return item.accepted === 'YES';
			});//
	
			setCountSelected(selectedinvites.length);

			setPendingInvites(testinvites);

			setProfile({
				network_autoacceptinvite : 'NO',
				network_searchresults : 'YES'
			});
		}
		else if(response.success === false)
		{
			setCountSelected(0);

			setPendingInvites([]);
		}
	
	}

	function updateInviteAccept( inviteid , checkedflag )
	{
		let newinvites = [...pendinginvites];

		newinvites = newinvites.map( (item) => {
			if(item.inviteid === inviteid)
			{
				item.accepted = checkedflag ;
			}
			return item;
		});

		let selectedinvites = newinvites.filter( (item) => {
			return item.accepted === 'YES';
		});

		setCountSelected(selectedinvites.length);

		setPendingInvites(newinvites);
	}

	function updateProfile( field , value )
	{
		let newprofile = {...profile};
		newprofile[field] = value;
		setProfile(newprofile);
	}

	async function SaveInvites()
	{
		let selectedinvites = pendinginvites.filter( (item) => {
			return item.accepted === 'YES';
		});

		selectedinvites = selectedinvites.map( (item) => {
			return item.inviteid;
		});

		let response = await api.acceptpendinginvites( params.invitecode , selectedinvites , profile );

		if(response.success)
		{
			setInviteAcceptSuccess(true);
			setTimeout( () => {
				HardRedirect({to:'/login'});
			}, 8000);			
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () =>{

		if(pendinginvites.length === 0)
		{
			setTimeout( () => {
				HardRedirect({to:'/login'});
			}, 8000);
		}

	},[pendinginvites.length])


	React.useEffect(() => {

		( async () => {
			await GetAlerts();
		})();

	}, [params.invitecode]);
	/* eslint-disable react-hooks/exhaustive-deps */	

	return (
		<>
			<Helmet>
				<title>ShieldHub Invite Center</title>
			</Helmet>
			<div className="login-position container">
				<Stack gap={2}>
					<Row className="justify-content-center login-portal">
						<Col xs="auto" style={{height:'90px'}}>
							{process.env.REACT_APP_LOCALDEBUG ? <img src={Logo} alt="ShieldHub Logo" className="branding"/> : <img src={branding.Company.branding_logo_path} alt="ShieldHub Logo" className="branding"/>}
						</Col>
					</Row>
					<Row className="justify-content-center login-portal">
						<Col xs={12} md={10} lg={8}>
							{ inviteacceptsuccess === true && <Container className="login-container text-center">
								<img src={`${process.env.PUBLIC_URL}/images/accept_invitation.svg`} className="icon-125 mb-2" />
								<h3 className="mb-0">Way to go! You&apos;re growing your trusted network.</h3>
								<Button variant="link" as={Link} to="/login" style={{textDecoration:'none',color:branding.PrimaryColor}}> Back to Login <ChevronDoubleRight style={{marginTop:'-3px'}}/></Button>
								<p>Redirecting <Spinner size="sm" /></p>
							</Container>}


							{ !inviteacceptsuccess && (!(pendinginvites) || pendinginvites.length === 0 ) && 
							<Container className="login-container text-center">
								<img src={`${process.env.PUBLIC_URL}/images/empty_invitations.svg`} className="icon-125 mb-2" />
								<h3 className="mb-0">Sorry. Your mailbox is empty</h3>
								<Col sm={{span: 10, offset: 1}}>
									<p className="mt-0">Share your ShieldScore and accept network invites to grow your network.</p>
								</Col>

								<Button variant="link" as={Link} to="/login" style={{textDecoration:'none',color:branding.PrimaryColor}}> Back to Login <ChevronDoubleRight style={{marginTop:'-3px'}}/></Button>
								<Col sm={{span: 10, offset: 1}}>
									<p className="mt-0">Congraulations, your ShieldScore is now available to your selected network partners.</p>
								</Col>
								<p>Redirecting <Spinner size="sm" /></p>
							</Container>}

							{ !inviteacceptsuccess && pendinginvites && pendinginvites.length > 0 && 
							<Form className="login-container px-1 px-sm-3">
								<Col xs={12} className="text-center">		
									<img src={`${process.env.PUBLIC_URL}/images/pending_invitations.svg`} className="icon-125 mb-2" />
									<h3 className="mb-0">You&apos;ve Got Invites!</h3>
									<Col sm={{span: 10, offset: 1}}>
										<p className="mt-0">Companies below have invited you to join their trusted network to share your background check ShieldScore. Select which network invitations you would like to accept and then click the accept button below.</p>
									</Col>
								</Col>
								<Col sm={{span:10,offset: 1}}>
									<Row className="row-rule">
										<Col xs={3}><label style={labelstyle}>Name</label></Col>
										<Col xs={4}><label style={labelstyle}>Company</label></Col>
										<Col xs={3}><label style={labelstyle}>Invitation Date</label></Col>
										<Col xs={1}></Col>
									</Row>
									{pendinginvites && pendinginvites.length > 0 && pendinginvites.map( (item) => {
										return (
											<Row key={item.inviteid} className="mt-2">
												<Col xs={3}>{`${item.firstname} ${item.lastname}`}</Col>
												<Col xs={4}>{item.businessname}</Col>
												<Col xs={3}>{item.invite_dts}</Col>
												<Col xs={2} className="text-center">
													<Form.Check
														type="switch"
														style={{transform:"scale(1.3)"}}
														checked={(item.accepted === 'YES')}
														value="YES"
														onChange={(event) => {
															if(event.target.checked)
															{
																updateInviteAccept(item.inviteid,'YES');
															}
															else
															{
																updateInviteAccept(item.inviteid,'NO');
															}
														}}
													/>
												</Col>
											</Row>
										);
									})}
									<Row>
										<Col xs={{span:12}} className="mt-4 ps-0">
											<h5 style={{fontSize:"16px"}} className="mb-0">Network Preferences</h5>
											<p className="small mt--">Update your network preferences to make it easier for customers to find and invite you.</p>
										</Col>
									</Row>
									<Row>
										<Col xs={{span:10}} className="mt-2">
											<h6 className="text-start">Auto Accept Network Invites</h6>
											<p className="small">Automatically accept network invites.  Please be aware that automatically enabling this feature may allow you to share scores with unintended users.</p>
										</Col>
										<Col xs={2} className="text-center">
											<Form.Check
												type="switch"
												style={{transform:"scale(1.3)"}}
												checked={profile.network_autoacceptinvite === 'YES'}
												value="YES"
												onChange={(event) => {
													if(event.target.checked)
													{
														updateProfile('network_autoacceptinvite', 'YES');
													}
													else
													{
														updateProfile('network_autoacceptinvite', 'NO');
													}
												}}
											/>									
										</Col>
									</Row>
		
									<Row className="mt-2">
										<Col xs={10}>
											<h6 className="text-start">ShieldID<sup>TM</sup> (ABC#) Search Results</h6>
											<p className="small">Share your ShieldScore (IC#) results with users searching for your ShieldID (ABC#). Disabling this feature will make your profile unsearchable within the network.</p>
										</Col>
										<Col xs={2} className="text-center">
											<Form.Check
												type="switch"
												style={{transform:"scale(1.3)"}}
												checked={profile.network_searchresults === 'YES'}
												value="YES"
												onChange={(event) => {
													if(event.target.checked)
													{
														updateProfile('network_searchresults', 'YES');
													}
													else
													{
														updateProfile('network_searchresults', 'NO');
													}
												}}
											/>									
										</Col>
									</Row>

								</Col>

								<Row className="mt-5">
									<Col xs={12} className="text-center">
										<Button size="lg" disabled={(countselected === 0)} onClick={SaveInvites}>Accept All Selected Invitations</Button>
									</Col>
								</Row>
							</Form>}
						</Col>
					</Row>
				</Stack>
			</div>
		</>
	);
}
export default PendingInvites;