import * as React from "react";
import { Alert, Button, Card, Col, Collapse, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { ChevronDown, ChevronRight, ExclamationTriangleFill } from "react-bootstrap-icons";
import { ChromePicker } from "react-color";
import { useLocation } from "react-router-dom";
import api from '../../../io/admin-enterprise';
import { useRefreshEmitter } from "../../RefreshEmitter";

function Branding( props ) {

	const [ panelshow , setPanelShow ] = React.useState(true);
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ enterpriseprofile , setEnterpriseProfile ] = React.useState({});
	const [ showcolorpicker , setShowcolorpicker ] = React.useState(false);
	const [ subdomainerror , setSubdomainerror ] = React.useState(false);
	const [ showuploadlogomodal , setShowuploadlogomodal ] = React.useState(false);

	const [ brandinglogo_fileObject , setLogo_fileObject ] = React.useState(false);
	const [ logoPreviewContainer , setLogoPreviewContainer ] = React.useState('none');
	const [ logopreviewData , setLogoPreviewData ] = React.useState('');


	const formref = React.createRef();
	const modaldropzoneref = React.createRef();
	const uploadInputRef = React.createRef();

	async function LoadProfile( userid )
	{
		let response = await api.getenterpriseprofile( userid );
		if(response.success === true)
		{
			if( !(response.profile.primary_color) || response.profile.primary_color === '' )
			{
				response.profile.primary_color = '#ED683C';
			}
			if( !(response.profile.primary_button_text) || response.profile.primary_button_text === '' )
			{
				response.profile.primary_button_text = '#FFFFFF';
			}

			if(response.profile.branding_logo_path && response.profile.branding_logo_path !== '' && !response.profile.branding_logo_path.match(/^https/) )
			{
				response.profile.branding_logo_path = `${process.env.PUBLIC_URL}/images/${response.profile.branding_logo_path}`;
			}

			setEnterpriseProfile(response.profile);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setEnterpriseProfile({
				custom_branding_enabled : 'YES',
				branding_hostname : 'localhost',
				branding_logo_path : `${process.env.PUBLIC_URL}/images/brandinglogos/shieldhub-logo.png`,
				business_footer : 'YES',
				primary_color : '#000000',
				primary_button_text : '#FFFFFF',
				shieldhub_branding : 'YES',
				custom_store_url : 'https://www.google.com'
			});
		}
		else
		{
			console.warn(response);
			setEnterpriseProfile({});
		}
	}

	async function SaveProfile( newcompanyid = null )
	{

		let profileobject = {
			companyid : ( enterpriseprofile.companyid ? enterpriseprofile.companyid : null ),
			userid : enterpriseprofile.userid,
		};

		if(newcompanyid !== null && newcompanyid !== profileobject.companyid)
		{
			profileobject.companyid = newcompanyid;
		}

		let formData = new FormData(formref.current);

		profileobject.custom_branding_enabled = ( formData.get('custom_branding_enabled') === 'YES' ? 'YES' : 'NO' );
		profileobject.branding_hostname = formData.get('branding_hostname');
		profileobject.custom_store_url = formData.get('custom_store_url');
		profileobject.business_footer = ( formData.get('business_footer') === 'YES' ? 'YES' : 'NO' );
		profileobject.shieldhub_branding = ( formData.get('shieldhub_branding') === 'YES' ? 'YES' : 'NO' );
		profileobject.primary_color = formData.get('primary_color');
		// profileobject.primary_button_text = formData.get('primary_button_text');

		let response = await api.saveenterpriseprofile(profileobject);

		if(response.success === true)
		{
			props.setFormDirtyFunction(false);
		}
		else
		{
			console.warn(response);
		}
	}

	async function checkDomainAvailalable( event )
	{
		let profileobject = { ...enterpriseprofile };

		profileobject.branding_hostname = event.currentTarget.value.toLowerCase();

		setEnterpriseProfile(profileobject);

		let checkdomainrequest = {
			domain : profileobject.branding_hostname,
			companyid : enterpriseprofile.companyid
		};

		let response = await api.checkdomainavailable(checkdomainrequest);
		if(response.success === true && response.available === true)
		{
			setSubdomainerror(false);

			props.setFormDirtyFunction(true);
		}
		else if(response.success === true && response.available === false)
		{
			setSubdomainerror(true);
		}
	}

	async function handleColorSwatchChange( event , fieldname )
	{

		let profileobject = { ...enterpriseprofile };

		profileobject[fieldname] = event.currentTarget.value.toUpperCase();

		setEnterpriseProfile(profileobject);

		props.setFormDirtyFunction(true);
	}

	async function handleFormBlur()
	{
		props.setFormDirtyFunction(true);

		let profileobject = { ...enterpriseprofile };

		let formData = new FormData(formref.current);

		profileobject.custom_branding_enabled = ( formData.get('custom_branding_enabled') === 'YES' ? 'YES' : 'NO' );
		profileobject.business_footer = ( formData.get('business_footer') === 'YES' ? 'YES' : 'NO' );
		profileobject.shieldhub_branding = ( formData.get('shieldhub_branding') === 'YES' ? 'YES' : 'NO' );

		setEnterpriseProfile(profileobject);
	}

	function ShowPreview( event )
	{
		event.preventDefault();
		event.stopPropagation();

		let fileobject;
		if(event.target.files && event.target.files[0])
		{
			fileobject = event.target.files[0];
		}
		else if(event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0])
		{
			fileobject = event.dataTransfer.files[0];
			event.currentTarget.className = '';
		}

		setLogo_fileObject(fileobject);

		let freader = new FileReader();
		try{
			freader.readAsDataURL(fileobject);

			freader.onload = () => {

				setLogoPreviewData(freader.result);
				setLogoPreviewContainer('');
			}
		}
		catch (e)
		{
			console.warn(e);
		}

	}

	async function uploadLogo( )
	{

		let getposturl = await api.getbrandinguploadurl( enterpriseprofile.companyid );

		let freader = new FileReader();
		try{
			freader.readAsArrayBuffer( brandinglogo_fileObject );

			freader.onload = async () => {

				let s3response = await api.uploadbrandinglogotos3(getposturl.posturl, freader.result);

				if(s3response.success === true)
				{

					let response = await api.confirmbrandingupload( { companyid : enterpriseprofile.companyid , uploadstatus : 'success' });
					if(response.success === true)
					{
						resetUploadModal();

						let profileobject = { ...enterpriseprofile };

						let now = Date.now();

						profileobject.branding_logo_path = `${response.branding_logo_path}?cachebust=${now}`;

						setEnterpriseProfile(profileobject);
					}
					else
					{
						console.warn(response);
					}
				}
				else
				{
					console.warn(s3response);
				}
			}
			freader.onerror = (e) => {
				console.warn(e);
			}
		}
		catch (e)
		{
			console.warn(e);
		}
	}

	async function draghandler( event )
	{
		event.preventDefault();
		event.stopPropagation();

		if( event.type === 'dragenter')
		{
			event.currentTarget.className = 'dropzone-hover';
		}
		else if( event.type === 'dragover')
		{
			event.currentTarget.className = 'dropzone-hover';
		}
		else if( event.type === 'dragleave')
		{
			event.currentTarget.className = '';
		}
	}

	function resetUploadModal()
	{
		setShowuploadlogomodal(false);
		setLogo_fileObject(false);
		setLogoPreviewData('');
		setLogoPreviewContainer('none');
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setEnterpriseProfile({});

		( async () => {
			await LoadProfile( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		if( ['admin:saveenterprise'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await SaveProfile();
			})();
		}
		else if( ['admin:newcompanyid'].indexOf(emitter.eventname) !== -1)
		{
			let newprofile = { ...enterpriseprofile };
			newprofile.companyid = emitter.data;
			setEnterpriseProfile(newprofile);

			( async () => {
				await SaveProfile( newprofile.companyid );
			})();
		}

	},[emitter.eventname]);
	React.useEffect( () => {

		if(showuploadlogomodal && modaldropzoneref)
		{
			modaldropzoneref.current.addEventListener('dragenter',draghandler);
			modaldropzoneref.current.addEventListener('dragleave',draghandler);
			modaldropzoneref.current.addEventListener('dragexit',draghandler);
			modaldropzoneref.current.addEventListener('dragover',draghandler);
			modaldropzoneref.current.addEventListener('drop',ShowPreview);

		}

	},[showuploadlogomodal])
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<Card className="p-4 d-inline-block">
			<Form ref={formref} autoComplete="off">
				<Row>
					<Col xs={11}>
						<Button variant="link" className="accordian-title" style={{minWidth:'150px',textAlign:'left'}} onClick={() => { setPanelShow( !panelshow )}}>
							{panelshow && enterpriseprofile.custom_branding_enabled === 'YES' && <ChevronDown />}
							{!panelshow && <ChevronRight />}
							&nbsp;Branding</Button>
					</Col>
					<Col xs={1} style={{textAlign:'right'}} className="pt-2">
						<Form.Check 
							type="switch" 
							style={{transform:"scale(1.3)"}} 
							checked={enterpriseprofile.custom_branding_enabled === 'YES'}
							id="custom_branding_enabled"
							name="custom_branding_enabled" 
							value="YES"
							onChange={handleFormBlur}
						/>				
					</Col>
				</Row>
				<Collapse in={panelshow && enterpriseprofile.custom_branding_enabled === 'YES'}>
					<Container>
						<Row className="feature-row" style={{paddingLeft:'35px',borderTop:'solid 1px #B1B1B1'}}>
							<Col xs={5}>
								<h6>Domain</h6>
								Where the clients portal will be found<br />
								Domain names must be unique
							</Col>
							<Col xs={7} style={{textAlign:'left',minHeight:'150px'}}>
								<InputGroup>
									<InputGroup.Text>https://</InputGroup.Text>
									<Form.Control 
										type="text"
										inline="true"
										name="branding_hostname"
										defaultValue={enterpriseprofile.branding_hostname}
										onChange={checkDomainAvailalable}
										onBlur={checkDomainAvailalable}
										isInvalid={subdomainerror}
									/>
									<InputGroup.Text>.shieldhub.com</InputGroup.Text>
								</InputGroup><br />
								<Alert variant="danger" show={subdomainerror}> <ExclamationTriangleFill /> Error: domain name not available. please select a different domain</Alert>
							</Col>
						</Row>
						<Row className="feature-row" style={{paddingLeft:'35px'}}>
							<Col xs={5}>
								<h6>Custom Store Page</h6>
								Enter the URL of the custom store page for this client
							</Col>
							<Col xs={7} style={{textAlign:'left',minHeight:'75px'}}>
								<Form.Control
									type="text"
									inline='true'
									name='custom_store_url'
									defaultValue={enterpriseprofile.custom_store_url}
									disabled={ (enterpriseprofile.branding_hostname === null || enterpriseprofile.branding_hostname === undefined || enterpriseprofile.branding_hostname.length === 0) }
									onChange={handleFormBlur}
									onBlur={handleFormBlur}
								/>
								<Alert variant="warning" show={ (enterpriseprofile.branding_hostname === null || enterpriseprofile.branding_hostname === undefined || enterpriseprofile.branding_hostname.length === 0) } className="mt-3" > <ExclamationTriangleFill /> Error: Custom store requires client portal</Alert>
							</Col>
						</Row>
						<Row className="feature-row" style={{paddingLeft:'35px'}}>
							<Col xs={5}>
								<h6>Business Name &amp; Location </h6>
								Show Business Name and Location in page footer
							</Col>
							<Col xs={7} style={{textAlign:'left',maxWidth:'100px',paddingLeft:'20px'}}>
								<Form.Check
									type="switch"
									style={{transform:"scale(1.3)"}}
									checked={enterpriseprofile?.business_footer === 'YES'}
									name="business_footer"
									value="YES"
									onChange={handleFormBlur}
								/>
							</Col>
						</Row>
						<Row className="feature-row" style={{paddingLeft:'35px'}}>
							<Col xs={5}>
								<h6>Theme</h6>
								Use this to adjust the look and feel of the client portal
							</Col>
							<Col xs={7} style={{textAlign:'left'}}>
								<Row>
									<Col xs={6}>
										<h6>Site Logo</h6>
										<div style={{border:'1px solid black',height:'80px',backgroundColor:'#2A2D40',cursor:'pointer'}} onClick={() => setShowuploadlogomodal(true) }>
											{enterpriseprofile.branding_logo_path && <img src={enterpriseprofile.branding_logo_path} alt="ShieldHub Logo" className="branding" style={{maxHeight:'80px'}}/>}
										</div>
									</Col>
									<Col xs={3} style={{textAlign:'center'}}>
										<h6>Primary Color</h6>
										<div onClick={() => setShowcolorpicker('primary') } style={{border:'1px solid black',borderRadius:'5px',overflow:'hidden',cursor:'pointer'}}>
											<div style={{width:'100%',height:'45px',backgroundColor:enterpriseprofile.primary_color}}>&nbsp;</div>
											<Form.Control 
												defaultValue={enterpriseprofile.primary_color} 
												name="primary_color"
												type="text" 
												size="sm"
												onChange={(event) => handleColorSwatchChange(event, 'primary_color') }
											/>
										</div>
										{showcolorpicker === 'primary' && 
											<>
												<div style={{position:'absolute',zIndex:'1000'}}>
													<ChromePicker 
														color={enterpriseprofile.primary_color}
														onChangeComplete={(color) => {
															handleColorSwatchChange({ currentTarget : { value : color.hex }},'primary_color');
														}}
													/>
												</div>
												<div style={{position:'fixed',top:'0px',left:'0px',bottom:'0px',right:'0px'}} onClick={() => setShowcolorpicker(false) } />
											</>}
									</Col>
									{/*}
									<Col xs={3} style={{textAlign:'center'}}>
										<h6>Text Color</h6>
										<div onClick={() => setShowcolorpicker('accent')} style={{border:'1px solid black',borderRadius:'5px',overflow:'hidden',cursor:'pointer'}}>
											<div style={{width:'100%',height:'45px',backgroundColor:enterpriseprofile.primary_button_text}}>&nbsp;</div>
											<Form.Control 
												defaultValue={enterpriseprofile.primary_button_text} 
												name="primary_button_text"
												type="text" 
												size="sm"
												onChange={(event) => handleColorSwatchChange(event, 'primary_button_text') }
											/>
										</div>
										{showcolorpicker === 'accent' &&
											<>
												<div style={{position:'absolute',zIndex:'1000',right:'5px'}}>
													<ChromePicker 
														color={enterpriseprofile.primary_button_text} 
														onChangeComplete={(color) => {
															handleColorSwatchChange({ currentTarget : { value : color.hex }},'primary_button_text');
														}}
													/>
												</div>
												<div style={{position:'fixed',top:'0px',left:'0px',bottom:'0px',right:'0px'}} onClick={() => setShowcolorpicker(false) } />
											</>}
									</Col>
									*/}
								</Row>
							</Col>
						</Row>
						<Row className="feature-row" style={{paddingLeft:'35px'}}>
							<Col xs={5}>
								<h6>Powered by ShieldHub Branding</h6>
								Include ShieldHub branding in the footer
							</Col>
							<Col xs={7} style={{textAlign:'left',maxWidth:'100px',paddingLeft:'20px'}}>
								<Form.Check
									type="switch"
									style={{transform:"scale(1.3)"}}
									checked={enterpriseprofile.shieldhub_branding === 'YES'}
									name="shieldhub_branding"
									value="YES"
									onChange={handleFormBlur}
								/>
							</Col>
						</Row>
					</Container>
				</Collapse>
				<Modal show={showuploadlogomodal} id="enterpriseuploadmodal" centered onHide={resetUploadModal}>
					<Modal.Header closeButton>Upload new site logo</Modal.Header>
					<Modal.Body>
						<input ref={uploadInputRef} type="file" name="brandinglogoupload" style={{display:"none"}} onChange={ShowPreview} />
						<div 
							ref={modaldropzoneref}
							id="dropzone"
							style={{textAlign:'center'}}
						>
							<Button onClick={() => { uploadInputRef.current.click(); }}>Click here to upload</Button>
						</div>
						<div style={{border:'1px solid black',height:'80px',backgroundColor:'#2A2D40',display:logoPreviewContainer,overflow:'hidden'}}>
							<img src={logopreviewData} style={{height:'80px'}}/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={resetUploadModal} >Nevermind</Button>
						<Button variant="primary" onClick={uploadLogo}>Upload New Logo</Button>
					</Modal.Footer>
				</Modal>
			</Form>
		</Card>
	);
}
export default Branding;