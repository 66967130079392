import * as React from "react";
import { Card, Collapse , Button, Container , Row , Col , Form , Alert } from "react-bootstrap";
import { ChevronDown, ChevronRight, ExclamationTriangleFill } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import { useRefreshEmitter } from "../../RefreshEmitter";
import api from '../../../io/admin';
import { format as dateFormat , parseJSON as dateParseJSON , parseISO as dateParseISO , isValid as dateValid} from "date-fns";
import { useBranding } from "../../Branding";


function ShieldID( props )
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const branding = useBranding();
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ shieldids , setShieldIDs ] = React.useState([]);
	const [ errormessage , setErrormessage ] = React.useState(false);
	const [ originalactivecandidateid , setOriginalactivecandidateid ] = React.useState(false); 

	async function LoadCurrentShieldIDs( userid )
	{
		let response = await api.getshieldids(userid);
		if(response.success)
		{
			response.shieldids = response.shieldids.map( (record) => {

				if(record.effective_date)
				{
					try {
						record.effective_date = dateParseJSON(record.effective_date);
					}
					catch (e){
						console.warn(e);
					}
				}
				if(record.last_update)
				{
					try {
						record.last_update = dateParseJSON(record.last_update);
					}
					catch (e){
						console.warn(e);
					}
				}
				if(record.expiration_date)
				{
					try {
						record.expiration_date = dateParseISO(record.expiration_date);
					}
					catch (e){
						console.warn(e);
					}
				}

				if(record.active === 'YES')
				{
					setOriginalactivecandidateid(record.candidateid);
				}

				return record;
			});


			setShieldIDs(response.shieldids);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setShieldIDs([
				{
					candidateid : '45',
					legalfirstname: 'John',
					legalmiddlename: 'Wilson',
					legallastname: 'Doe',
					shieldid: 'CA920571030',
					first_effective_dts: dateParseJSON('2024-06-09T13:05:06.000Z'),
					last_update: dateParseJSON('2024-06-09T13:05:06.000Z'),
					expiration_date: dateParseJSON('2023-01-01T00:00:00'),
					active: 'YES'
				},
				{
					candidateid : '46',
					legalfirstname: 'Jane',
					legalmiddlename: 'Quincy',
					legallastname: 'Doe',
					shieldid: 'CA920571030',
					first_effective_dts: dateParseJSON('2024-06-09T13:05:06.000Z'),
					last_update: dateParseJSON('2024-06-09T13:05:06.000Z'),
					expiration_date: dateParseJSON('2023-01-01T00:00:00'),
					active: 'NO'
				}
			]);

			setOriginalactivecandidateid('45');
		}
		else
		{
			console.error(response);
			setShieldIDs([]);
		}
	}

	async function handleFormBlur( candidateid )
	{
		let shieldidlist = [ ...shieldids ];

		shieldidlist = shieldidlist.map((shieldid) => {
			if(shieldid.candidateid === candidateid)
			{
				shieldid.active = shieldid.active === 'YES' ? 'NO' : 'YES';
			}
			return shieldid;
		});

		let active_count = shieldidlist.filter((shieldid) => shieldid.active === 'YES').length;

		let newactivecandidateid;
		shieldidlist.forEach((shieldid) => {
			if(shieldid.active === 'YES')
			{
				newactivecandidateid = shieldid.candidateid;
			}
		});

		if(active_count > 1)
		{
			setErrormessage('Error: Only one ShieldID can be active at a time.');
		}
		else if(originalactivecandidateid !== newactivecandidateid)
		{
			props.setFormDirtyFunction(true);

			setErrormessage(false);
		}
		else
		{
			props.setFormDirtyFunction(false);

			setErrormessage(false);
		}


		setShieldIDs(shieldidlist);
	}

	async function SaveProfile()
	{
		let userid = hash.replace(/#([0-9]+)/,'$1');
		
		let active_candidateid = null;
		let inactive_candidateids = [];

		for ( const record of shieldids )
		{
			if(record.active === 'YES')
			{
				active_candidateid = record.candidateid;
				break;
			}
		}

		for ( const record of shieldids )
		{
			if(record.active === 'NO' && record.candidateid !== active_candidateid)
			{
				inactive_candidateids.push(record.candidateid);
			}
		}

		let response = await api.updateshieldids({ userid , active_candidateid , inactive_candidateids });
		if(response.success)
		{
			props.setFormDirtyFunction(false);
		}
	}


	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setShieldIDs([]);

		( async () => {
			await LoadCurrentShieldIDs( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		if( ['admin:saveuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await SaveProfile();
			})();
		}		
		else if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadCurrentShieldIDs( userid );
			})();
		}
	},[emitter.eventname,hash]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
			&nbsp;ShieldID</Button>

			<Collapse in={panelshow}>
				<Container style={{textAlign:'center'}} id="admin-score-block">
					<Row style={{color:branding.PrimaryColor,textTransform:'uppercase'}}>
						<Col>Legal Name</Col>
						<Col>ShieldID</Col>
						<Col>Effective Date</Col>
						<Col>Last Update</Col>
						<Col>Expiration</Col>
						<Col>Enable/Disable</Col>
					</Row>
					{shieldids && shieldids.length > 0 && shieldids.map((shieldid) => {
						return (<Row className="py-3" key={shieldid.candidateid} style={{backgroundColor: shieldid.active === 'NO' ? '#EEEEEE' : null , color : shieldid.active === 'NO' ? '#AAAAAA' : null }}>
							<Col>{shieldid.legalfirstname} {shieldid.legalmiddlename} {shieldid.legallastname}</Col>
							<Col>{shieldid.shieldid}</Col>
							<Col>{shieldid.first_effective_dts && dateValid(shieldid.first_effective_dts) ? dateFormat(shieldid.first_effective_dts,'MM/dd/yyyy') : null }</Col>
							<Col>{shieldid.last_update && dateValid(shieldid.last_update) ? dateFormat(shieldid.last_update,'MM/dd/yyyy') : null}</Col>
							<Col>{shieldid.expiration_date && dateValid(shieldid.expiration_date) ? dateFormat(shieldid.expiration_date,'MM/dd/yyyy') : null }</Col>
							<Col>
								<Form.Check 
									type="switch" 
									style={{transform:"scale(1.3)"}} 
									checked={shieldid.active === 'YES'}
									name="active"
									value="YES"
									onChange={() => {
										handleFormBlur( shieldid.candidateid );
									}}
								/>						
							</Col>
						</Row>);
					})}
					<Alert className="mt-3" variant='danger' show={errormessage !== false}><ExclamationTriangleFill size={20} /> {errormessage}</Alert>
				</Container>
			</Collapse>
		</Card>
	);
}
export default ShieldID;