import { Button , Stack } from 'react-bootstrap';
import { GridFill, PersonSquare, ChevronRight, GearWideConnected } from 'react-bootstrap-icons';
import { useBranding } from '../Branding';
import { useLocation , Link } from 'react-router-dom';

function SideBar(props) {

	const branding = useBranding();
	const location = useLocation();

	const linkStyles = {
		color: "#8A92A6",
		textAlign:"left",
		textUnderline:"none",
		textDecorationLine : "none",
		fontSize:"14px"
	};

	const activeLinkStyle = {
		color: branding.PrimaryColor,
		textAlign:"left",
		textUnderline:"none",
		textDecorationLine : "none",
		fontSize:"14px"
	};

	const iconStyles = {
		marginTop:"-3px",
		marginRight:"10px"
	};

	const expandIconStyle = {
		marginTop:"3px",
		float:"right"
	};

	function handleClick()
	{
		if(typeof props.onClick === 'function')
		{
			//trigger click up the component tree to dismiss the left menu
			props.onClick();
		}
	}


	return (
		<Stack gap={2} className="p-3">
			<Button onClick={handleClick} variant="link" as={Link} style={ (location.pathname === '/admin' ? activeLinkStyle : linkStyles)} to="/admin"><GridFill style={iconStyles}/> Dashboard {(location.pathname === '/admin') && <ChevronRight style={expandIconStyle} size={18}/>}</Button>
			<Button onClick={handleClick} variant="link" as={Link} style={ (location.pathname === '/user/profile' ? activeLinkStyle : linkStyles)} to="/user/profile"><PersonSquare style={iconStyles} /> My Profile {(location.pathname === '/user/profile') && <ChevronRight style={expandIconStyle} size={18}/>} </Button>
			<Button onClick={handleClick} variant='link' as={Link} style={ (location.pathname.match(/\/admin\/(tools|user)/) ? activeLinkStyle : linkStyles)} to="/admin/tools"><GearWideConnected style={iconStyles} /> Admin Tools {(location.pathname.match(/\/admin\/(tools|user)/)) && <ChevronRight style={expandIconStyle} size={18}/>} </Button>
		</Stack>
	);

}

export default SideBar;