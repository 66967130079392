import * as React from "react";
import { Card , Form , Col , Row , Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useRefreshEmitter } from "../../RefreshEmitter";
import api from '../../../io/admin.js';
import { format as dateFormat } from 'date-fns';
import { ExclamationTriangleFill, PatchCheckFill } from "react-bootstrap-icons";

function AccountStatus( props )
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ userprofile , setUserProfile ] = React.useState({});
	const [ originalProfileActive , setOriginalProfileActive ] = React.useState(null);
	const [ deactivatewarning , setDeactivatewarning ] = React.useState(false);
	const [ activatewarning , setActivatewarning ] = React.useState(false);

	const formref = React.createRef();

	async function LoadProfile( userid )
	{
		let response = await api.getuser( userid );

		if(response.success === true)
		{
			setOriginalProfileActive( response.profile.active );

			setUserProfile({
				userid : response.profile.userid,
				active : response.profile.active,
				previous_expiration : response.profile.previous_expiration,
				previous_score : response.profile.previous_score,
			});
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setOriginalProfileActive( 'NO' );

			setUserProfile({
				userid : 1000,
				active : 'NO',
				previous_expiration : dateFormat(new Date(), 'MM/dd/yyyy'),
				previous_score : 'IC01+',
			});
		}
		else
		{
			console.warn(response);
			setUserProfile({});
		}
	}

	async function SaveProfile()
	{
		let newprofile = { ...userprofile };
		newprofile.active = formref.current.active.checked ? 'YES' : 'NO';

		newprofile.changeaccountstatus = 1;

		let response = await api.saveuser( newprofile );

		if(response.success === true)
		{
			props.setFormDirtyFunction(false);

			emitter.Emit('admin:refreshuser');

			await LoadProfile( newprofile.userid );
		}
		else
		{
			console.warn(response);
		}
	}

	async function handleFormBlur()
	{
		props.setFormDirtyFunction(true);

		let profileobject = { ...userprofile };

		let formData = new FormData(formref.current);

		profileobject.active = ( formData.get('active') === 'YES' ? 'YES' : 'NO' );

		if(profileobject.active === 'NO' && profileobject.active !== originalProfileActive )
		{
			setDeactivatewarning(true);
			setActivatewarning(false);
		}
		else if(profileobject.active === 'YES' && profileobject.active !== originalProfileActive )
		{
			setDeactivatewarning(false);
			setActivatewarning(true);
		}
		else if(profileobject.active === originalProfileActive)
		{
			setDeactivatewarning(false);
			setActivatewarning(false);
			props.setFormDirtyFunction(false);
		}

		setUserProfile(profileobject);
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setUserProfile({});
		props.setFormDirtyFunction(false);

		( async () => {
			await LoadProfile( userid );
		})();

	},[hash]);

	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');

		if( ['admin:saveuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await SaveProfile();
			})();
		}
		else if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadProfile( userid );
			})();
		}

	},[emitter.eventname,hash]);	
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<Card className="p-4 d-inline-block">
			<Form ref={formref} autoComplete="off">
				<Row>
					<Col xs={9} className="accordian-title ps-3">Account Enable/Disable</Col>
					<Col xs={3} className="text-center mt-1">
						<Form.Check 
							type="switch" 
							style={{transform:"scale(1.3)"}} 
							checked={userprofile.active === 'YES'} 
							name="active" 
							value="YES"
							onChange={handleFormBlur}
						/>
					</Col>
				</Row>
				<Alert variant='warning' className="mt-4" show={deactivatewarning}><ExclamationTriangleFill size={20} /> Warning: Deactivating this profile will prevent the user from logging in, and expire any active ShieldID&apos;s as of today.</Alert>
				<Alert variant='warning' transition={false} className="mt-4 text-center" show={ (deactivatewarning === false && activatewarning === false && userprofile.active === 'NO') }>Profile inactive, user cannot login, and ShieldID will not validate.</Alert>
				<Alert variant='info' className="mt-4" show={activatewarning} ><PatchCheckFill size={15} /> Reactiving user will restore Shield ID Expiration of <b>{userprofile.previous_expiration}</b> and ShieldScore of <b>{userprofile.previous_score}</b> </Alert>
			</Form>
		</Card>
	);
}
export default AccountStatus;