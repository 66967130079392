import * as React from "react";
import { Container , Row, Col , Image} from 'react-bootstrap';
import PoweredBy from '../assets/powered-by-shieldhub.svg';
import { useBranding } from './Branding';
import {MegaphoneFill } from 'react-bootstrap-icons';

function Footer() {

	const branding = useBranding();

	const styles = {

		footer : {
			color : '#8A8C96',
			position : 'relative',
			textAlign : 'center',
			top: '50%',
			bottom: '50%',
			fontSize : '10pt',
			clear : "both"
		},
		links : {
			color : '#8A8C96'
		}
	}

	React.useEffect(() => {

		if(window.Headway)
		{
			const HW_config = {
				selector: ".headway_icon", // CSS selector where to inject the badge
				account:  "yp9NQx"
			};
			
			window.Headway.init(HW_config);
		}

	},[]);

	return (
		<>
			<div style={{height:"25px",clear:"both"}}>&nbsp;</div>

			<div className="headway_icon"><MegaphoneFill/></div>

		
			
			<Container fluid style={styles.footer}>
				{branding.Company.business_footer !== 'NO' && <Row>
					<Col>
						<b>{branding?.Company && branding.Company.company_name}</b><br />
						{branding?.Company && branding.Company.company_phone}<br />
						{branding?.Company && branding.Company.company_address1} {branding?.Company && branding.Company.company_address2 && branding.Company.company_address2 !== '' && <>, {branding.Company.company_address2}</>}<br />
						{branding?.Company && branding.Company.company_city}{branding?.Company && branding.Company.company_city && <>, </>}{branding?.Company && branding.Company.company_state} {branding?.Company && branding.Company.company_postalcode}<br />
					</Col>
				</Row>}
				<Row style={{paddingTop:'20px'}}>
					<Col>
						{branding.Company.shieldhub_branding !== 'NO' && <><Image src={PoweredBy} height={40} /><br /></>}
						&copy; {(new Date().getFullYear())} ShieldHub Inc. All Rights Reserved
					</Col>
				</Row>
				<Row className="print-hide pb-3">
					<Col><a href="https://www.shieldhub.com/disclaimer" style={styles.links} target="_blank">General Disclaimer</a> | <a href="https://www.shieldhub.com/privacy-policy" style={styles.links} target="_blank">Privacy Policy</a> | <a href="https://www.shieldhub.com/terms/" style={styles.links} target="_blank">Terms &amp; Conditions</a> | <a href="https://www.shieldhub.com/cookie-policy/" style={styles.links} target="_blank">Cookie Policy</a> <br /> <a href="https://portal.shieldhub.com/paymentterms" style={styles.links} target="_blank">Purchase Terms &amp; Conditions</a> | <a href="https://www.shieldhub.com/dsar" style={styles.links} target="_blank">Limit the Use of My Personal Data (DSAR)</a></Col>
				</Row>
			</Container>
		</>
	);
}

export default Footer;