import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Stack , Button, Form, Alert , Row , Col , FloatingLabel } from 'react-bootstrap';
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import PasswordStrengthMeter from "../components/PasswordStrength";
import Logo from '../assets/shieldhub-logo.png';
import api from "../io/user";
import { useBranding } from "../components/Branding";


function RegisterVerification(){

	const [ errorfields , setErrorfields ] = React.useState({password:false,confirmpassword:false});
	const [ submitdisable , setSubmitdisable ] = React.useState(false);
	const [ passwordMeterValue , setPasswordMeterValue ] = React.useState(false);
	const [ errorbanner, setErrorbanner ] = React.useState(false);
	const [ errormessage , setErrormessage ] = React.useState(false);
	const formRef = React.createRef(null);
	const navigate = useNavigate();
	const branding = useBranding();

	function handlePasswordKeyEvent( event ){

		var password = event.currentTarget.value;

		setPasswordMeterValue(password);

	}

	async function handleFieldChange( event , field ){

		let fields = { ...errorfields };

		if(event?.currentTarget?.value?.length > 0)
		{
			fields[field] = false;
		}

		let formData = new FormData(formRef.current);

		let password = formData.get('password');
		let confirmpassword = formData.get('confirmpassword');

		if(field === 'password' && password !== confirmpassword)
		{
			fields.password = true;
			fields.confirmpassword = true;
		}
		else
		{
			fields.password = false;
			fields.confirmpassword = false;
		}
		setErrorfields(fields);
	}

	async function handleSubmitNewPassword(event){

		if(event)
		{
			event.preventDefault();
		}
		let fields = { ...errorfields };

		let errorbanner = false;

		let formData = new FormData(formRef.current);

		let password = formData.get('password');
		let confirmpassword = formData.get('confirmpassword');

		if(password !== confirmpassword)
		{
			fields.password = true;
			fields.confirmpassword = true;
			errorbanner = true;
		}
		else
		{
			fields.password = false;
			fields.confirmpassword = false;
		}

		setErrorbanner(errorbanner);
		setErrorfields(fields);

		if(errorbanner === false)
		{
			setSubmitdisable(true);

			let response = await api.forgotnewpassword(password);

			if(response.success)
			{
				navigate("/logout?newpassword");
			}

			setSubmitdisable(false);
		}
		else
		{
			setErrormessage("Error: Please fill in the highlighted required fields.");
		}

	}

	return (
		<div className="login-position container">
			<Stack gap={2}>
				<Row className="justify-content-center login-portal">
					<Col xs="auto" style={{height:'90px'}}>
						{process.env.REACT_APP_LOCALDEBUG ? <img src={Logo} alt="ShieldHub" className="branding"/> : <img src={branding.Company.branding_logo_path} alt="ShieldHub" className="branding"/>}
					</Col>
				</Row>			
				<Row className="justify-content-center login-portal">
					<Col xs={12} sm={10} md={8} lg={6}>
						<Form onSubmit={handleSubmitNewPassword} autoComplete="off" ref={formRef} className="login-container">
							<Row>
								<h2>Update Password</h2>
								<h6>Choose a strong password that you&apos;ve never used on this account</h6>
							</Row>
							<Row className="justify-content-md-center login-portal" style={{marginTop:"20px",clear:"both"}}>
								<Col xs={{ span: 10, offset: 1 }} md={{ span: 10, offset: 0 }}>
									<FloatingLabel controlId="password" label="PASSWORD">
										<Form.Control 
											type="password" 
											placeholder="PASSWORD" 
											name="password" 
											isInvalid={errorfields.password}
											onKeyUp={handlePasswordKeyEvent}
											onChange={(event) => {
												handleFieldChange(event,"password")
											}}
										/>
									</FloatingLabel>
									<PasswordStrengthMeter password={passwordMeterValue}/>
								</Col>
							</Row>
							<Row className="justify-content-center login-portal" style={{marginTop:"20px",clear:"both"}}>
								<Col xs={{ span: 10, offset: 0 }}>
									<FloatingLabel controlId="confirmpassword" label="CONFIRM PASSWORD">
										<Form.Control
											type="password"
											placeholder="CONFIRM PASSWORD"
											name="confirmpassword"
											isInvalid={errorfields.confirmpassword}
											onChange={(event) => {
												handleFieldChange(event,"confirmpassword")
											}}
										/>
									</FloatingLabel>
								</Col>
							</Row>
							<Row>
								<Col xs={{ span: 10, offset: 1 }} style={{marginTop:"20px"}}>
									<Button  type="submit" disabled={submitdisable} className="login-button">Update Password</Button>
								</Col>
							</Row>
							<Alert variant="danger" show={errorbanner}> <ExclamationTriangleFill size={20} />{errormessage}</Alert>
						</Form>
					</Col>
				</Row>
			</Stack>
		</div>	);

}

export default RegisterVerification;