import * as React from "react";
import { Card , Button , Collapse , Form , Col , Row } from "react-bootstrap";
import { CheckCircleFill, ChevronDown, ChevronRight, QuestionCircleFill, XCircleFill } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import { useRefreshEmitter } from "../../RefreshEmitter";
import { format as dateFormat , isValid as dateValid , parseJSON as dateParse } from 'date-fns';
import api from '../../../io/admin.js';


function LegacyLinking( props )
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ userprofile , setUserProfile ] = React.useState({});
	const [ accountlist , setAccountList ] = React.useState([]);
	const [ irecordcheckinprogress , setIrecordcheckinprogress ] = React.useState(false);

	const formref = React.createRef();

	async function LoadAccountList()
	{
		let response = await api.getlegacyaccountlist();

		if(response.success === true)
		{
			setAccountList(response.accountlist);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setAccountList([
				{
					craaccountid : 'ASPN_00057',
					doingbusinessasname : 'Carlson Field Services, Inc'
				}
			]);
		}

	}

	async function LoadProfile( userid )
	{
		let response = await api.getuser( userid );

		if(response.success === true)
		{

			if(response.profile.legacy_processed_date)
			{
				try{
					response.profile.legacy_processed_date = dateParse(response.profile.legacy_processed_date);
				}
				catch(e)
				{
					response.profile.legacy_processed_date = null;
				}
			}
			if(response.profile.legacy_account_verified && response.profile.legacy_account_verified !== 'NO')
			{
				try{
					response.profile.legacy_account_verified = dateParse(response.profile.legacy_account_verified);
				}
				catch(e)
				{
					response.profile.legacy_account_verified = null;
				}
			}


			setUserProfile({
				userid : response.profile.userid,
				legacy_account_link : ( response.profile.legacy_account_link ? response.profile.legacy_account_link : '' ),
				legacy_account_verified : ( response.profile.legacy_account_verified ? response.profile.legacy_account_verified : null ),
				legacy_processed_date : ( response.profile.legacy_processed_date ? response.profile.legacy_processed_date : null )
			});
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setUserProfile({
				userid : 1000,
				legacy_account_link : 'ASPN_00057',
				legacy_account_verified : dateParse('2024-06-01T14:00:00.0Z'),
				legacy_processed_date : dateParse('2024-06-10T14:00:00.0Z')
			});
		}
		else
		{
			console.warn(response);
			setUserProfile({});
		}

	}

	function handleAccountSelectChange( event )
	{
		let newprofile = { ...userprofile };

		newprofile.legacy_account_link = event.currentTarget.value;

		setUserProfile(newprofile);
		props.setFormDirtyFunction(true);
	}

	async function SaveProfile()
	{
		let response = await api.saveuser( userprofile );

		if(response.success === true)
		{
			console.log('Profile Saved');
		}
		else
		{
			console.warn(response);
		}
	}

	async function RecheckiRecord( userid )
	{
		setIrecordcheckinprogress(true);

		let response = await api.checkirecord( userid );
		if(response.success)
		{
			await LoadProfile( userid );
			setIrecordcheckinprogress(false);
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setUserProfile({});
		props.setFormDirtyFunction(false);

		( async () => {
			await LoadAccountList();
			await LoadProfile( userid );
		})();

	},[hash]);

	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		if( ['admin:saveuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await SaveProfile();
			})();
		}
		else if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadProfile( userid );
			})();
		}
	},[emitter.eventname,hash]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;Legacy System Linking</Button>
			<Collapse in={panelshow}>
				<Form ref={formref} autoComplete="off">
					<Row className="p-3">
						<Col sm={6} className="mb-2">
							<p>Select Account to link this profile to the Legacy System</p>
						</Col>
						<Col sm={6} className="mb-2">
							<Form.Select 
								aria-label="Default select example"
								value={userprofile.legacy_account_link}
								onChange={handleAccountSelectChange}
							>
								<option value="">No Legacy Account</option>
								{accountlist.map( (account) => {
									return <option key={account.craaccountid} value={account.craaccountid}>{`${account.craaccountid} | ${account.doingbusinessasname}`}</option>;
								})
								}

							</Form.Select>
						</Col>
					</Row>
					<Row className="p-3">
						<Col xs={5} className="mb-2">
							<h6>iRecord</h6>
							<p>Validate this record exists in iRecord</p>
						</Col>
						<Col xs={1} className="mt-4" style={{width:'50px'}}>
							{ !(userprofile.legacy_account_verified) && <QuestionCircleFill size={30} color="grey"/> }
							{ userprofile.legacy_account_verified === 'NO' && <XCircleFill size={30} color="#D32F2F"/> }
							{ userprofile.legacy_account_verified && dateValid(userprofile.legacy_account_verified) && <CheckCircleFill size={30} color="green"/> }
						</Col>
						<Col xs={2} className="mt-3 text-center" >
							{ userprofile.legacy_account_verified && dateValid(userprofile.legacy_account_verified) && <>Last Verified<br />by Aspen<br />{dateFormat(userprofile.legacy_account_verified,"MM/dd/yyyy")}</> }
							{ userprofile.legacy_account_verified === 'NO' && <>Does Not Exist<br />in Aspen</> }
							{ !(userprofile.legacy_account_verified) && <>Not Verified<br />by Aspen</> }
						</Col>
						<Col xs={2} className="mt-3 text-center" >
							{userprofile.legacy_processed_date && dateValid(userprofile.legacy_processed_date) && <>Last Processed<br />by ShieldHub<br />{dateFormat(userprofile.legacy_processed_date,"MM/dd/yyyy")}</>}
							{ ( !(userprofile.legacy_processed_date) || !dateValid(userprofile.legacy_processed_date) ) && <>Not Processed<br />by ShieldHub</>}
						</Col>
						<Col xs={2} className="mt-3 text-center">
							<Button disabled={irecordcheckinprogress} onClick={ async () => {
								await RecheckiRecord(userprofile.userid);
							}}>{ irecordcheckinprogress ? 'Processing...' : 'Check Now' }</Button>
						</Col>
					</Row>
				</Form>
			</Collapse>
		</Card>
	)
}
export default LegacyLinking;