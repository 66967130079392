import * as React from "react";
import { Card, Collapse , Button, Form , Row, Col } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";
import api from '../../../io/admin';
import { useLocation } from "react-router-dom";
import { useRefreshEmitter } from "../../RefreshEmitter";




function PrivacyAndPersonal( props )
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ userprofile , setUserProfile ] = React.useState({});
	const [ panelshow , setPanelShow ] = React.useState(true);

	const formref = React.createRef();

	async function LoadProfile( userid )
	{
		let response = await api.getuser( userid );

		if(response.success === true)
		{
			setUserProfile(response.profile);
		}
		else
		{
			setUserProfile({
				userid : 1000,
				last_auth_dts : new Date(),
				last_auth_ip : '127.0.0.1',
				register_dts : new Date(),
				last_auth_useragent : 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_2) AppleWebKit/601.3.9 (KHTML, like Gecko) Version/9.0.2 Safari/601.3.9'
			});
		}
	}

	async function handleFormBlur()
	{
		props.setFormDirtyFunction(true);

		let profileobject = { ...userprofile };

		let formData = new FormData(formref.current);

		profileobject.network_sharing_master = ( formData.get('network_sharing_master') === 'YES' ? 'YES' : 'NO' );
		profileobject.network_autoacceptinvite = ( formData.get('network_autoacceptinvite') === 'YES' ? 'YES' : 'NO' );
		profileobject.network_searchresults = ( formData.get('network_searchresults') === 'YES' ? 'YES' : 'NO' );

		setUserProfile(profileobject);

	}

	async function SaveProfile()
	{
		let profileobject = {
			userid : userprofile.userid,
		};

		let formData = new FormData(formref.current);

		profileobject.network_sharing_master = formData.get('network_sharing_master');
		if(profileobject.network_sharing_master !== 'YES')
		{
			profileobject.network_sharing_master = 'NO';
		}
		profileobject.network_autoacceptinvite = formData.get('network_autoacceptinvite');
		if(profileobject.network_autoacceptinvite !== 'YES')
		{
			profileobject.network_autoacceptinvite = 'NO';
		}
		profileobject.network_searchresults = formData.get('network_searchresults');
		if(profileobject.network_searchresults !== 'YES')
		{
			profileobject.network_searchresults = 'NO';
		}
		
		let response = await api.saveuser( profileobject );

		if(response.success === true)
		{
			props.setFormDirtyFunction(false);
		}

	}
	
	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setUserProfile({});
		props.setFormDirtyFunction(false);

		( async () => {
			await LoadProfile( userid );
		})();

	},[hash]);

	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		if( ['admin:saveuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await SaveProfile();
			})();
		}
		else if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadProfile( userid );
			})();
		}
	},[emitter.eventname,hash]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;Privacy &amp; Personal Data</Button>


			<Collapse in={panelshow}>
				<Form ref={formref}>
					<Row className="feature-row">
						<Col xs={8} sm={8}>
							<h6>ShieldID<sup>TM</sup> Network Master Switch</h6>
							<p>Enable data sharing across your trusted network.  Disabling sharing here will disable sharing for your network. This feature is enabled by default.</p>
						</Col>
						<Col xs={{span:1, offset:2}}>
							<Form.Check 
								type="switch" 
								style={{transform:"scale(1.3)"}} 
								checked={userprofile.network_sharing_master !== 'NO'}
								name="network_sharing_master" 
								value="YES"
								onChange={handleFormBlur}
							/>
						</Col>
					</Row>
					<Row className="feature-row">
						<Col xs={8}>
							<h6>Auto Accept Network Invites</h6>
							<p>Automatically accept network invites.  Please be aware that automatically enabling this feature may allow you to share scores with unintended users.</p>
						</Col>
						<Col xs={{span:1, offset:2}}>
							<Form.Check 
								type="switch" 
								style={{transform:"scale(1.3)"}} 
								checked={userprofile.network_autoacceptinvite === 'YES'} 
								name="network_autoacceptinvite" 
								value="YES"
								onChange={handleFormBlur}
							/>
						</Col>
					</Row>
					<Row className="feature-row">
						<Col xs={8}>
							<h6>ShieldID<sup>TM</sup> (ABC#) Search Results</h6>
							<p>Automatically share your ShieldScore (IC# )results with users searching for your ShieldID (ABC#). Disabling this feature will require searches to request consent before your score is shared. This may likely cause delays for users seeking to verify your ShieldScore. This feature is enabled by default.</p>
						</Col>
						<Col xs={{span:1, offset:2}}>
							<Form.Check 
								type="switch" 
								style={{transform:"scale(1.3)"}} 
								checked={userprofile.network_searchresults !== 'NO'}
								name="network_searchresults" 
								value="YES"
								onChange={handleFormBlur}
							/>
						</Col>
					</Row>
					
				</Form>
			</Collapse>
		</Card>

	);

}
export default PrivacyAndPersonal;