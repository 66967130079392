import * as React from "react";
import {Button, Col, Form, Row, Table, Card, Alert } from "react-bootstrap";
import ClearStarLogo from "../../assets/cra_logos/clearstar.png"
import { ExclamationTriangleFill, ShieldFill } from "react-bootstrap-icons";
import { useBranding } from "../Branding";
import api from '../../io/profile';


function CRAPackages(){

	const branding = useBranding();
	const [ packagelist , setPackageList ] = React.useState([]);
	const [ selectedpackage , setSelectedPackage ] = React.useState(false);
	const [ error , setError ] = React.useState(false);

	const package_row_class = {
		cursor : 'pointer'
	};

	async function getCRAPackages()
	{

		let response = await api.listpackages();
		if(response.success)
		{
			setPackageList(response.packages);
		}
	}

	function handlePackageSelection()
	{
		if(selectedpackage === false || selectedpackage === '')
		{
			setError(true);
		}
		else
		{
			window.location.hash = `buybackgroundcheck=${selectedpackage}`;
		}
	}

	React.useEffect(() => {

		( async () => {
			await getCRAPackages();
		})();

	},[]);

	return (
		<Card className="p-4">
			<h4>Purchase Background Check</h4>
			<p className="small">Purchase a Criminal Background Check package from the Consumer Reporting Partner below:</p>
			<Row>
				<Col md={4}>
					<div style={{textAlign: "Center"}}><img src={ClearStarLogo} alt="ClearStar CRA" className="mb-2 mt-4" style={{display: "inline-block"}}/></div>
					<p className="small" >ClearStar is a leading provider of Human Capital Integrity technology-based services specializing in background and medical screening.</p>
				</Col>
				<Col md={8}>
					<Form>
						<Table className="cra-table" hover={true}>
							<tbody>
								{packagelist.map( (pkg) => {

									pkg.base_price = parseInt(Math.round(pkg.base_price*100)/100);

									return (<tr key={pkg.crapackageid} style={package_row_class} onClick={() => {
										setError(false);
										setSelectedPackage(pkg.crapackageid);
									}} className={( selectedpackage === pkg.crapackageid ? 'selected_cra_package' : '')}>
										<td><Form.Check type="radio" name="cra_package" value={pkg.crapackageid} checked={selectedpackage === pkg.crapackageid} onChange={() => {}}/></td>
										<td>{pkg.package_descrip}</td>
										<td style={{fontWeight:"bold"}}>{pkg.renewalfrequencyinyears} Year</td>
										<td style={{fontSize:"30px",color:branding.PrimaryColor}}><b>${pkg.base_price}</b></td>
									</tr>)
								})}
							</tbody>
						</Table>
					</Form>
				</Col>
			</Row>
			{error && <Alert variant="danger"><ExclamationTriangleFill/> Error: Please select a package to continue</Alert>}
			<Card.Link>
				<Button className="btn-primary float-end" onClick={handlePackageSelection}><ShieldFill style={{ marginTop:"-3px"}} /> Purchase Background Check</Button>
			</Card.Link>	
		</Card>
	);
}

export default CRAPackages;