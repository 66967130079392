import * as React from "react";
import { Card , Form , Stack , Row , Col , Button, ListGroup } from "react-bootstrap";
import qrcode from 'qrcode';
import { Icon1Circle, Icon2Circle, Icon3Circle } from "react-bootstrap-icons";
import { Link } from "react-router-dom";


function IDVerifyPrep(props)
{
	const [ mobilelink , setMobilelink ] = React.useState(null);

	React.useEffect(() => {

		if (!props.isMobile)
		{
			qrcode.toDataURL('https://www.shieldhublabs.com/mobileid/12345',{ errorCorrectionLevel: 'H' } , (err,data) => {
				setMobilelink(data);
			});
		}

	},[props.isMobile]);


	return (
		<Form>
			<Stack>
				<Row className="justify-content-center">
					<Col xs={12} md={props.isMobile ? 12 : 4}>
						<h1>Verify your identity</h1>
						<p>This should only take a few minutes</p>
						<p>You will need:</p>
						<ListGroup variant="flush">
							<ListGroup.Item><Icon1Circle size={18} /> A valid government-issued photo ID</ListGroup.Item>
							<ListGroup.Item><Icon2Circle size={18} /> A webcam (if on a desktop) or </ListGroup.Item>
							<ListGroup.Item><Icon3Circle size={18} /> A mobile device with a camera</ListGroup.Item>
						</ListGroup>
					</Col>
					{props.isMobile === false && 
						<Col xs={12} md={4}>
							<Card>
								<Card.Body className="text-center">
									<p>Continue this workflow on your mobile device</p>
									<img src={mobilelink} />
								</Card.Body>
							</Card>
						</Col>
					}
				</Row>
				<Row className="justify-content-center pt-5">
					<Col xs={8}>
						<Button as={Link} to='/user/idverify/choosedocument' style={{width:'100%'}}>I&apos;m ready</Button>
					</Col>
				</Row>
			</Stack>
		</Form>

	);

}
export default IDVerifyPrep;