import * as React from "react";
import { Card , Row , Col , Button } from "react-bootstrap";


function HelpMeChoose()
{
	return (
		<Card>
			<Card.Body>
				<h2>New York Residency</h2>
				<p>Have you lived in New York in the last 10 years?</p>
				<Row>
					<Col xs={5}>
						<Button variant="outline-primary" href='/user/purchase/selectpackage?pkgfilter=US' style={{width:'100%'}}>No</Button>
					</Col>
					<Col xs={{offset:2,span:5}}>
						<Button variant="primary" href='/user/purchase/selectpackage?pkgfilter=NY' style={{width:'100%'}}>Yes</Button>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
}
export default HelpMeChoose;