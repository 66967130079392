import * as React from "react";
import { Button, Card, Collapse, Form , Container , Row , Col, FloatingLabel, Alert } from "react-bootstrap";
import { ChevronDown, ChevronRight, PersonFillExclamation } from "react-bootstrap-icons";
import statelist from '../../../io/states';
import api from '../../../io/admin-enterprise';
import { useLocation } from "react-router-dom";
import { useRefreshEmitter } from "../../RefreshEmitter";


function AccountDetails( props ) {

	const [ panelshow , setPanelShow ] = React.useState(true);
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ enterpriseprofile , setEnterpriseProfile ] = React.useState({});

	const formref = React.createRef();

	async function LoadProfile( userid )
	{
		let response = await api.getenterpriseprofile( userid );
		if(response.success === true)
		{
			if(response.profile.accounting_phone && response.profile.accounting_phone.length > 0)
			{
				response.profile.accounting_phone = response.profile.accounting_phone.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/,'$1-$2-$3');
			}
			if(response.profile.company_phone && response.profile.company_phone.length > 0)
			{
				response.profile.company_phone = response.profile.company_phone.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/,'$1-$2-$3');
			}

			if(typeof props.setEnterpriseFlagFunction === 'function')
			{
				props.setEnterpriseFlagFunction(response.profile.enterprise_enabled === 'YES' ? true : false);
			}

			response.profile.userid = userid;

			setEnterpriseProfile(response.profile);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			if(typeof props.setEnterpriseFlagFunction === 'function')
			{
				props.setEnterpriseFlagFunction(true);
			}

			setEnterpriseProfile({
				enterprise_enabled : 'YES',
				contact_firstname : 'John',
				contact_lastname : 'Doe',
				accounting_phone : '314-831-0000',
				accounting_email : 'john.doe@example.com',
				company_name : 'Example Company',
				legal_business_name : 'Example Company, LLC',
				company_address1 : '112233 Main St',
				company_address2 : 'Suite 1',
				company_city : 'Anytown',
				company_state : 'CA',
				company_postalcode : '12345',
				company_phone : '314-837-0000',
				tax_id : '12-3456789',
				crm_id : 'e8c8f4e2-791a-47a8-8838-8a8534a9ee5c',
				accounting_id : '987654321',
				quicksight_dashboard_enabled : 'NO',
				alert_dashboard_enabled : 'NO',
			});
		}
		else
		{
			console.warn(response);
			setEnterpriseProfile({});
		}
	}

	async function SaveProfile( )
	{

		let profileobject = {
			companyid : ( enterpriseprofile.companyid ? enterpriseprofile.companyid : null ),
			userid : enterpriseprofile.userid,
		};

		let formData = new FormData(formref.current);

		profileobject.enterprise_enabled = formData.get('enterprise_enabled');
		profileobject.contact_firstname = formData.get('contact_firstname');
		profileobject.contact_lastname = formData.get('contact_lastname');
		profileobject.accounting_phone = formData.get('accounting_phone');
		profileobject.accounting_email = formData.get('accounting_email');
		profileobject.company_name = formData.get('company_name');
		profileobject.legal_business_name = formData.get('legal_business_name');
		profileobject.company_address1 = formData.get('company_address1');
		profileobject.company_address2 = formData.get('company_address2');
		profileobject.company_city = formData.get('company_city');
		profileobject.company_state = formData.get('company_state');
		profileobject.company_postalcode = formData.get('company_postalcode');
		profileobject.company_phone = formData.get('company_phone');
		profileobject.tax_id = formData.get('tax_id');
		profileobject.crm_id = formData.get('crm_id');
		profileobject.accounting_id = formData.get('accounting_id');

		if(profileobject.accounting_phone && profileobject.accounting_phone.length > 0)
		{
			profileobject.accounting_phone = profileobject.accounting_phone.replace(/[^0-9]/g, '');
		}
		if(profileobject.company_phone && profileobject.company_phone.length > 0)
		{
			profileobject.company_phone = profileobject.company_phone.replace(/[^0-9]/g, '');
		}
		profileobject.quicksight_dashboard_enabled = ( formData.get('quicksight_dashboard_enabled') === 'YES' ? 'YES' : 'NO' );
		profileobject.alert_dashboard_enabled = ( formData.get('alert_dashboard_enabled') === 'YES' ? 'YES' : 'NO' );

		let response = await api.saveenterpriseprofile( profileobject );
		if(response.success === true)
		{
			if(profileobject.companyid !== response.profile.companyid)
			{
				let newcompanyid = {...enterpriseprofile};
				newcompanyid.companyid = response.profile.companyid;
				setEnterpriseProfile(newcompanyid);

				emitter.Emit('admin:newcompanyid',response.profile.companyid);
			}
			else
			{
				// If existing company id just trigger the save
				//chaining save events to main account save to prevent race condition
				emitter.Emit('admin:saveenterprise');
			}

		}
		else
		{
			console.warn(response);
		}

	}

	function handleStateSelectChange( event )
	{
		let newprofile = { ...enterpriseprofile };

		newprofile.company_state = event.currentTarget.value;

		setEnterpriseProfile(newprofile);

	}

	async function handleFormBlur( event )
	{
		if(event?.currentTarget?.name === 'enterprise_enabled')
		{

			let profileobject = { ...enterpriseprofile };

			let formData = new FormData(formref.current);

			profileobject.enterprise_enabled = ( formData.get('enterprise_enabled') === 'YES' ? 'YES' : 'NO' );

			if(typeof props.setEnterpriseFlagFunction === 'function')
			{
				props.setEnterpriseFlagFunction(profileobject.enterprise_enabled === 'YES' ? true : false);
			}

			setEnterpriseProfile(profileobject);
		}
		else if(event?.currentTarget?.name === 'quicksight_dashboard_enabled')
		{
			let profileobject = { ...enterpriseprofile };

			profileobject.quicksight_dashboard_enabled = ( event?.currentTarget.checked ? 'YES' : 'NO' ) ;

			setEnterpriseProfile(profileobject);
		}
		else if(event?.currentTarget?.name === 'alert_dashboard_enabled')
		{
			let profileobject = { ...enterpriseprofile };

			profileobject.alert_dashboard_enabled = ( event?.currentTarget.checked ? 'YES' : 'NO' ) ;

			setEnterpriseProfile(profileobject);
		}

		props.setFormDirtyFunction(true);
	}

	function handlePhoneFormat( event , fieldname )
	{
		let validControlKeyCodes = [8,9,37,39,46];

		let newprofile = { ...enterpriseprofile };

		if(newprofile[fieldname] === undefined || newprofile[fieldname] === null)
		{
			newprofile[fieldname] = '';
		}

		if(validControlKeyCodes.indexOf(event.keyCode) !== -1)
		{
			if(event.keyCode === 8 && newprofile[fieldname] && newprofile[fieldname].length > 0)
			{
				newprofile[fieldname] = newprofile[fieldname].substring(0,newprofile[fieldname].length-1);

				props.setFormDirtyFunction(true);
				setEnterpriseProfile(newprofile);
			}

			//control keys like Tab, backspace or arrows
			return;
		}
		else if(!event.key.match(/[0-9]/))
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		newprofile[fieldname] += event.key;

		if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{3}$/))
		{
			newprofile[fieldname] += "-";
		}
		else if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{3}-[0-9]{3}$/))
		{
			newprofile[fieldname] += "-";
		}
		else if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/))
		{

		}
		else if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{1,3}-?[0-9]{0,3}-?[0-9]{0,4}$/))
		{

		}
		else
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		props.setFormDirtyFunction(true);
		setEnterpriseProfile(newprofile);		
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setEnterpriseProfile({});
		props.setFormDirtyFunction(false);

		( async () => {
			await LoadProfile( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		if( ['admin:saveuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await SaveProfile();
			})();
		}

	},[emitter.eventname]);		
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow )}}>
				{panelshow && <ChevronDown />}
				{!panelshow && <ChevronRight />}
				&nbsp;Account Details</Button>
			<Collapse in={panelshow}>
				<Form ref={formref} autoComplete="off">
					<Container>
						<Row className="feature-row" style={{paddingLeft:'35px',borderTop:'solid 1px #B1B1B1'}}>
							<Col xs={5}>
								<h6>Enterprise Enabled</h6>
							</Col>
							<Col xs={7} style={{textAlign:'left',maxWidth:'100px',paddingLeft:'20px'}}>
								<Form.Check 
									type="switch" 
									style={{transform:"scale(1.3)"}}
									id="enterprise_enabled"
									name="enterprise_enabled"
									label=""
									checked={enterpriseprofile.enterprise_enabled === 'YES'}
									value='YES'
									onChange={handleFormBlur}
									disabled={enterpriseprofile.enterprise_enabled === 'BLOCKED'}
								/>
							</Col>
							<Alert show={enterpriseprofile.enterprise_enabled === 'BLOCKED'} variant="warning"><PersonFillExclamation size={25} /> User is a team member of {enterpriseprofile?.company_name} Team. Remove user from {enterpriseprofile?.company_name} Team before enabling this enterprise profile </Alert>
						</Row>
						{enterpriseprofile.enterprise_enabled === 'YES' && <>
							<Row className="feature-row" style={{paddingLeft:'35px'}}>
								<Col xs={5}>
									<h6>Accounting Information</h6>
								</Col>
								<Col xs={7} style={{textAlign:'left'}}>
									<Row className="p-1">
										<Col>
											<FloatingLabel controlId="contact_firstname" label="CONTACT FIRST NAME">
												<Form.Control 
													type="text" 
													placeholder="CONTACT FIRST NAME" 
													name="contact_firstname"
													defaultValue={enterpriseprofile.contact_firstname}
													onChange={handleFormBlur}
												/>
											</FloatingLabel>
										</Col>
										<Col>
											<FloatingLabel controlId="contact_lastname" label="CONTACT LAST NAME">
												<Form.Control
													type="text"
													placeholder="CONTACT LAST NAME"
													name="contact_lastname"
													defaultValue={enterpriseprofile.contact_lastname}
													onChange={handleFormBlur}
												/>
											</FloatingLabel>
										</Col>
									</Row>
									<Row className="p-1">
										<Col>
											<FloatingLabel controlId="accounting_phone" label="ACCOUNTING PHONE">
												<Form.Control
													type="text"
													placeholder="ACCOUNTING PHONE"
													name="accounting_phone"
													value={enterpriseprofile.accounting_phone ? enterpriseprofile.accounting_phone : ''}
													onKeyDown={(event) => {
														handlePhoneFormat(event,'accounting_phone')
													}}
													onChange={() => {

													}}
												/>
											</FloatingLabel>
										</Col>
										<Col>
											<FloatingLabel controlId="accounting_email" label="ACCOUNTING EMAIL">
												<Form.Control
													type="text"
													placeholder="ACCOUNTING EMAIL"
													name="accounting_email"
													defaultValue={enterpriseprofile.accounting_email}
													onChange={handleFormBlur}
												/>
											</FloatingLabel>
										</Col>
									</Row>
									<Row className="p-1">
										<Col>
											<FloatingLabel controlId="company_name" label="BUSINESS NAME">
												<Form.Control
													type="text"
													placeholder="BUSINESS NAME"
													name="company_name"
													defaultValue={enterpriseprofile.company_name}
													onChange={handleFormBlur}
												/>
											</FloatingLabel>
										</Col>
										<Col>
											<FloatingLabel controlId="legal_business_name" label="LEGAL BUSINESS NAME (IF DIFFERENT)" style={{fontSize:'11pt'}}>
												<Form.Control
													type="text"
													placeholder="LEGAL BUSINESS NAME (IF DIFFERENT)"
													name="legal_business_name"
													defaultValue={enterpriseprofile.legal_business_name}
													onChange={handleFormBlur}
												/>
											</FloatingLabel>
										</Col>
									</Row>
									<Row className="p-1">
										<Col>
											<FloatingLabel controlId="company_address1" label="ADDRESS 1">
												<Form.Control
													type="text"
													placeholder="ADDRESS 1"
													name="company_address1"
													defaultValue={enterpriseprofile.company_address1}
													onChange={handleFormBlur}
												/>
											</FloatingLabel>
										</Col>
									</Row>
									<Row className="p-1">
										<Col>
											<FloatingLabel controlId="company_address2" label="ADDRESS 2">
												<Form.Control
													type="text"
													placeholder="ADDRESS 2"
													name="company_address2"
													defaultValue={enterpriseprofile.company_address2}
													onChange={handleFormBlur}
												/>
											</FloatingLabel>
										</Col>
									</Row>
									<Row className="p-1">
										<Col xs={7}>
											<FloatingLabel controlId="company_city" label="CITY">
												<Form.Control
													type="text"
													placeholder="CITY"
													name="company_city"
													defaultValue={enterpriseprofile.company_city}
													onChange={handleFormBlur}
												/>
											</FloatingLabel>
										</Col>
										<Col xs={2}>
											<FloatingLabel controlId="company_state" label="STATE" style={{marginLeft:'-5px',minWidth:'80px'}}>
												<Form.Select
													placeholder="STATE"
													name="company_state"
													size="lg"
													value={enterpriseprofile.company_state ? enterpriseprofile.company_state : ''}
													onBlur={handleFormBlur}
													onChange={handleStateSelectChange}

												>
													<option></option>
													{Object.keys(statelist).map((abbr) => {
														return <option key={abbr} value={abbr}>{abbr}</option>
													})}
												</Form.Select>
											</FloatingLabel>
										</Col>
										<Col xs={3}>
											<FloatingLabel controlId="company_postalcode" label="ZIP">
												<Form.Control
													type="text"
													placeholder="ZIP"
													name="company_postalcode"
													defaultValue={enterpriseprofile.company_postalcode}
													onChange={handleFormBlur}
												/>
											</FloatingLabel>
										</Col>
									</Row>
									<Row className="p-1">
										<Col xs={6}>
											<FloatingLabel controlId="company_phone" label="BUSINESS PHONE">
												<Form.Control
													type="text"
													placeholder="BUSINESS PHONE"
													name="company_phone"
													value={enterpriseprofile.company_phone ? enterpriseprofile.company_phone : ''}
													onKeyDown={(event) => {
														handlePhoneFormat(event,'company_phone')
													}}
													onChange={() => {

													}}
												/>
											</FloatingLabel>
										</Col>
									</Row>
									<Row className="p-1">
										<Col>
											<FloatingLabel controlId="tax_id" label="EIN/TAX IDENTIFICATION NUMBER">
												<Form.Control
													type="text"
													placeholder="EIN/TAX IDENTIFICATION NUMBER"
													name="tax_id"
													defaultValue={enterpriseprofile.tax_id}
													onChange={handleFormBlur}
												/>
											</FloatingLabel>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="feature-row" style={{paddingLeft:'35px'}}>
								<Col xs={5}>
									<h6>ShieldHub Accounts</h6>
								</Col>
								<Col xs={7} style={{textAlign:'left'}}>
									<FloatingLabel controlId="crm_id" label="CRM ACCOUNT IDENTIFIER">
										<Form.Control
											type="text"
											placeholder="CRM ACCOUNT IDENTIFIER"
											name="crm_id"
											defaultValue={enterpriseprofile.crm_id}
											onChange={handleFormBlur}
										/>
									</FloatingLabel><br />
									<FloatingLabel controlId="accounting_id" label="ACCOUNTING IDENTIFIER">
										<Form.Control
											type="text"
											placeholder="ACCOUNTINGIDENTIFIER"
											name="accounting_id"
											defaultValue={enterpriseprofile.accounting_id}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>
							</Row>
							<Row className="feature-row" style={{borderBottom:'0px',paddingLeft:'35px'}}>
								<Col xs={5}>
									<h6>Enable Enterprise Dashboard</h6>
								</Col>
								<Col xs={7} style={{textAlign:'left',maxWidth:'100px',paddingLeft:'20px'}}>
									<Form.Check
										type="switch"
										style={{transform:"scale(1.3)"}}
										checked={enterpriseprofile?.quicksight_dashboard_enabled === 'YES'}
										name="quicksight_dashboard_enabled"
										value="YES"
										onChange={handleFormBlur}
									/>
								</Col>
							</Row>
							<Row className="feature-row" style={{paddingLeft:'35px',borderBottom:'0px',paddingTop:'6px',}}>
								<Col xs={5}>
									<h6>Enable Alert Dashboard</h6>
								</Col>
								<Col xs={7} style={{textAlign:'left',maxWidth:'100px',paddingLeft:'20px'}}>
									<Form.Check
										type='switch'
										style={{transform:"scale(1.3)"}}
										checked={enterpriseprofile?.alert_dashboard_enabled === 'YES'}
										name='alert_dashboard_enabled'
										value='YES'
										onChange={handleFormBlur}
									/>
								</Col>
							</Row>
							<Row className="feature-row" style={{paddingTop:'0px',paddingBottom:'20px'}}>
								<Col xs={12}>
									<Alert variant="info" className="p-2 text-center">Users must log out and log back in to see changes to these settings</Alert>
								</Col>
							</Row>
						</>}
					</Container>
				</Form>
			</Collapse>
		</Card>
	);
}
export default AccountDetails;