import * as React from "react";
import CaptureIDFront from "../components/user/purchasecomponents/CaptureIDFrontTest";
import CaptureIDBack from "../components/user/purchasecomponents/CaptureIDBackTest";
import CaptureUserSelfie from "../components/user/purchasecomponents/CaptureUserSelfieTest";
import { Stack , Button } from "react-bootstrap";
import Verifications from "../components/user/purchasecomponents/Verifications";


function TestID()
{
	const [ step , setStep ] = React.useState(false);

	function CompleteStep()
	{

		let newstep = step + 1;

		setStep(newstep);

	}

	return (
		
		<div className="login-position container">
			<Stack gap={3} style={{backgroundColor:'#FFFFFF'}}>
				{step === false && <Button onClick={()=>setStep(1)}>Start</Button>}
				{step >= 1 && <CaptureIDFront side="front" CompleteStep={CompleteStep} />}
				{step >= 2 && <CaptureIDBack side="back" CompleteStep={CompleteStep} />}
				{step >= 3 && <CaptureUserSelfie CompleteStep={CompleteStep} /> }
				{step >= 4 && <Verifications /> }
			</Stack>
		</div>

	);
}
export default TestID;