import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Stack , Row , Col } from 'react-bootstrap';
import Logo from '../assets/shieldhub-logo.png'
import api from '../io/general';
import { useBranding } from "../components/Branding";

function PaymentTerms() {

	const [ PaymentTermsHtml , setPaymentTermsHtml ] = React.useState({ __html : '<div></div>'});
	const branding = useBranding();

	async function getTerms()
	{
		let response = await api.branding();
		if(response?.success)
		{
			setPaymentTermsHtml({ __html : response.branding.credit_card_terms });
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		( async () => {
			await getTerms();
		})();

	},[]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<>
			<Helmet>
				<title>ShieldHub - Purchase Terms & Conditions</title>
			</Helmet>		
			<div className="login-position container">
				<Stack gap={2}>
					<Row className="justify-content-center login-portal">
						<Col xs="auto" style={{height:'90px'}}>
							{process.env.REACT_APP_LOCALDEBUG ? <img src={Logo} alt="ShieldHub Logo" className="branding"/> : <img src={branding.Company.branding_logo_path} alt="ShieldHub Logo" className="branding"/>}
						</Col>
					</Row>				
					<Row className="justify-content-center login-portal">
						<Col xs={12} sm={10} md={8}>
							<div className="login-container">
								<div dangerouslySetInnerHTML={PaymentTermsHtml}></div>
							</div>
						</Col>
					</Row>
				</Stack>
			</div>
		</>
	);
}
export default PaymentTerms;