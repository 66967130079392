import * as React from "react";
import { Alert, Button, Card, Col, Collapse, Container, Dropdown, DropdownButton, Form, Modal, Row, Toast, ToastContainer } from "react-bootstrap";
import { ArrowCounterclockwise, ChevronDown, ChevronRight, ExclamationTriangleFill, TrashFill } from "react-bootstrap-icons";
import { formatDistanceToNow , parseJSON as dateParseJSON } from "date-fns";
import { useRefreshEmitter } from "../../RefreshEmitter";
import { useLocation, useNavigate } from "react-router-dom";
import api from '../../../io/admin-enterprise';


function Team( props )
{

	const team_member_role_map = {
		'owner' : 'Owner',
		'admin' : 'Admin',
		'viewer' : 'Viewer',
	};
	const emitter = useRefreshEmitter();
	const { hash } = useLocation();
	const navigate = useNavigate();
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ enterpriseprofile , setEnterpriseProfile ] = React.useState({});

	const [ teamlist , setTeamslist ] = React.useState([]);
	const [ showInviteModal , setShowInviteModal ] = React.useState(false);
	const [ confirmOwnerSwitch , setConfirmOwnerSwitch ] = React.useState(false);
	const [ newOwner , setNewOwner ] = React.useState({});
	const [ currentOwner , setCurrentOwner ] = React.useState({});

	const [ inviterole , setInviterole ] = React.useState(false);
	const [ inviteemail , setInviteEmail ] = React.useState(false);
	const [ validinviteMember , setValidInviteMember ] = React.useState(false);
	const [ invalidUser , setInvalidUser ] = React.useState(false);

	const [ TeamInviteSent , setTeamInviteSent ] = React.useState(false);
	const [ OwnerSwitchComplete , setOwnerSwitchComplete ] = React.useState(false);

	const formref = React.createRef();

	async function LoadProfile( userid )
	{
		let response = await api.getenterpriseprofile( userid );
		if(response.success === true)
		{
			setEnterpriseProfile(response.profile);
			LoadTeamList( response.profile.companyid );
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setCurrentOwner({
				memberid : 1,
				firstname : 'John',
				lastname : 'Doe',
				initials : 'JD',
				emailaddress : 'john@example.com',
			});

			setEnterpriseProfile({
				companyid : 1,
				team_management_enabled : 'YES',
			});
			setTeamslist([
				{
					memberid : 1,
					firstname: "John",
					lastname : 'Doe',
					initials : 'JD',
					emailaddress: "john@example.com",
					role: "owner",
					last_auth_dts: new Date().toJSON(),
					access: "YES",
					invite_dts : '',
					accepted : ''
				},
				{
					memberid : 2,
					firstname : "Jane",
					lastname : 'Doe',
					initials : 'JD',
					emailaddress : "jane@example.com",
					role : "admin",
					last_auth_dts : new Date().toJSON(),
					team_access : "YES",
					invite_dts : new Date().toJSON(),
					accepted : 'YES'
				},
				{
					memberid : 2,
					firstname : "Larry",
					lastname : 'Bird',
					initials : 'LB',
					emailaddress : "larry@example.com",
					role : "admin",
					last_auth_dts : new Date().toJSON(),
					team_access : "YES",
					invite_dts : new Date().toJSON(),
					accepted : 'NO'
				}
			]);
		}
	}

	async function SaveProfile()
	{
		let profileobject = {
			companyid : ( enterpriseprofile.companyid ? enterpriseprofile.companyid : null ),
			userid : enterpriseprofile.userid,
		};

		let formData = new FormData(formref.current);

		profileobject.team_management_enabled =  ( formData.get('team_management_enabled') === 'YES' ? 'YES' : 'NO' );

		let response = await api.saveenterpriseprofile(profileobject);

		if(response.success === true)
		{
			await SaveTeamList();
		}
		else
		{
			console.warn(response);
		}		
	}

	async function LoadTeamList( companyid )
	{
		let response = await api.listenterpriseteam( companyid );
		if(response.success === true)
		{
			response.members = response.members.map( (member) => {

				if(member.last_auth_dts)
				{
					member.last_auth_dts = dateParseJSON(member.last_auth_dts);
				}
				
				return member;
			});

			let ownerlist = response.members.filter( (member) => {
				return member.role === 'owner';
			});

			if(ownerlist.length > 0)
			{
				setCurrentOwner(ownerlist[0]);
			}

			setTeamslist(response.members);
		}
	}

	async function SaveTeamList()
	{
		let request = {
			companyid : enterpriseprofile.companyid,
			members : teamlist,
		};

		let response = await api.saveenterpriseteam( request );
		if(response.success === true)
		{
			props.setFormDirtyFunction(false);
		}
		else
		{
			console.warn(response);
		}
	}

	async function handleFormBlur()
	{
		props.setFormDirtyFunction(true);

		let profileobject = { ...enterpriseprofile };

		let formData = new FormData(formref.current);

		profileobject.team_management_enabled = ( formData.get('team_management_enabled') === 'YES' ? 'YES' : 'NO' );

		setEnterpriseProfile(profileobject);
	}

	async function updateTeamMemberRole( id , role )
	{
		let newlist = [...teamlist];

		newlist = newlist.map( (item) => {
			if(item.memberid === id)
			{
				item.role = role;
			}

			return item;
		});

		setTeamslist(newlist);

		props.setFormDirtyFunction(true);
	}

	async function switchOwner( )
	{
		let newlist = [...teamlist];

		newlist = newlist.map( (item) => {

			if(item.role === 'owner')
			{
				item.role = 'admin'
			}
			else if(item.memberid === newOwner.memberid)
			{
				item.role = 'owner';
			}

			return item;
		});

		setTeamslist(newlist);

		let response = await api.switchenterpriseowner( enterpriseprofile.companyid, newOwner.memberid );
		if(response.success === true)
		{
			setConfirmOwnerSwitch(false);
			setNewOwner({});
			setCurrentOwner({});
			navigate(`/admin/user#${newOwner.memberid}`, { replace : true });

			setOwnerSwitchComplete(true);
			setTimeout( () => {
				setOwnerSwitchComplete(false);
			}, 5000);
		}
	}

	async function updateTeamMemberAccess( id , event )
	{
		let newaccess = event.currentTarget.checked;

		let newlist = [...teamlist];

		newlist = newlist.map( (item) => {
			if(item.memberid === id)
			{
				item.team_access = ( newaccess ? 'YES' : 'NO');
			}

			return item;
		});

		setTeamslist(newlist);

		props.setFormDirtyFunction(true);
	}

	async function setDeleteFlag( id )
	{
		let newlist = [...teamlist];

		newlist = newlist.map( (item) => {
			if(item.memberid === id)
			{
				item.delete_flag = 'YES';
			}

			return item;
		});

		setTeamslist(newlist);

		props.setFormDirtyFunction(true);
	}

	async function CheckUserExists( event )
	{
		let usertoinvite = event.target.value;

		if(!usertoinvite || usertoinvite.length < 4 || !usertoinvite.match(/.+@.+\..+/))
		{
			return;
		}

		let response = await api.checkinviteuser( usertoinvite );
		if(response.success === true && response.exists === false)
		{
			setInvalidUser(true);
		}
		else if(response.success == true && response.exists === 'alreadylinked')
		{
			setInvalidUser('alreadylinked');
		}
		else if(response.success == true && response.exists === 'adminuser')
		{
			setInvalidUser('adminuser');
		}
		else if(response.success == true && response.exists === true)
		{
			setInviteEmail(event.target.value);
			setInvalidUser(false);
		}
	}

	function CheckInviteModal()
	{
		if(!inviteemail || !inviteemail.match(/.+@.+\..+/))
		{
			setValidInviteMember(false);
			return;
		}
		if(['admin','viewer'].indexOf(inviterole) === -1)
		{
			setValidInviteMember(false);
			return;
		}
		setValidInviteMember(true);
	}

	async function InviteUser()
	{
		let requestobject = {
			companyid : enterpriseprofile.companyid,
			emailaddress : inviteemail,
			role : inviterole,
		};

		let response = await api.inviteteammember( requestobject );
		if(response.success === true)
		{
			setShowInviteModal(false);
			setInviteEmail(false);
			setInviterole('');
			setValidInviteMember(false);
			LoadTeamList( enterpriseprofile.companyid );

			setTeamInviteSent(true);
			
			setTimeout(() => {
				setTeamInviteSent(false);
			}, 2000);			
		}
	}

	async function ResendInvite( memberid )
	{
		let response = await api.resendteaminvite( enterpriseprofile.companyid , memberid );
		if(response.success === true)
		{
			setTeamInviteSent(true);
			
			setTimeout(() => {
				setTeamInviteSent(false);
			}, 2000);
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		CheckInviteModal();

	},[inviteemail,inviterole]);

	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setEnterpriseProfile({});

		( async () => {
			await LoadProfile( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		if( ['admin:saveenterprise'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await SaveProfile();
			})();
		}

	},[emitter.eventname]);		
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<Card className="p-4 d-inline-block">
			<Form ref={formref} autoComplete="off">
				<Row>
					<Col xs={11}>
						<Button variant="link" className="accordian-title" style={{minWidth:'150px',textAlign:'left'}} onClick={() => { setPanelShow( !panelshow )}}>
							{panelshow && enterpriseprofile.team_management_enabled === 'YES' && <ChevronDown />}
							{!panelshow && <ChevronRight />}
							&nbsp;Team</Button>
					</Col>
					<Col xs={1} style={{textAlign:'right'}} className="pt-2">
						<Form.Check 
							type="switch" 
							style={{transform:"scale(1.3)"}} 
							checked={enterpriseprofile.team_management_enabled === 'YES'}
							name="team_management_enabled" 
							value="YES"
							onChange={handleFormBlur}
						/>				
					</Col>
				</Row>
				<Collapse in={panelshow && enterpriseprofile.team_management_enabled === 'YES'}>
					<Container>
						<Row className="feature-row" style={{paddingLeft:'35px',borderTop:'solid 1px #B1B1B1',borderBottom:'0px'}}>
							<Col xs={9}><p>Use this section to manage access to a client&apos;s network. <b>Viewer</b> users are limited to searching and viewing network records. <b>Admin</b> users can also send, accept and manage network invites on behalf of the enterprise. <b>Owners</b> have access to all account functions. One account owner is required, but ownership can be transferred to any existing team member.</p></Col>
							<Col xs={3}><Button onClick={() => setShowInviteModal(true)}>Invite Team Member</Button></Col>
						</Row>
						<Row style={{paddingLeft:'35px',}}>
							<Col>
								<Row className="p-2" style={{fontSize:'11pt'}}>
									<Col xs={5}>TEAM MEMBER</Col>
									<Col xs={1} style={{textAlign:'center'}}>ROLE</Col>
									<Col xs={3} style={{textAlign:'center'}}>LAST SIGN-IN</Col>
									<Col xs={2} style={{textAlign:'center'}} >TEAM ACCESS</Col>
									<Col xs={1} style={{textAlign:'center'}}>REMOVE</Col>
								</Row>
								{teamlist && teamlist.map( (member) => {
									return (
										<Collapse key={member.memberid} in={ (!member.delete_flag || member.delete_flag !== 'YES') }>
											<Row className="p-2">
												<Col xs={5}>
													<Row>
														<Col xs={1} style={{minWidth:'60px'}}>
															{(member.profileimageurl && member.profileimageurl !== '' ? 
																<div className="profile-image-frame" style={{width:'50px',height:'50px',paddingTop:'13px',borderWidth:'3px',backgroundImage:`url("${member.profileimageurl}")`}} /> : 
																<div className="missing-profile-image" style={{width:'50px',height:'50px',paddingTop:'13px'}}>{member.initials}</div>)}
														</Col>
														<Col>
															<b>{`${member.firstname} ${member.lastname}`}</b><br />
															{member.emailaddress}
														</Col>
													</Row>
												</Col>
												<Col xs={1} style={{paddingTop:'6px',textAlign:'center'}}>

													<DropdownButton
														variant="dark-link"
														title={team_member_role_map[member.role]}
														id="dropdown-basic-button"
														
													>
														<Dropdown.Item 
															active={member.role === 'owner'}
															disabled={!(member.accepted === 'YES')}
															onClick={() => {
																if(member.role !== 'owner')
																{
																	setNewOwner({
																		memberid : member.memberid,
																		firstname : member.firstname,
																		lastname : member.lastname,
																		emailaddress : member.emailaddress,
																	});
																	setConfirmOwnerSwitch(true);
																}
															}}
														>Owner</Dropdown.Item>
														<Dropdown.Item 
															active={member.role === 'admin'}
															onClick={() => {
																if(member.role !== 'admin')
																{
																	updateTeamMemberRole(member.memberid, 'admin');
																}
															}}
														>Admin</Dropdown.Item>
														<Dropdown.Item 
															active={member.role === 'viewer'}
															onClick={() => {
																if(member.role !== 'viewer')
																{
																	updateTeamMemberRole(member.memberid, 'viewer');
																}
															}}
														>Viewer</Dropdown.Item>
													</DropdownButton>
												</Col>
												{ (member.role === 'owner' || member.accepted === 'YES') && <>
													<Col xs={3} style={{paddingTop:'13px',textAlign:'center',fontSize:'11pt'}} >
														{(member.last_auth_dts ? formatDistanceToNow(member.last_auth_dts)+' ago' : 'Never')}
													</Col>
													<Col xs={2} style={{paddingTop:'13px'}}>
														{member.role !== 'owner' && <Form.Check
															type="switch"
															style={{maxWidth:'50px',marginLeft:'auto',marginRight:'auto',transform:"scale(1.3)"}}
															checked={member.team_access === 'YES'}
															name="access"
															value="YES"
															onClick={(event) => {
																updateTeamMemberAccess(member.memberid,event);
															}}
															onChange={() => {}}
														/>}
													</Col>
													<Col xs={1} style={{paddingTop:'13px',textAlign:'center'}}>
														{member.role !== 'owner' && <Button variant="link" onClick={() => setDeleteFlag(member.memberid)}><TrashFill color="#BE2026" size={19}/></Button>}
													</Col>
												</>}
												{ (member.role !== 'owner' && member.accepted !== 'YES') && <Col xs={3} style={{paddingTop:'13px',textAlign:'center',fontSize:'11pt'}}>
													<Button variant="outline-primary" onClick={() => ResendInvite(member.memberid)}><ArrowCounterclockwise /> Resend Invite</Button>
												</Col>}
											</Row>
										</Collapse>
									); 	
								})}							
							</Col>
						</Row>
					</Container>
				</Collapse>
				<Modal show={showInviteModal} centered>
					<Modal.Header>
						<Modal.Title>Invite Team Member</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col xs={2}>
								<Form.Label>Email</Form.Label>
							</Col>
							<Col>
								<Form.Control 
									autoComplete="off"
									name="inviteemail"
									onKeyUp={CheckUserExists}
									onChange={CheckInviteModal}
								/>
							</Col>
						</Row>
						<Row>
							<Col xs={2}>
								<Form.Label>Role</Form.Label>
							</Col>
							<Col>
								<DropdownButton
									variant="dark-link"
									title={ (!inviterole ? 'Pick User Role' : team_member_role_map[inviterole] ) }
									id="dropdown-basic-button"
								>
									<Dropdown.Item 
										active={inviterole === 'admin'}
										onClick={() => {
											setInviterole('admin');
										}}
									>Admin</Dropdown.Item>
									<Dropdown.Item 
										active={inviterole === 'viewer'}
										onClick={() => {
											setInviterole('viewer');
										}}
									>Viewer</Dropdown.Item>
								</DropdownButton>
							</Col>
						</Row>
						<Alert show={invalidUser === true} variant="danger"><ExclamationTriangleFill /> Error: User does not exist.</Alert>
						<Alert show={invalidUser === 'alreadylinked'} variant="danger"><ExclamationTriangleFill /> Error: User already attached to another team.</Alert>
						<Alert show={invalidUser === 'adminuser'} variant="danger"><ExclamationTriangleFill /> Error: Admin users cannot be added to teams.</Alert>
					</Modal.Body>
					<Modal.Footer className="justify-content-between">
						<Button variant="secondary" onClick={() => {
							setInviteEmail(false);
							setInviterole(false);
							setShowInviteModal(false);
						}}>Nevermind</Button>
						<Button variant="primary" disabled={(!validinviteMember)} onClick={InviteUser} >Send Invite</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={confirmOwnerSwitch} centered>
					<Modal.Header>
						<Modal.Title>Switch Owner ?</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<b>Are you sure?</b><br /><br />

						<p>Only one user can have the Owner role at a time. Confirm switching the owner <br />
						from <b>{currentOwner.firstname} {currentOwner.lastname} &lt;{currentOwner.emailaddress}&gt;</b> <br />
						to <b>{newOwner.firstname} {newOwner.lastname} &lt;{newOwner.emailaddress}&gt;</b></p>
					</Modal.Body>
					<Modal.Footer className="justify-content-between">
						<Button variant="secondary" onClick={() => {
							setConfirmOwnerSwitch(false);
							setNewOwner({});
						}}>Nevermind</Button>
						<Button variant="primary" onClick={switchOwner}>Confirm Owner Switch</Button>
					</Modal.Footer>
				</Modal>

				<ToastContainer position='bottom-center' style={{zIndex:1000}} className="position-fixed p-4">
					<Toast bg='primary' show={TeamInviteSent}>
						<Toast.Body style={{textAlign:'center',color:'#FFFFFF',fontWeight:'bold'}}>Team Invite Sent</Toast.Body>
					</Toast>
					<Toast bg='primary' show={OwnerSwitchComplete}>
						<Toast.Body style={{textAlign:'center',color:'#FFFFFF',fontWeight:'bold'}}>Owner switch completed</Toast.Body>
					</Toast>
				</ToastContainer>
			</Form>
		</Card>

	);
}
export default Team;