import axios from "axios";

const base_api_endpoint = process.env.REACT_APP_API_ENDPOINT;

async function user ( )
{
	let apiresponse = {
		success : false
	};

	try {
		const response = await axios.get(base_api_endpoint+"/user",{withCredentials: true});

		if(response.data.sessionid)
		{
			apiresponse.success = true;
			apiresponse.user = response.data.user;
		}

	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function register( userobject )
{
	let apiresponse = {
		success : false
	};

	try {
		const response = await axios.post(base_api_endpoint+"/user/register",userobject,{withCredentials: true});

		if(response.data.sessionid)
		{
			apiresponse.success = true;
			apiresponse.sessionid = response.data.sessionid;
		}        
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;

}

async function duplicateaccountcheck( emailaddress )
{
	let apiresponse = {
		success : false
	};

	try {
		const response = await axios.post(base_api_endpoint+"/user/checkemail", { emailaddress },{withCredentials: true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.accountexists = response.data.accountexists;
		}

	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function registerterms( accept_terms , email_copy = null , updatedterms = 'false' )
{
	let apiresponse = {
		success : false
	};

	try {
		const response = await axios.post(base_api_endpoint+"/user/acceptterms",{ accept_terms , email_copy , updatedterms },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function registerverification( code )
{
	let apiresponse = {
		success : false
	};

	try {
		const response = await axios.post(base_api_endpoint+"/user/registerverify",{ code },{withCredentials: true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function verificationretry()
{
	let apiresponse = {
		success : false
	};

	try {
		const response = await axios.get(base_api_endpoint+"/user/verifyretry",{withCredentials: true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function startforgotpassword( emailaddress ){
	let apiresponse = {
		success : false
	};

	try{
		const response = await axios.post(base_api_endpoint+"/user/forgotpassword",{emailaddress},{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function forgotpasswordverification( code )
{
	let apiresponse = {
		success : false
	};

	try {
		const response = await axios.post(base_api_endpoint+"/user/forgotpasswordverify",{ code },{withCredentials: true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	
}

async function forgotnewpassword( password )
{
	let apiresponse = {
		success : false
	};

	try {
		const response = await axios.post(base_api_endpoint+"/user/forgotnewpassword",{ password },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e)
	{
		console.log(e);
	}
	return apiresponse;	
}

async function getlink( linkid )
{
	let apiresponse = {
		success : false
	};

	try {
		const response = await axios.post(base_api_endpoint+"/getlink",{linkid},{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.details = response.data.details;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function getlink_w_auth( linkid )
{
	let apiresponse = {
		success : false
	};

	try {
		const response = await axios.post(base_api_endpoint+"/user/getlink",{linkid},{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.details = response.data.details;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function shareshieldid( emailaddress )
{
	let apiresponse = {
		success : false
	};

	try {
		const response = await axios.post(base_api_endpoint+"/user/shareshieldid", { emailaddress } ,{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

var methods = { user , register , duplicateaccountcheck , registerverification , registerterms , verificationretry , startforgotpassword , forgotpasswordverification , forgotnewpassword , getlink , getlink_w_auth , shareshieldid};

export default methods;