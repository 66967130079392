import * as React from "react";

const RefreshEmitter = {
	eventname : null,
	data : null, 
	Emit : null
};

let RefreshEmitterContext = React.createContext(RefreshEmitter);

function RefreshEmitterProvider({children}){

	const [ eventname , setEventName ] = React.useState(null);
	const memoEventName = React.useMemo(() => eventname,[eventname]);
	const [ data , setData ] = React.useState(null);
	const memoData = React.useMemo(() => data,[data]);

	function Emit( eventname , data )
	{
		if(eventname !== null && eventname !== undefined)
		{
			setEventName(eventname);
		}
		if(data !== null && data !== undefined)
		{
			setData(data);
		}
		setTimeout( () => {
			setEventName(null);
			setData(null);
		},1000);
	}

	React.useEffect( () => {

		setInterval(() => {
			Emit('globalrefresh');
		},30 * 1000);

	},[]);

	let value = { eventname : memoEventName , data : memoData , Emit };
	
	return <RefreshEmitterContext.Provider value={value}>{children}</RefreshEmitterContext.Provider>;
}

function useRefreshEmitter(){
	return React.useContext(RefreshEmitterContext);
}

export { RefreshEmitterProvider , useRefreshEmitter }