import * as React from "react";
import api from '../../../io/workflow';
import { Container , Row , Col, Badge } from "react-bootstrap";

function Verifications( )
{

	const [ verificationdata , setVerificationData ] = React.useState(null);


	React.useEffect(() => {

		( async () => {

			let response = await api.verificationdata();
			if(response.success)
			{

				response.verification.id_front.important_labels = [];

				response.verification.id_front.important_labels = response.verification.id_front.labels.filter( (item) => {

					return item.Name === "Driving License" || item.Name === "Id Cards" || item.Name === "Credit Card";

				});

				response.verification.id_back.important_labels = [];

				response.verification.id_back.important_labels = response.verification.id_front.labels.filter( (item) => {

					return item.Name === "Driving License" || item.Name === "Id Cards" || item.Name === "Credit Card";

				});


				setVerificationData(response.verification);
			}
			else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
			{
			
				let raw_data = {
					id_front : {
						labels : [],
					},
					id_back : {
						labels : [],
					},
				};

				raw_data.id_front.important_labels = [];

				raw_data.id_front.important_labels = raw_data.id_front.labels.filter( (item) => {

					return item.Name === "Driving License" || item.Name === "Id Cards" || item.Name === "Credit Card";

				});

				raw_data.id_back.important_labels = [];

				raw_data.id_back.important_labels = raw_data.id_front.labels.filter( (item) => {

					return item.Name === "Driving License" || item.Name === "Id Cards" || item.Name === "Credit Card";

				});

				setVerificationData(raw_data);
			}

		} )();


	}, []);


	return (

		<>
			{verificationdata !== null &&
			<Container className="pt-5">
				<Row>
					<Col className="text-center">
						<h1>Verifications</h1>
					</Col>
				</Row>
				<Row>
					<Col>FIELD</Col><Col>VALUE</Col><Col>SOURCE</Col><Col>Confidence</Col>
				</Row>
				{verificationdata?.id_front?.id_values?.FIRST_NAME && <>
					<Row>
						<Col>First Name</Col><Col>{verificationdata.id_front.id_values.FIRST_NAME}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.FIRST_NAME}</Col>
					</Row>
					<Row>
						<Col>Last Name</Col><Col>{verificationdata.id_front.id_values.LAST_NAME}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.LAST_NAME}</Col>
					</Row>
					<Row>
						<Col>Middle Name</Col><Col>{verificationdata.id_front.id_values.MIDDLE_NAME}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.MIDDLE_NAME}</Col>
					</Row>
					<Row>
						<Col>Suffix</Col><Col>{verificationdata.id_front.id_values.SUFFIX}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.SUFFIX}</Col>
					</Row>
					<Row>
						<Col>Address</Col><Col>{verificationdata.id_front.id_values.ADDRESS}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.ADDRESS}</Col>
					</Row>
					<Row>
						<Col>Address City</Col><Col>{verificationdata.id_front.id_values.CITY_IN_ADDRESS}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.CITY_IN_ADDRESS}</Col>
					</Row>
					<Row>
						<Col>Address State</Col><Col>{verificationdata.id_front.id_values.STATE_IN_ADDRESS}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.STATE_IN_ADDRESS}</Col>
					</Row>
					<Row>
						<Col>Address ZIP</Col><Col>{verificationdata.id_front.id_values.ZIP_CODE_IN_ADDRESS}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.ZIP_CODE_IN_ADDRESS}</Col>
					</Row>
					<Row>
						<Col>Drivers License Number</Col><Col>{verificationdata.id_front.id_values.DOCUMENT_NUMBER}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.DOCUMENT_NUMBER}</Col>
					</Row>
					<Row>
						<Col>Drivers License State</Col><Col>{verificationdata.id_front.id_values.STATE_NAME}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.STATE_NAME}</Col>
					</Row>
					<Row>
						<Col>Date of Birth</Col><Col>{verificationdata.id_front.id_values.DATE_OF_BIRTH}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.DATE_OF_BIRTH}</Col>
					</Row>
					<Row>
						<Col>Date of Issue</Col><Col>{verificationdata.id_front.id_values.DATE_OF_ISSUE}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.DATE_OF_ISSUE}</Col>
					</Row>
					<Row>
						<Col>Date of Expiration</Col><Col>{verificationdata.id_front.id_values.EXPIRATION_DATE}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.EXPIRATION_DATE}</Col>
					</Row>
					<Row>
						<Col>ID Type</Col><Col>{verificationdata.id_front.id_values.ID_TYPE}</Col><Col>Front ID Textract</Col><Col>{verificationdata.id_front.id_values.Confidence.ID_TYPE}</Col>
					</Row>
				</>}
				{verificationdata.id_front.important_labels.map( (item , index ) => {

					return <Row key={index}>
						<Col>Detect Labels</Col><Col>{item.Name}</Col><Col>Front ID Detect Labels</Col><Col>{item.Confidence}</Col>
					</Row>

				} )}
				<Row>
					<Col>Front ID contains a face</Col><Col></Col><Col>Front ID Detect Faces</Col><Col>{verificationdata?.id_front?.face && verificationdata?.id_front?.face[0]?.Confidence}</Col>
				</Row>
				<Row>
					<Col>ID Type</Col><Col>{verificationdata?.id_back?.id_values?.ID_TYPE}</Col><Col>Back ID Textract</Col><Col>{verificationdata?.id_back?.id_values?.Confidence?.ID_TYPE}</Col>
				</Row>
				<Row>
					<Col>ID Class</Col><Col>{verificationdata?.id_back?.id_values?.CLASS}</Col><Col>Back ID Textract</Col><Col>{verificationdata?.id_back?.id_values?.Confidence?.CLASS}</Col>
				</Row>
				{verificationdata?.id_back?.id_values?.dlbarcode?.documentNumber && <>
					<Row>
						<Col>Document Number</Col><Col>{verificationdata.id_back.id_values.dlbarcode.documentNumber}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Last Name</Col><Col>{verificationdata.id_back.id_values.dlbarcode.lastName}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Last Name Truncated</Col><Col>{verificationdata.id_back.id_values.dlbarcode.lastNameTruncated}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>First Name</Col><Col>{verificationdata.id_back.id_values.dlbarcode.firstName}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>First Name Truncated</Col><Col>{verificationdata.id_back.id_values.dlbarcode.firstNameTruncated}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>First Name Truncated</Col><Col>{verificationdata.id_back.id_values.dlbarcode.firstNameTruncated}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Middle Name</Col><Col>{verificationdata.id_back.id_values.dlbarcode.middleName}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Middle Name Truncated</Col><Col>{verificationdata.id_back.id_values.dlbarcode.middleNameTruncated}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>ID Class</Col><Col>{verificationdata.id_back.id_values.dlbarcode.jurisdictionVehicleClass}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Issue Date</Col><Col>{verificationdata.id_back.id_values.dlbarcode.dateOfIssue}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Birth Date</Col><Col>{verificationdata.id_back.id_values.dlbarcode.dateOfBirth}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Expiration Date</Col><Col>{verificationdata.id_back.id_values.dlbarcode.dateOfExpiry}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Sex</Col><Col>{verificationdata.id_back.id_values.dlbarcode.sex}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Height</Col><Col>{verificationdata.id_back.id_values.dlbarcode.height}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Weight</Col><Col>{verificationdata.id_back.id_values.dlbarcode.weightLb}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Eye Color</Col><Col>{verificationdata.id_back.id_values.dlbarcode.eyeColor}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Address Street</Col><Col>{verificationdata.id_back.id_values.dlbarcode.addressStreet}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Address City</Col><Col>{verificationdata.id_back.id_values.dlbarcode.addressCity}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Address State</Col><Col>{verificationdata.id_back.id_values.dlbarcode.addressState}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Address ZIP</Col><Col>{verificationdata.id_back.id_values.dlbarcode.addressPostalCode}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Document Issuer</Col><Col>{verificationdata.id_back.id_values.dlbarcode.issuer}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Hair Color</Col><Col>{verificationdata.id_back.id_values.dlbarcode.hairColor}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
					<Row>
						<Col>Date Revised</Col><Col>{verificationdata.id_back.id_values.dlbarcode.dateCardRevised}</Col><Col>Back ID PDF417 Barcode</Col><Col></Col>
					</Row>
				</>}
				{verificationdata.id_back.important_labels.map( (item , index ) => {
					return <Row key={index}>
						<Col>Detect Labels</Col><Col>{item.Name}</Col><Col>Back ID Detect Labels</Col><Col>{item.Confidence}</Col>
					</Row>
				} )}
				<Row>
					<Col>Liveness capture Confidence</Col><Col></Col><Col>Liveness Face Capture</Col><Col>{verificationdata?.liveness?.raw?.Confidence}</Col>
				</Row>

				<Row>
					<Col className="text-center">
						<h1>Matches</h1>
					</Col>
				</Row>
				<Row>
					<Col>Front ID First Name matches back Barcode</Col>
					<Col>{`${verificationdata?.id_front?.id_values?.FIRST_NAME} = ${verificationdata?.id_back?.id_values?.dlbarcode?.firstName}`}</Col>
					<Col>
						{ verificationdata?.id_front?.id_values?.FIRST_NAME && verificationdata?.id_back?.id_values?.dlbarcode?.firstName && (verificationdata.id_front.id_values.FIRST_NAME === verificationdata.id_back.id_values.dlbarcode.firstName) ? <Badge bg='success'>YES</Badge> : <Badge bg='danger'>NO</Badge> }
					</Col>
				</Row>
				<Row>
					<Col>Front ID Last Name matches back Barcode</Col>
					<Col>{`${verificationdata?.id_front?.id_values?.LAST_NAME} = ${verificationdata?.id_back?.id_values?.dlbarcode?.lastName}`}</Col>
					<Col>
						{ verificationdata?.id_front?.id_values?.LAST_NAME && verificationdata?.id_back?.id_values?.dlbarcode?.lastName && (verificationdata.id_front.id_values.LAST_NAME === verificationdata.id_back.id_values.dlbarcode.lastName) ? <Badge bg='success'>YES</Badge> : <Badge bg='danger'>NO</Badge> }
					</Col>
				</Row>
				<Row>
					<Col>Front ID Document ID matches back Barcode</Col>
					<Col>{`${verificationdata?.id_front?.id_values?.DOCUMENT_NUMBER} = ${verificationdata?.id_back?.id_values?.dlbarcode?.documentNumber}`}</Col>
					<Col>
						{ verificationdata?.id_front?.id_values?.DOCUMENT_NUMBER && verificationdata?.id_back?.id_values?.dlbarcode?.documentNumber && (verificationdata.id_front.id_values.DOCUMENT_NUMBER === verificationdata.id_back.id_values.dlbarcode.documentNumber) ? <Badge bg='success'>YES</Badge> : <Badge bg='danger'>NO</Badge> }
					</Col>
				</Row>
				<Row>
					<Col>ID Document ID matches back Barcode</Col>
					<Col>{`${verificationdata?.id_front?.id_values?.DOCUMENT_NUMBER} = ${verificationdata?.id_back?.id_values?.dlbarcode?.documentNumber}`}</Col>
					<Col>
						{ verificationdata?.id_front?.id_values?.DOCUMENT_NUMBER && verificationdata?.id_back?.id_values?.dlbarcode?.documentNumber && (verificationdata.id_front.id_values.DOCUMENT_NUMBER === verificationdata.id_back.id_values.dlbarcode.documentNumber) ? <Badge bg='success'>YES</Badge> : <Badge bg='danger'>NO</Badge> }
					</Col>
				</Row>
				<Row>
					<Col>Front ID Face matches Liveness Capture</Col>
					<Col>{`${verificationdata?.compare_faces?.FaceMatches[0]?.Similarity} %`}</Col>
					<Col>
						{ verificationdata?.compare_faces?.FaceMatches[0]?.Similarity && parseFloat(verificationdata?.compare_faces?.FaceMatches[0]?.Similarity) > 75 ? <Badge bg='success'>YES</Badge> : <Badge bg='danger'>NO</Badge> }
					</Col>
				</Row>

			</Container>}
		</>
	)


}
export default Verifications;