import * as React from "react";
import { Button, Card, Collapse, Form , Container , Row, Col } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";
import { useBranding } from "../../Branding";
import { useLocation } from "react-router-dom";
import {format as dateFormat , isValid as dateValid , parseJSON as dateParseJSON } from "date-fns";
import api from '../../../io/admin';
import { useRefreshEmitter } from "../../RefreshEmitter";


function MonitoringActions()
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ actionslist , setActionsList ] = React.useState([]);
	const branding = useBranding();

	async function LoadActionsList( userid )
	{
		let response = await api.getmonitoringactions( userid );
		
		if(response.success === true)
		{
			response.actions.map( (action) => {

				if(action.dts)
				{
					action.dts = dateParseJSON(action.dts);
				}
				if(action.resolved_dts)
				{
					action.resolved_dts = dateParseJSON(action.resolved_dts);
				}

				return action;
			});

			setActionsList( response.actions );
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setActionsList([
				{
					id : 4,
					applicationid : 143,
					candidate_action : 'APPROVALREQUIRED',
					dts : new Date(),
					action_status : 'NEW',
				}
			])
		}


	}	



	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setActionsList([]);

		( async () => {
			await LoadActionsList( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');

		if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadActionsList( userid );
			})();
		}
	},[emitter.eventname,hash]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;Monitoring Actions</Button>
			<Collapse in={panelshow}>
				<Form>
					<Container style={{textAlign:'center'}} >
						{actionslist && actionslist.length > 0 &&
							<>
								<Row style={{color:branding.PrimaryColor,textTransform:'uppercase'}} className="p-4">
									<Col>Date</Col>
									<Col>Action Required</Col>
									<Col>Status</Col>
									<Col>Resolved By</Col>
									<Col xs={1}></Col>
								</Row>
								{actionslist && actionslist.map((action) => {

									return (<Row key={action.id} className="p-4 border-top">
										<Col>{(action.dts && dateValid(action.dts) ) ? dateFormat(action.dts,'MM/dd/yyyy') : null }</Col>
										<Col>{action.candidate_action}</Col>
										<Col>{action.action_status}</Col>
										<Col>{action.resolved_by}</Col>
										<Col xs={1}>
											{/*<DropdownButton id="dropdown-basic-button" size="sm" title={<GearFill />}>
												<Dropdown.Item>Marked Resolved</Dropdown.Item>
												<Dropdown.Item>Revert Expiration Date Change and Resolve</Dropdown.Item>
											</DropdownButton>*/}
										</Col>
									</Row>);

								})}
							</>
						}
					</Container>
				</Form>
			</Collapse>
		</Card>
	);
}
export default MonitoringActions;