import * as React from "react";
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import ProgressBar from 'react-bootstrap/ProgressBar';
import useDerivedState from "@twipped/hooks/useDerivedState";
// import './PasswordStrengthMeter.css';

function PasswordStrengthMeter( { password , returnScore } ) {

	const passwordoptions = {
		translations: zxcvbnEnPackage.translations,
		dictionary: {
			...zxcvbnCommonPackage.dictionary,
			...zxcvbnEnPackage.dictionary,
		},
		graphs: zxcvbnCommonPackage.adjacencyGraphs,
	};

	const [ passwordscore ] = useDerivedState(() => {

		if(password === false || password === "")
		{
			return 1;
		}
		else
		{
			zxcvbnOptions.setOptions(passwordoptions);
			var result = zxcvbn(password);

			if(result.score === 0)
			{
				return 1;
			}

			return ( result.score * 10 );
		}

	}, [ password ] );

	const [ threshold , setThreshold ] = React.useState("danger");

	React.useEffect( () => {

		if(parseInt(passwordscore) < 20)
		{
			setThreshold("danger");
		}
		else if(parseInt(passwordscore) === 30)
		{
			setThreshold("warning");
		}
		else if(parseInt(passwordscore) === 40)
		{
			setThreshold("success");
		}

		if(typeof returnScore === 'function')
		{
			returnScore(passwordscore);
		}

	}, [ passwordscore , returnScore]);


	return (
		<div className="password-strength-meter">
			<ProgressBar
				variant={threshold}
				now={passwordscore}
				min="0"
				max="40"

			/>
		</div>
	);

}

export default PasswordStrengthMeter;