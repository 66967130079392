import * as React from "react";
import { Modal , ProgressBar } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import FCRATerms from "./idverifycomponents/FCRATerms";
import IDVerifyPrep from "./idverifycomponents/IDVerifyPrep";
import ChooseIdDocument from "./idverifycomponents/ChooseIDDocument";
import CheckPermissions from "./idverifycomponents/CheckPermissions";
import CaptureIDFront from "./idverifycomponents/CaptureIDFront";
import CaptureIDBack from "./idverifycomponents/CaptureIDBack";
import CaptureUserSelfie from "./idverifycomponents/CaptureUserSelfie";

function PurchaseWorkflow() {

	let ua = navigator.userAgent.toLowerCase();
	let platform = navigator.platform.toLowerCase();
	let platformName = ua.match(/ip(?:ad|od|hone)/) ? 'ios' : (ua.match(/(?:webos|android)/) || platform.match(/mac|win|linux/) || ['other'])[0];
	let isMobile = /ios|android|webos/.test(platformName);
	
	const params = useParams();
	const navigate = useNavigate();

	const pagetitles = {
		'fcra' : {
			title : 'Disclaimer',
			progressvalue : 5,
		},
		'idready' : {
			title : 'Ready to Verify Identity',
			progressvalue : 10,
		},
		'choosedocument' : {
			title : 'Choose ID Document',
			progressvalue : 15,
		},
		'checkpermissions' : {
			title : 'Check Permissions',
			progressvalue : 18,
		},
		'capturefront' : {
			title : 'Capture ID',
			progressvalue : 20,
		},
		'captureback' : {
			title : 'Capture ID',
			progressvalue : 25,
		},
		'capturevideo' : {
			title : 'Capture Video',
			progressvalue : 30,
		},
	};

	const [ screenOrientation , setScreenOrientation ] = React.useState('portrait');

	function exitWorkflow()
	{
		navigate('/user');
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let portrait = window.matchMedia('(orientation: portrait)');
		let landscape = window.matchMedia('(orientation: landscape)');

		if(portrait.matches)
		{
			setScreenOrientation('portrait');
		}
		portrait.addEventListener('change', (event) => {
			if(event.matches)
			{
				setScreenOrientation('portrait');
			}
		});
		if(landscape.matches)
		{
			setScreenOrientation('landscape');
		}
		landscape.addEventListener('change', (event) => {
			if(event.matches)
			{
				setScreenOrientation('landscape');
			}
		});
	},[]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<>
			{(isMobile && screenOrientation === 'landscape' && params.workflowstep === 'capturefront') && <CaptureIDFront isMobile={isMobile} platformNam={platformName} screenOrientation={screenOrientation} side='Front' />}	
			{(isMobile && screenOrientation === 'landscape' && params.workflowstep === 'captureback') && <CaptureIDBack isMobile={isMobile} platformNam={platformName} screenOrientation={screenOrientation} side='Back' />}	
			{(isMobile && screenOrientation === 'portrait' && params.workflowstep === 'capturevideo') && <CaptureUserSelfie isMobile={isMobile} platformNam={platformName} screenOrientation={screenOrientation} />}	

			{!(isMobile && screenOrientation === 'landscape' && ['capturefront','captureback'].indexOf(params.workflowstep) !== -1 ) && 
			<Modal show={true} fullscreen='lg-down' size='xl' centered onHide={exitWorkflow} animation={false} backdrop='static'>
				<Modal.Header closeButton style={{backgroundColor:'#2A2D40',color:'#FFFFFF'}} closeVariant="white" className="rounded-0">
					<Modal.Title>{( params.workflowstep === undefined || (pagetitles[params.workflowstep] === undefined ) ? pagetitles['fcra'].title : pagetitles[params.workflowstep].title )}</Modal.Title>
				</Modal.Header>
				<ProgressBar variant="primary" now={( params.workflowstep === undefined || (pagetitles[params.workflowstep] === undefined ) ? pagetitles['fcra'].progressvalue : pagetitles[params.workflowstep].progressvalue )} className="rounded-0"  style={{marginTop:'-10px',height:'5px'}}/>
				<Modal.Body style={{backgroundColor:'#E9ECEF'}}>
					{(params.workflowstep === undefined || pagetitles[params.workflowstep] === undefined || params.workflowstep === 'fcra' ) && <FCRATerms isMobile={isMobile} screenOrientation={screenOrientation} />}
					{params.workflowstep === 'idready' && <IDVerifyPrep isMobile={isMobile} platformNam={platformName} screenOrientation={screenOrientation} /> }
					{params.workflowstep === 'choosedocument' && <ChooseIdDocument isMobile={isMobile} platformNam={platformName} screenOrientation={screenOrientation} />}
					{params.workflowstep === 'checkpermissions' && <CheckPermissions isMobile={isMobile} platformNam={platformName} screenOrientation={screenOrientation} />}
					{params.workflowstep === 'capturefront' && !(isMobile && screenOrientation === 'landscape') && <CaptureIDFront isMobile={isMobile} platformNam={platformName} screenOrientation={screenOrientation} side='Front' />}
					{params.workflowstep === 'captureback' && !(isMobile && screenOrientation === 'landscape') && <CaptureIDBack isMobile={isMobile} platformNam={platformName} screenOrientation={screenOrientation} side='Back' />}
					{params.workflowstep === 'capturevideo' && <CaptureUserSelfie isMobile={isMobile} screenOrientation={screenOrientation} />}
				</Modal.Body>
				<Modal.Footer style={{backgroundColor:'#2A2D40',color:'#FFFFFF'}} className="rounded-0">
					Powered by ShieldHub
				</Modal.Footer>
			</Modal>}
		</>
	);
}
export default PurchaseWorkflow;