import { Button , Container, Stack , Badge } from 'react-bootstrap';
import { GridFill, PersonSquare, ChevronRight , Diagram3Fill, GearWideConnected, PersonBadge, BagFill, BellFill, CollectionPlayFill } from 'react-bootstrap-icons';
import { useBranding } from '../Branding';
import { useLocation , Link } from 'react-router-dom';
import { useAuth } from '../Auth';
import { useNavigate } from 'react-router-dom';


function SideBar(props) {

	const branding = useBranding();
	const location = useLocation();
	const navigate = useNavigate();
	const auth = useAuth();

	const linkStyles = {
		color: "#8A92A6",
		textAlign:"left",
		textUnderline:"none",
		textDecorationLine : "none",
		fontSize:"14px"
	};

	const activeLinkStyle = {
		color: branding.PrimaryColor,
		textAlign:"left",
		textUnderline:"none",
		textDecorationLine : "none",
		fontSize:"14px"
	};

	const iconStyles = {
		marginTop:"-3px",
		marginRight:"10px"
	};

	const expandIconStyle = {
		marginTop:"3px",
		float:"right",
	};

	function handleClick()
	{
		if(typeof props.onClick === 'function')
		{
			//trigger click up the component tree to dismiss the left menu
			props.onClick();
		}
	}


	return (
		<>
			<Stack gap={2} className="pt-3 ps-0 pe-1">
				<Container className='d-lg-none d-xl-none d-md-none' style={{color:'#2A2D40'}}>
					<table>
						<tbody>
							<tr>
								<td className="pe-2" style={{verticalAlign:'top'}}><PersonBadge className="float-left" size="44" color="#2A2D40"/></td>
								<td>
									<h5>{auth?.user?.firstname} {auth?.user?.lastname}</h5>
									<p>{auth?.user?.emailaddress}</p>
									<p>{ ( auth?.user?.certification_number?.length > 0 ? "ShieldID: " + auth.user.certification_number : '' )}</p>
								</td>
							</tr>
						</tbody>
					</table>
				</Container>
				<Button onClick={handleClick} style={{color:branding.PrimaryButtonText,backgroundColor:branding.PrimaryButtonBackground,borderColor:branding.PrimaryButtonBackground}} className="pt-2" href="/user/purchase"><BagFill className="d-lg-none text-md-center me-md-0" style={iconStyles}/><span className="d-md-none d-lg-inline" style={{whiteSpace:'normal'}}>Purchase Background Check</span></Button>
				<Button onClick={handleClick} variant="link" as={Link} className="dashboard" style={ (location.pathname === '/user' ? activeLinkStyle : linkStyles)} to="/user"><GridFill  style={iconStyles}/><span className="d-md-none d-lg-inline">Dashboard</span> {(location.pathname === '/user') && <ChevronRight className="d-md-none d-lg-inline"  style={expandIconStyle} size={18}/>}</Button>
				<Button onClick={handleClick} variant="link" as={Link} className="profile" style={ (location.pathname === '/user/profile' ? activeLinkStyle : linkStyles)} to="/user/profile"><PersonSquare style={iconStyles} /><span className="d-md-none d-lg-inline">My Profile</span>{(location.pathname === '/user/profile') && <ChevronRight className="d-md-none d-lg-inline" style={expandIconStyle} size={18}/>} </Button>
				<Button onClick={handleClick} variant="link" as={Link} className="network" style={ (location.pathname === '/user/network' ? activeLinkStyle : linkStyles)} to="/user/network"><Diagram3Fill style={iconStyles} /><span className="d-md-none d-lg-inline">Network</span> {(location.pathname === '/user/network') && <ChevronRight className="d-md-none d-lg-inline"  style={expandIconStyle} size={18}/>} </Button>
				{ auth.enterpriseprofile && auth.enterpriseprofile.alert_dashboard_enabled && auth.enterpriseprofile.alert_dashboard_enabled === true ? <>
					<Button className="alerts-navigation" onClick={handleClick} variant="link" as={Link} style={ (location.pathname === '/user/alerts' ? activeLinkStyle : linkStyles)} to="/user/alerts" ><BellFill style={iconStyles}/> <span className="d-md-none d-lg-inline">Alerts <Badge className='ms-2' bg={(auth.enterpriseprofile.alert_count === 0 ? 'secondary' : 'primary' )}>{auth.enterpriseprofile.alert_count}</Badge></span> {(location.pathname === '/user/alerts') && <ChevronRight className="d-md-none d-lg-inline"  style={expandIconStyle} size={18}/>}</Button>
				</> : null }
				{auth.user.role === 'ADMIN' && <Button onClick={handleClick} variant='link' as={Link} style={ (location.pathname.match(/\/admin\/(tools|user)/) ? activeLinkStyle : linkStyles)} to="/admin/tools"><GearWideConnected style={iconStyles} /><span className="d-none d-none d-lg-inline">Admin Tools</span> {(location.pathname.match(/\/admin\/(tools|user)/)) && <ChevronRight className="d-md-none" style={expandIconStyle} size={18}/>} </Button> }
				{/* <Button variant="link" as={Link} style={linkStyles}><FileEarmarkCheckFill style={iconStyles} /> Reporting <ChevronRight style={expandIconStyle} size={18}/></Button> */}
				<Button onClick={() => {
					handleClick();
					if(location.pathname === '/user' && props.starttourmethod)
					{
						props.starttourmethod();
					}
					else
					{
						navigate('/user?starttour=1');
					}
				}} variant="link" className="tour" style={linkStyles} ><CollectionPlayFill  style={iconStyles}/><span className="d-md-none d-lg-inline">Take a Tour</span></Button>


			</Stack>
		</>
	);

}

export default SideBar;