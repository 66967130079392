import * as React from "react";
import { Button, Card, Collapse, Form , Container , Row , Col } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";
import { useBranding } from "../../Branding";
import { useLocation } from "react-router-dom";
import { useRefreshEmitter } from "../../RefreshEmitter";
import {format as dateFormat , isValid as dateValid , parseJSON as dateParseJSON } from "date-fns";
import api from '../../../io/admin';


function PendingApproval()
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const branding = useBranding();
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ scorechanges , setScoreChanges ] = React.useState([]);

	async function LoadScoreChanges( userid )
	{
		let response = await api.getscorechanges( userid );

		if(response.success === true)
		{
			response.score_changes = response.score_changes.map( (score) => {

				if(score.change_dts)
				{
					score.change_dts = dateParseJSON(score.change_dts);
				}
				if(score.accepted_dts)
				{
					score.accepted_dts = dateParseJSON(score.accepted_dts);
				}

				return score;
			});

			setScoreChanges(response.score_changes);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setScoreChanges([
				{
					change_dts : new Date(),
					change_type : 'new',
					from_score : 'IC01+',
					from_expiration : '01/31/2024',
					to_score : 'IC01',
					to_expiration : '01/31/2026',
					accepted_dts : new Date(),
					status : 'APPROVED',
				},
				{
					change_dts : new Date(),
					change_type : 'monitorupdate',
					from_score : 'IC01+',
					from_expiration : '01/31/2024',
					to_score : 'IC01',
					to_expiration : '01/31/2026',
					accepted_dts : null,
					status : 'PENDING',
				},
				{
					change_dts : new Date(),
					change_type : 'monitorupdate',
					from_score : 'IC01+',
					from_expiration : '01/31/2024',
					to_score : 'IC04',
					to_expiration : '01/31/2026',
					accepted_dts : null,
					status : 'DISPUTE',
				},
			])
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setScoreChanges([]);

		( async () => {
			await LoadScoreChanges( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');

		if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadScoreChanges( userid );
			})();
		}
	},[emitter.eventname,hash]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;Pending Approvals</Button>
			<Collapse in={panelshow}>
				<Form>
					<Container style={{textAlign:'center'}} >
						{scorechanges && scorechanges.length > 0 &&
							<>
								<Row style={{color:branding.PrimaryColor,textTransform:'uppercase'}} className="p-4">
									<Col>Date Received</Col>
									<Col>Change Type</Col>
									<Col>Status</Col>
									<Col>Previous Score<br />Exp Date</Col>
									<Col>New Score<br />Exp Date</Col>
									<Col>Approved</Col>
								</Row>
								{scorechanges && scorechanges.map((change) => {

									return (<Row key={change.id} className="p-4 border-top">
										<Col>{(change.change_dts && dateValid(change.change_dts) ) ? dateFormat(change.change_dts,'MM/dd/yyyy') : null }</Col>
										<Col>
											{change.change_type === 'new' ? 'New/Renewal' : null }
											{change.change_type === 'monitorupdate' ? 'Monitoring Update' : null }
										</Col>
										<Col>
											{change.status === 'APPROVED' ? 'Approved' : null }
											{change.status === 'PENDING' ? 'Pending' : null }
											{change.status === 'DISPUTE' ? 'Dispute Submitted' : null }
										</Col>
										<Col>{change.from_score}<br />{change.from_expiration}</Col>
										<Col>{change.to_score}<br />{change.to_expiration}</Col>
										<Col>{change.accepted_dts && dateValid(change.accepted_dts) ? dateFormat(change.accepted_dts,'MM/dd/yyyy') : null }</Col>
									</Row>);

								})}
							</>
						}

					</Container>
				</Form>
			</Collapse>
		</Card>
	);
}
export default PendingApproval;