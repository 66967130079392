import { Container, Navbar , Button , Row , Col } from 'react-bootstrap';
import Logo from '../../assets/shieldhub-logo.png';
import { PersonBadge } from 'react-bootstrap-icons';
import { useAuth } from '../Auth';
import { useBranding } from '../Branding';

function Header() {

	const auth = useAuth();
	const branding = useBranding();

	return (
		<Navbar expand="lg">
			<Container fluid className="p-3 ps-5 pe-0">
				<Row style={{width: "100%"}}>
					<Col xs={1} sm={1} className="d-block d-md-none">

					</Col>
					<Col xs={5} sm={7} md={4} lg={2}>
						<Navbar.Brand>
							{process.env.REACT_APP_LOCALDEBUG ? <img src={Logo} height="50" alt="user home"/> : <img src={branding.Company.branding_logo_path} height="50" alt="user home"/>}
						</Navbar.Brand>
					</Col>
					<Col lg={9} xs={1} className="d-none d-lg-block user-info">
						<div className="float-end" style={{float: "right"}}>
							<table>
								<tbody>
									<tr>
										<td className="pe-2"><PersonBadge className="float-left" size="44" color="#BABADE"/></td>
										<td>
											<h5>{auth?.user?.firstname} {auth?.user?.lastname}</h5>
											<p>{auth?.user?.emailaddress}</p>
											<p>{ ( auth?.user?.certification_number?.length > 0 ? "ShieldID: " + auth.user.certification_number : '' )}</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Col>
					<Col xs={6} sm={4} md={8} lg={1}>
						<div className="float-end">
							<Button className="logout-button float-right" type="link" variant="outline-light" href="/logout">Logout</Button>
						</div>
					</Col>
				</Row>				
			</Container>
		</Navbar>
	);
}

export default Header;