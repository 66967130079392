import ShieldCheck from "../assets/white_shield_check.png"

function ICScore(props) {

	const scoremap = {
		'1' : {
			backgroundColor:"#B3C935",
			text : "IC01"
		},
		'IC01' : {
			backgroundColor:"#B3C935",
			text : "IC01"
		},
		'1+' : {
			backgroundColor:"#929E38",
			text : "IC01+"
		},
		'IC01+' : {
			backgroundColor:"#929E38",
			text : "IC01+"
		},
		'2' : {
			backgroundColor:"#FFB300",
			text : "IC02"
		},
		'IC02' : {
			backgroundColor:"#FFB300",
			text : "IC02"
		},
		'3' : {
			backgroundColor:"#F57C00",
			text : "IC03"
		},
		'IC03' : {
			backgroundColor:"#F57C00",
			text : "IC03"
		},
		'4' : {
			backgroundColor:"#D32F2F",
			text : "IC04"
		},
		'IC04' : {
			backgroundColor:"#D32F2F",
			text : "IC04"
		},
		'NA' : {
			backgroundColor:"#B1B1B1",
			text : ''
		}
	};

	let imageMarginAdjustment = "-1px";
	let score = scoremap['NA'];
	if(props?.score > 0 || scoremap[ props.score ] !== undefined)
	{
		score = scoremap[ props.score ];
		imageMarginAdjustment = "-5px"
	}

	const pillStyle = {
		backgroundColor:score.backgroundColor,
	};

	return (
		<div className="pill-style iscorecomponent" style={pillStyle}> 
			<img src={ShieldCheck} alt="Shield Checkmark" style={{marginTop:imageMarginAdjustment,marginLeft:"-10px",width:"20px"}}/><div style={{display:"inline-block",marginLeft:"5px",letterSpacing:"0px",minWidth:"40px"}}>
				{ ( score.text === 'IC01+' ? <>ICO1<sup>+</sup></> : score.text ) }
			</div>
		</div>
	);
}

export default ICScore;