import * as React from "react";
import { Button, Card, Col, Collapse, Modal, Row , Form , Alert, Spinner , Table , Tab, Nav , InputGroup } from "react-bootstrap";
import { ChevronDown, ChevronRight, ExclamationTriangleFill, FiletypeCsv, FiletypeXlsx, Upload, XCircleFill , Search } from "react-bootstrap-icons";
import api from '../../../io/admin-enterprise';
import { useLocation } from "react-router-dom";
import { filetypename } from 'magic-bytes.js';
import readXlsxFile from 'read-excel-file'
import { splitEasy } from "csv-split-easy";
import { useRefreshEmitter } from "../../RefreshEmitter";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { format as dateFormat , parseJSON as dateParse } from "date-fns";


function NetworkManagement( props )
{
	const knownColumns = [
		{
			'name':'firstname',
			'label' : 'First Name'
		},
		{
			'name':'lastname',
			'label' : 'Last Name'
		},
		{
			'name':'shieldid',
			'label' : 'ShieldID'
		},
		{
			'name':'emailaddress',
			'label' : 'Email Address'
		},
		{
			'name':'fullname',
			'label' : 'Full Name'
		},
	];

	const [ panelshow , setPanelShow ] = React.useState(true);
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();

	const [ enterpriseprofile , setEnterpriseProfile ] = React.useState({});
	const [ showInviteModal , setShowInviteModal ] = React.useState(false);
	const [ inviteFileData , setInviteFileData ] = React.useState(false);
	const [ uploadedfileinfo , setUploadedFileinfo ] = React.useState(false);
	const [ inviteDataIncludesHeader , setInviteDataIncludesHeader ] = React.useState(false);
	const [ columnMap , setColumnMap ] = React.useState([]);
	const [ DryRunResults , setDryRunResults ] = React.useState(false);
	const [ showinvalidrecords , setShowinvalidrecords ] = React.useState(false);
	const [ dryruninprogress , setDryruninprogress ] = React.useState(false);
	const [ sendinviteinprogress , setSendinviteinprogress ] = React.useState(false);
	const [ networkrecords , setNetworkrecords ] = React.useState({});
	const [ invitation_qf_text , setInvitation_qf_text ] = React.useState('');
	const [ connection_qf_text , setConnection_qf_text ] = React.useState('');

	const invitationColumns = [
		{ field : 'firstname' , headerName : 'First Name' , filter : true , },
		{ field : 'lastname' , headerName : 'Last Name' , filter : true , },
		{ field : 'shieldid' , headerName : 'ShieldID' , filter : true , } ,
		{ field : 'emailaddress' , headerName : 'Email Address' , filter : true , },
		{ field : 'connectionstatus' , headerName : 'Status' , filter : true ,} ,
		{ field : 'invite_dts_formatted' , headerName : 'Invite Date' , filter : 'agDateColumnFilter' , },
		{ field : 'invite_ignored' , headerName : 'Invite Ignored' , filter : true , },
	];

	const connectionColumns = [
		{ field : 'firstname' , headerName : 'First Name' , filter : true , },
		{ field : 'lastname' , headerName : 'Last Name' , filter : true , },
		{ field : 'shieldid' , headerName : 'Sheild ID' , filter : true , } ,
		{ field : 'emailaddress' , headerName : 'Email Address' , filter : true , },
		{ field : 'invite_dts_formatted' , headerName : 'Invite Date' , filter : 'agDateColumnFilter' , },
		{ field : 'share_dts_formatted' , headerName : 'Accept Date' , filter : 'agDateColumnFilter' , },
		{ field : 'reportshared' , headerName : 'PDF Report Shared' , filter : true },
	];

	const modaldropzoneref = React.createRef();
	const uploadInputRef = React.createRef();

	const formRef = React.createRef();

	const invitationgridref = React.createRef();
	const invitation_qf_ref = React.createRef();
	const connectiongridref = React.createRef();
	const connection_qf_ref = React.createRef();

	async function LoadProfile( userid )
	{
		let response = await api.getenterpriseprofile( userid , true );
		if(response.success === true)
		{
			setEnterpriseProfile({
				companyid : response.profile.companyid,
				network_autoinvite : response.profile.network_autoinvite,
				network_requires_pdf : response.profile.network_requires_pdf
			});

			if(response.invitations && response.invitations.length > 0)
			{
				response.invitations = response.invitations.map( (record) => {

					try{
						record.invite_dts_formatted = dateFormat(dateParse( record.invite_dts ), 'MM/dd/yyyy');
					}
					catch {
						record.invite_dts_formatted = null;
					}
					return record;
				});
			}

			if(response.connections && response.connections.length > 0)
			{
				response.connections = response.connections.map( (record) => {

					try{
						record.invite_dts_formatted = dateFormat(dateParse( record.invite_dts ), 'MM/dd/yyyy');
					}
					catch {
						record.invite_dts_formatted = null;
					}

					try { 
						record.share_dts_formatted = dateFormat(dateParse( record.share_dts ), 'MM/dd/yyyy');
					}
					catch{
						record.share_dts_formatted = null;
					}
					
					return record;
				});
			}


			setNetworkrecords({
				invitations : response.invitations,
				connections : response.connections
			})

		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setEnterpriseProfile({
				companyid : 1,
				network_autoinvite : 'YES'
			});

			setNetworkrecords({
				invitations : [
					{
						id : 2000,
						shieldid : 'CA999991030',
						firstname : 'John',
						lastname : 'Doe',
						emailaddress : 'john.doe@example.com',
						invite_dts : new Date(),
						invite_dts_formatted : dateFormat(dateParse( '2024-05-01T20:47:35.237Z' ),'MM/dd/yyyy'),
						invite_ignored : 'NO',
						connectionstatus : 'APPSTARTED'
					}
				],
				connections : [
					{
						id : 3000,
						shieldid : 'CA999991030',
						firstname : 'John',
						lastname : 'Doe',
						emailaddress : 'john.doe@example.com',
						invite_dts : new Date(),
						invite_dts_formatted : dateFormat(dateParse( '2024-05-01T20:47:35.237Z' ),'MM/dd/yyyy'),
						share_dts : new Date(),
						share_dts_formatted : dateFormat(dateParse( '2024-05-01T20:47:35.237Z' ),'MM/dd/yyyy'),
						reportshared : 'NO'
					}
				]
			})
		}
		else
		{
			console.warn(response);
		}
	}

	async function draghandler( event )
	{
		event.preventDefault();
		event.stopPropagation();

		if( event.type === 'dragenter')
		{
			event.currentTarget.classList.add('dropzone-hover');
		}
		else if( event.type === 'dragover')
		{
			event.currentTarget.classList.add('dropzone-hover');
		}
		else if( event.type === 'dragleave')
		{
			event.currentTarget.classList.remove('dropzone-hover');
		}
	}

	async function ParseFile( event )
	{
		event.preventDefault();
		event.stopPropagation();

		let fileobject;
		if(event.target.files && event.target.files[0])
		{
			fileobject = event.target.files[0];
		}
		else if(event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0])
		{
			fileobject = event.dataTransfer.files[0];
			event.currentTarget.className = '';
		}

		let freader = new FileReader();
		try{
			freader.onload = async () => {
				const bytes = new Uint8Array(freader.result);
				// Check file type
				let possiblefiletypes = filetypename(bytes);

				if(possiblefiletypes.indexOf('txt') !== -1 || possiblefiletypes.length === 0)
				{
					setUploadedFileinfo({ filename : fileobject.name , filetype : 'txt' });

					let enc = new TextDecoder('utf-8');
					let rows = splitEasy(enc.decode(freader.result));
					setInviteFileData(rows);
				}
				else if(possiblefiletypes.indexOf('xlsx') !== -1)
				{
					setUploadedFileinfo({ filename : fileobject.name , filetype : 'xlsx' });

					let rows = await readXlsxFile(freader.result);
					setInviteFileData(rows);
				}
				else
				{
					setUploadedFileinfo({ filename : fileobject.name , filetype : null });
					setInviteFileData([]);
				}
			}
			freader.readAsArrayBuffer(fileobject);
		}
		catch (e)
		{
			console.warn(e);
		}

	}

	function UpdateColumnMap( event , columnindex )
	{
		let newColMap = [ ...columnMap ];

		newColMap[columnindex] = event.target.value;

		setColumnMap(newColMap);

	}

	async function DryRun()
	{
		setDryruninprogress(true);
		
		let dryrunrequest = {
			dryrun : true,
			companyid : enterpriseprofile.companyid,
			invite_data : inviteFileData,
			invite_data_includes_header : inviteDataIncludesHeader,
			column_map : columnMap,
		};

		let response = await api.sendenterpriseinvites(dryrunrequest);
		if(response.success === true)
		{
			console.log(response);

			setDryRunResults(response.results);

			setDryruninprogress(false);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			
			setDryRunResults({
				existing_users_notinvited_found : 1,
				existing_users_has_active_invite : 5,
				existing_users_ignored_previous_invite : 2,
				existing_users_innetwork_skipped : 3,
				new_users_by_email_found : 4,
				emails_on_supression_list : 6,
				invalid_records : [
					['CA920251030']
				]
			});
			setDryruninprogress(false);
		}
	}

	async function RunInvites()
	{
		setSendinviteinprogress(true);

		let runinviterequest = {
			dryrun : false,
			companyid : enterpriseprofile.companyid,
			invite_data : inviteFileData,
			invite_data_includes_header : inviteDataIncludesHeader,
			column_map : columnMap,
		};

		let response = await api.sendenterpriseinvites(runinviterequest);
		if(response.success === true)
		{
			console.log(response);

			setDryRunResults(response.results);

			setSendinviteinprogress(false);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			
			setDryRunResults({
				existing_users_notinvited_found : 1,
				existing_users_has_active_invite : 5,
				existing_users_ignored_previous_invite : 2,
				existing_users_innetwork_skipped : 3,
				new_users_by_email_found : 4,
				emails_on_supression_list : 6,
				invitessent : 1,
				invalid_records : [
					['CA920251030']
				]
			});
			setSendinviteinprogress(false);
		}

	}

	function ResetModal()
	{
		setUploadedFileinfo(false);
		setInviteFileData(false);
		setInviteDataIncludesHeader(false);
		setColumnMap([]);
		setDryRunResults(false);
		setShowinvalidrecords(false);
	}

	function CloseandResetModal()
	{
		setShowInviteModal(false);
		ResetModal();
	}

	async function SaveProfile( newcompanyid = null )
	{
		let profileobject = {
			companyid : ( enterpriseprofile.companyid ? enterpriseprofile.companyid : null ),
			userid : enterpriseprofile.userid,
		};

		if(newcompanyid !== null && newcompanyid !== profileobject.companyid)
		{
			profileobject.companyid = newcompanyid;
		}

		let formData = new FormData(formRef.current);

		profileobject.network_autoinvite = ( formData.get('network_autoinvite') === 'YES' ? 'YES' : 'NO' );
		profileobject.network_requires_pdf = ( formData.get('network_requires_pdf') === 'YES' ? 'YES' : 'NO' );

		let response = await api.saveenterpriseprofile(profileobject);
		if(response.success === true)
		{
			props.setFormDirtyFunction(false);
		}
		else
		{
			console.warn(response);
		}
	}

	async function handleFormBlur()
	{
		props.setFormDirtyFunction(true);

		let profileobject = { ...enterpriseprofile };

		let formData = new FormData(formRef.current);

		profileobject.network_autoinvite = ( formData.get('network_autoinvite') === 'YES' ? 'YES' : 'NO' );
		profileobject.network_requires_pdf = ( formData.get('network_requires_pdf') === 'YES' ? 'YES' : 'NO' );

		setEnterpriseProfile(profileobject);
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');

		( async () => {
			await LoadProfile( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		if( ['admin:saveenterprise'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await SaveProfile();
			})();
		}
		else if( ['admin:newcompanyid'].indexOf(emitter.eventname) !== -1)
		{
			let newprofile = { ...enterpriseprofile };
			newprofile.companyid = emitter.data;
			setEnterpriseProfile(newprofile);

			( async () => {
				await SaveProfile( newprofile.companyid );
			})();
		}
	},[emitter.eventname]);	
	React.useEffect( () => {

		if(showInviteModal && modaldropzoneref)
		{
			modaldropzoneref.current.addEventListener('dragenter',draghandler);
			modaldropzoneref.current.addEventListener('dragleave',draghandler);
			modaldropzoneref.current.addEventListener('dragexit',draghandler);
			modaldropzoneref.current.addEventListener('dragover',draghandler);
			modaldropzoneref.current.addEventListener('drop',ParseFile);

		}

	},[showInviteModal])	
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<>
			<Card className="p-4 d-inline-block">
				<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow )}}>
					{panelshow && <ChevronDown />}
					{!panelshow && <ChevronRight />}
					&nbsp;Network Management</Button>
				<Collapse in={panelshow}>
					<Form ref={formRef} autoComplete="off">
						<Row className="feature-row" style={{paddingLeft:'35px',borderTop:'solid 1px #B1B1B1',borderBottom:'0px'}}>
							<Col xs={6}>
								<h6>Bulk Invitation Upload</h6>
							Create new invites from an uploaded CSV or Excel file.</Col>
							<Col><Button onClick={() => { setShowInviteModal(true); }}>Start Bulk Upload</Button></Col>
						</Row>
						<Row className="feature-row" style={{paddingLeft:'35px',borderTop:'solid 1px #B1B1B1',borderBottom:'0px'}}>
							<Col xs={6}>
								<h6>Network Auto-Invite</h6>
								<p>Automatically invite users who register or login to purchase background checks within the client&apos;s branded subdomain to the client&apos;s trusted network.</p>
							</Col>
							<Col xs={1}>
								<Form.Check
									type="switch"
									style={{maxWidth:'50px',marginLeft:'auto',marginRight:'auto',transform:"scale(1.3)"}}
									checked={enterpriseprofile.network_autoinvite === 'YES'}
									name="network_autoinvite"
									value="YES"
									onChange={handleFormBlur}
								/>																			
							</Col>
						</Row>
						<Row className="feature-row" style={{paddingLeft:'35px',borderTop:'solid 1px #B1B1B1',borderBottom:'0px'}}>
							<Col xs={6}>
								<h6>Network Connections require PDF Share</h6>
								<p>Automatically enable PDF sharing when network invites are accepted</p>
							</Col>
							<Col xs={1}>
								<Form.Check
									type='switch'
									style={{maxWidth:'50px',marginLeft:'auto',marginRight:'auto',transform:"scale(1.3)"}}
									checked={enterpriseprofile.network_requires_pdf === 'YES'}
									name='network_requires_pdf'
									value='YES'
									onChange={handleFormBlur}
								/>
							</Col>
						</Row>
						<Row className="pt-3" style={{borderTop:'solid 1px #B1B1B1'}}>
							<Col xs={12}>
								<Tab.Container defaultActiveKey='invitations'>
									<Nav variant="tabs">
										<Nav.Item xs={6}>
											<Nav.Link eventKey='invitations'>Invitations</Nav.Link>
										</Nav.Item>
										<Nav.Item xs={6}>
											<Nav.Link eventKey='connections'>Connections</Nav.Link>
										</Nav.Item>
									</Nav>
									<Tab.Content className="ps-1">
										<Tab.Pane eventKey='invitations'>
											<div >
												<InputGroup className="mb-1 enterprise-invitations-quickfilter">
													<Form.Control 
														ref={invitation_qf_ref}
														type='text' 
														name="invitiationquickfilter" 
														placeholder="Quick Filter"
														onInput={(e) => {
															setInvitation_qf_text(e.target.value);
														}}
													/>
													<InputGroup.Text>
														<Search className="searchbutton" size={12} />
														<Button className="clearbutton p-0" variant="link" onClick={() => { invitation_qf_ref.current.value = '';  }}><XCircleFill style={{pointer:'cursor'}} color="grey"/></Button>
													</InputGroup.Text>
												</InputGroup>
											</div>


											<div className="ag-theme-quartz" style={{height:'500px',minWidth:'1100px'}}>
												<AgGridReact
													ref={invitationgridref}
													rowData={networkrecords.invitations}
													columnDefs={invitationColumns}
													pagination={true}
													paginationPageSize={20}
													quickFilterText={invitation_qf_text}
												/>
											</div>
										</Tab.Pane>
										<Tab.Pane eventKey='connections'>
											<div >
												<InputGroup className="mb-1 enterprise-connections-quickfilter">
													<Form.Control 
														ref={connection_qf_ref}
														type='text' 
														name="connectionquickfilter" 
														placeholder="Quick Filter"
														onInput={(e) => {
															setConnection_qf_text(e.target.value);
														}}
													/>
													<InputGroup.Text>
														<Search className="searchbutton" size={12} />
														<Button className="clearbutton p-0" variant="link" onClick={() => { connection_qf_ref.current.value = '';  }}><XCircleFill style={{pointer:'cursor'}} color="grey"/></Button>
													</InputGroup.Text>
												</InputGroup>
											</div>


											<div className="ag-theme-quartz" style={{height:'500px',minWidth:'1100px'}}>
												<AgGridReact
													ref={connectiongridref}
													rowData={networkrecords.connections}
													columnDefs={connectionColumns}
													pagination={true}
													paginationPageSize={20}
													quickFilterText={connection_qf_text}
												/>
											</div>
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
							</Col>
						</Row>
					</Form>
				</Collapse>
			</Card>
			<Modal size='xl' id='inviteuploadmodal' show={showInviteModal} onHide={CloseandResetModal}>
				<Modal.Header closeButton>
					<Modal.Title>Invite Users</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					<Row>
						<Col xs={2}className='pt-2'><h6>Upload XLS,CSV File</h6></Col>
						<Col xs={10}>
							<div ref={modaldropzoneref} id="dropzone" className={ uploadedfileinfo?.filename && uploadedfileinfo?.filename !== '' ? 'dropzone-hasfile' : '' }>
								<span>Drop File or&nbsp;</span><Button className="upload-btn" onClick={() => { uploadInputRef.current.click(); }}><Upload/>&nbsp;&nbsp;Click here to upload</Button>
								<input ref={uploadInputRef} type="file" name="invitefileupload" style={{display:"none"}} onChange={ParseFile} />
								{uploadedfileinfo.filename && 
									<>
										<span><br /></span>
										<Button variant='light' className="mt-3" onClick={ResetModal}>
											{uploadedfileinfo.filetype === 'xlsx' && <FiletypeXlsx size={20}/>}
											{uploadedfileinfo.filetype === 'txt' && <FiletypeCsv size={20}/>}
											{uploadedfileinfo.filename}
											<XCircleFill style={{marginLeft:'10px'}} />
										</Button>
									</>
								}
							</div>
						</Col>
					</Row>
					{inviteFileData && inviteFileData.length > 0 &&
						<>
							<Row className="mt-4">
								<Col xs={2} className='pt-2'>
								
									<h6 className="mb-2">Sample Rows</h6>
									Total records detected :<br />{inviteDataIncludesHeader ? (inviteFileData.length-1) : inviteFileData.length}
								</Col>
								<Col xs={10}>
									<Row>
										<Form.Check 
											id='data_includes_header_check'
											label='Data includes header'
											checked={inviteDataIncludesHeader}
											onClick={(event) => {
												setInviteDataIncludesHeader(event.target.checked);
											}}
										/>
									</Row>
									<Row>
										<Col xs={12} style={{overflowX:'scroll'}}>
											<Table>
												<thead>
													<tr className="import-headers">
														{inviteFileData[0].slice(0, 11).map((cell, index) => (
															<th key={index}>
																{inviteDataIncludesHeader === true ? (cell && typeof cell === 'object' ? cell.toString() : cell ) : <>{`Column ${index+1}`}</> }&nbsp;&nbsp;
																<select onChange={(event) => { UpdateColumnMap(event,index); }}>
																	<option value=''>Ignore Column</option>
																	{knownColumns.map((column, index) => (
																		<option key={index} value={column.name}>{column.label}</option>
																	))}
																</select>
															</th>
														))}
													</tr>
												</thead>
												<tbody>
													{inviteFileData.slice( (inviteDataIncludesHeader ? 1 : 0 )  ,5).map((row, index) => (
														<tr key={index}>
															{row.slice(0,11).map((cell, cellIndex) => (
																<td key={cellIndex}>{(cell && typeof cell === 'object' ? cell.toString() : cell )}</td>
															))}
														</tr>
													)) }
												</tbody>
											</Table>
										</Col>
									</Row>
									{ columnMap.indexOf('shieldid') === -1 && columnMap.indexOf('emailaddress') === -1 &&
										<Row className="pt-2">
											<Col>
												<Alert variant='warning'>Required columns of ShieldID or Email Address are not mapped. Please map more columns.</Alert>
											</Col>
										</Row>
									}

									{ (columnMap.indexOf('shieldid') !== -1 || columnMap.indexOf('emailaddress') !== -1) &&
										<Row className="pt-2">
											<Col>
												<Button onClick={DryRun}>Count & Verify Records {dryruninprogress && <Spinner size="sm" />} </Button>
											</Col>
										</Row>
									}
									{DryRunResults &&
									<>
										<Col className="p-3 mt-4 import-summary">
											<h6>Records for Import:</h6>
											<Row>
												<Col xs={7}>Existing Users Already in Network (will skip)</Col><Col>{DryRunResults.existing_users_innetwork_skipped}</Col>
											</Row>
											<Row>
												<Col xs={7}>Existing Users with Pending Invite (will skip)</Col><Col>{DryRunResults.existing_users_has_active_invite}</Col>
											</Row>
											<Row>
												<Col xs={7}>Existing Users To Be Invited</Col><Col>{DryRunResults.existing_users_notinvited_found}</Col>
											</Row>
											<Row>
												<Col xs={7}>Existing Users Ignored Previous Invites (resending invite)</Col><Col>{DryRunResults.existing_users_ignored_previous_invite}</Col>
											</Row>
											<Row>
												<Col xs={7}>New Email Addresses To Be Invited</Col><Col>{DryRunResults.new_users_by_email_found}</Col>
											</Row>
											<Row>
												<Col xs={7}>Email Addresses on supression list (will attempt, but might be blocked, counted across all results) </Col><Col>{DryRunResults.emails_on_supression_list}</Col>
											</Row>
											<Row>
												<Col xs={7}>Invalid Records {DryRunResults.invalid_records.length > 0 && <a href="javascript:void(0);" onClick={() => { setShowinvalidrecords(!showinvalidrecords); }}>{showinvalidrecords === false ? 'show' : 'hide'}</a>}</Col>
												<Col>
													{DryRunResults.invalid_records.length}
												</Col>
											</Row>
											{showinvalidrecords && <Row>
												<Col xs={12} className="ps-5">
													<Table>
														<tbody>
															{DryRunResults.invalid_records.map((row, index) => (
																<tr key={index}>
																	{row.map((cell, cellIndex) => (
																		<td key={cellIndex}>{cell}</td>
																	))}
																</tr>
															)) }
														</tbody>
													</Table>
												</Col>
											</Row>
											}
										</Col>
									</>
									}

									{ (DryRunResults.existing_users_notinvited_found + DryRunResults.new_users_by_email_found + DryRunResults.existing_users_ignored_previous_invite) > 0 && 
									<>
										<Row className="pt-2">
											<Col>
												<Button onClick={RunInvites} disabled={(DryRunResults.invitessent && DryRunResults.invitessent >= 1)}  >Create and Send Invites to {(DryRunResults.existing_users_notinvited_found + DryRunResults.new_users_by_email_found + DryRunResults.existing_users_ignored_previous_invite)} users {sendinviteinprogress && <Spinner size="sm" />} </Button>
											</Col>
										</Row>
										{DryRunResults.invitessent && DryRunResults.invitessent >= 0 && 
										<Row className="pt-3">
											<Col>
												<Alert variant="success">{DryRunResults.invitessent} invites have been queued. Invites might take a few minutes to finish sending.</Alert>
											</Col>
										</Row>
										}
									</>
									}
								</Col>
							</Row>
						</>
					}

					{inviteFileData && inviteFileData.length === 0 && 
						<Row>
							<Col xs={{offset:2,span:10}}>
								<Alert variant="danger" className="mt-3 text-center"><ExclamationTriangleFill size={18} /> Error : No records detected in uploaded file </Alert>
							</Col>
						</Row>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={CloseandResetModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>

	);

}
export default NetworkManagement;