import * as React from 'react';
import { Modal , Card , Row , Col , Form , FloatingLabel , Button , Alert, Spinner } from 'react-bootstrap';
import { ShieldFillCheck , ExclamationTriangleFill , Link45deg } from 'react-bootstrap-icons';
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { format as dateFormat , isValid as dateValid } from 'date-fns';
import api from "../../io/profile";
import { useRefreshEmitter } from '../RefreshEmitter';


function LookupArchiveShieldID() {

	const params = useParams();
	const navigate = useNavigate();
	const emitter = useRefreshEmitter();
		
	const [ profile , setProfile ] = React.useState({});

	const [ ssnfocus , setSSnFocus ] = React.useState(false);

	const [ errorfields , setErrorFields ] = React.useState({});

	const [ lookupinprogress , setLookupinprogress ] = React.useState(false);

	const [ lookupresult , setLookupResult ] = React.useState(null);

	const formref = React.createRef();

	function formatSSN( event )
	{
		let validControlKeyCodes = [8,9,37,39,46];

		let newprofile = { ...profile };

		if(newprofile.ssn === undefined || newprofile.ssn === null)
		{
			newprofile.ssn = '';
		}

		if(validControlKeyCodes.indexOf(event.keyCode) !== -1)
		{
			if(event.keyCode === 8 && newprofile.ssn && newprofile.ssn.length > 0)
			{
				newprofile.ssn = newprofile.ssn.substring(0,newprofile.ssn.length-1);

				setProfile(newprofile);
			}

			//control keys like Tab, backspace or arrows
			return;
		}
		else if(!event.key.match(/[0-9]/))
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		newprofile.ssn += event.key;

		if(newprofile.ssn && newprofile.ssn.match(/^[0-9]{3}$/))
		{
			newprofile.ssn += "-";
		}
		else if(newprofile.ssn && newprofile.ssn.match(/^[0-9]{3}-[0-9]{2}$/))
		{
			newprofile.ssn += "-";
		}
		else if(newprofile.ssn && newprofile.ssn.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/))
		{
			
		}
		else if(newprofile.ssn && newprofile.ssn.match(/^[0-9]{1,3}-?[0-9]{0,3}-?[0-9]{0,4}$/))
		{

		}
		else
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		newprofile.maskedssn = "●●●-●●-●●●●";

		setProfile(newprofile);		
	}

	function handleDOBChange( date )
	{
		let newprofile = { ...profile };

		newprofile.dateofbirth = date;

		setProfile(newprofile);
	}

	function handleValueUpdate( event )
	{
		let newprofile = { ...profile };

		newprofile.sheildid = event.currentTarget.value.toUpperCase();
		event.currentTarget.value = event.currentTarget.value.toUpperCase();

		setProfile(newprofile);
	}

	async function Lookup()
	{
		let newerrorfields = {};
		let formData = new FormData(formref.current);

		if(!formData.get('shieldid') || formData.get('shieldid').length !== 11)
		{
			newerrorfields.shieldid = true;
		}
		if(!profile.ssn || profile.ssn.length !== 11)
		{
			newerrorfields.ssn = true;
		}
		if(!formData.get('dateofbirth') || formData.get('dateofbirth').length !== 10)
		{
			newerrorfields.dateofbirth = true;
		}

		if(Object.keys(newerrorfields).length > 0)
		{
			setErrorFields(newerrorfields);
			return;
		}
		setErrorFields(newerrorfields);


		setLookupinprogress(true);

		let request = {
			shieldid : formData.get('shieldid'),
			ssn: profile.ssn,
			dateofbirth: profile.dateofbirth,
		};

		let response = await api.lookuparchivedshieldid(request);
		if(response.success)
		{
			if(response.matchfound !== undefined && response.matchfound !== null)
			{
				setLookupResult({ 
					matchfound : response.matchfound,
					shieldid : response.shieldid
				});

				emitter.Emit('globalrefresh');
				emitter.Emit('shieldidrefresh');
			}
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setLookupResult({ matchfound : true });
		}

		setLookupinprogress(false);
	}

	function ResetModal()
	{
		setProfile({});
		setSSnFocus(false);
		setErrorFields({});
		setLookupinprogress(false);
		setLookupResult(null);

		navigate('/user/profile');
		return;
	}

	return (
		<Modal show={params.workflowstep === 'linkmyaccount'} onHide={ResetModal}>
			<Modal.Header closeButton>
				<Modal.Title>Link My Account</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Card border='light'>
					<Card.Body>
						<h2 className="light text-primary">Maybe it&apos;s been a while,<br />but we&apos;re glad to see you&apos;re back.</h2>

						<Row className="mt-3 mb-2">
							<p className="text-dark">
							If you are an existing ShieldHub customer but do not have access to your archived ShieldID&trade;, you can attempt to link your previous ShieldHub account to your ShieldHub profile.</p>
							<p><b>Reactivate your ShieldID using your Social Security Number and Birthdate&nbsp;below.</b></p>
						</Row>
						<Row>
							<Form ref={formref} className="mb-3" autoComplete='off'>
								<FloatingLabel className="Form-Control mb-2" controlId="shieldid" label="SHIELD ID"> 
									<Form.Control
										name="shieldid"
										placeholder='SHIELD ID'
										value={profile.shieldid}
										type="text"
										isInvalid={errorfields.shieldid}
										maxLength={11}
										onChange={handleValueUpdate}
										onKeyUpCapture={handleValueUpdate}
									/>
								</FloatingLabel>
								<FloatingLabel className="Form-Control mb-2" controlId="ssn" label="SOCIAL SECURITY NUMBER"> 
									<Form.Control
										name="ssn"
										placeholder='SOCIAL SECURITY NUMBER'
										type="text"
										isInvalid={errorfields.ssn}
										value={ssnfocus ? (profile.ssn ? profile.ssn : '') : ( profile.maskedssn ? profile.maskedssn : '') }
										onKeyDown={formatSSN}
										onBlur={() => {
											setSSnFocus(false);
										}}
										onFocus={() => {
											setSSnFocus(true);
										}}
										onChange={() => {

										}}

									/>
								</FloatingLabel>
								<DatePicker 
									selected={(profile.dateofbirth && dateValid(profile.dateofbirth) ? profile.dateofbirth : null )} 
									onSelect={handleDOBChange}
									showYearDropdown
									yearDropdownItemNumber={100}
									scrollableYearDropdown
									popperPlacement='top-start'
									filterDate={(date) => {
										const currentDate = new Date();
										const min_date = new Date('1900-01-01T00:00:00');
										return date < currentDate && date > min_date ;
									}}
									customInput={<FloatingLabel controlId="dateofbirth" label="DATE OF BIRTH"><Form.Control type="text" placeholder="DATE OF BIRTH" name="dateofbirth" isInvalid={errorfields.dateofbirth} defaultValue={ (profile.dateofbirth && dateValid(profile.dateofbirth) ? dateFormat(profile.dateofbirth,"MM/dd/yyyy") : null )} /></FloatingLabel>}
									popperClassName="react-datepicker-popper-zindex"
								/>
							</Form>
						</Row>
						{(!lookupresult || lookupresult?.matchfound === false) && <Row>
							<Col xs={3} sm={5}>
								<Button size="lg" variant="link" onClick={ResetModal} className="exit-link text-primary">Close</Button>
							</Col>
							<Col xs={{offset:1,span:8}} sm={{offset:2,span:5}}>
								<Button size="lg" variant="primary" onClick={Lookup} disabled={lookupinprogress} style={{width:'100%'}}>
									{lookupinprogress && <><Spinner size='sm' /> Please wait </>}
									{lookupinprogress === false && <><Link45deg className="icon-left" />Link My ShieldID&trade;</>}
								</Button>
							</Col>
						</Row>}
					</Card.Body>
				</Card>

				<Alert variant="success" show={lookupresult && lookupresult?.matchfound === true} className="mt-2 mb-2">
					<Row>
						<Col xs={1}><ShieldFillCheck style={{fontSize: '24px', marginRight: '5px'}}/></Col>
						<Col xs={9}>Your ShieldID #{lookupresult && lookupresult.shieldid} was found and linked to your profile.</Col>
						<Col xs={2}><Button variant='success' onClick={ResetModal}>OK</Button></Col>
					</Row>
				</Alert>
				<Alert variant="danger" show={lookupresult && lookupresult?.matchfound === false} className="mt-2 mb-2">
					<Row>
						<Col xs={1}><ExclamationTriangleFill style={{fontSize: '24px', marginRight: '5px'}}/></Col>
						<Col xs={11}>The information you provided was unable to provide a match. Please try again or contact <a href="https://support.shieldhub.com/" target='_blank'>ShieldHub Support</a> for more information.</Col>
					</Row>
				</Alert>

			</Modal.Body>
		</Modal>
	)

}
export default LookupArchiveShieldID;