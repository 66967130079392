import axios from "axios";

const base_api_endpoint = process.env.REACT_APP_API_ENDPOINT;

async function getstates()
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.get(base_api_endpoint+"/general/states",{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.states = response.data.states;
		}
	}
	catch (e){
		console.log(e);
	}


	return apiresponse;	
}

async function branding()
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.get(base_api_endpoint+"/general/branding",{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.branding = response.data.branding;
			apiresponse.defaultbranding = response.data.defaultbranding;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	

}

var methods = { getstates , branding };

export default methods;