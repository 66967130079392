import * as React from "react";
import { useNavigate , useLocation } from "react-router-dom";
import { useAuth } from "../components/Auth";


function Logout() {

	const navigate = useNavigate();
	const auth = useAuth();
	const { search } = useLocation();
	const querystring = new URLSearchParams(search, [search]);

	React.useEffect(() => {

		(async () => {

			await auth.logout();

			if(querystring.get('newpassword') !== null && querystring.get('newpassword') !== undefined)
			{
				navigate("/login?newpassword");
			}
			else
			{
				navigate("/login");
			}

		})();

	});

	return (
		<></>
	)
}

export default Logout;