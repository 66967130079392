import * as React from "react";
import { Card , Row , Col , Button } from "react-bootstrap";
import { PlayFill,PersonFillAdd, Link45deg } from "react-bootstrap-icons";
import api from "../../../io/profile";
import { isFuture , differenceInDays , isValid, isPast , format as dateFormat , parseJSON as dateParse } from "date-fns";
import { Link, useLocation } from "react-router-dom";

function PurchaseAlerts( props )
{

	const { hash } = useLocation();

	const [ profile , setProfile ] = React.useState({});
	const [ InProgressApp , setInProgressApp ] = React.useState(false);
	const [ PaidApp , setPaidApp ] = React.useState(false);

	const [ UserStatus , setUserStatus ] = React.useState(null);

	const [ selectedPackageId , setSelectedPackageId ] = React.useState(false);
	
	async function LoadProfile()
	{
		let profileresponse = await api.getmyprofile();

		let applicationresponse = await api.listapplications();

		if(profileresponse.success && applicationresponse.success)
		{
			if(profileresponse.profile && profileresponse.profile.current_expiration)
			{
				profileresponse.profile.current_expiration_dts = dateParse(`${profileresponse.profile.current_expiration}T11:00:00Z`);
			}

			setProfile(profileresponse.profile);

			let in_progress_applications = [];
			let paid_applications = [];

			if(applicationresponse.applications && applicationresponse.applications.length > 0 )
			{
				in_progress_applications = applicationresponse.applications.filter( (app) => {
					return app.composite_status_code && app.composite_status_code !== 'paid' && app.composite_status_code !== 'done';
				});

				if(in_progress_applications[0] && in_progress_applications[0].ordered)
				{
					in_progress_applications[0].ordered_dts = dateParse(in_progress_applications[0].ordered);
				}

				setInProgressApp(in_progress_applications[0]);

				paid_applications = applicationresponse.applications.filter( (app) => {
					return app.composite_status_code && app.composite_status_code === 'paid';
				});

				setPaidApp(paid_applications[0]);
			}

			if(profileresponse.profile && profileresponse.profile.certification_number?.length > 0 && profileresponse.profile.current_expiration_dts && isValid(profileresponse.profile.current_expiration_dts) && isFuture(profileresponse.profile.current_expiration_dts) && differenceInDays(profileresponse.profile.current_expiration_dts,new Date()) > 45)
			{
				setUserStatus('active');
			}
			else if(profileresponse.profile && profileresponse.profile.certification_number?.length > 0 && profileresponse.profile.current_expiration_dts && isValid(profileresponse.profile.current_expiration_dts) && isFuture(profileresponse.profile.current_expiration_dts) && differenceInDays(profileresponse.profile.current_expiration_dts, new Date()) <= 45)
			{
				setUserStatus('renewal');
			}
			else if(profileresponse.profile && profileresponse.profile.certification_number?.length > 0 && profileresponse.profile.current_expiration_dts && isValid(profileresponse.profile.current_expiration_dts) && isPast(profileresponse.profile.current_expiration_dts))
			{
				setUserStatus('expired');
			}
			else if(paid_applications.length > 0)
			{
				setUserStatus('paid');
			}
			else if(in_progress_applications.length > 0)
			{
				setUserStatus('inprogress');
			}
			else
			{
				setUserStatus('new');
			}
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		if(hash.match(/#pkgid=/))
		{
			if(hash.match(/#pkgid=.+/))
			{
				var hashvalues = hash.match(/#pkgid=(.+)/);

				setSelectedPackageId(hashvalues[1]);
			}
		}

	},[ hash  ]);

	React.useEffect( () => {
		( async () => {
			await LoadProfile();
		})();
	},[]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<>
			{UserStatus === 'new' && <>
				<Card className="card-blue">
					<Card.Body>
						<h2>Welcome.</h2>
						<h2 className="light">We see you&apos;re purchasing your first ShieldScore<span style={{letterSpacing: '-8px'}}>&trade;.</span></h2>
						<Row className="mt-3 mb-3">
							<p>After completing the purchase, you will be requested to submit a background check application.  Your ShieldID&trade; and ShieldScore&trade; will be calculated when the background check is complete and delivered from the consumer reporting agency.</p>
						</Row>
						<Row className="mt-2 mb-2">
							<Col xs={3} sm={5}>
								<Button size="lg" variant="link" onClick={( props.exitWorkflowMethod ? props.exitWorkflowMethod : null ) } className="exit-link">Exit</Button>
							</Col>
							<Col xs={{offset:1,span:8}} sm={{offset:2,span:5}}>
								<Button size="lg" variant="primary" as={Link} to={`/user/purchase/completepurchase#pkgid=${selectedPackageId}`} style={{width:'100%'}}>Continue Purchase<PlayFill className="icon-right" /></Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
				<Card className="register-container mt-2">
					<Card.Body>
						<h2>Returning to ShieldHub?</h2>
						<Row className="mt-3 mb-3">
							<p className="text-light">If you are a returning customer with an <b><u>existing ABC# or ShieldID</u></b>, use the link below to relink and activate your previous ShieldID.</p>
						</Row>
						<Row className="mt-2 mb-2">
							<Col xs={3} sm={5}>
								<Button size="lg" variant="link" onClick={( props.exitWorkflowMethod ? props.exitWorkflowMethod : null ) } className="exit-link">Exit</Button>
							</Col>
							<Col xs={{offset:1,span:8}} sm={{offset:2,span:5}}>
								<Button size="lg" variant="outline-light" as={Link} to='/user/profile/linkmyaccount' style={{width:'100%'}}>
									<Link45deg className="icon-left" />Link My ShieldID&trade; (ABC#)
								</Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</>}

			{UserStatus === 'inprogress' && 
				<Card className="card-blue mt-2">
					<Card.Body>
						<h2>Welcome Back.</h2>
						<h2 className="light">We&apos;re still working on your background check.</h2>
						<Row className="mt-3 mb-3">
							<p>Our records indicate that you already ordered a background check on {(InProgressApp.ordered_dts && isValid(InProgressApp.ordered_dts) ? dateFormat(InProgressApp.ordered_dts,"MM/dd/yyyy") : null )}.</p>

							<p>We have not received the final results from the consumer reporting agency.  If you have questions, we recommend you contact the consumer reporting agency processing your order:</p>

						</Row>
						<Row className="mt-3 mb-3">
							<Col xs={6} className="cra-block">
								<label>CRA provider</label>
								<p>{InProgressApp.provider}</p>
							</Col>
							<Col xs={6} className="cra-block">
								<label>Order Date</label>
								<p>{(InProgressApp.ordered_dts && isValid(InProgressApp.ordered_dts) ? dateFormat(InProgressApp.ordered_dts,"MM/dd/yyyy") : null )}</p>
							</Col>
							<Col xs={6} className="cra-block">
								<label>Phone</label>
								<p>{InProgressApp.provider_support_phone}</p>
							</Col>
							<Col xs={6} className="cra-block">
								<label>ORDER/Profile ID</label>
								<p>{InProgressApp.provider_profile_number}</p>
							</Col>
						</Row>
						<Row>
							<Col xs={3} sm={5}>
								<Button size="lg" variant="link" onClick={( props.exitWorkflowMethod ? props.exitWorkflowMethod : null ) } className="exit-link">Exit</Button>
							</Col>
							<Col xs={{offset:1,span:8}} sm={{offset:2,span:5}}>
								<Button size="lg" variant="primary" as={Link} to={`/user/purchase/completepurchase#pkgid=${selectedPackageId}`} style={{width:'100%'}}>Continue Purchase<PlayFill className="icon-right" /></Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			}

			{UserStatus === 'paid' && 
				<Card className="card-blue mt-2">
					<Card.Body>
						<h2>Welcome Back.</h2>
						<h2 className="light">We see yo&apos;ve started the process, but haven&apos;t submitted your application.</h2>
						<Row className="mt-3 mb-3">
							<p>Our records indicate that you already ordered a background check on {(PaidApp.dts && isValid(PaidApp.dts) ? dateFormat(PaidApp.dts,"MM/dd/yyyy") : null )}.</p>

							<p>We have not received the final results from the consumer reporting agency.  If you have questions, we recommend you contact the consumer reporting agency processing your order:</p>

						</Row>
						<Row>
							<Col xs={3} sm={5}>
								<Button size="lg" variant="link" onClick={( props.exitWorkflowMethod ? props.exitWorkflowMethod : null ) } className="exit-link">Exit</Button>
							</Col>
							<Col xs={{offset:1,span:8}} sm={{offset:2,span:5}}>
								<Button size="lg" variant="primary" href='' style={{width:'100%'}}>Continue Purchase<PlayFill className="icon-right" /></Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			}

			{UserStatus === 'renewal' &&
				<Card className="card-blue mt-2">
					<Card.Body>
						<h2>Welcome Back.</h2>
						<h2 className="light">We see your ShieldScore&trade; is expiring soon.</h2>
						<Row className="mt-3 mb-3">
							<p>
								Our records indicate that your ShieldScore&trade; expires on <>{(profile.current_expiration_dts && isValid(profile.current_expiration_dts) ? dateFormat(profile.current_expiration_dts,"MM/dd/yyyy") : null )}</>.  We recommend you continue your purchase to avoid service interruptions. 
							</p>
						</Row>
						<Row>
							<Col xs={3} sm={5}>
								<Button size="lg" variant="link" onClick={( props.exitWorkflowMethod ? props.exitWorkflowMethod : null ) } className="exit-link">Exit</Button>
							</Col>
							<Col xs={{offset:1,span:8}} sm={{offset:2,span:5}}>
								<Button size="lg" variant="primary" as={Link} to={`/user/purchase/completepurchase#pkgid=${selectedPackageId}`} style={{width:'100%'}}>Continue Purchase<PlayFill className="icon-right" /></Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			}
			{UserStatus === 'expired' &&
				<Card className="card-blue mt-2">
					<Card.Body>
						<h2>Welcome Back.</h2>
						<h2 className="light">We see your current ShieldScore&trade; has expired.</h2>
						<Row className="mt-3 mb-3">
							<p>
								Our records indicate that your ShieldScore expired on <>{(profile.current_expiration_dts && isValid(profile.current_expiration_dts) ? dateFormat(profile.current_expiration_dts,"MM/dd/yyyy") : null )}</>.
							</p>
							<p>
								We will reactivate your ShieldScore once we receive your background check results. Once activated, users will be able to verify your ShieldScore from your ShieldID&trade; of <>{profile.certification_number}</>.
							</p>
						</Row>
						<Row>
							<Col xs={3} sm={5}>
								<Button size="lg" variant="link" onClick={( props.exitWorkflowMethod ? props.exitWorkflowMethod : null ) } className="exit-link">Exit</Button>
							</Col>
							<Col xs={{offset:1,span:8}} sm={{offset:2,span:5}}>
								<Button size="lg" variant="primary" as={Link} to={`/user/purchase/completepurchase#pkgid=${selectedPackageId}`} style={{width:'100%'}}>Continue Purchase<PlayFill className="icon-right" /></Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			}

			{UserStatus === 'active' &&
				<Card className="card-blue mt-2">
					<Card.Body>
						<h2>Welcome Back.</h2>
						<h2 className="light">We see you already have an active ShieldScore<span style={{letterSpacing: '-8px'}}>&trade;.</span></h2>
						<Row className="mt-3 mb-3">
							<p>
							Our records indicate that you currently have an active ShieldScore&trade; that is valid through <>{(profile.current_expiration_dts && isValid(profile.current_expiration_dts) ? dateFormat(profile.current_expiration_dts,"MM/dd/yyyy") : null )}</>.   You may not need to purchase another background check at this time.
							</p>
							<p>
							As an active ShieldHub customer you can share your
							ShieldScore with unlimited clients using your ShieldID&trade; of <>{profile.certification_number}</>.
							</p>
						</Row>
						<Row>
							<Col xs={3} sm={5}>
								<Button size="lg" variant="link" onClick={( props.exitWorkflowMethod ? props.exitWorkflowMethod : null ) } className="exit-link">Exit</Button>
							</Col>
							<Col xs={{offset:1,span:8}} sm={{offset:2,span:5}}>
								<Button size="lg" variant="outline-light" as={Link} to={`/user/purchase/completepurchase#pkgid=${selectedPackageId}`} style={{width:'100%'}}>Continue<PlayFill className="icon-right" /></Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			}

			<Card className="mt-2">
				<Card.Body>
					<h2>Buying for others?</h2>
					<Row className="mt-3 mb-3">
						<p className="text-dark">ShieldHub only allows you to <b><u>purchase background checks for yourself</u></b>. If you need to order a background check for other users, you must invite them to make their own purchase. Results can be shared with you once the order is complete and the user accepted your invite.
						</p>
					</Row>
					<Row>
						<Col xs={3} sm={5}>
							<Button size="lg" variant="link" onClick={( props.exitWorkflowMethod ? props.exitWorkflowMethod : null ) } className="exit-link text-primary">Exit</Button>
						</Col>
						<Col xs={{offset:1,span:8}} sm={{offset:2,span:5}}>
							<Button size="lg" variant="outline-primary" as={Link} to='/user#addtomynetwork' style={{width:'100%'}}>
								<PersonFillAdd className="icon-left" />Invite User
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>

		</>

	);
}
export default PurchaseAlerts;