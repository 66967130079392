import * as React from "react";
import { Card, Col , Row, Form , InputGroup, Table } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { useBranding } from "../../Branding";
import ICScore from "../../ICScore";
import {format as dateFormat , parseISO as dateParseISO , isValid as dateValid } from "date-fns";
import { useNavigate } from "react-router-dom";
import api from '../../../io/admin.js';


function HightlightSearchText(props)
{

	if(props.searchvalue === null || props.searchvalue.length === 0 || props.text === null)
	{
		return props.text;
	}
	else if(props.searchvalue != '' && props.text.toLowerCase().indexOf(props.searchvalue.toLowerCase()) !== -1)
	{
		let term_index_start = props.text.toLowerCase().indexOf(props.searchvalue.toLowerCase());
		let term_index_end = term_index_start + props.searchvalue.length;

		let prefix = props.text.substring(0,term_index_start);
		let highlightedtext = props.text.substring(term_index_start,term_index_end);
		let suffix = props.text.substring(term_index_end,props.text.length);

		return ( <>{prefix}<span className='search_results_highlighted'>{highlightedtext}</span>{suffix}</> );
	}
	else
	{
		return props.text;
	}
}


function SearchUser() {

	const branding = useBranding();
	const navigate = useNavigate();

	const [user_search_results , setUserSearchResults ] = React.useState([]);

	const searchfieldref = React.createRef();

	async function handleSearch( event )
	{
		if(!event?.target?.value || event.target.value.length < 3)
		{
			setUserSearchResults([]);
			return;
		}

		let searchvalue = event.target.value.replace(/(<([^>]+)>)/gi, '');

		let response = await api.searchusers(searchvalue);

		if(response.success === true)
		{
			let results = response.results.map( (result) => {

				if(result.current_expiration)
				{
					try{
						result.current_expiration = dateParseISO(result.current_expiration);
					}
					catch(e)
					{
						console.warn(e);
						result.current_expiration = null;
					}
				}
				return result;
			});

			setUserSearchResults(results);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setUserSearchResults([
				{
					searchvalue,
					userid : 1000,
					firstname : 'First name',
					lastname : 'Last name',
					businessname : 'Business name',
					emailaddress : 'email@email.com',
					abcnumber : 'CA000009999',
					currenticstatus : 'IC01',
					cra_provider : 'CRA Provider',
					current_expiration : new Date()
				}
			]);
		}
		else
		{
			console.warn(response);
			setUserSearchResults([]);
		}		
	}

	async function handleSearchControlkeys(event)
	{
		if(event.key === 'Enter')
		{
			event.preventDefault();
			await handleSearch(event);
		}
	}


	function selectUserResultItem( userid )
	{
		
		navigate(`/admin/user#${userid}`, { replace : true });

		searchfieldref.current.value = '';
		setUserSearchResults([]);
		
		return;
	

	}


	return (
		<>
			<Card className="card-blue p-2">
				<Row>
					<Col md={{span:3}}>
						<h3>Search User</h3>
						<p>Search by name email or ShieldID</p>
					</Col>
					<Col md={{span:9}} className="d-flex align-items-center">
						<Form>
							<InputGroup style={{width:"100%"}}>
								<Form.Control ref={searchfieldref} size="lg" type="text" style={{height:"60px"}} onKeyDown={handleSearchControlkeys} onKeyUp={handleSearch}/>
								<InputGroup.Text id="search-addon" style={{cursor:"pointer",backgroundColor:branding.PrimaryButtonBackground,borderColor:branding.PrimaryButtonBackground,paddingLeft:"25px",paddingRight:"25px"}}><Search color={branding.PrimaryButtonText} size={33}/></InputGroup.Text>
							</InputGroup>
						</Form>
					</Col>
				</Row>
				<Row>
					{user_search_results && user_search_results.length > 0 &&
						<Table id='admin_search_results'>
							<thead>
								<tr>
									<th>Name</th>
									<th>Company</th>
									<th>Email</th>
									<th>Shield ID</th>
									<th>Shield Score</th>
									<th>CRA Provider</th>
									<th>Expiration Date</th>
								</tr>
							</thead>
							<tbody>
								{ user_search_results.map( (record) => {

									return ( 
										<tr key={record.userid} className='resultrow' onClick={() => {
											selectUserResultItem(record.userid);
										}}>
											<td><HightlightSearchText searchvalue={record.searchvalue} text={(record.firstname+' '+record.lastname)} /> </td>
											<td><HightlightSearchText searchvalue={record.searchvalue} text={record.businessname} /></td>
											<td><HightlightSearchText searchvalue={record.searchvalue} text={record.emailaddress} /></td>
											<td><HightlightSearchText searchvalue={record.searchvalue} text={record.abcnumber} /></td>
											<td className="scorecolumn">
												{record.currenticstatus && record.currenticstatus.match(/^IC[0-9]{2}/) && <ICScore score={record.currenticstatus} />}
											</td>
											<td>{record.cra_provider}</td>
											<td>{(record.current_expiration && dateValid(record.current_expiration) ) ? dateFormat(record.current_expiration,'MM/dd/yyyy') : null }</td>
										</tr>
									);

								}) }

							</tbody>
						</Table>
					}
				</Row>
			</Card>
		</>
	)

}
export default SearchUser;