import * as React from 'react';
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useBranding } from '../Branding';
import { ExclamationTriangleFill, SlashCircleFill } from 'react-bootstrap-icons';
import ICScore from '../ICScore';
import networkapi from '../../io/network';
import { useRefreshEmitter } from '../RefreshEmitter';
import { format as dateFormat , isValid as dateValid , parseISO as dateParseISO } from "date-fns";



function SearchResultsModal(props)
{

	const branding = useBranding();
	const emitter = useRefreshEmitter();
	const [ invitesent , setInviteSent ] = React.useState(false);
	let DismissSearchModal = props.DismissSearchModal;

	async function SendInvite( userid )
	{
		await networkapi.sendinternalinvite(userid);

		setInviteSent(true);

		emitter.Emit('networkupdate');

		setTimeout( () => {
			setInviteSent(false);
		},5000);

	}

	if(typeof DismissSearchModal !== 'function')
	{
		DismissSearchModal = () => {
			setInviteSent(false);
		};
	}

	return (
		<>
			{/* ShieldScore Search Profile Modal */}
			{/*
			<Modal show={showSearchResultsModal} centered={true} onHide={DismissSearchModal}>
				<Modal.Header closeButton />
				<Modal.Body style={{height:"624px",textAlign:"center",background:"#FFF"}}>
					<h2>ShieldScore Results</h2>
					<p style={{color:branding.PrimaryColor}}>IC (Individual Compliance) Score</p>
						<Col md={{span:1,offset:3}}><PersonCircle size={116}/></Col>
						<Col md={{span:3,offset:2}} style={{paddingTop:"15px"}}>
							<p style={{fontWeight:"bold",color:"#2A2D40"}}>{searchresults.firstname} {searchresults.lastname}</p>
							<ICScore score={searchresults.shieldscore} />
						</Col>
						<h2>Add to Network</h2>
							<Button onClick={() => {
							SendInvite(searchresults.userid);
						}} style={{width:"210px",marginTop:"20px",color:branding.PrimaryButtonText,backgroundColor:branding.PrimaryButtonBackground,borderColor:branding.PrimaryButtonBackground}}> Add to My Network </Button>
						<div>&nbsp;</div>
				</Modal.Body>
			</Modal>
					*/}


			<Modal show={props.showSearchResultsModal} centered={true} onHide={DismissSearchModal}>

				<Modal.Header closeButton />
				<Modal.Body style={{height:"624px",textAlign:"center"}}>
					<h2 className="mb-0">ShieldScore Results</h2>
					<h6 style={{color:branding.PrimaryColor,marginBottom: "20px"}}>IC (Individual Compliance) Score</h6>
					{props.searchresults.userid && props.searchresults.share_status === 'SHARED' &&
					<>
						<Row>
							<Col>
								{props.searchresults.profileimageurl && props.searchresults.profileimageurl.length > 20 && <div className="profile-image-frame" style={{marginLeft:'auto',marginRight:'auto',backgroundImage:'url("'+props.searchresults.profileimageurl+'")'}} />}
								{( !(props.searchresults.profileimageurl) || props.searchresults.profileimageurl.length === 0) && <div className="profile-image-frame" style={{marginLeft:'auto',marginRight:'auto',backgroundImage:'url("/images/user-icon.png")'}} /> }
							</Col>
						</Row>
						<Row>
							<Col style={{paddingTop:"15px",textAlign:"center"}}>
								<p style={{fontSize:"28px",color:"#2A2D40"}}><b>{props.searchresults.firstname} {props.searchresults.lastname}</b></p>
							</Col>
						</Row>
						<Row>
							<Col className="justify-content-center search-results-score" style={{textAlign:"center"}}>
								<ICScore score={props.searchresults.shieldscore} />
							</Col>
						</Row>
						<Row>
							<Col className="justify-content-center search-results-score mt-3" style={{textAlign:"center"}}>
								<b>Expires:&nbsp;{(props.searchresults.current_expiration && dateValid(props.searchresults.current_expiration) ) ? dateFormat(props.searchresults.current_expiration,'MM/dd/yyyy') : (props.searchresults.current_expiration && props.searchresults.current_expiration?.length > 0 ? dateFormat(dateParseISO(props.searchresults.current_expiration),"MM/dd/yyyy") : null ) }</b>
							</Col>
						</Row>
						<div style={{marginTop:"50px"}}>&nbsp;</div>
						{!(invitesent) && <Button onClick={() => {
							SendInvite(props.searchresults.userid);
						}} style={{width:"210px",marginTop:"20px",color:branding.PrimaryButtonText,backgroundColor:branding.PrimaryButtonBackground,borderColor:branding.PrimaryButtonBackground}}> Add to My Network </Button>}

						{invitesent && <Alert variant="success">Invite Sent</Alert>}
					</>}
					{props.searchresults.userid && props.searchresults.share_status === 'PRIVATE' && 
						<Alert variant='info'> <SlashCircleFill /> ShieldScore sharing not enabled for this user.</Alert>
					}
					{!(props.searchresults.userid) && 
						<Alert variant='danger'><ExclamationTriangleFill /> Error: Shield ID Not Found</Alert>
					}
				</Modal.Body>
			</Modal>
		</>
	)

}

export default SearchResultsModal;