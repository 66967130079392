import * as React from "react";
import { Card, Form , Stack , Row , Col , Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";



function FCRATerms()
{
	const formref = React.createRef();
	const navigate = useNavigate();

	const [ acceptButtonDisabled , setAcceptButtonDisabled ] = React.useState(true);

	async function Submit( event )
	{
		event.preventDefault();

		let formData = new FormData(formref.current);

		let agree_fcra = ( formData.get('agree_fcra') === 'YES' ? 'YES' : 'NO' );

		console.log(agree_fcra);

		navigate(`/user/idverify/idready`);
	}

	return (
		<Form ref={formref} onSubmit={Submit}>
			<Stack>
				<Card>
					<Card.Header><h2>A Summary of Your Rights Under the Fair Credit Reporting Act</h2></Card.Header>
					<Card.Body style={{maxHeight:'400px',overflowY:'scroll'}}>
						<p>Nullam id dolor id nibh ultricies vehicula ut id elit. Nullam id dolor id nibh ultricies vehicula ut id elit. Cras mattis consectetur purus sit amet fermentum. Nullam quis risus eget urna mollis ornare vel eu leo. Nulla vitae elit libero, a pharetra augue.

		Maecenas faucibus mollis interdum. Cras mattis consectetur purus sit amet fermentum. Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue. Nulla vitae elit libero, a pharetra augue.

		Donec sed odio dui. Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehicula ut id elit. Curabitur blandit tempus porttitor. Donec ullamcorper nulla non metus auctor fringilla. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Curabitur blandit tempus porttitor.

		Cras mattis consectetur purus sit amet fermentum. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas sed diam eget risus varius blandit sit amet non magna. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
					</Card.Body>
				</Card>
				<Row>
					<Col className="ms-1">
						<Form.Check.Label style={{cursor:'pointer'}}>
							<Form.Check 
								type="switch" 
								id='agree_fcra'
								name='agree_fcra'
								value='YES'
								style={{transform:"scale(1.3)"}} 
								inline
								onChange={(event) => {
									setAcceptButtonDisabled( !event.target.checked );
								}}
							/> 
							I certify that I have read and understand the above information
						</Form.Check.Label>
					</Col>
				</Row>
				<Row>
					<Col><Button variant='link'>Cancel</Button></Col>
					<Col><Button type='submit' disabled={acceptButtonDisabled}>Accept</Button></Col>
				</Row>
			</Stack>
		</Form>

	);

}
export default FCRATerms;