import * as api from './apibase';
import axios from 'axios';

async function startpurchase( packageid )
{
	return await api.post('/application/startpurchase',{packageid});
}

async function setpurchaseconfirmed( purchaseid )
{
	return await api.post('/application/setpurchaseconfirmed', {purchaseid});
}

async function getpurchase( purchaseid )
{
	return await api.post('/application/getpurchase', {purchaseid});
}

async function downloadreceipt( purchaseid , appid )
{
	return await api.get('/application/downloadreceipt', { purchaseid , appid });
}

async function startcaptureid( side )
{
	return await api.get('/application/captureid', { side });
}

async function uploadcapture( posturl , imagecontent )
{

	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.put(posturl,imagecontent);

		if(response.status === 200)
		{
			apiresponse.success = true;
		}
	}
	catch (e){
		console.log(e);
	}


	return apiresponse;	

}

async function completecapture( side , uploadstatus , barcode , coords )
{
	return await api.post('/application/captureid', { side , uploadstatus , barcode , coords });
}

async function startlivefacecapture( step )
{
	let session , auth;
	session = auth = null;
	if(step === 'getauth')
	{
		auth = '1';
	}
	else if(step === 'getsession')
	{
		session = '1';
	}

	return await api.get('/application/livefacecapture',{ getsession : session , getauth : auth });
}
async function completelivefacecapture( )
{
	return await api.post('/application/livefacecapture');
}
async function verificationdata( checkstep )
{
	return await api.post('/application/verificationdata',{ checkstep });
}
async function setselecteddocument( selecteddocumenttype )
{
	return await api.post('/application/setselecteddocument',{ selecteddocumenttype });
}

export default { 	
	startpurchase,
	setpurchaseconfirmed,
	getpurchase,
	downloadreceipt,
	startcaptureid,
	uploadcapture,
	completecapture,
	startlivefacecapture,
	completelivefacecapture,
	verificationdata,
	setselecteddocument,
};