import * as React from "react";
import { Col, Card, Row , FloatingLabel , Form , Button , Collapse, Alert, Modal } from "react-bootstrap";
import { CheckSquareFill, ChevronDown, ChevronRight , ExclamationTriangleFill,  Square , RecordCircleFill, Circle } from "react-bootstrap-icons";
import PasswordStrengthMeter from "../../PasswordStrength";
import api from "../../../io/profile";
import qrcode from 'qrcode';
import { useBranding } from "../../Branding";


function PasswordAndSecurity( props )
{
	const branding = useBranding();
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ errorfields , setErrorfields ] = React.useState({password:false,confirmpassword:false});
	const [ errormessage , setErrormessage ] = React.useState(false);
	const [ updatesuccessful , setUpdatesuccessful ] = React.useState(false);
	const [ passwordMeterValue , setPasswordMeterValue ] = React.useState(false);
	const [ passwordscore , setPasswordScore ] = React.useState(0);

	const [ userprofile , setUserProfile ] = React.useState({});
	const [ showsmsmodal , setShowSMSModal ] = React.useState(false);
	const [ showotpmodal , setShowOTPModal ] = React.useState(false);
	const [ smscodeverifybutton , setSmscodeverifybutton ] = React.useState(true);
	const [ otpcodeverifybutton , setOtpcodeverifybutton ] = React.useState(true);
	const [ twofactorerror , setTwoFactorError ] = React.useState(false);
	const [ twofactorerrormessage , setTwoFactorErrorMessage ] = React.useState(false);
	const [ confirmremovemethod , setConfirmremovemethod ] = React.useState(false);

	const [ otpdata , setOtpData ] = React.useState(false);

	const formref = React.createRef();
	const smsCodeRef = React.createRef();
	const otpCodeRef = React.createRef();

	function CheckPasswords()
	{
		setUpdatesuccessful(false);

		let formData = new FormData(formref.current);

		var newerrors = { ...errorfields };

		var password = formData.get('password');
		var confirmpassword = formData.get('confirmpassword');

		setPasswordMeterValue(password);

		if(password === '' && confirmpassword === '')
		{
			return;
		}

		if(password !== confirmpassword)
		{
			setErrormessage('Passwords do not match');
			newerrors.password = true;
			newerrors.confirmpassword = true;
		}
		else if(passwordscore < 30)
		{
			setErrormessage('Please pick a stronger password');
			newerrors.password = true;
			newerrors.confirmpassword = true;
		}
		else
		{
			setErrormessage(false);
			newerrors.password = false;
			newerrors.confirmpassword = false;
		}

		setErrorfields(newerrors);
	}

	async function SavePasswordUpdate()
	{
		if(errorfields.password === true || errorfields.confirmpassword === true)
		{
			return;
		}

		props.SaveEvent('PasswordAndSecurity','startsave');

		let currentformelement = formref.current;

		let formData = new FormData(formref.current);

		var password = formData.get('password');
		var confirmpassword = formData.get('confirmpassword');

		if(password !== confirmpassword)
		{
			return;
		}

		var response = await api.updatepassword(password);

		if(response.success)
		{
			if(currentformelement)
			{
				currentformelement.reset();
			}
			setUpdatesuccessful(true);

			setPasswordMeterValue(false);

			props.SaveEvent('PasswordAndSecurity','endsave');
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			if(currentformelement)
			{
				currentformelement.reset();
			}
			setUpdatesuccessful(true);

			setPasswordMeterValue(false);

			props.SaveEvent('PasswordAndSecurity','endsave');
		}
	}

	async function GetProfileDetails()
	{
		var response = await api.getmyprofile();

		if(response.success)
		{
			setUserProfile(response.profile);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setUserProfile({
				selected_two_step_method : 'OTPAPP',
				enabled_two_step_methods : [
					'SMS',
					'OTPAPP'
				]
			});
		}
		else
		{
			console.warn(response);
		}
	}

	async function StartSMSMethod()
	{
		await api.updatetwofactor('start','SMS',null);
	}

	function handleSMSCodeEvent( event )
	{
		event.currentTarget.value = event.currentTarget.value.toUpperCase();

		if(event.currentTarget.value.match(/^[0-9A-Z]{6}$/))
		{
			setSmscodeverifybutton(false);
		}
		else
		{
			setSmscodeverifybutton(true);
		}
	}

	async function StartOTPMethod()
	{
		let response = await api.updatetwofactor('start','OTPAPP',null);
		if(response.success)
		{
			qrcode.toDataURL(response.otpurl, { errorCorrectionLevel: 'H' }, function (err, data) {
				setOtpData(data);
				setShowOTPModal(true);
			});
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			qrcode.toDataURL('otpauth://totp/ShieldHub:testaccount@shieldhub.com?secret=JBSWY3DPEHPK3PXP&issuer=ShieldHubPortal', { errorCorrectionLevel: 'H' }, function (err, data) {
				setOtpData(data);
				setShowOTPModal(true);
			});
			setShowOTPModal(true);
		}
		else
		{
			console.warn(response);
		}
	
	}

	function handleOTPCodeEvent( event )
	{
		event.currentTarget.value = event.currentTarget.value.toUpperCase();

		if(event.currentTarget.value.match(/^[0-9A-Z]{6}$/))
		{
			setOtpcodeverifybutton(false);
		}
		else
		{
			setOtpcodeverifybutton(true);
		}
	
	}

	async function VerifySMSMethod()
	{
		let code = smsCodeRef.current.value;

		await SaveTwoFactorMethod('SMS',code);
	}
	async function VerifyOTPMethod()
	{
		let code = otpCodeRef.current.value;

		await SaveTwoFactorMethod('OTPAPP',code);
	}

	async function SaveTwoFactorMethod( method , code )
	{
		props.SaveEvent('PasswordAndSecurity','startsave');

		let response = await api.updatetwofactor('verify',method,code);
		if(response.success)
		{
			setShowSMSModal(false);
			setShowOTPModal(false);
			await GetProfileDetails();
		}

		props.SaveEvent('PasswordAndSecurity','endsave');
	}

	async function RemoveTwoFactorMethod( method )
	{
		props.SaveEvent('PasswordAndSecurity','startsave');

		let response = await api.updatetwofactor('remove',method,null);
		if(response.success)
		{
			await GetProfileDetails();
		}

		props.SaveEvent('PasswordAndSecurity','endsave');
	}

	async function SavePrimaryMethod( method )
	{
		props.SaveEvent('PasswordAndSecurity','startsave');

		let response = await api.updatetwofactor('primary',method,null);
		if(response.success)
		{
			await GetProfileDetails();
		}

		props.SaveEvent('PasswordAndSecurity','endsave');
	}

	React.useEffect( () => {

		( async () => {
			await GetProfileDetails();
		})();

	},[]);

	return (

		<>
			<Card className="p-4 d-inline-block password-security">
				<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
					{panelshow && <ChevronDown/>}
					{!panelshow && <ChevronRight />}
					&nbsp;Passwords &amp; Security</Button>
				<Collapse in={panelshow}>
					<Form ref={formref}>
						<Row>
							<Col md={12} lg={6} className="mb-3">
								<FloatingLabel controlId="password" label="PASSWORD" style={{paddingBottom:"3px"}}>
									<Form.Control 
										type="password" 
										placeholder="PASSWORD" 
										name="password"
										isInvalid={errorfields.password}
										onChange={CheckPasswords}
									/>
								</FloatingLabel>
								<PasswordStrengthMeter password={passwordMeterValue} returnScore={setPasswordScore} />
							</Col>
							<Col md={12} lg={6} className="mb-3">
								<FloatingLabel controlId="confirmpassword" label="CONFIRM PASSWORD">
									<Form.Control 
										type="password" 
										placeholder="CONFIRM PASSWORD" 
										name="confirmpassword"
										isInvalid={errorfields.confirmpassword}
										onChange={CheckPasswords}
									/>
								</FloatingLabel>
							</Col>
							<Col sm={{span:4}}>
								<Alert variant="danger" show={errormessage && errormessage.length > 0}><ExclamationTriangleFill size={20} /> Error: {errormessage}</Alert>
								<Alert variant="success" show={updatesuccessful} >Password successfully updated</Alert>
							</Col>
							<Col sm={{span:4, offset:4}} className="mb-1">
								<Button className="btn btn-primary float-end text-nowrap" onClick={SavePasswordUpdate}>Update Password</Button>
							</Col>
						</Row>
						<Row>
							<Col sm={10}>
								<h6 className="mb-1">2-Step Verification</h6>
								<p className="small">Enable 2-Step verification to secure your account. Select Email, Text Message, or Authenticator App methods below</p>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col sm={{span:8,offset:1}}>
								<Row style={{color:branding.PrimaryColor,paddingBottom:'10px'}}>
									<Col md={3}></Col>
									<Col style={{textAlign:'center'}}><b>ENABLED</b></Col>
									<Col style={{textAlign:'center'}}><b>PREFERRED</b></Col>
								</Row>
								<Row style={{paddingBottom:'10px'}}>
									<Col md={3} style={{verticalAlign:'middle'}}><b>Email</b></Col>
									<Col style={{textAlign:'center'}}>
										{userprofile.selected_two_step_method !== 'DISABLED' && <div style={{paddingTop:'6px',paddingBottom:'6px'}}><CheckSquareFill color={branding.PrimaryColor} /></div>}
										{userprofile.selected_two_step_method === 'DISABLED' && <Button variant="link"><Square color={branding.PrimaryColor} /></Button>}
									</Col>
									<Col style={{textAlign:'center'}}>
										{userprofile.selected_two_step_method === 'EMAIL' && <Button variant="link"><RecordCircleFill color={branding.PrimaryColor} /></Button>}
										{userprofile.selected_two_step_method !== 'EMAIL' && <Button variant="link" onClick={() => {
											SavePrimaryMethod('EMAIL');
										}}><Circle color={branding.PrimaryColor} /></Button>}
									</Col>
								</Row>
								<Row style={{paddingBottom:'10px'}}>
									<Col md={3}><b>Text Message</b></Col>
									<Col style={{textAlign:'center'}}>
										{userprofile.enabled_two_step_methods && userprofile.enabled_two_step_methods.length > 0 && userprofile.enabled_two_step_methods.indexOf('SMS') !== -1 && <Button variant="link" onClick={() => {
											if(userprofile.selected_two_step_method === 'SMS')
											{
												setTwoFactorErrorMessage('Error: Select another primary verification method before removing');
												setTwoFactorError(true);
											}
											else
											{
												setConfirmremovemethod('SMS');
											}
										}}><CheckSquareFill color={branding.PrimaryColor} /></Button>}
										{(userprofile.enabled_two_step_methods === null || userprofile.enabled_two_step_methods?.length === 0 || userprofile.enabled_two_step_methods?.indexOf('SMS') === -1) && <Button variant="link" onClick={() => {
											setShowSMSModal(true);
										}}><Square color={branding.PrimaryColor} /></Button>}
									</Col>
									<Col style={{textAlign:'center'}}>
										{userprofile.selected_two_step_method === 'SMS' && <Button variant="link"><RecordCircleFill color={branding.PrimaryColor} /></Button>}
										{(userprofile.enabled_two_step_methods === null || userprofile.enabled_two_step_methods?.length === 0 || userprofile.enabled_two_step_methods?.indexOf('SMS') === -1) && <Button variant="link" onClick={() => {
											setShowSMSModal(true);
										}}><Circle color={branding.PrimaryColor} /></Button>}
										{userprofile.enabled_two_step_methods && userprofile.enabled_two_step_methods.length > 0 && userprofile.enabled_two_step_methods.indexOf('SMS') !== -1 && userprofile.selected_two_step_method !== 'SMS' && <Button variant="link" onClick={() => {
											SavePrimaryMethod('SMS');
										}}><Circle color={branding.PrimaryColor} /></Button>}
									</Col>
								</Row>
								<Row style={{paddingBottom:'10px'}}>
									<Col md={3}><b>Authenticator App</b></Col>
									<Col style={{textAlign:'center'}}>
										{userprofile.enabled_two_step_methods && userprofile.enabled_two_step_methods.length > 0 && userprofile.enabled_two_step_methods.indexOf('OTPAPP') !== -1 && <Button variant="link" onClick={() => {
											if(userprofile.selected_two_step_method === 'OTPAPP')
											{
												setTwoFactorErrorMessage('Error: Select another primary verification method before removing');
												setTwoFactorError(true);
											}
											else
											{
												setConfirmremovemethod('OTPAPP');
											}
										}}><CheckSquareFill color={branding.PrimaryColor} /></Button>}
										{(userprofile.enabled_two_step_methods === null || userprofile.enabled_two_step_methods?.length === 0 || userprofile.enabled_two_step_methods?.indexOf('OTPAPP') === -1) && <Button variant="link" onClick={() => {
											StartOTPMethod();
										}}><Square color={branding.PrimaryColor} /></Button>}
									</Col>
									<Col style={{textAlign:'center'}}>
										{userprofile.selected_two_step_method === 'OTPAPP' && <Button variant="link"><RecordCircleFill color={branding.PrimaryColor} /></Button>}
										{(userprofile.enabled_two_step_methods === null || userprofile.enabled_two_step_methods?.length === 0 || userprofile.enabled_two_step_methods?.indexOf('OTPAPP') === -1) && <Button variant="link" onClick={() => {
											StartOTPMethod();
										}}><Circle color={branding.PrimaryColor} /></Button>}
										{userprofile.enabled_two_step_methods && userprofile.enabled_two_step_methods.length > 0 && userprofile.enabled_two_step_methods.indexOf('OTPAPP') !== -1 && userprofile.selected_two_step_method !== 'OTPAPP' && <Button variant="link" onClick={() => {
											SavePrimaryMethod('OTPAPP');
										}}><Circle color={branding.PrimaryColor} /></Button>}
									</Col>
								</Row>
							</Col>
							{twofactorerror && <Alert variant="danger" className="m-4 p-3 text-center"><ExclamationTriangleFill /> {twofactorerrormessage}</Alert>}
						</Row>
					</Form>
				</Collapse>
			</Card>
			<Modal show={showsmsmodal} centered>
				<Modal.Header>Enable Text Message Verification</Modal.Header>
				<Modal.Body>
					<ol>
						<li>Click Send Code below</li>
						<li>Enter the code that was sent to your mobile phone</li>
						<li>Click Verify to enable Text Message Verification</li>
					</ol>
					<Button variant="primary" onClick={StartSMSMethod}>Send Code</Button>
					<br />
					<br />
					<Form.Control ref={smsCodeRef} type="text" placeholder="Enter Code" maxLength={6} className="p-3 text-center" onChange={handleSMSCodeEvent}/>
					<div style={{fontSize:'8pt'}} className="p-2">
						<ul style={{marginBottom:'1px'}}>
							<li>You will recieve a verification text message from ShieldHub when you click &apos;Send Code&apos; above.</li>
							<li>You will recieve verification text messages from ShieldHub when you login from a new browser.</li>
							<li>Please check the box &apos;Remember this browser for 30 days&apos; on login verification to indicate a trusted browser.</li>
							<li>Message and data rates may apply.</li>
							<li>Reply STOP to the text message at any time to disable this verification method.</li>
							<li>Please see <a href="https://www.shieldhub.com/terms/" target="_blank">ShieldHub Terms and Conditions</a> for more details.</li>
						</ul>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => {
						setShowSMSModal(false);
					}}>Nevermind</Button>
					<Button variant="primary" disabled={smscodeverifybutton} onClick={VerifySMSMethod}>Verify</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showotpmodal} centered>
				<Modal.Header>Enable Authenticator App Verification</Modal.Header>
				<Modal.Body>
					<ol>
						<li>Scan the QR code below</li>
						<li>Enter the code that was generated by your authenticator app</li>
					</ol>
					<div style={{textAlign:'center'}} className="p-1">
						<img src={otpdata} alt="QR Code" />
					</div>
					<Form.Control ref={otpCodeRef} type="text" placeholder="Enter Code" maxLength={6} className="p-3 text-center" onChange={handleOTPCodeEvent}/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => {
						setShowOTPModal(false);
					}}>Nevermind</Button>
					<Button variant="primary" disabled={otpcodeverifybutton} onClick={VerifyOTPMethod}>Verify</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={(confirmremovemethod !== false)} centered>
				<Modal.Body style={{textAlign:'center'}} className="p-4">
					<h5>Remove 
						{confirmremovemethod === 'SMS' && <> Text Message </>}
						{confirmremovemethod === 'OTPAPP' && <> Authenticator App </>}
						Verification Method
					</h5>
					<h5>Are you Sure ?</h5>
					<Row>
						<Col xs={{span:2,offset:3}}><Button variant="secondary" onClick={() => {
							setConfirmremovemethod(false);
						}}>No</Button></Col>
						<Col xs={{span:2,offset:2}}><Button variant="primary" onClick={() => {
							RemoveTwoFactorMethod(confirmremovemethod);
						}}>Yes</Button></Col>
					</Row>
				</Modal.Body>
			</Modal>
		</>

	);
}

export default PasswordAndSecurity;