import * as React from "react";
import '@aws-amplify/ui-react/styles.css';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import api from '../../../io/workflow';
import { Container , Row , Col } from "react-bootstrap";

function CaptureUserSelfie( props ) {

	const [ selfiesessionid , setSelfiesessionid ] = React.useState(null);
	const [ liveselfievalues , setSelfieValues ] = React.useState(null);

	async function StartSessionAuth()
	{
		let response = await api.startlivefacecapture('getauth');
		if(response.success)
		{
			return Promise.resolve({
				accessKeyId : response.credentials.accessKeyId,
				secretAccessKey : response.credentials.secretAccessKey,
				sessionToken : response.credentials.sessionToken,
			});
		}
	}

	async function SessionComplete()
	{
		let response = await api.completelivefacecapture();
		if(response.success)
		{
			setSelfieValues(response.liveness);
			props.CompleteStep();
		}

	}

	React.useEffect(() => {

		( async () => {

			let response = await api.startlivefacecapture('getsession');
			if(response.success)
			{
				setSelfiesessionid(response.sessionid);
			}

		} )();


	}, []);



	return (
		<>
			<h1>Live Selfie Verification</h1>
			{selfiesessionid && liveselfievalues === null && <FaceLivenessDetector
				config={{ credentialProvider : async () => { return await StartSessionAuth() }, binaryPath : 'https://static.shieldhublabs.com/' , faceModelUrl : 'https://static.shieldhublabs.com/model.json' }}
				region='us-east-1'
				sessionId={selfiesessionid}
				onAnalysisComplete={SessionComplete}
				onError={(error) => {
					console.log(error);
				}}
				
			/>}
			{liveselfievalues !== null && 
				<Container>
					<Row>
						{liveselfievalues.AuditImages.map( (image , index ) => {
							return <Col xs={2} key={index}>
								<p>{`Audit Image ${index}`}</p>
								<img src={image.S3Object.Url} style={{width:'100%'}}/>
							</Col>
						})}
						<Col xs={2}>
							<p>{`Reference Final Image`}</p>
							<img src={liveselfievalues.ReferenceImage.S3Object.Url} style={{width:'100%'}}/>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>{`Liveness Confidence : ${liveselfievalues.Confidence} %`}</p>
						</Col>
					</Row>
				</Container>
			}
		</>
	);
}
export default CaptureUserSelfie;

/*

https://aws.amazon.com/rekognition/identity-verification/

https://ui.docs.amplify.aws/react/connected-components/liveness

https://github.com/aws-amplify/amplify-ui/blob/main/packages/react-liveness/src/components/FaceLivenessDetector/FaceLivenessDetector.tsx

https://docs.aws.amazon.com/rekognition/latest/dg/face-liveness-prerequisites.html

*/