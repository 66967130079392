import * as React from "react";
import { Card, Collapse , Button, Form , Row , Col, Container, Dropdown, Modal, InputGroup, Alert , Table } from "react-bootstrap";
import { ChevronDown, ChevronRight, CurrencyDollar, Download, ExclamationTriangleFill, Gear, Receipt, Stripe , CheckCircleFill , ShieldFillPlus , Search } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import {format as dateFormat , parseJSON as dateParseJSON , parseISO as dateParseISO , isValid as dateValid } from "date-fns";
import api from '../../../io/admin';
import { useBranding } from "../../Branding";
import ICScore from "../../ICScore";
import FileDownloadModal from '../../FileDownloadModal';
import { useRefreshEmitter } from "../../RefreshEmitter";



function BackgroundChecks( props )
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ userprofile , setUserprofile ] = React.useState({});
	const [ bgchecks , setBgChecks ] = React.useState([]);
	const [ orderdetailsmodal , setOrderdetailsmodal ] = React.useState(false);
	const [ orderdetailsobject , setOrderdetailsobject ] = React.useState({});
	const [ orderdetailsrefundtoggle , setDetailsrefundtoggle ] = React.useState(false);
	const [ orderedtailsrefundtype , setDetailsrefundtype ] = React.useState(false);
	const [ refunddetails , setRefundDetails ] = React.useState({});
	const [ overrefunderror , setOverRefundError ] = React.useState(false);
	const [ refundbuttonactive , setRefundbuttonactive ] = React.useState(false);
	const [ refundsuccess , setRefundSuccess ] = React.useState(false);
	const [ refundinprogress , setRefundinprogress ] = React.useState(false);
	const [ downloadModal , setDownloadModal ] = React.useState(false);
	const [ downloadlink , setDownloadlink ] = React.useState(false);
	const [ downloaderror , setDownloaderror ] = React.useState(false);
	const [ receiptpdfcontenturl , setReceiptContent ] = React.useState(false);
	const [ receiptpdfname , setReceiptpdfname ] = React.useState('shieldhub_receipt.pdf');
	const [ addbgcheckmodal , setAddBGCheckModal ] = React.useState(false);
	const [ searchbgresults , setSearchbgresults ] = React.useState([]);
	const [ selectedbgprofiles , setSelectedbgprofiles ] = React.useState([]);
	const [ allsearchitems , setAllsearchitems ] = React.useState(false);
	const [ transferconfirm , setTransferconfirm ] = React.useState(false);
	const [ errormessage , setErrormessage ] = React.useState(false);

	const branding = useBranding();
	const otherrefundamountref = React.createRef();
	const bgchecksearchfield = React.createRef();

	const formRef = React.createRef(null);
	const addbgchkformRef = React.createRef(null);

	async function LoadBgChecks( userid )
	{
		let userresponse = await api.getuser( userid );
		if(userresponse.success)
		{
			setUserprofile({
				userid : userresponse.profile.userid,
				firstname : userresponse.profile.firstname,
				lastname : userresponse.profile.lastname,
				emailaddress : userresponse.profile.emailaddress,
			});
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setUserprofile({
				userid : '1000',
				firstname : 'John',
				lastname : 'Doe',
				emailaddress : 'test@example.com',
			});
		}

		let response = await api.getbgchecks( userid );
		
		if(response.success === true)
		{
			let bgchecks = response.bgchecks.map( (bgcheck) => {

				if(bgcheck.dts)
				{
					try {
						bgcheck.dts = dateParseJSON(bgcheck.dts);
					}
					catch (e){
						console.warn(e);
						bgcheck.dts = null;
					}
				}
				if(bgcheck.expiration_date)
				{
					try {
						bgcheck.expiration_date = dateParseISO(bgcheck.expiration_date);
					}
					catch (e){
						console.warn(e);
						bgcheck.expiration_date = null;
					}
				}

				if(bgcheck.stripe_transactions && bgcheck.stripe_transactions.length > 0)
				{
					bgcheck.stripe_transactions = bgcheck.stripe_transactions.map( (transaction) => {

						if(transaction.dts)
						{
							try {
								transaction.dts = dateParseJSON(transaction.dts);
							}
							catch (e){
								console.warn(e);
								transaction.dts = null;
							}
						}

						return transaction;

					});
				}

				return bgcheck;
			});

			setBgChecks(bgchecks);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setBgChecks([
				{
					id : 1000,
					cra_name : 'ClearStar',
					dts : new Date(),
					composite_status : 'In Progress',
					composite_status_code : 'done',
					expiration_date : new Date(),
					package_descrip : 'Non New York State 1-Year Package with Continuous Monitoring',
					cra_profilenumber : '2024081366024937',
					shieldid : 'CA920571030',
					amount : 50,
					paid_amount : 25,
					refunded_amount : 25,
					stripe_transactions : [
						{
							id : 'pm_1POmtPRwQeGUavhlfJuOm9cI',
							dts : dateParseJSON('2024-06-09T13:05:06.000Z'),
							type : 'CHARGE',
							amount : 50,
							method : 'VISA 4242'
						},
						{
							id : 9001,
							dts : dateParseJSON('2024-06-10T12:15:06.000Z'),
							type : 'REFUND',
							amount : 25,
							method : 'VISA 4242'
						},
					],
					active : 'YES',
				},
				{
					id : 1001,
					cra_name : 'ClearStar',
					dts : new Date(),
					composite_status : 'In Progress',
					expiration_date : dateParseJSON("2023-10-30T15:51:48.012Z"),
					package_descrip : 'Non New York State 1-Year Package with Continuous Monitoring',
					cra_profilenumber : '2024061060929473',
					shieldid : null,
					amount : 50,
					paid_amount : 50,
					refunded_amount : 0,
					stripe_transactions : [
						{
							id : 'pm_1POmtPRwQeGUavhlfJuOm9cI',
							dts : '06/09/2024 13:05:06',
							type : 'CHARGE',
							amount : 50,
							method : 'VISA 4242'
						},
					],
					active : 'NO',
				},
				{
					id : 1002,
					cra_name : 'ClearStar',
					dts : new Date(),
					composite_status : 'In Progress',
					expiration_date :  " 23:59:59",
					package_descrip : 'Non New York State 1-Year Package with Continuous Monitoring',
					stripe_transactions : null,
					active : 'NO',
				}
			]);
		}
		else
		{
			console.warn(response);
			setBgChecks([]);
		}
	}

	function ShowDetailsModal( appid )
	{
		let details = bgchecks.find( (bgcheck) => {
			return bgcheck.id === appid;
		});

		setOrderdetailsobject(details);
		setOrderdetailsmodal(true);
	}

	function HideDetailsModal( )
	{
		setOrderdetailsmodal(false);

		setRefundDetails({});
		setOverRefundError(false);
		setRefundbuttonactive(false);
		setDetailsrefundtype(false);
		setDetailsrefundtoggle(false);
		setOrderdetailsobject({});
		setRefundSuccess(false);
		setRefundinprogress(false);
	}

	function CurrencyFormat( event )
	{
		let validControlKeyCodes = [8,9,37,39,46];

		let newdetails = { ...refunddetails };

		if(newdetails.otherrefundamount === undefined || newdetails.otherrefundamount === null)
		{
			newdetails.otherrefundamount = '';
		}
		if(validControlKeyCodes.indexOf(event.keyCode) !== -1)
		{
			if(event.keyCode === 8 && newdetails.otherrefundamount && newdetails.otherrefundamount.length > 0)
			{
				newdetails.otherrefundamount = newdetails.otherrefundamount.substring(0,newdetails.otherrefundamount.length-1);

				if( parseFloat(orderdetailsobject.amount) - parseFloat(newdetails.otherrefundamount) < 0 )
				{
					setOverRefundError(true);
				}
				else
				{
					setOverRefundError(false);
				}
			

				setRefundDetails(newdetails);
			}

			//control keys like Tab, backspace or arrows
			return;
		}
		else if(event.key && !event.key.match(/[0-9.]/))
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}
	
		newdetails.otherrefundamount += event.key;

		if( parseFloat(orderdetailsobject.amount) - parseFloat(orderdetailsobject.refunded_amount) - parseFloat(newdetails.otherrefundamount) < 0 )
		{
			setOverRefundError(true);
		}
		else
		{
			setOverRefundError(false);
		}

		setRefundDetails(newdetails);
		CheckRefundStatus();
	}

	function CheckRefundStatus()
	{
		setRefundbuttonactive(false);

		if(orderdetailsrefundtoggle === false)
		{
			setRefundbuttonactive(false);
			setDetailsrefundtype(false);
		}
		else if(orderdetailsrefundtoggle && orderedtailsrefundtype === 'full' && parseFloat(orderdetailsobject.refunded_amount) === 0)
		{
			setRefundDetails({otherrefundamount : ''});

			setRefundbuttonactive(true);
		}
		else
		{
			if(orderdetailsrefundtoggle && parseFloat(refunddetails.otherrefundamount) > 0 && ( parseFloat(orderdetailsobject.amount) - parseFloat(orderdetailsobject.refunded_amount) - parseFloat(refunddetails.otherrefundamount) ) < 0 )
			{
				setOverRefundError(true);
				setRefundbuttonactive(false);
			}
			else if(orderdetailsrefundtoggle && parseFloat(refunddetails.otherrefundamount) > 0 && ( parseFloat(orderdetailsobject.amount) - parseFloat(orderdetailsobject.refunded_amount) - parseFloat(refunddetails.otherrefundamount) ) >= 0 )
			{
				setRefundbuttonactive(true);
			}
			else
			{
				setRefundbuttonactive(false);
			}
		}

	}

	async function SubmitRefund( event )
	{
		setRefundinprogress(true);
		event.preventDefault();

		let formData = new FormData(formRef.current);

		let refundrequest = { ...refunddetails };

		refundrequest.applicationid = orderdetailsobject.id;
		if(orderedtailsrefundtype === 'full')
		{
			refundrequest.refundamount = orderdetailsobject.amount;
		}
		else if(orderedtailsrefundtype === 'partial')
		{
			refundrequest.refundamount = refunddetails.otherrefundamount;
		}
		refundrequest.cancel_application = formData.get('cancel_application') === 'YES' ? 'YES' : 'NO';

		let response = await api.submitrefund(refundrequest);
		if(response.success)
		{
			let userid = hash.replace(/#([0-9]+)/,'$1');
			await LoadBgChecks( userid );

			setRefundbuttonactive(false);
			setRefundSuccess(true);
			setTimeout(() => {
				HideDetailsModal();
			},3000);
		}
		else
		{
			setRefundinprogress(false);
		}
	}

	async function DownloadClick( appid )
	{
		setDownloadModal(true);
		let response = await api.getReportDownloadLink( appid );

		if(response.success)
		{
			setDownloadlink(response.downloadlink);
		}
		else
		{
			setDownloaderror('Error: Report failure');
		}
	}


	async function DownloadReceipt( applicationid )
	{
		setDownloadModal(true);
		let response = await api.downloadreceipt( null , applicationid );
		if(response.success)
		{
			let now = parseInt(Date.now()/1000);
			setReceiptpdfname(`shieldhub_receipt_${now}.pdf`);
			setReceiptContent(`data:application/pdf;base64,${response.receipt_body}`);
		}
	}

	function HideBGCheckListModal()
	{
		setAddBGCheckModal(false);
		setAllsearchitems(false);
		setSearchbgresults([]);
		setSelectedbgprofiles([]);
		bgchecksearchfield.current.value = '';
	}

	async function SearchBackgroundCheck( event )
	{
		if(!event?.target?.value || event.target.value.length < 3)
		{
			setSearchbgresults([]);
			return;
		}

		let searchvalue = event.target.value.replace(/(<([^>]+)>)/gi, '');

		let response = await api.searchbackgroundcheck( searchvalue );
		if(response.success === true)
		{
			setSearchbgresults(response.results);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setSearchbgresults([
				{
					legalfirstname : 'William',
					legalmiddlename : 'John',
					legallastname : 'Smith',
					cra_profilenumber : '2024081366024937',
					shieldid : 'AA123451030',
					emailaddress : 'test@example.com',
					completed_date : new Date(),
					expiration_date : new Date(),
					icscore : 'IC01+',
					user_emailaddress : 'user@example.com',
					user_firstname : 'Bill',
					user_lastname : 'Smith'
				},
				{
					legalfirstname : 'Jack',
					legalmiddlename : 'John',
					legallastname : 'Smith',
					cra_profilenumber : 'ASPN_0001-2024081366024938',
					shieldid : null,
					emailaddress : 'clearstar@example.com',
					completed_date : new Date(),
					expiration_date : null,
					icscore : 'IC01',
				}
			]);
		}
		else
		{
			console.warn(response);
			setSearchbgresults([]);
		}

		setSelectedbgprofiles([]);
	}

	async function handleSearchControlkeys(event)
	{
		if(event.key === 'Enter')
		{
			event.preventDefault();
			await SearchBackgroundCheck(event);
		}
	}	

	function AllBGProfiles( action )
	{
		if(action === 'add')
		{
			let selectedprofiles = searchbgresults.map((record) => {
				return record.cra_profilenumber;
			});

			setAllsearchitems(true);
			setSelectedbgprofiles(selectedprofiles);
		}
		else if(action === 'remove')
		{
			setAllsearchitems(false);
			setSelectedbgprofiles([]);
		}
	}

	function UpdateSelectedBGProfile( profileid , action )
	{
		let selectedprofiles = [ ...selectedbgprofiles ];

		if(action === 'add')
		{
			selectedprofiles.push(profileid);
		}
		else if(action === 'remove')
		{
			selectedprofiles = selectedprofiles.filter( (profile) => {
				return profile !== profileid;
			});
		}

		setSelectedbgprofiles(selectedprofiles);

		if(selectedprofiles.length === 0)
		{
			setAllsearchitems(false);
		}
		else
		{
			let profiles_not_selected = searchbgresults.filter((record) => {
				return ( selectedprofiles.indexOf(record.cra_profilenumber) === -1 );
			});
			
			setAllsearchitems(profiles_not_selected.length === 0)
		}
	}

	function handleToggleChange( cra_id )
	{
		let bgchecklist = [ ...bgchecks ];

		bgchecklist = bgchecklist.map( (record) => {
			if(record.cra_profilenumber === cra_id)
			{
				record.active = ( record.active === 'YES' ? 'NO' : 'YES' );
			}
			return record;
		});

		let active_count = bgchecklist.filter((record) => {
			return record.active === 'YES';
		}).length;

		if(active_count > 1)
		{
			setErrormessage('Error : Only one background check can be active at a time');
		}
		else
		{
			props.setFormDirtyFunction(true);
			setErrormessage(false);
		}
		

		setBgChecks(bgchecklist);
	}

	async function SaveBGStatuses( )
	{
		let userid = hash.replace(/#([0-9]+)/,'$1');

		let active_cra_id;
		let inactive_bg_checks = [];

		for ( const record of bgchecks )
		{
			if(record.active === 'YES')
			{
				active_cra_id = record.cra_profilenumber;
				break;
			}
			else if(record.active === 'NO')
			{
				inactive_bg_checks.push(record.cra_profilenumber);
			}
		}
		let response = api.updatebgchecks({ userid , active_cra_id , inactive_bg_checks });
		if(response.success)
		{
			props.setFormDirtyFunction(false);
		}
		else
		{
			console.warn(response);
		}

	}

	async function LinkBgChks( event )
	{
		event.preventDefault();

		let userid = hash.replace(/#([0-9]+)/,'$1');

		let response = await api.linkprofiles({ userid , newprofileids : selectedbgprofiles });
		if(response.success)
		{
			HideBGCheckListModal();
			emitter.Emit('admin:refreshuser');
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setBgChecks([]);

		( async () => {
			await LoadBgChecks( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');

		if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadBgChecks( userid );
			})();
		}
		else if( ['admin:saveuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await SaveBGStatuses();
			})();
		}
	},[emitter.eventname,hash]);
	React.useEffect( () => {

		CheckRefundStatus();

	},[orderedtailsrefundtype,orderdetailsrefundtoggle,refunddetails.otherrefundamount]);

	/* eslint-enable react-hooks/exhaustive-deps */


	React.useEffect( () => {

		if(orderedtailsrefundtype === 'partial' && otherrefundamountref && otherrefundamountref.current && ( !refunddetails.otherrefundamount || refunddetails.otherrefundamount === '' ) )
		{
			otherrefundamountref.current.focus();
		}

	},[orderedtailsrefundtype,otherrefundamountref,refunddetails.otherrefundamount]);

	return (
		<>
			<Card className="p-4 d-inline-block">
				<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
					{panelshow && <ChevronDown/>}
					{!panelshow && <ChevronRight />}
					&nbsp;Background Checks</Button>


				<Collapse in={panelshow}>
					<Form>
						<Container style={{textAlign:'center'}} id="admin-background-check-list">
							{bgchecks && bgchecks.length > 0 && 
								<Row style={{color:branding.PrimaryColor,textTransform:'uppercase'}} className="p-4">
									<Col>Provider</Col>
									<Col>Package</Col>
									<Col>CRA ID</Col>
									<Col>ShieldID</Col>
									<Col>Start Date</Col>
									<Col>Status</Col>
									<Col>Exp Date</Col>
									<Col xs={1} style={{width:'100px'}}>Amounts</Col>
									<Col xs={1}>Actions</Col>
									<Col xs={1}></Col>
								</Row>
							}
							{bgchecks && bgchecks.map((bgcheck) => {

								return (<>
									<Row key={bgcheck.id} className="p-4 border-top" style={{backgroundColor: bgcheck.active === 'NO' ? '#EEEEEE' : null , color : bgcheck.active === 'NO' ? '#AAAAAA' : null }}>
										<Col>{bgcheck.cra_name}</Col>
										<Col style={{fontSize:'9pt'}}>{bgcheck.package_descrip}</Col>
										<Col>{bgcheck.cra_profilenumber}</Col>
										<Col>{bgcheck.shieldid}</Col>
										<Col>{(bgcheck.dts && dateValid(bgcheck.dts) ) ? dateFormat(bgcheck.dts,'MM/dd/yyyy') : null }</Col>
										<Col>{bgcheck.composite_status}</Col>
										<Col>{(bgcheck.expiration_date && dateValid(bgcheck.expiration_date) ) ? dateFormat(bgcheck.expiration_date,'MM/dd/yyyy') : null }</Col>
										<Col xs={1} style={{width:'100px'}} className="text-end" >
											$ {bgcheck.amount}<br />
											{bgcheck.stripe_transactions && bgcheck.stripe_transactions.length > 0 && <><Stripe size={15} style={{color:'rgb(98, 90, 250)',marginTop:'-2px'}} /> $ {bgcheck.paid_amount} </>}
										</Col>
										<Col xs={1}>
											<Dropdown>
												<Dropdown.Toggle variant="primary" size="sm"><Gear/></Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item onClick={ async () => {
														await DownloadClick(bgcheck.id);
													}} disabled={!(bgcheck.composite_status_code === 'done')} ><Download /> Download Report</Dropdown.Item>
													{bgcheck.stripe_transactions && bgcheck.stripe_transactions.length > 0 && <>
														<Dropdown.Item onClick={ async () => {
															await DownloadReceipt(bgcheck.id);
														}}><Receipt /> Download Receipt</Dropdown.Item>
														<Dropdown.Item onClick={() => {
															ShowDetailsModal(bgcheck.id);
														}} ><CurrencyDollar /> Payment Details / Refund</Dropdown.Item>
													</>}
												</Dropdown.Menu>
											</Dropdown>
										</Col>
										<Col xs={1}>
											{bgcheck.composite_status_code === 'done' && ['YES','NO'].indexOf(bgcheck.active) !== -1 &&
												<Form.Check 
													type="switch" 
													style={{transform:"scale(1.3)"}} 
													checked={bgcheck.active === 'YES'}
													name="active" 
													value="YES"
													onChange={() => {
														handleToggleChange(bgcheck.cra_profilenumber);
													}}
												/>
											}
										</Col>
									</Row>
								</>
								);
							})}
							<Alert className="mt-3" variant="danger" show={errormessage !== false}><ExclamationTriangleFill size={20} /> {errormessage} </Alert>
						</Container>
					</Form>
				</Collapse>
			</Card>
			<Row className="justify-content-end">
				<Col xs={3} className="text-end">
					<Button onClick={() => {
						setAddBGCheckModal(true);
					}} ><ShieldFillPlus /> Add Background Check</Button>
				</Col>
			</Row>
			<Modal show={addbgcheckmodal} id="add-bg-check-modal" size="xl" onHide={HideBGCheckListModal} onShow={() => { bgchecksearchfield.current.focus(); }} centered>
				<Modal.Header closeButton>
					<Modal.Title>Add Background Check</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form ref={addbgchkformRef} onSubmit={LinkBgChks}>
						<Row>
							<Col xs={12}>
								<InputGroup style={{width:"100%"}}>
									<Form.Control 
										ref={bgchecksearchfield}
										type="text"
										placeholder="Search for CRA ID, legal name , email or Shield ID"
										onKeyUp={SearchBackgroundCheck}
										onKeyDown={handleSearchControlkeys}
									/>
									<InputGroup.Text id="search-addon" style={{cursor:"pointer",backgroundColor:branding.PrimaryButtonBackground,borderColor:branding.PrimaryButtonBackground,paddingLeft:"15px",paddingRight:"15px"}}><Search color={branding.PrimaryButtonText}/></InputGroup.Text>
								</InputGroup>
							</Col>
						</Row>
						{searchbgresults && searchbgresults.length > 0 &&
							<Container className="mt-4 p-2">
								<Table>
									<thead>
										<tr>
											<th>
												<Form.Check 
													checked={allsearchitems}
													onChange={(event) => {
														if(event.target.checked)
														{
															AllBGProfiles('add');
														}
														else
														{
															AllBGProfiles('remove');
														}
													}}
												/>
											</th>
											<th>CRA ID</th>
											<th>Legal Name</th>
											<th>ShieldID</th>
											<th>Email</th>
											<th>Purchase Date</th>
											<th>Expiration Date</th>
											<th>Score</th>
										</tr>
									</thead>
									<tbody>
										{searchbgresults && searchbgresults.length > 0 && searchbgresults.map( (record) => {
											return (
												<tr key={record.cra_profilenumber} className={(selectedbgprofiles.indexOf(record.cra_profilenumber) !== -1 ? 'bg-selected' : null )} onClick={() => {

													if(selectedbgprofiles.indexOf(record.cra_profilenumber) === -1)
													{
														UpdateSelectedBGProfile(record.cra_profilenumber, 'add');
													}
													else
													{
														UpdateSelectedBGProfile(record.cra_profilenumber, 'remove');
													}
												}}>
													<td>
														<Form.Check 
															checked={ selectedbgprofiles.indexOf(record.cra_profilenumber) !== -1 }
															onChange={(event) => {

																if(event.target.checked)
																{
																	UpdateSelectedBGProfile(record.cra_profilenumber, 'add');
																}
																else
																{
																	UpdateSelectedBGProfile(record.cra_profilenumber, 'remove');
																}

															}}
														/>
													</td>
													<td>{record.cra_profilenumber}</td>
													<td>{record.legalfirstname} {record.legalmiddlename} {record.legallastname}</td>
													<td>{record.shieldid}</td>
													<td>{record.emailaddress}</td>
													<td>{record.completed_date && dateFormat(record.completed_date,"MM/dd/yyyy")} </td>
													<td>{record.expiration_date && dateFormat(record.expiration_date,"MM/dd/yyyy")}</td>
													<td className="scorecolumn"><ICScore score={record.icscore} /></td>
												</tr>
											);
										})
										}
									</tbody>
								</Table>
							</Container>
						}

						{selectedbgprofiles.length > 0 && transferconfirm && 
							<Row className="justify-content-center">
								<Col xs={6}>
									<Card>
										<Card.Body className="text-center">
										Are you sure you want to transfer the selected records to user <br /><b>{userprofile.firstname} {userprofile.lastname} &lt;{userprofile.emailaddress}&gt;</b> ? <br />
										This removes the records from the current user(s) <br />
											{searchbgresults && searchbgresults.map((record) => {

												if(selectedbgprofiles.indexOf(record.cra_profilenumber) !== -1)
												{
													if(record.user_emailaddress === null || record.user_emailaddress === undefined)
													{
														return ( <><b>NO CURRENT USER</b></> )
													}
													else
													{
														return ( <><b>{record.user_firstname} {record.user_lastname} &lt;{record.user_emailaddress}&gt;</b><br /></> );
													}
												}
												else
												{
													return ( <></> );
												}

											})}
										</Card.Body>
										<Card.Footer className="d-flex justify-content-between">
											<Button variant="secondary" onClick={() => setTransferconfirm(false) }>Nevermind</Button>
											<Button variant="primary" type='submit'>Yes, Transfer Records</Button>
										</Card.Footer>
									</Card>

								</Col>
							</Row>
						}
					</Form>
				</Modal.Body>
				{transferconfirm === false && 
					<Modal.Footer className="justify-content-between">
						<Button variant="link" onClick={HideBGCheckListModal} style={{textDecoration:'none',color:branding.PrimaryColor}} >Close</Button>
						<Button variant="primary" disabled={(selectedbgprofiles.length === 0)} onClick={() => setTransferconfirm(true)}> Link Selected Profile(s)</Button>
					</Modal.Footer>
				}
			</Modal>
			<Modal show={ !( orderdetailsmodal === false ) } size="xl" centered onHide={() => { HideDetailsModal(); }}>
				<Form ref={formRef} onSubmit={SubmitRefund}>
					<Modal.Header closeButton>
						<Modal.Title>Order Transaction Details and Refund</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container className="p-3">
							<Row style={{color:branding.PrimaryColor,textTransform:'uppercase'}}>
								<Col xs={6}>PACKAGE DETAILS</Col>
								<Col xs={3}>DATE</Col>
								<Col >METHOD</Col>
								<Col className="text-end">AMOUNT</Col>
							</Row>
							<Row className="pt-1 pb-1">
								<Col xs={6}>{orderdetailsobject.package_descrip}</Col>
								<Col xs={3}>{(orderdetailsobject.dts && dateValid(orderdetailsobject.dts) ) ? dateFormat(orderdetailsobject.dts,'MM/dd/yyyy ppp') : null }</Col>
								<Col className="text-end">$ {orderdetailsobject.amount}</Col>
							</Row>
							<Row>
								<Col xs={6} style={{color:branding.PrimaryColor,textTransform:'uppercase'}}></Col>
								<Col xs={3}></Col>
								<Col style={{fontWeight:'bold'}} className="border-top pt-2">SUBTOTAL</Col>
								<Col xs={1} style={{fontWeight:'bold'}} className="text-end border-top pt-2">${orderdetailsobject.amount}</Col>
							</Row>
						</Container>
						<Container className="p-3 pt-0" >
							<Row >
								<Col xs={6} style={{color:branding.PrimaryColor,textTransform:'uppercase'}}>PAYMENTS &amp; CREDITS</Col>
								<Col xs={3}></Col>
							</Row>
							{orderdetailsobject?.stripe_transactions?.length > 0 && orderdetailsobject.stripe_transactions.map( (transaction) => {

								return (
									<Row key={transaction.id} className="pt-1">
										<Col xs={6}>{`${transaction.type} (Trans ID: ${transaction.id}) `}</Col>
										<Col xs={3}>
											{(transaction.dts && dateValid(transaction.dts) ) ? dateFormat(transaction.dts,'MM/dd/yyyy ppp') : null }
										</Col>
										<Col>{transaction.method}</Col>
										<Col xs={1} className="text-end">
											{transaction.type === 'REFUND' ? <span className="text-danger">($ {transaction.amount})</span> : <>$ {transaction.amount}</> }
										</Col>
									</Row>);
							})}
							<Row className="pt-1">
								<Col xs={{offset:9}} style={{textTransform:'uppercase',fontWeight:'bold'}} className="pt-3 border-top">BALANCE DUE</Col>
								<Col className="text-end pt-3 border-top" style={{fontWeight:'bold'}}>$ {( parseFloat(orderdetailsobject.amount) - parseFloat(orderdetailsobject.paid_amount) )}</Col>
							</Row>
						</Container>
						<Container className="p-3 rounded-1" style={{backgroundColor:'#F8F9FA'}}>
							<Row>
								<Col>
									<Form.Check type="checkbox" label={<Form.Check.Label role='button'>ISSUE REFUND</Form.Check.Label>} id="issue_refund_toggle" checked={orderdetailsrefundtoggle} onClick={(e) => {
										setDetailsrefundtoggle(e.target.checked);
									}} role='button' inline disabled={!( orderdetailsobject.amount > orderdetailsobject.refunded_amount )} onChange={() => {} } />
								</Col>
								{orderdetailsrefundtoggle && <Col xs={{offset:1}}>
									<Form.Check type="radio" name="refund_type" id="refund_type_full" label={<Form.Check.Label role='button'>Issue Full Refund (${orderdetailsobject.amount})</Form.Check.Label>} checked={(orderedtailsrefundtype === 'full' )} onChange={() => {
										setDetailsrefundtype('full');
									}} disabled={!( orderdetailsobject.refunded_amount === 0 )} role='button' />
									<InputGroup style={{width:'350px'}}>
										<Form.Check type="radio" name="refund_type" id="refund_type_partial" label={<Form.Check.Label role='button'>Other Amount</Form.Check.Label>} inline style={{marginTop:'8px'}} role='button' checked={(orderedtailsrefundtype === 'partial')} onChange={() => {
											setDetailsrefundtype('partial');
										}} /><InputGroup.Text>$</InputGroup.Text><Form.Control ref={otherrefundamountref} onKeyDown={CurrencyFormat} value={refunddetails.otherrefundamount} disabled={(orderedtailsrefundtype !== 'partial')} isInvalid={overrefunderror}  style={{textAlign:'right'}}/>
									</InputGroup>
									<p className="pt-3">If background check has not been started, check this box to cancel the pending background check</p>
									<Form.Check type="checkbox" name="cancel_application" id="cancel_application" label='Cancel Application' value='YES' defaultChecked={true} />
								</Col>}
							</Row>
						</Container>
						<Alert variant="danger" transition={false} show={orderdetailsrefundtoggle} className="m-3"><ExclamationTriangleFill /> Issuing a refund on an active background check will expire the user&apos;s ShieldScore. </Alert>
						<Alert variant="danger" transition={false} show={overrefunderror} className="m-3" ><ExclamationTriangleFill /> Error: Total refund amount cannot exceed original charge amount </Alert>
						<Alert variant="success" show={refundsuccess} className="m-3" ><CheckCircleFill /> Refund Successful </Alert>
					</Modal.Body>
					<Modal.Footer className="justify-content-between">
						<Button variant="link" onClick={() => { HideDetailsModal(); }} style={{textDecoration:'none',color:branding.PrimaryColor}} >Close</Button>
						<Button variant="primary" type='submit' disabled={!(refundbuttonactive) || refundinprogress } > { ( refundinprogress === true ? <>Processing ...</> : <>Issue Refund</> ) }</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<FileDownloadModal
				show={downloadModal}
				downloadlink={ (downloadlink !== false && downloadlink.length > 10 ? downloadlink : ( (receiptpdfcontenturl !== false && receiptpdfcontenturl.length > 10) ? receiptpdfcontenturl : false )  ) }
				downloadtext={ (downloadlink !== false && downloadlink.length > 10 ? 'Download Report' : ( (receiptpdfcontenturl !== false && receiptpdfcontenturl.length > 10) ? 'Download Receipt' : null )  ) }
				downloaderror={downloaderror}
				downloadname={receiptpdfname}
				CancelMethod={() => {
					setDownloadModal(false);
					setDownloadlink(false);
					setReceiptContent(false);
					setDownloaderror(false);
				}}
			/>
		</>
	);

}
export default BackgroundChecks;