import * as React from "react";
import { Container, Spinner } from "react-bootstrap";
import api from '../../../io/user-enterprise';


function UserEnterpriseDashboardEmbed()
{
	const [ isLoaded , setIsLoaded ] = React.useState(false);
	const [ dashboardurl , setDashboardurl ] = React.useState(false);

	async function getDashboardUrl()
	{
		let response = await api.getuserenterprisedashboard();

		if(response.success)
		{
			setIsLoaded(true);
			setDashboardurl(response.dashboard);
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {
		
		( async () => {
			await getDashboardUrl();
		})();

	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<>
			{ !isLoaded &&
				<Container className="enterprise-dashboard" style={{textAlign:'center'}} >
					<Spinner />
				</Container>
			}

			{ isLoaded && dashboardurl && <iframe src={dashboardurl} className="enterprise-dashboard" style={{width:"100%",height:"1120px"}} /> }
		</>
	);
}
export default UserEnterpriseDashboardEmbed;