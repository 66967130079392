import * as React from "react";
import { Card, Collapse , Button, Form , Row , Col , FloatingLabel, Alert } from "react-bootstrap";
import { ChevronDown, ChevronRight  } from "react-bootstrap-icons";
import statelist from '../../../io/states';
import DatePicker from "react-datepicker";
import { format as dateFormat , parse as dateParse , parseISO as dateParseISO , isValid as dateValid } from 'date-fns';
import { useLocation } from "react-router-dom";
import api from '../../../io/admin.js';
import userapi from '../../../io/user.js';
import { useRefreshEmitter } from "../../RefreshEmitter.js";
import ICScore from '../../ICScore.js';





function UserProfile(props)
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ userprofile , setUserProfile ] = React.useState({});
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ emailwarning , setEmailWarning ] = React.useState(false);
	const [ emailexists , setEmailExists ] = React.useState(false);

	const formref = React.createRef();

	async function LoadProfile( userid )
	{
		let response = await api.getuser( userid );

		if(response.success === true)
		{
			if(response.profile.mobile && response.profile.mobile.length > 0)
			{
				response.profile.mobile = response.profile.mobile.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/,'$1-$2-$3');
			}
			if(response.profile.businessphone && response.profile.businessphone.length > 0)
			{
				response.profile.businessphone = response.profile.businessphone.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/,'$1-$2-$3');
			}
			if(response.profile.dateofbirth && typeof response.profile.dateofbirth === 'string' && response.profile.dateofbirth.length > 0)
			{
				try{
					response.profile.dateofbirth = dateParseISO(response.profile.dateofbirth);
				}
				catch(e)
				{
					console.warn(e);
					response.profile.dateofbirth = null;
				}
			}
			else if(response.profile.dateofbirth && response.profile.dateofbirth.length > 0)
			{
				try{
					response.profile.dateofbirth = dateParse(response.profile.dateofbirth,"yyyy-MM-dd",new Date());
				}
				catch(e)
				{
					console.warn(e);
					response.profile.dateofbirth = null;
				}
			}
			if(response.profile.current_expiration && typeof response.profile.current_expiration === 'string' && response.profile.current_expiration.length > 0)
			{
				try{
					response.profile.current_expiration = dateParseISO(response.profile.current_expiration);
				}
				catch(e)
				{
					console.warn(e);
					response.profile.current_expiration = null;
				}
			}
			else if(response.profile.current_expiration && response.profile.current_expiration.length > 0)
			{
				try{
					response.profile.current_expiration = dateParse(response.profile.current_expiration,"yyyy-MM-dd",new Date());
				}
				catch(e)
				{
					console.warn(e);
					response.profile.current_expiration = null;
				}
			}

			setUserProfile(response.profile);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setUserProfile({
				userid : 1000,
				firstname : 'John',
				legalfirstname : 'Jonathan',
				currenticstatus : 'IC01',
				abcnumber : 'CA920571000',
				current_expiration : new Date()
			});
		}
		else
		{
			console.warn(response);
			setUserProfile({});
		}

		setEmailWarning(false);
	}

	function handleStateSelectChange( event )
	{
		let newprofile = { ...userprofile };

		newprofile.state = event.currentTarget.value;

		setUserProfile(newprofile);

		handleFormBlur();
	}

	function handleDOBChange( date )
	{
		let newprofile = { ...userprofile };

		newprofile.dateofbirth = date;

		setUserProfile(newprofile);

		handleFormBlur();
	}	

	function formatPhoneNumber(event , fieldname )
	{
		let validControlKeyCodes = [8,9,37,39,46];

		let newprofile = { ...userprofile };

		if(newprofile[fieldname] === undefined || newprofile[fieldname] === null)
		{
			newprofile[fieldname] = '';
		}

		if(validControlKeyCodes.indexOf(event.keyCode) !== -1)
		{
			if(event.keyCode === 8 && newprofile[fieldname] && newprofile[fieldname].length > 0)
			{
				newprofile[fieldname] = newprofile[fieldname].substring(0,newprofile[fieldname].length-1);

				setUserProfile(newprofile);
			}

			//control keys like Tab, backspace or arrows
			return;
		}
		else if(!event.key.match(/[0-9]/))
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		newprofile[fieldname] += event.key;

		if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{3}$/))
		{
			newprofile[fieldname] += "-";
		}
		else if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{3}-[0-9]{3}$/))
		{
			newprofile[fieldname] += "-";
		}
		else if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/))
		{

		}
		else if(newprofile[fieldname] && newprofile[fieldname].match(/^[0-9]{1,3}-?[0-9]{0,3}-?[0-9]{0,4}$/))
		{

		}
		else
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		setUserProfile(newprofile);
	}

	async function handleFormBlur( event )
	{
		if(event?.currentTarget?.name === 'emailaddress')
		{
			let emailexists_error = await CheckEmail( event.currentTarget.value );

			if(emailexists_error === true)
			{
				props.setFormDirtyFunction(false);
				setEmailWarning(false);
				setEmailExists(emailexists_error);

				//return early so the form dirty flag is not set so the admin
				//user cannot save the wrong value
				return;
			}
			else 
			{
				setEmailWarning(true);
			}
		}

		props.setFormDirtyFunction(true);

	}

	async function CheckEmail( emailaddress )
	{
		let response = await userapi.duplicateaccountcheck( emailaddress );

		if(response.success === true && response.accountexists === true)
		{
			return true;
		}

		return false;
	}

	async function SaveProfile()
	{
		let profileobject = {
			userid : userprofile.userid,
		};

		let formData = new FormData(formref.current);

		profileobject.firstname = formData.get('firstname');
		profileobject.middlename = formData.get('middlename');
		profileobject.lastname = formData.get('lastname');
		profileobject.namesuffix = formData.get('namesuffix');
		profileobject.businessname = formData.get('businessname');
		profileobject.address1 = formData.get('address1');
		profileobject.address2 = formData.get('address2');
		profileobject.state = formData.get('state');
		profileobject.postalcode = formData.get('postalcode');
		profileobject.mobile = formData.get('mobile');
		profileobject.businessphone = formData.get('businessphone');
		profileobject.emailaddress = formData.get('emailaddress');
		profileobject.dateofbirth = formData.get('dateofbirth');

		if(profileobject.mobile && profileobject.mobile.length > 0)
		{
			profileobject.mobile = profileobject.mobile.replace(/[^0-9]/g,'');
		}
		if(profileobject.businessphone && profileobject.businessphone.length > 0)
		{
			profileobject.businessphone = profileobject.businessphone.replace(/[^0-9]/g,'');
		}

		let response = await api.saveuser( profileobject );

		if(response.success === true)
		{
			props.setFormDirtyFunction(false);
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setUserProfile({});
		props.setFormDirtyFunction(false);

		( async () => {
			await LoadProfile( userid );
		})();

	},[hash]);

	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		if( ['admin:saveuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await SaveProfile();
			})();
		}
		else if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadProfile( userid );
			})();
		}
	},[emitter.eventname,hash]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;Personal &amp; Business Profile</Button>


			<Collapse in={panelshow}>
				<Form ref={formref} autoComplete="off">
					<Row className="d-flex">
						<Col lg={{span:9,order:1}} xs={{span:12,order:2}} className="profileinfo-container">
							<Row>
								<Col sm={12} lg={6} className="mb-2" >
									<FloatingLabel controlId="firstname" label="FIRST NAME">
										<Form.Control 
											type="text" 
											placeholder="FIRST NAME" 
											name="firstname"
											defaultValue={userprofile.firstname}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>
								<Col sm={12} lg={6} className="mb-2">
									<FloatingLabel controlId="middlename" label="MIDDLE NAME">
										<Form.Control 
											type="text" 
											placeholder="MIDDLE NAME" 
											name="middlename"
											defaultValue={userprofile.middlename}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>
								<Col sm={12} lg={6} className="mb-2">
									<FloatingLabel controlId="lastname" label="LAST NAME">
										<Form.Control 
											type="text" 
											placeholder="LAST NAME" 
											name="lastname"
											defaultValue={userprofile.lastname}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>
								<Col sm={12} lg={6} className="mb-2">
									<FloatingLabel controlId="namesuffix" label="SUFFIX">
										<Form.Control 
											type="text" 
											placeholder="SUFFIX" 
											name="namesuffix"
											defaultValue={userprofile.namesuffix}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>
								<Col sm={12} xs={12} className="mb-2">
									<FloatingLabel controlId="businessname" label="BUSINESS NAME">
										<Form.Control 
											type="text" 
											placeholder="BUSINESS NAME" 
											name="businessname"
											defaultValue={userprofile.businessname}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>
					
								<Col sm={12} className="mb-2">
									<FloatingLabel controlId="address1" label="ADDRESS 1">
										<Form.Control 
											type="text" 
											placeholder="ADDRESS 1" 
											name="address1"
											defaultValue={userprofile.address1}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>

								<Col sm={12} className="mb-2">
									<FloatingLabel controlId="address2" label="ADDRESS 2">
										<Form.Control 
											type="text" 
											placeholder="ADDRESS 2" 
											name="address2"
											defaultValue={userprofile.address2}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>

								<Col sm={12} className="mb-2">
									<FloatingLabel controlId="city" label="CITY">
										<Form.Control 
											type="text" 
											placeholder="CITY" 
											name="city"
											defaultValue={userprofile.city}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>

								<Col sm={6} className="mb-2">
									<FloatingLabel className="statedropdownprofile" controlId="state" label="STATE">
										<Form.Select
											placeholder="STATE" 
											name="state"
											size="lg"
											value={userprofile.state}
											style={{paddingRight:"0px"}}
											onChange={handleStateSelectChange}
										>
											<option></option>
											{Object.keys(statelist).map( (abbr) => {
												return <option key={abbr} value={abbr}>{abbr}</option>;
											} )}
									
										</Form.Select>
									</FloatingLabel>
								</Col>
		
								<Col sm={6} className="mb-2">
									<FloatingLabel controlId="postalcode" label="POSTAL CODE">
										<Form.Control 
											type="text" 
											placeholder="POSTAL CODE" 
											name="postalcode"
											defaultValue={userprofile.postalcode}
											maxLength={5}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>

								<Col sm={6} className="mb-2">
									<FloatingLabel controlId="mobile" label="MOBILE PHONE NUMBER">
										<Form.Control 
											type="text" 
											placeholder="MOBILE PHONE NUMBER" 
											name="mobile"
											value={userprofile.mobile ? userprofile.mobile : ''}
											onKeyDown={(event) => { formatPhoneNumber(event,'mobile') }}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
								</Col>
								<Col sm={6} className="mb-2">
									<FloatingLabel controlId="businessphone" label="BUSINESS PHONE NUMBER">
										<Form.Control 
											type="text" 
											placeholder="BUSINESS PHONE NUMBER" 
											name="businessphone"
											value={userprofile.businessphone ? userprofile.businessphone : ''}
											onKeyDown={(event) => { formatPhoneNumber(event,'businessphone') }}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>						
								</Col>

								{/* changing from 12 to 8 while SSN is hidden until MFA can be enabled */}
								<Col md={8} className="mb-2">
									<FloatingLabel controlId="emailaddress" label="EMAIL ADDRESS">
										<Form.Control 
											type="text" 
											placeholder="EMAIL ADDRESS" 
											name="emailaddress"
											defaultValue={userprofile.emailaddress}
											onChange={handleFormBlur}
										/>
									</FloatingLabel>
									<Alert show={emailwarning} variant="warning" className="p-1 m-1 text-center">Changing user&apos;s email will change their login</Alert>
									<Alert show={emailexists} variant="danger" className="p-1 m-1 text-center">Error: Email address provided already exists</Alert>
								</Col>

								{/* changing from 6 to 4 while SSN is hidden until MFA can be enabled */}
								<Col sm={4} className="mb-2">
									<DatePicker 
										selected={(userprofile.dateofbirth && dateValid(userprofile.dateofbirth) ? userprofile.dateofbirth : null )} 
										onSelect={handleDOBChange}
										onChange={handleFormBlur}
										showYearDropdown
										yearDropdownItemNumber={100}
										scrollableYearDropdown
										filterDate={(date) => {
											const currentDate = new Date();
											const min_date = new Date('1900-01-01T00:00:00');
											return date < currentDate && date > min_date ;
										}}
										customInput={<FloatingLabel controlId="dateofbirth" label="DATE OF BIRTH"><Form.Control type="text" placeholder="DATE OF BIRTH" name="dateofbirth" defaultValue={ (userprofile.dateofbirth && dateValid(userprofile.dateofbirth) ? dateFormat(userprofile.dateofbirth,"MM/dd/yyyy") : null )} /></FloatingLabel>}
										popperClassName="react-datepicker-popper-zindex"
									/>
								</Col>								
							</Row>
						</Col>
						<Col lg={{span:3,order:2}} xs={{span:12,order:1}} id='profileimage-container'>
							<Row className="p-2">
								<Col style={{textAlign:'center',height:'220px'}}>
									<div style={{zIndex:"1",position:"relative",color:"#2A2D40"}}>
										{userprofile.profileimageurl && userprofile.profileimageurl.length > 20 && <div className="profile-image-frame" style={{marginLeft:'auto',marginRight:'auto',backgroundImage:'url("'+userprofile.profileimageurl+'")'}} />}
										{( !(userprofile.profileimageurl) || userprofile.profileimageurl.length === 0) && <div className="profile-image-frame" style={{marginLeft:'auto',marginRight:'auto',backgroundImage:'url("/images/user-icon.png")'}} /> }
									</div>
								</Col>
							</Row>
							<Row className="p-2"><Col style={{textAlign:'center'}}>{userprofile.legalfirstname} {userprofile.legallastname}</Col></Row>
							<Row className="p-2"><Col className='scorecolumn'>{userprofile.currenticstatus && <ICScore score={userprofile.currenticstatus} />}</Col></Row>
							<Row className="p-2"><Col style={{textAlign:'center'}}>{userprofile.abcnumber && ('ShieldID '+userprofile.abcnumber)}</Col></Row>
							<Row className="p-2"><Col style={{textAlign:'center'}}>Expires: {(userprofile.current_expiration && dateValid(userprofile.current_expiration) ? dateFormat(userprofile.current_expiration,"MM/dd/yyyy") : null )}</Col></Row>
						</Col>
					</Row>
				</Form>
			</Collapse>
		</Card>
	);
}
export default UserProfile;