import * as api from './apibase';

async function login ( emailaddress , password )
{
	return await api.post('/login',{emailaddress,password});
}

async function logout ( )
{
	return await api.get('/logout');
}

async function verify( checktwofactor = false )
{
	let querystring = '';
	if(checktwofactor)
	{
		querystring = '?checktwofactor=true';
	}

	return await api.get(`/verifysession${querystring}`);
}

async function sendverificationcode( method )
{
	return await api.post('/twostepverify', {method} );
}

async function twostepverification( code , rememberbrowser )
{
	return await api.post('/twostepverify', {code, rememberbrowser});
}

async function getAlertCount()
{
	return await api.post('/network/updatealertcount');
}

export default { 
	login , 
	logout , 
	verify , 
	sendverificationcode , 
	twostepverification ,
	getAlertCount ,
};