import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import { Stack , Button, Form, Alert , FloatingLabel , Row , Col } from 'react-bootstrap';
import Logo from '../assets/shieldhub-logo.png'
import { ArrowRightShort } from 'react-bootstrap-icons';
import api from "../io/user";
import { useBranding } from "../components/Branding";


function ForgotPassword(){

	const [ errorbanner, setErrorbanner ] = React.useState(false);
	const [ errormessage , setErrormessage ] = React.useState(false);
	const [ errorfields , setErrorfields ] = React.useState({emailaddress:false});
	const formRef = React.createRef(null);
	const navigate = useNavigate();
	const branding = useBranding();

	async function startForgotPassword(event)
	{
		event.preventDefault();

		let errorbanner = false;

		let fields = { ...errorfields };		

		let formData = new FormData(formRef.current);
		let emailaddress = formData.get('emailaddress');

		if( emailaddress === null || emailaddress === undefined || emailaddress.length === 0 )
		{
			fields.emailaddress = true;
			errorbanner = true;
		}

		setErrorbanner(errorbanner);
		setErrorfields(fields);

		if(errorbanner === false)
		{
			let forgotpasswordresponse = await api.startforgotpassword(emailaddress);

			if(forgotpasswordresponse.success === false)
			{
				setErrormessage(" Error: There was a problem with your form submission. ");
				return;
			}

			navigate("/forgotpassword/verification");
		}
		else
		{
			setErrormessage(" Error: Please fill in the highlighted required fields. ");
		}

	}

	return (

		<div className="login-position container">
			<Stack gap={2}>
				<Row className="justify-content-center login-portal">
					<Col xs="auto" style={{height:'90px'}}>
						{process.env.REACT_APP_LOCALDEBUG ? <img src={Logo} alt="ShieldHub Logo" className="branding"/> : <img src={branding.Company.branding_logo_path} alt="ShieldHub Logo" className="branding"/>}
					</Col>
				</Row>
				<Row className="justify-content-center login-portal">
					<Col xs={12} sm={10} md={8} lg={6}>
						<Form onSubmit={startForgotPassword} autoComplete="off" ref={formRef}  className="login-container">
							<h2>Forgot Password</h2>
							<h6>It&apos;s okay to forget sometimes. Please enter the email address you used to sign up with ShieldHub.</h6>
							<Row className="p-3 justify-content-md-center login-portal">
								<Col xs={{ span: 10, offset: 1 }}>
									<FloatingLabel controlId="emailaddress" label="EMAIL ADDRESS">
										<Form.Control
											name="emailaddress"
											type="emailaddress"
											placeholder="EMAIL ADDRESS"
											aria-label="Email Address"
											aria-describedby="basic-addon1"
											isInvalid={errorfields.emailaddress}
											data-clarity-unmask="true"
										/>
									</FloatingLabel>
								</Col>
							</Row>
							<Row>
								<Col>
									<Alert variant="danger" show={errorbanner} style={{clear:"both"}}> <ExclamationTriangleFill size={20} />{errormessage}</Alert>

									<div style={{clear:"both"}}>&nbsp;</div>
									<div style={{float:"left",marginTop:"20px"}}>
										<Button className="cancel-button" variant="link">Cancel</Button>
									</div>
									<div style={{float:"right",marginTop:"20px"}}>
										<Button type="submit" className="continue-button">Continue <ArrowRightShort size={16} style={{marginTop:"-2px"}} /></Button>
									</div>
								</Col>
							</Row>
							<div style={{clear:"both"}}>&nbsp;</div>
							<div style={{"color":"#6C757D",textAlign:"center"}}>Don&apos;t have an account? <Button variant="link" href="/register">Sign Up</Button></div>
						</Form>
					</Col>
				</Row>
			</Stack>
		</div>
	)

}

export default ForgotPassword;