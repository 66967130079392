import * as React from "react";
import { createGlobalStyle } from 'styled-components';
import useLocalStorage from '@twipped/hooks/useLocalStorage';
import api from '../io/general';

const Branding = {
	PrimaryButtonBackground : "#ED683C",
	PrimaryButtonText : "#FFFFFF",
	PrimaryColor : "#ED683C",
	loadBranding : null
};

let BrandingContext = React.createContext(Branding);

function BrandingProvider({children}){

	let Defaults = { ...Branding };

	const [ brandingValues , setBrandingValues ] = useLocalStorage('Branding',{
		PrimaryButtonBackground: Defaults.PrimaryButtonBackground , 
		PrimaryButtonText: Defaults.PrimaryButtonText,
		PrimaryColor : Defaults.PrimaryColor,
		Company : {
			company_id : '',
			company_name : '',
			company_address1 : '',
			company_address2 : '',
			company_city : '',
			company_state : '',
			company_postalcode : '',
			company_country : '',
			company_phone : '',
			branding_logo_path : '',
			stripepublishablekey : '',
		}
	});

	//Modify branding if needed here

	async function loadBranding()
	{
		let response = await api.branding();

		if(response.success === true && response.defaultbranding === true && response.branding)
		{
			window.location = response.branding.company_url;
			return;
		}
		else if(response.success)
		{
			let overrideBranding = {...brandingValues};

			overrideBranding.PrimaryColor = ( response.branding?.primary_color && response.branding.primary_color !== '' ? response.branding.primary_color : Defaults.PrimaryColor ) ;
			overrideBranding.PrimaryButtonBackground = ( response.branding?.primary_button_background && response.branding.primary_button_background !== '' ? response.branding.primary_button_background : Defaults.PrimaryButtonBackground ) ;
			overrideBranding.PrimaryButtonText = ( response.branding?.primary_button_text && response.branding.primary_button_text !== '' ? response.branding.primary_button_text : Defaults.PrimaryButtonText ) ;

			overrideBranding.Company = {
				company_id : response.branding?.id,
				company_name : response.branding?.company_name,
				company_address1 : response.branding?.company_address1,
				company_address2 : response.branding?.company_address2,
				company_city : response.branding?.company_city,
				company_state : response.branding?.company_state,
				company_postalcode : response.branding?.company_postalcode,
				company_country : response.branding?.company_country,
				company_phone : response.branding?.company_phone,
				branding_logo_path : response.branding?.branding_logo_path,
				business_footer : response.branding?.business_footer,
				shieldhub_branding : response.branding?.shieldhub_branding,
				stripepublishablekey : response.branding?.stripepublishablekey,
			};

			if(overrideBranding.Company.branding_logo_path && overrideBranding.Company.branding_logo_path !== '' && !overrideBranding.Company.branding_logo_path.match(/^https/) )
			{
				overrideBranding.Company.branding_logo_path = `${process.env.PUBLIC_URL}/images/${overrideBranding.Company.branding_logo_path}`;
			}


			setBrandingValues(overrideBranding);
		}
		return;
	}

	let value = {...brandingValues, loadBranding};

	return <BrandingContext.Provider value={value}>{children}</BrandingContext.Provider>;
}

function useBranding(){
	return React.useContext(BrandingContext);
}

const GlobalStyles = createGlobalStyle`
html {
	--primary-color : ${props => props.branding.PrimaryColor};
	--primary-button-background : ${props => props.branding.PrimaryButtonBackground};
	--primary-button-background-darker : color-mix(in srgb,var(--primary-button-background),#000 15%);
	--primary-button-background-lighter : color-mix(in srgb, var(--primary-button-background), #fff 15%);
	--primary-button-text-color : ${props => props.branding.PrimaryButtonText};
}
`;

function BrandingCSS({children})
{
	let brandingObj = useBranding();

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		( async () => {
			await brandingObj.loadBranding();
		} )();

	}, [window.location.hostname] );
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<>
			<GlobalStyles branding={brandingObj} />
			{children}
		</>
	);
}

export { BrandingProvider, useBranding , BrandingCSS }