import * as React from 'react';
import { Alert, Button, Col, Form, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import api from "../../io/user";
import generalapi from "../../io/general";
import { ArrowRightShort, ExclamationTriangleFill } from 'react-bootstrap-icons';
import { useAuth } from '../Auth';


function AcceptNewTerms (props)
{
	const [ errorbanner, setErrorbanner ] = React.useState(false);
	const [ errormessage , setErrormessage ] = React.useState(false);
	const [ errorfields , setErrorfields ] = React.useState({accept_terms:false});
	const formRef = React.createRef(null);
	const [ TermsAndConditions , setTermsAndConditions ] = React.useState({ __html : '<div></div>'});
	const navigate = useNavigate();
	const auth = useAuth()

	async function handleAcceptTerms(event)
	{
		event.preventDefault();

		let errorbanner = false;

		let fields = { ...errorfields };		

		let formData = new FormData(formRef.current);
		let accept_terms = formData.get('accept_terms');

		if( accept_terms === null || accept_terms === undefined || accept_terms.length === 0 )
		{
			fields.accept_terms = true;
			errorbanner = true;
		}

		setErrorbanner(errorbanner);
		setErrorfields(fields);

		if(errorbanner === false)
		{
			let registerresponse = await api.registerterms(accept_terms,null,'true');

			if(registerresponse.success === false)
			{
				setErrormessage(" Error: There was a problem with registration. ");
				return;
			}

			auth.checkExistingSession();

			navigate("/user");
			return;
		}
		else
		{
			setErrormessage(" Error: Please fill in the highlighted required fields. ");
		}

	}

	async function getTermsAndConditions()
	{
		let termsandconditions = await generalapi.branding();

		if(termsandconditions.success && termsandconditions.branding)
		{
			setTermsAndConditions({__html : termsandconditions.branding.company_terms_conditions});
		}
	
	}

	React.useEffect( () => {

		( async () => {
			await getTermsAndConditions();
		})();

	},[]);

	return (
		<>
			<Modal show={props.show} centered size='lg' keyboard={false} backdrop="static">
				<Modal.Body className="p-4">
					<Form onSubmit={handleAcceptTerms} autoComplete="off" ref={formRef}>
						<Col xs={12}>
							<Alert variant='info' style={{textAlign:'center'}}>Our terms and conditions have changed. Pease review and accept before proceeding.</Alert>
							<h4 style={{display: "block"}} className="print-hide">Terms &amp; Conditions</h4>
							<div className="tc-container" dangerouslySetInnerHTML={TermsAndConditions}></div>
						</Col>
						<Col>
							<Form.Check id="accept_terms" className="print-hide" >
								<Form.Check.Input type="checkbox" name="accept_terms" value="Y" style={{cursor:'pointer'}} isInvalid={errorfields.accept_terms}/>
								<Form.Check.Label style={{fontSize:"14px",cursor:'pointer'}}>I understand and accept these terms of service</Form.Check.Label>
							</Form.Check>
						</Col>
						<Alert variant="danger" show={errorbanner} style={{clear:"both"}}> <ExclamationTriangleFill size={20} />{errormessage}</Alert>
						<div style={{clear:"both"}}>&nbsp;</div>	
						<div className="print-hide" style={{float:"left",marginTop:"20px"}}>
							<Button variant='secondary' className="cancel-button" href="/logout">Decline</Button>
						</div>
						<div className="print-hide" style={{float:"right",marginTop:"20px"}}>
							<Button className="btn-primary continue-button" type="submit">Continue <ArrowRightShort size={16} style={{marginTop:"-2px"}} /></Button>
						</div>
						<div style={{clear:"both"}}>&nbsp;</div>	
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
}
export default AcceptNewTerms;