import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import api from '../io/user.js';
import authapi from "../io/login";
import { useCookies } from "react-cookie";


function DeepLink () {

	let params = useParams();
	const navigate = useNavigate();
	const [ cookies , setCookie ] = useCookies(['sh_link_redirect_after_login']);

	async function GetLink( linkid )
	{
		let authresponse = await authapi.verify( true );

		let response = await api.getlink( linkid );

		if( authresponse.success && response.success && response.details.newuser === false )
		{
			if(cookies?.sh_link_redirect_after_login)
			{
				setCookie( 'sh_link_redirect_after_login' , '' , { maxAge : -1 } );
			}

			if(response?.details?.redirectto)
			{
				navigate(response.details.redirectto);
				return;
			}
			else
			{
				navigate("/user");
				return;
			}
		}
		else if(response.success && response.details.newuser)
		{
			navigate("/register");
			return;
		}
		else if( response.success )
		{
			navigate("/login");
			return;
		}

		navigate("/login");
		return;
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		( async () => {
			await GetLink( params.linkid );
		})();

	},[params.linkid]);
	/* eslint-enablereact-hooks/exhaustive-deps */


	return (
		<></>
	);
}
export default DeepLink;