import * as React from "react"
import { useNavigate , useLocation } from "react-router-dom";
import api from "../io/login";
import useMemoObject from '@twipped/hooks/useMemoObject';
import useSessionStorage from '@twipped/hooks/useSessionStorage';
import { parse as dateParse , parseISO as dateParseISO } from 'date-fns';
import { useRefreshEmitter } from "./RefreshEmitter";

const Auth = {
	isValidUser : false,
	user : null,
	profile : null,
	login : null,
	logout : null,
	checkExistingSession : null,
	registersession : null
};

let AuthContext = React.createContext(Auth);

function AuthProvider({children}) {

	const [ sessionUser , setSessionUser ] = useSessionStorage('ShieldHubUser',{});
	const [ sessionProfile , setSessionProfile ] = useSessionStorage('ShieldHubProfile',{});
	const [ user , setUser ] = React.useState(sessionUser);
	const [ isValidUser , setValidUser ] = React.useState(false);
	const [ enterpriseProfile , setEnterpriseProfile ] = React.useState({});
	const memoedUser = useMemoObject(user,{comparison:true});

	async function login(emailaddress,password){

		var response = await api.login(emailaddress,password);

		if(response.success === true)
		{
			setValidUser(true);
			setUser(response.user);
			setSessionUser(response.user);
			if(response.profile.dateofbirth && typeof response.profile.dateofbirth === 'string' && response.profile.dateofbirth.length > 0)
			{
				response.profile.dateofbirth = dateParseISO(response.profile.dateofbirth);
			}
			else if(response.profile.dateofbirth && response.profile.dateofbirth.length > 0)
			{
				response.profile.dateofbirth = dateParse(response.profile.dateofbirth,"yyyy-MM-dd",new Date());
			}
			setSessionProfile(response.profile);
			setEnterpriseProfile(response.enterprise);
		}

		return response;
	}

	async function logout(){
		let response = await api.logout();

		setValidUser(false);
		setUser(null);
		setSessionUser({});
		setSessionProfile({});
		setEnterpriseProfile({});

		return response;
	}

	async function checkExistingSession( checktwofactor = false ){

		var response = await api.verify( checktwofactor );

		if(response.success)
		{
			setValidUser(true);
			setUser(response.user);
			setSessionUser(response.user);
			setEnterpriseProfile(response.enterprise);
			return true;
		}

		setUser(null);
		setValidUser(false);
		setSessionUser({});
		setEnterpriseProfile({});

		return false;

	}

	async function checkAdminSession(checktwofactor = false){

		let existingsession = await checkExistingSession(checktwofactor);

		if(existingsession)
		{
			if(user.role === 'ADMIN')
			{
				return true;
			}
			else
			{
				return false;
			}
		}

		return false;
	}

	async function registersession( user ){

		setValidUser(true);
		setUser(user);
		setSessionUser(user);

	}

	async function refreshAlertCount(){

		if(enterpriseProfile.alert_dashboard_enabled !== true)
		{
			return;
		}

		let newenterpriseobject = { ...enterpriseProfile };

		let response = await api.getAlertCount();
		if(response.success)
		{
			newenterpriseobject.alert_count = parseInt(response.alert_count);
			setEnterpriseProfile(newenterpriseobject);
		}
	}

	let value = { user : memoedUser , isValidUser , profile : sessionProfile , enterpriseprofile : enterpriseProfile , login, logout , checkExistingSession , checkAdminSession , registersession , refreshAlertCount };

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {

	return React.useContext(AuthContext);
}

function RequiresAuth({children}){

	let auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const emitter = useRefreshEmitter();

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		( async () => {

			let checktwofactor = true;
			if( ['/verify'].indexOf(location.pathname) !== -1 )
			{
				checktwofactor = false;
			}

			var existingsession = await auth.checkExistingSession( checktwofactor );

			if(!existingsession)
			{
				navigate("/logout");
			}

		} )();


	}, [location] );

	React.useEffect( () => {

		if( ['newapplicationcheck','globalrefresh'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {

				let checktwofactor = true;
				if( ['/verify'].indexOf(location.pathname) !== -1 )
				{
					checktwofactor = false;
				}

				let existingsession = await auth.checkExistingSession( checktwofactor );
				if(!existingsession)
				{
					navigate("/logout");
				}

				await auth.refreshAlertCount();

			})();
		}

	},[emitter.eventname]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return children;
}

function RequiresAdmin({children}) {

	let auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const emitter = useRefreshEmitter();

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		( async () => {

			let checktwofactor = true;
			if( ['/verify'].indexOf(location.pathname) !== -1 )
			{
				checktwofactor = false;
			}

			var existingsession = await auth.checkExistingSession( checktwofactor );
			if(!existingsession)
			{
				navigate("/logout");
			}
			else if(existingsession && ( !auth.user || !auth.user.role || auth.user.role !== 'ADMIN' ) )
			{
				navigate("/logout");
			}

		} )();


	}, [location] );

	React.useEffect( () => {

		if( ['newapplicationcheck','globalrefresh'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {

				let checktwofactor = true;
				if( ['/verify'].indexOf(location.pathname) !== -1 )
				{
					checktwofactor = false;
				}
	
				let existingsession = await auth.checkAdminSession( checktwofactor );
				if(!existingsession)
				{
					navigate("/logout");
				}

				await auth.refreshAlertCount();

			})();
		}

	},[emitter.eventname]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return children;

}

export { AuthProvider , useAuth , RequiresAuth , RequiresAdmin };
