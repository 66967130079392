import * as React from "react";
import { Card, Button, Row , Col, Collapse, Container} from "react-bootstrap";
import api from '../../../io/admin';
import { useLocation } from "react-router-dom";
import { ChevronDown , ChevronRight, Square, CheckSquareFill, Circle, RecordCircleFill } from "react-bootstrap-icons";
import { useBranding } from "../../Branding";


function PasswordAndSecurity(props)
{
	const { hash } = useLocation();
	const [ userprofile , setUserProfile ] = React.useState({});
	const [ panelshow , setPanelShow ] = React.useState(true);
	const branding = useBranding();

	async function LoadProfile( userid )
	{
		let response = await api.getuser( userid );

		if(response.success === true)
		{
			setUserProfile(response.profile);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setUserProfile({
				selected_two_step_method : 'OTPAPP',
				enabled_two_step_methods : [
					'SMS',
					'OTPAPP'
				]
			});
		}
		else
		{
			console.warn(response);
			setUserProfile({});
		}

	}

	async function SendPasswordReset()
	{
		let userid = hash.replace(/#([0-9]+)/,'$1');

		let response = await api.sendforgotpassword( userid );

		if(response.success)
		{
			if(props.setSuccessFunction)
			{
				props.setSuccessFunction('resetpassword');
				setTimeout(() => {

					props.setSuccessFunction(false);

				},10000);
			}
		}
		else
		{
			console.warn(response);
		}
	}

	async function ResetTwoFactorMethod()
	{
		let userid = hash.replace(/#([0-9]+)/,'$1');

		let response = await api.resettwofactor( userid );

		if(response.success)
		{
			await LoadProfile( userid );
		}
	}

	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setUserProfile({});

		( async () => {
			await LoadProfile( userid );
		})();

	},[hash]);


	return ( 
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;Password and Security</Button>
			<Collapse in={panelshow}>
				<Container>
					<Row className="feature-row" style={{paddingLeft:'35px',borderTop:'solid 1px #B1B1B1'}}>
						<Col xs={7}>
							<h6>Password Reset</h6>
							<p>Send a password reset email to the user&apos;s email</p>
						</Col>
						<Col xs={5} style={{textAlign:'left'}}>
							<Button onClick={SendPasswordReset}>Send Reset Password</Button>
						</Col>
					</Row>
					<Row className="feature-row" style={{paddingLeft:'35px'}}>
						<Col xs={7}>
							<h6>2-Step Verification</h6>
							<p>Enable 2-Step Verification across devices.</p>
						</Col>
						<Col xs={5} className="p-2">
							<Row style={{color:branding.PrimaryColor,paddingBottom:'10px'}}>
								<Col></Col>
								<Col style={{textAlign:'center'}}><b>ENABLED</b></Col>
								<Col style={{textAlign:'center'}}><b>PREFERRED</b></Col>
							</Row>
							<Row style={{paddingBottom:'10px'}}>
								<Col><b>Email</b></Col>
								<Col style={{textAlign:'center'}}>
									{userprofile.selected_two_step_method !== 'DISABLED' && <CheckSquareFill color={branding.PrimaryColor} />}
									{userprofile.selected_two_step_method === 'DISABLED' && <Square color={branding.PrimaryColor} />}
								</Col>
								<Col style={{textAlign:'center'}}>
									{userprofile.selected_two_step_method === 'EMAIL' && <RecordCircleFill color={branding.PrimaryColor} />}
									{userprofile.selected_two_step_method !== 'EMAIL' && <Circle color={branding.PrimaryColor} />}
								</Col>
							</Row>
							<Row style={{paddingBottom:'10px'}}>
								<Col><b>SMS</b></Col>
								<Col style={{textAlign:'center'}}>
									{userprofile.enabled_two_step_methods && userprofile.enabled_two_step_methods.length > 0 && userprofile.enabled_two_step_methods.indexOf('SMS') !== -1 && <CheckSquareFill color={branding.PrimaryColor} />}
									{(userprofile.enabled_two_step_methods === null || userprofile.enabled_two_step_methods?.length === 0 || userprofile.enabled_two_step_methods?.indexOf('SMS') === -1) && <Square color={branding.PrimaryColor} />}
								</Col>
								<Col style={{textAlign:'center'}}>
									{userprofile.selected_two_step_method === 'SMS' && <RecordCircleFill color={branding.PrimaryColor} />}
									{userprofile.selected_two_step_method !== 'SMS' && <Circle color={branding.PrimaryColor} />}
								</Col>
							</Row>
							<Row style={{paddingBottom:'10px'}}>
								<Col><b>Authenticator App</b></Col>
								<Col style={{textAlign:'center'}}>
									{userprofile.enabled_two_step_methods && userprofile.enabled_two_step_methods.length > 0 && userprofile.enabled_two_step_methods.indexOf('OTPAPP') !== -1 && <CheckSquareFill color={branding.PrimaryColor} />}
									{(userprofile.enabled_two_step_methods === null || userprofile.enabled_two_step_methods?.length === 0 || userprofile.enabled_two_step_methods?.indexOf('OTPAPP') === -1) && <Square color={branding.PrimaryColor} />}
								</Col>
								<Col style={{textAlign:'center'}}>
									{userprofile.selected_two_step_method === 'OTPAPP' && <RecordCircleFill color={branding.PrimaryColor} />}
									{userprofile.selected_two_step_method !== 'OTPAPP' && <Circle color={branding.PrimaryColor} />}
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="feature-row" style={{paddingLeft:'35px'}}>
						<Col xs={7}>
							<h6>2-Step Verification Reset</h6>
							<p>Removes user preferences, and resets authentication to Email only.</p>
						</Col>
						<Col xs={5} style={{textAlign:'left'}}>
							<Button onClick={ResetTwoFactorMethod}>Reset 2-Step Verification</Button><br />
						</Col>
						
					</Row>
				</Container>
			</Collapse>
		</Card>
	);
}
export default PasswordAndSecurity;