import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import { Stack , Button, Form, Alert , Row , Col} from 'react-bootstrap';
import Logo from '../assets/shieldhub-logo.png'
import { ArrowRightShort, Printer } from 'react-bootstrap-icons';
import api from "../io/user";
import generalapi from "../io/general";
import { useBranding } from "../components/Branding";
import { useAuth } from "../components/Auth";


function RegisterTandC(){

	const [ errorbanner, setErrorbanner ] = React.useState(false);
	const [ errormessage , setErrormessage ] = React.useState(false);
	const [ errorfields , setErrorfields ] = React.useState({accept_terms:false});
	const [ TermsAndConditions , setTermsAndConditions ] = React.useState({ __html : '<div></div>'});
	const formRef = React.createRef(null);
	const navigate = useNavigate();
	const branding = useBranding();
	const auth = useAuth();
	

	async function handleAcceptTerms(event)
	{
		event.preventDefault();

		let errorbanner = false;

		let fields = { ...errorfields };		

		let formData = new FormData(formRef.current);
		let accept_terms = formData.get('accept_terms');
		let email_copy = formData.get('email_copy');

		if( accept_terms === null || accept_terms === undefined || accept_terms.length === 0 )
		{
			fields.accept_terms = true;
			errorbanner = true;
		}

		setErrorbanner(errorbanner);
		setErrorfields(fields);

		if(errorbanner === false)
		{
			let acceptobject = {
				accept_terms,
				email_copy
			}

			let registerresponse = await api.registerterms(acceptobject);

			if(registerresponse.success === false)
			{
				setErrormessage(" Error: There was a problem with registration. ");
				return;
			}

			navigate("/register/verification");
		}
		else
		{
			setErrormessage(" Error: Please fill in the highlighted required fields. ");
		}

	}

	async function getTermsAndConditions()
	{
		let termsandconditions = await generalapi.branding();

		if(termsandconditions.success)
		{
			setTermsAndConditions({__html : termsandconditions.branding.company_terms_conditions});
		}
	
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		( async () => {
			await getTermsAndConditions();
		})();

		if(window.clarity)
		{
			window.clarity('set','userid',auth?.user?.userid);
		}

	},[]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (

		<div className="login-position container">
			<Stack gap={2}>
				<Row className="justify-content-center login-portal">
					<Col xs="auto" style={{height:'90px'}}>
						{process.env.REACT_APP_LOCALDEBUG ? <img src={Logo} alt="ShieldHub Logo" className="branding"/> : <img src={branding.Company.branding_logo_path} alt="ShieldHub Logo" className="branding"/>}
						<img src="/images/shieldhub-logo-dark.svg" alt="ShieldHub Logo" className="print-branding"/>
					</Col>
				</Row>
				<Row className="justify-content-center login-portal">
					<Col xs={12} sm={10} md={8} lg={6}>
						<Form onSubmit={handleAcceptTerms} autoComplete="off" ref={formRef} className="login-container">
							<Col xs={12}>
								<h2 style={{display: "block"}} className="print-hide">Terms &amp; Conditions</h2>
								<div className="tc-container" dangerouslySetInnerHTML={TermsAndConditions}></div>
							</Col>

							<Col>
								<Form.Check id="accept_terms" className="print-hide">
									<Form.Check.Input type="checkbox" name="accept_terms" value="Y" isInvalid={errorfields.accept_terms}/>
									<Form.Check.Label style={{fontSize:"14px"}}>I understand and accept these terms of service</Form.Check.Label>
								</Form.Check>
							</Col>
							<Col>
								<Form.Check id="email_copy" className="print-hide print-terms">
									<Form.Check.Label style={{fontSize:"14px"}}><a href="#" onClick={window.print}><Printer style={{marginRight:"5px",marginTop:"-3px"}}/>Print Version</a></Form.Check.Label>
								</Form.Check>
							</Col>
							<Alert variant="danger" show={errorbanner} style={{clear:"both"}}> <ExclamationTriangleFill size={20} />{errormessage}</Alert>
							<div style={{clear:"both"}}>&nbsp;</div>	
							<div className="print-hide" style={{float:"left",marginTop:"20px"}}>
								<Button variant="link" className="cancel-button">Cancel</Button>
							</div>
							<div className="print-hide" style={{float:"right",marginTop:"20px"}}>
								<Button className="btn-primary continue-button" type="submit">Continue <ArrowRightShort size={16} style={{marginTop:"-2px"}} /></Button>
							</div>
							<div style={{clear:"both"}}>&nbsp;</div>	
						</Form>
					</Col>
				</Row>
			</Stack>
		</div>
	)

}

export default RegisterTandC;