import * as React from "react";
import api from '../../../io/profile';
import { Row , Col, Button, Container } from "react-bootstrap";
import { CartPlusFill } from "react-bootstrap-icons";
import ClearStarLogo from "../../../assets/cra_logos/clearstar.png"
import { useBranding } from "../../Branding";
import { useNavigate, useSearchParams } from "react-router-dom";

function PackageListItem( { pkg } )
{
	const branding = useBranding();

	const navigate = useNavigate();

	return (
		<Container key={pkg.id} onClick={() => {
			if(pkg.workflowstep && pkg.workflowstep !== '')
			{
				navigate(pkg.workflowstep);
			}
			else
			{
				navigate(`/user/purchase/purchasealerts#pkgid=${pkg.id}`);
			}
		}} className="mt-3 p-0 shadow-sm" style={{backgroundColor:'#FFFFFF',minHeight:'100px',width:'475px',cursor:'pointer'}} >
			<Row className="pe-2 ps-2">
				<Col xs={8} className="pt-2 pb-2">
					<img src={ClearStarLogo} alt="ClearStar CRA" className="mb-1 mt-1" style={{height:'30px'}}/><br />
					{pkg.package_descrip}
				</Col>
				<Col xs={2} className="text-center"><div style={{fontSize:'20px',fontWeight:'900',height:'100%',paddingTop:'55%'}}>${pkg.base_price}</div></Col>
				<Col xs={2} className="pe-0">
					<div className="text-center" style={{backgroundColor:branding.PrimaryButtonBackground,height:'100%',paddingTop:'50%',marginLeft:'19px',width:'50px'}}><CartPlusFill size={20} style={{color:'#FFFFFF'}}/></div>
				</Col>
			</Row>
		</Container>
	);

}


function SelectPackage()
{

	const [ packagelist , setPackageList ] = React.useState([]);
	const [ searchparam ] = useSearchParams();

	async function getCRAPackages()
	{
		let response = await api.listpackages();
		if(response.success)
		{
			setPackageList(response.packages);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setPackageList([
				{
					id : '10',
					crapackageid : 'SLRT_16412',
					base_price : 50,
					package_descrip : 'Non New York State 1-Year Package with Continuous Monitoring',
					selection_criteria : null,
					exclusion_criteria : 'state!=NY'
				},
				{
					id : '11',
					crapackageid : 'SLRT_16422',
					base_price : 90,
					package_descrip : 'Non New York State 2-Year Package with Continuous Monitoring',
					selection_criteria : null,
					exclusion_criteria : 'state!=NY'
				},
				{
					id : '12',
					crapackageid : 'SLRT_11811',
					base_price : 145,
					package_descrip : 'New York State Only 1-Year Package with Continuous Monitoring',
					selection_criteria : 'state=NY',
					exclusion_criteria : null
				}
			])
		}
	}

	React.useEffect(() => {

		( async () => {
			await getCRAPackages();
		})();

	},[]);

	return(
		<>
			{ searchparam.get('pkgfilter') === null && <Container className="p-1">
				<Row>
					<Col xs={12} md={7}>
						<h5 style={{fontSize:'18px',lineHeight:'120%'}}>Select Package</h5>
						<p style={{fontSize:'13px',lineHeight:'125%'}}>Select a national package based on past residency. If you&apos;re uncertain of which package to chose, you can use our package selection tool.</p>
					</Col>
					<Col xs={{offset:7,span:5}} md={{offset:0,span:5}} className="text-end align-self-center">
						<Button href='/user/purchase/helpmechoose'>Help Me Choose</Button>
					</Col>
				</Row>
			</Container>}

			{ ( searchparam.get('pkgfilter') === 'US' || searchparam.get('pkgfilter') === null ) && <Container className="p-1 mt-4">
				<Row>
					<h5 style={{fontSize:'18px',lineHeight:'120%'}}>ShieldScore&trade; National Packages</h5>
					<p style={{fontSize:'13px',lineHeight:'125%'}}>Select a multi-county background check from the packages below.  New York residents are excluded.</p>
				</Row>
				<Row className="ps-2 ps-sm-0 pe-4 pe-sm-0">
					{packagelist.filter( (pkg) => {
						return (pkg.selection_criteria === null && pkg.exclusion_criteria === 'state!=NY');
					}).map( (pkg) => {
						return (
							<PackageListItem key={pkg.id} pkg={pkg} />
						);
					})}
				</Row>
			</Container>}
			
			{ ( searchparam.get('pkgfilter') === 'NY' || searchparam.get('pkgfilter') === null ) && <Container gap={1} className="p-1 mt-4">
				<Row>
					<h5 style={{fontSize:'18px',lineHeight:'120%'}}>ShieldScore&trade; New York Packages</h5>
					<p style={{fontSize:'13px',lineHeight:'125%'}}>Select a New York package if you have resided in New York over the last 10-years. Fees include include required jurisdictional and processing fees.</p>
				</Row>
				<Row className="ps-2 ps-sm-0 pe-4 pe-sm-0">
					{packagelist.filter( (pkg) => {
						return (pkg.selection_criteria === 'state=NY' && pkg.exclusion_criteria === null);
					}).map( (pkg) => {
						return (
							<PackageListItem key={pkg.id} pkg={pkg} />
						);
					})}
				</Row>
			</Container>}
		</>
	);
}
export default SelectPackage;