import * as React from "react";
import { Stack , Row , Col , Form , Button , Alert } from "react-bootstrap";
import Logo from '../assets/shieldhub-logo.png';
import { useLocation, useNavigate } from "react-router-dom";
import api from "../io/login";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import { useAuth } from "../components/Auth";
import { useBranding } from "../components/Branding";
import { useCookies } from "react-cookie";


function TwoStepVerify()
{
	const auth = useAuth();
	const [ verificationcode , setVerificationCode ] = React.useState('');
	const [ submitdisable , setSubmitdisable ] = React.useState(true);
	const [ errorbanner, setErrorbanner ] = React.useState(false);
	const [ errormessage , setErrormessage ] = React.useState(false);
	const navigate = useNavigate();
	const { search } = useLocation();
	const [ cookies ] = useCookies(['sh_link_redirect_after_login']);
	const branding = useBranding();

	const querystring = new URLSearchParams(search, [search]);	

	const CodeFieldStyle = {
		paddingLeft:"10px",
		paddingRight:"10px",
		width:"41px",
		textTransform: "uppercase",
		textAlign:"center"
	};

	let CodeFields = {};

	CodeFields.one = React.createRef(null);
	CodeFields.two = React.createRef(null);
	CodeFields.three = React.createRef(null);
	CodeFields.four = React.createRef(null);
	CodeFields.five = React.createRef(null);
	CodeFields.six = React.createRef(null);

	let RememberBrowserToggle = React.createRef(null);

	function handleControlCodes(event)
	{
		if(event.keyCode === 8)
		{
			let code = verificationcode;

			code = code.substring(0,code.length-1);

			setVerificationCode(code);

			if(code.length === 0)
			{
				CodeFields.one.current.focus();
			}
			else if(code.length === 1)
			{
				CodeFields.two.current.focus();
			}
			else if(code.length === 2)
			{
				CodeFields.three.current.focus();
			}
			else if(code.length === 3)
			{
				CodeFields.four.current.focus();
			}
			else if(code.length === 4)
			{
				CodeFields.five.current.focus();
			}
			else if(code.length === 5)
			{
				CodeFields.six.current.focus();
			}

		}
	}

	async function handleAutoFillEvent( event )
	{
		let autofill_css_selector = false;
		try {

			document.getElementsByTagName('body')[0].matches(':autofill');
			autofill_css_selector = true;
		}
		catch(e)
		{
			autofill_css_selector = false;
		}

		let autofillonetimevalue;
		if(autofill_css_selector === true && event['_reactName'] === 'onChange' && ( event.target.matches(':autofill') || event.target.matches(':-webkit-autofill') ) )
		{
			autofillonetimevalue = event.target.value;
		}
		else if(autofill_css_selector === true && event['_reactName'] === 'onInput' && event.nativeEvent.inputType === 'insertReplacementText' && ( event.target.matches(':autofill') || event.target.matches(':-webkit-autofill') ) )
		{
			autofillonetimevalue = event.target.value;
		}
		else if(event['_reactName'] === 'onChange' && event.nativeEvent.inputType === undefined && event.keyCode === undefined)
		{
			autofillonetimevalue = event.target.value;
		}

		if(autofillonetimevalue && autofillonetimevalue.match(/^[0-9A-Z]{1,6}/))
		{
			setVerificationCode(autofillonetimevalue);

			if(autofillonetimevalue.substring(1,2) !== '')
			{
				CodeFields.two.current.value = autofillonetimevalue.substring(1,2);
			}
			if(autofillonetimevalue.substring(2,3) !== '')
			{
				CodeFields.three.current.value = autofillonetimevalue.substring(2,3);
			}
			if(autofillonetimevalue.substring(3,4) !== '')
			{
				CodeFields.four.current.value = autofillonetimevalue.substring(3,4);
			}
			if(autofillonetimevalue.substring(4,5) !== '')
			{
				CodeFields.five.current.value = autofillonetimevalue.substring(4,5);
			}
			if(autofillonetimevalue.substring(5,6) !== '')
			{
				CodeFields.six.current.value = autofillonetimevalue.substring(5,6);
			}

		}

	}

	async function handleCodePasteEvent(event)
	{
		var clipboardData = event.clipboardData || event.originalEvent.clipboardData || window.clipboardData;
		var pastedData = clipboardData.getData('text/plain');

		pastedData = pastedData.toUpperCase();

		if(pastedData.match(/^[0-9A-Z]{1,6}/))
		{
			setVerificationCode(pastedData);

			if(pastedData.substring(1,2) !== '')
			{
				CodeFields.two.current.value = pastedData.substring(1,2);
			}
			if(pastedData.substring(2,3) !== '')
			{
				CodeFields.three.current.value = pastedData.substring(2,3);
			}
			if(pastedData.substring(3,4) !== '')
			{
				CodeFields.four.current.value = pastedData.substring(3,4);
			}
			if(pastedData.substring(4,5) !== '')
			{
				CodeFields.five.current.value = pastedData.substring(4,5);
			}
			if(pastedData.substring(5,6) !== '')
			{
				CodeFields.six.current.value = pastedData.substring(5,6);
			}

		}
	}	

	function handleVerficationCode(event)
	{
		if(verificationcode.length === 0 && event?.currentTarget?.value?.length > 0)
		{
			setVerificationCode(event.currentTarget.value.toUpperCase());
			CodeFields.two.current.focus();
		}
		else
		{
			let code = verificationcode;

			code = CodeFields.one.current.value;
			if(CodeFields.two.current.value.length === 1)
			{
				code += CodeFields.two.current.value;
			}
			if(CodeFields.three.current.value.length === 1)
			{
				code += CodeFields.three.current.value;
			}
			if(CodeFields.four.current.value.length === 1)
			{
				code += CodeFields.four.current.value;
			}
			if(CodeFields.five.current.value.length === 1)
			{
				code += CodeFields.five.current.value;
			}
			if(CodeFields.six.current.value.length === 1)
			{
				code += CodeFields.six.current.value;
			}

			if(code.length < 6)
			{
				setSubmitdisable(true);
			}

			if(code.length === 2)
			{
				CodeFields.three.current.focus();
			}
			else if(code.length === 3)
			{
				CodeFields.four.current.focus();
			}
			else if(code.length === 4)
			{
				CodeFields.five.current.focus();
			}
			else if(code.length === 5)
			{
				CodeFields.six.current.focus();
			}
			else if(code.length === 6)
			{
				setSubmitdisable(false);
			}

			code = code.toUpperCase();

			setVerificationCode(code);
		}
	}

	async function handleSubmitCode(event){

		if(event)
		{
			event.preventDefault();
		}

		var code;

		if(querystring.get('code') !== null && querystring.get('code') !== undefined)
		{
			code = querystring.get('code');
		}
		else if(verificationcode.length !== 6)
		{
			setErrorbanner(true);
			setErrormessage("Error : Verification code is 6 charaters ");
			return;
		}
		else
		{
			code = verificationcode;
		}

		let rememberbrowser = !!(RememberBrowserToggle.current.checked);

		let response = await api.twostepverification(code,rememberbrowser);

		if(response.success && response.role === "ADMIN")
		{
			navigate("/admin", { replace: true });
		}
		else if(response.success)
		{
			if(cookies?.sh_link_redirect_after_login && cookies?.sh_link_redirect_after_login.length > 3)
			{
				window.location = `/link/${cookies.sh_link_redirect_after_login}`;
			}
			else
			{
				navigate("/user", { replace: true });
			}
		}
		else
		{
			setErrorbanner(true);
			setErrormessage('Error: the provided code does not match');
		}

	}

	async function SendVerificationCode()
	{
		if(auth.user.selected_two_step_method !== 'OTPAPP')
		{
			let response = await api.sendverificationcode();

			if(!response.success)
			{
				console.warn(response);
			}
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		if(querystring.get('code') !== null && querystring.get('code') !== undefined)
		{
			handleSubmitCode();
			return;
		}
		else
		{
			( async () => {
				await SendVerificationCode();
			})();

			CodeFields.one.current.focus();
		}

	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */	

	return (
		<>
			<div className="login-position container">
				<Stack gap={2}>
					<Row className="justify-content-center login-portal">
						<Col xs="auto" style={{height:'90px'}}>
							{process.env.REACT_APP_LOCALDEBUG ? <img src={Logo} alt="ShieldHub Logo" className="branding"/> : <img src={branding.Company.branding_logo_path} alt="ShieldHub Logo" className="branding"/>}
						</Col>
					</Row>
					<Row className="justify-content-center login-portal">
						<Col xs={12} sm={10} md={8} lg={6}>
							<Form onSubmit={handleSubmitCode} autoComplete="off" className="login-container">
								<h2>Additional Verification Required</h2>
								{auth.user.selected_two_step_method === 'OTPAPP' && <h6>Provide code generated by your authenticator app.</h6>}
								{auth.user.selected_two_step_method === 'SMS' && <h6>A verification code was sent to your mobile phone.</h6>}
								{ ( !(auth?.user?.selected_two_step_method) || auth.user.selected_two_step_method === 'EMAIL' ) && <h6>A verification code was sent to your email.</h6>}
								<Stack direction="horizontal" gap={1} style={{paddingTop:"50px",paddingBottom:"50px"}} className="justify-content-center">
									<div>
										<Form.Control 
											type="text"
											size="lg"
											maxLength={1}
											htmlSize={1}
											style={CodeFieldStyle}
											ref={CodeFields.one}
											onChange={handleVerficationCode}
											onKeyDown={handleControlCodes}
											onPaste={handleCodePasteEvent}
											onInput={handleAutoFillEvent}
											autoComplete="one-time-code"
										/>
									</div>
									<div>
										<Form.Control
											type="text"
											size="lg"
											maxLength={1}
											htmlSize={1}
											style={CodeFieldStyle}
											ref={CodeFields.two}
											onKeyDown={handleControlCodes}
											onChange={handleVerficationCode}
										/>
									</div>
									<div>
										<Form.Control
											type="text"
											size="lg"
											maxLength={1}
											htmlSize={1}
											ref={CodeFields.three}
											style={CodeFieldStyle}
											onKeyDown={handleControlCodes}
											onChange={handleVerficationCode}
										/>
									</div>
									<div>
										<Form.Control
											type="text"
											size="lg"
											maxLength={1}
											htmlSize={1}
											ref={CodeFields.four}
											style={CodeFieldStyle}
											onKeyDown={handleControlCodes}
											onChange={handleVerficationCode}
										/>
									</div>
									<div>
										<Form.Control
											type="text"
											size="lg"
											maxLength={1}
											htmlSize={1}
											ref={CodeFields.five}
											style={CodeFieldStyle}
											onKeyDown={handleControlCodes}
											onChange={handleVerficationCode}
										/>
									</div>
									<div>
										<Form.Control
											type="text"
											size="lg"
											maxLength={1}
											htmlSize={1}
											ref={CodeFields.six}
											style={CodeFieldStyle}
											onKeyDown={handleControlCodes}
											onChange={handleVerficationCode}
										/>
									</div>
								</Stack>
								<div className="justify-content-center">
									<Form.Check
										id="rememberbrowser"
										type="checkbox"
										ref={RememberBrowserToggle}
										label="Remember this browser for 30 days"
									/>
								</div>
								<Row>
									<Col>
										<div style={{textAlign:"center",marginTop:"20px"}}>
											<Button type="submit"  disabled={submitdisable} className="continue-button">Verify</Button>
										</div>
									</Col>
								</Row>
								<Alert className="m-1" variant="danger" show={errorbanner}> <ExclamationTriangleFill size={20} />{errormessage}</Alert>
							</Form>
						</Col>
					</Row>

				</Stack>
			</div>
		</>
	);

}
export default TwoStepVerify;