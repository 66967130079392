import * as React from "react";
import { Button, Modal, Spinner, Row , Col , Image } from "react-bootstrap";
import { useHref, useLocation, useNavigate } from "react-router-dom";
import profileapi from "../../../io/profile";
import CRAPackages from "../CRAPackages";
import { useRefreshEmitter } from "../../RefreshEmitter";
import MaintenanceSign from '../../../assets/maintenancesign.jpg'

function BGCheckApplicationModal() {

	const { hash } = useLocation();
	const href = useHref();
	const navigate = useNavigate();
	const emitter = useRefreshEmitter();

	const [ backgroundmodalshow , setBGmodalshow ] = React.useState(false);
	const [ registerlink , setRegisterlink ] = React.useState(false);
	const [ wait , setWait ] = React.useState(false);
	const [ selectedpackage , setSelectedPackage ] = React.useState(false);
	const [ packagetitle , setPackagetitle ] = React.useState(false);

	const clearstar_maintenance = false;

	async function GetRegisterLink( packageid )
	{
		let response = await profileapi.startapplication( packageid );

		if(response.success)
		{
			setPackagetitle(response.package_descrip);
			setRegisterlink(response.registrationlink);
			return true;
		}
		return false;
	}

	async function Startapplication( applicationid )
	{
		let response = await profileapi.startapplication( null , applicationid );

		if(response.success)
		{
			setPackagetitle(response.package_descrip);
			setRegisterlink(response.registrationlink);
			return true;
		}
		return false;
	}


	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		if(hash.match(/#buybackgroundcheck/) && registerlink === false)
		{
			setBGmodalshow(true);

			if(hash.match(/#buybackgroundcheck=.+/))
			{
				setWait(true);
				let hashvalues = hash.match(/#buybackgroundcheck=(.+)/);

				( async () => {
					await GetRegisterLink(hashvalues[1]);

					setWait(false);

				})();

				setSelectedPackage(hashvalues[1]);
			}
		}
		else if(hash.match(/#startapplication=.+/) && registerlink === false)
		{
			setBGmodalshow(true);

			if(hash.match(/#startapplication=.+/))
			{
				setWait(true);
				let hashvalues = hash.match(/#startapplication=(.+)/);

				( async () => {
					await Startapplication(hashvalues[1]);

					setWait(false);

				})();

				setSelectedPackage(hashvalues[1]);
			}
		}
		else
		{
			setRegisterlink(false);
			setSelectedPackage(false);
			setPackagetitle(false);
			emitter.Emit('newapplicationcheck');
		}
	},[ hash ]);
	/* eslint-enable react-hooks/exhaustive-deps */
	
	return (
		<Modal show={backgroundmodalshow} dialogClassName="modal-95w" scrollable>
			{wait && <Modal.Header className="p-1" style={{border:"0px"}}>
				<Spinner />{packagetitle}
			</Modal.Header> }
			{!wait && <Modal.Header className="p-1" style={{border:"0px"}}>
				<Row style={{width:'100%'}}>
					<Col sm={11} style={{textAlign:'center'}}>{packagetitle}</Col>
					<Col sm={1}>
						<Button size="sm" onClick={() => {
							setBGmodalshow(false);
							navigate(href);
						}}>Close</Button>
					</Col>
				</Row>
			</Modal.Header>}
			<Modal.Body style={{minHeight:"99%",paddingBottom:'50px'}}>
				{clearstar_maintenance && <div className="text-center">
					<h2>The background check store is down temporarily for maintenance.</h2>
					<Image src={MaintenanceSign} />
				</div>}
				{!clearstar_maintenance && selectedpackage === false && <CRAPackages />}
				{!clearstar_maintenance && selectedpackage !== true && <iframe title="background_check_application" src={registerlink ? registerlink : undefined } height="100%" width="100%" allow="autoplay; camera; microphone; geolocation; accelerometer;"></iframe>}
			</Modal.Body>
		</Modal>
	)

}

export default BGCheckApplicationModal;