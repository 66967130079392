import axios from "axios";

const base_api_endpoint = process.env.REACT_APP_API_ENDPOINT;

const apiclient = axios.create({
	baseURL: base_api_endpoint,
	withCredentials : true,
});

const get = async (url, params) => {

	let apiresponse = {
		success : false
	};

	let querystring = '';
	if(params && Object.keys(params).length > 0)
	{
		querystring = new URLSearchParams(params).toString();
		querystring = `?${querystring}`;
	}	

	try {
		let response = await apiclient.get( `${url}${querystring}` );
		if(response.data.success)
		{
			apiresponse = response.data;
		}
	}
	catch (e){
		console.log(e);
	}
	return apiresponse;
};
const post = async (url, params = {} ) => {

	let apiresponse = {
		success : false
	};

	try {
		let response = await apiclient.post( url , params );
		if(response.data.success)
		{
			apiresponse = response.data;
		}
	}
	catch (e){
		console.log(e);
	}
	return apiresponse;
};

export { 
	get,
	post,
};