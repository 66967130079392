import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Fade, Card, Col, Row, Alert} from "react-bootstrap";
import { Diagram3Fill, PeopleFill } from "react-bootstrap-icons";
import { useBranding } from '../Branding';
import { useRefreshEmitter } from "../RefreshEmitter";
import api from '../../io/network';
import userapi from '../../io/user.js';
import { format as dateFormat, parseJSON as dateParseJSON , isFuture } from 'date-fns';
import { useAuth } from "../Auth";
import { useCookies } from "react-cookie";


function InvitationTable() {

	const branding = useBranding();
	const emitter = useRefreshEmitter();
	const auth = useAuth();
	const [ cookies ] = useCookies(['sh_linkid']);
	const { hash } = useLocation();

	const labelstyle = {
		color:branding.PrimaryColor,
		textTransform: "uppercase",
		fontSize:"14px"
	};

	let params = useParams();

	const [ invitationlist , setInvitationlist ] = React.useState([]);
	const [ removingInviteId , setRemovingInviteId ] = React.useState(0);
	const [ teaminviteaccepted , setTeaminviteaccepted ] = React.useState(false);
	const [ teaminvitename , setTeaminvitename ] = React.useState(false);

	React.useEffect( () => {

		( async () => {
			await RefreshInviteList();
		})();

	},[]);

	async function RefreshInviteList(){

		var response = await api.listinvitations();
		if(response.success)
		{
			setInvitationlist(response.invitations);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setInvitationlist([
				{
					inviteid : 1000,
					firstname : 'John',
					lastname : 'Doe',
					businessname : 'Business Name',
					invite_dts : '2024-05-01T11:00:00',
					network_requires_pdf : 'NO'
				}
			]);
		}
		
		setRemovingInviteId(0);
	}

	async function IgnoreInvite( inviteid ){

		setRemovingInviteId(inviteid);

		await api.ignoreinvite(inviteid);

		await RefreshInviteList();
	}

	async function AcceptInvite( inviteid ){

		setRemovingInviteId(inviteid);

		await api.acceptinvite(inviteid);
		
		await RefreshInviteList();

		emitter.Emit('networkupdate');
	}

	async function GetLink( linkid )
	{
		let linkresponse = await userapi.getlink_w_auth( linkid );
		if(linkresponse.success && linkresponse.details.linktype === 'teaminvite' && linkresponse.details.invite_code)
		{
			let response = await api.acceptteaminvite(linkresponse.details.invite_code);
			if(response.success)
			{
				setTeaminviteaccepted(true);
				setTeaminvitename(response.company_name);
			}
		}
		else
		{
			console.warn(linkresponse);
		}
	}	

	React.useEffect( () =>{

		if(hash === '#teaminviteaccepted')
		{
			setTeaminviteaccepted(true);
		}
		else
		{
			setTeaminviteaccepted(false);
		}
	},[hash]);

	React.useEffect( () => {

		// Watch for events to trigger refresh methods
		if( ['newinvite','globalrefresh'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await RefreshInviteList();
			})();
		}
	},[emitter.eventname,emitter.data]);

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		if(cookies.sh_linkid !== undefined && cookies.sh_linkid !== null && cookies.sh_linkid !== '')
		{
			( async () => {
				await GetLink( cookies.sh_linkid );
			})();
		}

	},[cookies.sh_linkid]);
	/* eslint-enablereact-hooks/exhaustive-deps */

	return (
		
		<Card className="p-4 invitations">
			<Card.Title>
				<Col sm={12}>
					<h4>My Invitations</h4>
					<p className="small">Below are a list of active invitations for you to join a trusted network. By accepting an invitation, you are consenting to ShieldPulse<sup>TM</sup> monitoring. You can change your sharing preferences at any time using the <a href="/user/network">manage network</a> tools.</p>
				</Col>
			</Card.Title>
			<Card.Body className="cra-card">	
				{invitationlist.map( (invite) => {
						
					return (
						<Fade in={!(invite.inviteid === removingInviteId)} key={invite.inviteid}>
							<Row className="bc-card">
								<Col xs={12} lg={3}><label style={labelstyle}>Company or Name</label> {invite.businessname && invite.businessname.length > 0 ? <p>{invite.businessname}</p> : <p>{invite.firstname} {invite.lastname} </p>}</Col>
								<Col xs={6} sm={6} md={6} lg={2}><label style={labelstyle}>Invitation Date</label> {dateFormat(dateParseJSON(invite.invite_dts),'M/d/y')}</Col>
								<Col xs={5} sm={6} md={6} lg={2}>
									<Button className="invitation-btn"  variant="outline-secondary" style={{color:"#B1B1B1",textDecorationLine:"none"}} onClick={ async () => {
										await IgnoreInvite(invite.inviteid);
									}}>Ignore</Button>
								</Col>
								{auth?.user?.certification_number?.length > 0 && auth?.user?.certification_score?.length >= 4 && auth?.user?.certification_score?.length <= 5 && auth?.user?.certification_expiration && isFuture(dateParseJSON(`${auth?.user?.certification_expiration}T00:00:00`)) ? <>
									<Col xs={7} sm={6} md={6} lg={3}>
										<Button className="invitation-btn" variant="outline-primary" onClick={ async () => {
											await AcceptInvite(invite.inviteid);
										}}>Share ShieldScore {invite.network_requires_pdf === 'YES' ? <>&amp; Report</> : null } </Button>
									</Col>
								</> : 
									<Row>
										<Alert xs={7} sm={6} md={6} lg={3} variant="danger" className="mt-3 text-center">You must complete a background check before sharing your ShieldScore</Alert>
									</Row>
								}
							</Row>
						</Fade>
					)
				} 
				)}
			</Card.Body>
			<Card.Body style={{}}>
				<Alert variant="success" show={teaminviteaccepted} onClose={() => {
					window.location.hash = '';
				}} dismissible><PeopleFill size={20}/> Success: You have accepted the invitation from {teaminvitename} Team.</Alert>
			</Card.Body>

			{/* <Card.Body>
					<div className="table-responsive">
						<Table borderless responsive className="invitation-table">
							<thead>
								<tr>
									<th style={tableheaderstyle}>Name</th>
									<th style={tableheaderstyle}>Company</th>
									<th className="d-none d-md-table-cell" style={tableheaderstyle}>Request Date</th>
									<th className="d-none d-md-table-cell" style={tableheaderstyle}>Score Requirements</th>
									<th className="d-none d-sm-table-cell" style={tableheaderstyle}>Score</th>
									<th style={tableheaderstyle}></th>
								</tr>
							</thead>
							<tbody>
								{invitationlist.map( (invite) => {

									return (
										<Fade in={!(invite.inviteid === removingInviteId)} key={invite.inviteid}>
											<tr>
												<td >{invite.firstname} {invite.lastname}</td>
												<td>{invite.businessname}</td>
												<td>{dateFormat(dateParseJSON(invite.invite_dts),'M/d/y')}</td>
												<td></td>
												<td></td>
												<td>
													<Button variant="link" ssize="sm" style={{color:"#B1B1B1",textDecorationLine:"none"}} onClick={ async () => {
														await IgnoreInvite(invite.inviteid);
													}}>Ignore</Button>
													<Button ssize="sm" variant="outline-primary" onClick={ async () => {
														await AcceptInvite(invite.inviteid);
													}}>Share ShieldScore</Button>
												</td>
											</tr>
										</Fade>
									);
								})}
							</tbody>
						</Table>
					</div>
				</Card.Body> */}
			<Card.Link>
				{params.panel !== 'network' && 
					<Button variant="link" style={{color:branding.PrimaryButtonBackground,float:"right",textDecorationLine:"none"}} href="/user/network" ><Diagram3Fill /> Manage My Network</Button>
				}
			</Card.Link>
		</Card>

	);
}

export default InvitationTable;