import * as React from "react";
import { Button, Card, Col , Row, Nav , Tab, Form, Modal, Dropdown, InputGroup, ProgressBar , Popover , Image , Spinner, FormControl } from "react-bootstrap";
import { CheckCircleFill, PersonFillAdd, TrashFill, BoxArrowUpRight, Diagram3Fill, ShareFill, Search, XCircleFill, GearFill, Download, SlashCircleFill, EnvelopeFill , ShieldFillCheck, PlayCircleFill, EnvelopePlusFill, ExclamationCircleFill, EnvelopeOpenFill, ArrowRepeat, QuestionCircleFill, Copy , Link45deg , Clipboard } from "react-bootstrap-icons";
import { useBranding } from "../Branding";
import ICScore from "../ICScore";
import api from "../../io/network";
import profileapi from '../../io/profile';
import { format as dateFormat , parseJSON as dateParse , isPast } from "date-fns";
import { useRefreshEmitter } from "../RefreshEmitter";
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from "@ag-grid-community/csv-export";
import ShareMyShieldScore from "./ShareShieldScore";
import { useAuth } from "../Auth";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import FileDownloadModal from '../FileDownloadModal';

ModuleRegistry.register(CsvExportModule);


function StatusHistoryPopover ( props ){

	if( !props?.data?.historyrecords || props.data.historyrecords.length == 0 ){
		return null;
	} 

	return (
		<Popover style={{minWidth:'420px',position:'relative'}}>
			<Popover.Body style={{minWidth:'420px'}}>
				<b>STATUS HISTORY</b>
				{ props.data.historyrecords && props.data.historyrecords.length > 0 && props.data.historyrecords.map( (item) => {
					return (
						<Row key={item.id} className="p-0 m-0">
							<Col xs={1}>
								{ item.status === 'complete' && <ShieldFillCheck color="#93a01b" /> }
								{ item.status === 'inprogress' && <PlayCircleFill color='#039BE5' />}
								{ item.status === 'pending' && <PlayCircleFill color='#DEE2E6' />}
							</Col>
							<Col>{item.label}</Col>
							<Col xs={4}>{item.date_formatted}</Col>
						</Row>
					);
				})
				}
			</Popover.Body>
		</Popover>
	);
}


function MyNetwork(){

	const branding = useBranding();
	const emitter = useRefreshEmitter();
	const auth = useAuth();

	const rows_per_page = 10;

	const [ sharedconnections , setSharedConnections ] = React.useState([]);
	const [ connectionssharedwithme , setConnSharedWithMe ] = React.useState([]);
	const [ profile , setProfile ] = React.useState({});
	const [ inviteResent , setInviteResent ] = React.useState(false);

	const [ deleteConnection , setDeleteConnection ] = React.useState({});

	const [ downloadModal , setDownloadModal ] = React.useState(false);
	const [ downloadlink , setDownloadlink ] = React.useState(false);
	const [ downloaderror , setDownloaderror ] = React.useState(false);
	const [ downloadname , setDownloadname ] = React.useState(false);

	const [ RequestRenewalModal , setRequestRenewalModal ] = React.useState(false);
	const [ RequestRenewalStatus , setRequestRenewalStatus ] = React.useState(false);

	const [ InviteLinkModal , setInviteLinkModal ] = React.useState(false);
	const [ InviteLinkCopied , setInviteLinkCopied ] = React.useState(false);

	const sharedwithmegridref = React.createRef(false);
	const csvexportdefaults = {
		columnKeys : [
			'firstname',
			'lastname',
			'businessname',
			'emailaddress',
			'share_dts_formatted',
			'abcnumber',
			'currenticstatus',
			'expiration_dts_formatted'
		],
		fileName : 'myconnections_'+(Math.floor(Date.now()/1000))
	};
	const filterdefaults = {
		buttons : [ 'reset' ]
	};
	const [ shwme_qf_text , setshwme_qf_text ] = React.useState('');
	const shwme_qf_ref = React.createRef(false);


	/*
	ShieldScore header component to figure out how to make work
	headerComponent : () => {
			return 	<>ShieldScore&nbsp;<a href="https://support.shieldhub.com/hc/en-us/articles/21734150168859-What-is-a-ShieldScore-" target="_blank"><QuestionCircleFill/></a></>;
		}
	*/

	const sharedwithmeColDef = [
		{ field : 'fullname' , headerName : 'Name' , filter : true , filterParams : filterdefaults ,cellStyle : {lineHeight : '15px', paddingTop :'12px'} , cellRenderer : (props) => {

			if(props.data.businessname && props.data.businessname.length > 0)
			{
				return <>{props.data.firstname} {props.data.lastname}<br /><span className="business_name">{props.data.businessname}</span></>;
			}
			else
			{
				return <>{props.data.firstname} {props.data.lastname}</>;
			}
		}},
		{ field : 'firstname', headerName : 'First Name' , hide : true },
		{ field : 'lastname', headerName : 'Last Name' , hide : true },
		{ field : 'emailaddress' ,  initialWidth : 180, headerName : 'Email' , filter : true , filterParams : filterdefaults },
		{ field : 'share_dts_formatted' , headerName : 'Share Date' , initialWidth : 150 , filter : 'agDateColumnFilter' , filterParams : filterdefaults},
		{ field : 'abcnumber' , headerName : 'ShieldID' , filter : true , filterParams : filterdefaults , initialWidth : 140 , cellStyle : { overflow:'visible' },  },
		{ field : 'currenticstatus' , headerName : 'ShieldScore' , filter : true , filterParams : filterdefaults , initialWidth : 160 ,  cellRenderer : (props) => {
			if(props.data.connectionstatus === 'EXPIRED')
			{
				return <ICScore score={null} />;
			}
			else if(props.data.expiration_date && isPast(dateParse( props.data.expiration_date )) )
			{
				return <ICScore score={null} />;
			}
			else if(props.value)
			{
				return <ICScore score={props.value} />;
			}
		}},
		{ field : 'expiration_dts_formatted' , headerName : 'Expiration' , filter : 'agDateColumnFilter' , initialWidth : 140 },
		{ field : '' , initialWidth : 80 , headerComponent : () => {
			return <EnvelopeFill size={20} />
		} , cellRenderer : (props) => {

			if(props.data.connectionstatus === 'INVITEBOUNCE' || props.data?.emailstatus?.bounces )
			{
				return <ExclamationCircleFill className="link-danger" size={20} />;
			}
			else if(['INVITE','INVITEIGNORE'].indexOf(props.data.connectionstatus) !== -1 && props?.data?.emailstatus?.invite_sent && parseInt(props.data.emailstatus.invite_sent) > 0)
			{
				if(parseInt(props.data.emailstatus.invite_sent) === 1)
				{
					return <><EnvelopePlusFill size={20} className="invite-success" /><span className="invite-success invite-count">{props.data.emailstatus.invite_sent}</span></>;
				}
				else if(parseInt(props.data.emailstatus.invite_sent) === 2)
				{
					return <><EnvelopePlusFill size={20} className="invite-warning" /> <span className="invite-warning invite-count">{props.data.emailstatus.invite_sent}</span></>;
				}
				else if(parseInt(props.data.emailstatus.invite_sent) === 3)
				{
					return <><EnvelopePlusFill size={20} className="invite-danger" /><span className="invite-danger invite-count">{props.data.emailstatus.invite_sent}</span></>;
				}
				else
				{
					return <><EnvelopePlusFill size={20} /> <span className="invite-success invite-count">{props.data.emailstatus.invite_sent}</span></>;
				}
			}
			else if(['APPSTARTED','PENDINGINVITEACCEPT'].indexOf(props.data.connectionstatus) !== -1)
			{
				return <><EnvelopeOpenFill size={20} className="invite-success" /></>;
			}
			else if(['EXPIRED','INRENEWALWINDOW'].indexOf(props.data.connectionstatus) !== -1 && props?.data?.emailstatus?.renewal_count !== undefined && props?.data?.emailstatus?.renewal_count !== null && parseInt(props.data.emailstatus.renewal_count) >= 0)
			{
				if(parseInt(props.data.emailstatus.renewal_count) === 0)
				{
					return <><ArrowRepeat size={20} className="invite-success" /></>;
				}
				else if(parseInt(props.data.emailstatus.renewal_count) > 0 && parseInt(props.data.emailstatus.renewal_count) <= 2)
				{
					return <><ArrowRepeat size={20} className="invite-success" /> <span className="invite-success invite-count">{props.data.emailstatus.renewal_count}</span></>;
				}
				else if(parseInt(props.data.emailstatus.renewal_count) === 3)
				{
					return <><ArrowRepeat size={20} className="invite-warning " /> <span className="invite-warning invite-count">{props.data.emailstatus.renewal_count}</span> </>;
				}
				else if(parseInt(props.data.emailstatus.renewal_count) > 3)
				{
					return <><ArrowRepeat size={20} className="invite-danger" /> <span className="invite-danger invite-count">{props.data.emailstatus.renewal_count}</span> </>;
				}
			}
			
		}},
		{ field : '' , headerName : 'Status' , initialWidth : 200 , cellStyle : { lineHeight : '23px', paddingTop:'12px' } , cellRenderer : (props) => {

			let progress = 0;
			let variant = 'info';
			let label = '';

			if(props.data.connectionstatus === 'CURRENT')
			{
				progress = 100;
				label = 'ACTIVE';
				variant = 'success';
			}
			else if(props.data.connectionstatus === 'APPSTARTED')
			{
				progress = 15;
				label = 'APPLICATION STARTED';
			}
			else if(props.data.connectionstatus === 'INVITE' || props.data.connectionstatus === 'DISABLED')
			{
				progress = 5;
				label = 'APPLICATION REQUIRED';
			}
			else if(props.data.connectionstatus === 'PENDINGINVITEACCEPT')
			{
				progress = 80;
				label = 'Accept & Consent';
			}
			else if(props.data.connectionstatus === 'SCOREPENDING')
			{
				progress = 85;
				label = 'Score Pending';
			}
			else if(props.data.connectionstatus === 'CHECKINPROGRESS')
			{
				progress = 40;
				label = 'In Progress';
			}
			else if(props.data.connectionstatus === 'EXPIRED')
			{
				progress = 100;
				variant = 'danger';
				label = 'Expired';
			}
			else if(props.data.connectionstatus === 'INVITEBOUNCE')
			{
				progress = 100;
				variant = 'danger';
				label = 'Bounced/Rejected';
			}
			else if(props.data.connectionstatus === 'INVITEIGNORE')
			{
				progress = 0;
				label = 'Ignored';
			}
			else if(props.data.connectionstatus === 'INRENEWALWINDOW')
			{
				progress = 5;
				label = 'Renewal Required';
			}
	
			return (
				<>
					<ProgressBar now={progress} variant={variant} style={{height:'4px'}} />
					<span style={{fontSize:'11px',textTransform: 'Uppercase'}}>{label}</span>
				</>
			);

		} , tooltipField : 'historyrecords' , tooltipComponent : StatusHistoryPopover },
		{ field : '' , headerName : 'Action' , initialWidth : 135 , cellStyle : { overflow:'visible' } , cellRenderer : (props) => {

			return <div style={{textAlign:'center'}}>
				<Dropdown>
					<Dropdown.Toggle className="mb-1" size="sm" variant='outline-primary'><GearFill /> </Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item size="sm" disabled={props.data.connectionstatus !== 'INVITE' && props.data.connectionstatus !== 'INVITEIGNORE'} onClick={() => {CancelInvite(props.data.id);}} ><SlashCircleFill /> Cancel Invite</Dropdown.Item>

						<Dropdown.Item size="sm" disabled={props.data.connectionstatus !== 'INVITE' && props.data.connectionstatus !== 'PENDINGINVITEACCEPT' && props.data.connectionstatus !== 'INVITEIGNORE'} onClick={() => {ResendInvite(props.data.id);}} variant={(inviteResent && inviteResent === props.data.id ? 'success' : null )} >{( inviteResent && inviteResent === props.data.id ? <><CheckCircleFill /> Invite Resent </> : <><ArrowRepeat /> Resend Invite</>)}</Dropdown.Item>

						<Dropdown.Item size="sm" disabled={ !(props.data.connectionstatus === 'CURRENT' && props.data.reportshared === 'YES') } onClick={() => { DownloadReport(props.data.id); }} > <Download /> Download Full Report</Dropdown.Item>
						<Dropdown.Item size="sm" disabled={['INVITE','EXPIRED','INRENEWALWINDOW','INVITEIGNORE'].indexOf(props.data.connectionstatus) === -1} onClick={() => {setInviteLinkModal(true);}} ><Link45deg />Get Invite Link</Dropdown.Item>
						<Dropdown.Item size="sm" disabled={['EXPIRED'].indexOf(props.data.connectionstatus) === -1} onClick={() => {setRequestRenewalModal(props.data.id)}} ><ArrowRepeat/>Request Renewal</Dropdown.Item>
						<Dropdown.Item size="sm" disabled={ ['CURRENT','INRENEWALWINDOW','EXPIRED'].indexOf(props.data.connectionstatus) === -1 }  onClick={() => {setDeleteConnection({connectionid : props.data.id, type : 'sharedwithme'});}}><TrashFill color="#BE2026"/> Remove from Network</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown></div>
		}}
	];

	const sharedwithothersgridref = React.createRef(false);
	const sharedwithothersColDef = [
		{ field : 'fullname' , headerName : 'Name' , filter : true , initialWidth : 250 , filterParams : filterdefaults ,cellRenderer : (props) => {
			if(props.data.businessname && props.data.businessname.length > 0)
			{
				return <>{props.data.businessname}</>;
			}
			else
			{
				return <>{props.data.firstname} {props.data.lastname}</>;
			}
		}},
		{ field : 'firstname', headerName : 'First Name' , hide : true },
		{ field : 'lastname', headerName : 'Last Name' , hide : true },
		{ field : 'share_dts_formatted' , headerName : 'Share Date' , initialWidth : 130 , filter : 'agDateColumnFilter' , filterParams : filterdefaults},
		{ field : '' , headerName : 'Enable/Disable' , initialWidth : 130 , cellRenderer : (props) => {
			return <div style={{textAlign:'center',paddingTop:'10px'}}>
				<Form.Check type="switch" checked={props.data.active === 'YES'} style={{transform:"scale(1.3)"}} onChange={() => {
					ToggleActive(props.data.id);
				}}/>
			</div>
		}},
		{ field : '' , headerName : 'Share Report PDF' , initialWidth : 142 , cellRenderer : (props) => {
			return <div style={{textAlign:'center',paddingTop:'10px'}}>
				<Form.Check type="switch" checked={props.data.reportshared === 'YES'} style={{transform:"scale(1.3)"}} onChange={() => {
					TogglePDFShared(props.data.id);
				}}/>
			</div>
		}},
		{ field : '' , initialWidth : 130, cellRenderer : (props) => {

			if(props.data.active !== 'YES')
			{
				return <Button variant="link" onClick={() => { setDeleteConnection({connectionid:props.data.id,type:'myshares'}) }}><TrashFill size={20} color="#BE2026"/></Button>
			}
		}}
	];

	const sharedwithothersAutosizeStrategy = {
		type: 'fitGridWidth',
	};
	const sharedwithmeAutosizeStrategy = {
		type: 'fitGridWidth',
	};

	// const TooltipLink = ({ id, children, title}) => (
	// 	<OverlayTrigger overlay={<Tooltip id={id} placement="placement">{title}</Tooltip>}>
	// 		<a href="#" className="condhelp">{children}</a>
	// 	</OverlayTrigger>
	// );

	
	async function RefreshProfile()
	{

		let response = await profileapi.getmyprofile();

		if(response.success)
		{
			setProfile(response.profile);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setProfile({
				network_searchresults : 'YES',
				network_sharing_master : 'YES',
				network_invite_url : 'https://www.shieldhublabs.com',
			});
		}

	}

	async function RefreshConnections(){

		var response = await api.listconnections();

		if(response.success)
		{
			response.connections.sharedwithme = response.connections.sharedwithme.map( (item) => {

				item.fullname = `${item.lastname}, ${item.firstname}`;
				item.share_dts_formatted = dateFormat(dateParse( item.share_dts ), 'MM/dd/yyyy');

				if(item.expiration_date)
				{
					try{
						item.expiration_dts_formatted = dateFormat(dateParse( item.expiration_date ), 'MM/dd/yyyy');
					}
					catch {
						item.expiration_dts_formatted = null;
					}
				}

				if(item.historyrecords && item.historyrecords.length > 0)
				{
					item.historyrecords = item.historyrecords.map( (historyrecord) => {
						try{
							historyrecord.date_formatted = dateFormat(dateParse( historyrecord.date ), 'MM/dd/yyyy');
						}
						catch {
							historyrecord.date_formatted = null;
						}
						return historyrecord;
					});
				}

				// let disabled = (item.connectionstatus === 'DISABLED');

				return item;
			});

			response.connections.myshares = response.connections.myshares.map( (item) => {
				item.share_dts_formatted = dateFormat(dateParse( item.share_dts ), 'MM/dd/yyyy');
				return item;
			});

			setSharedConnections(response.connections.myshares);
			setConnSharedWithMe(response.connections.sharedwithme);
			await RefreshProfile();
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setConnSharedWithMe([
				{
					id : 1001,
					keyid : 1001,
					firstname : '',
					lastname : '',
					fullname : '',
					businessname : '',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'INVITE',
					expiration_date : null,
					expiration_dts_formatted : null,
					purchase_date : new Date().toJSON(),
					reportshared : 'NO',
					emailstatus : {
						invite_sent : 1,
						renewal_count : 0,
						bounces : false
					},
					historyrecords : [
						{
							id : 2000,
							status : 'complete',
							label : 'First Invite Sent',
							date : new Date().toJSON(),
							date_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy')
						},
						{
							id : 20001,
							status : 'complete',
							label : 'Second Invite Sent',
							date : new Date().toJSON(),
							date_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy')
						},
						{
							id : 2002,
							status : 'complete',
							label : 'Application Submitted',
							date : new Date().toJSON(),
							date_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy')
						},
						{
							id : 2003,
							status : 'inprogress',
							label : 'Application In Progress',
							date : new Date().toJSON(),
							date_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy')
						},
						{
							id : 2004,
							status : 'pending',
							label : 'Score Pending',
							date : new Date().toJSON(),
							date_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy')
						},
						{
							id : 2005,
							status : 'pending',
							label : 'Accept & Consent',
							date : new Date().toJSON(),
							date_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy')
						},
					]
				},
				{
					id : 1000,
					keyid : 1000,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : 'MO630341030',
					currenticstatus : 'IC01',
					connectionstatus : 'CURRENT',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					purchase_date : new Date().toJSON(),
					reportshared : 'YES',
					emailstatus : {
						invite_sent : 1,
						renewal_count : 0,
						bounces : false
					},
				},
				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'APPSTARTED',
					expiration_date : null,
					expiration_dts_formatted : null,
					purchase_date : new Date().toJSON(),
					emailstatus : {
						invite_sent : 1,
						renewal_count : 0,
						bounces : false
					},
				},
				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'APPSTARTED',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : null,
					purchase_date : new Date().toJSON(),
					emailstatus : {
						invite_sent : 1,
						renewal_count : 0,
						bounces : false
					},
				},
				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'CHECKINPROGRESS',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : null,
					purchase_date : new Date().toJSON(),
					emailstatus : {
						invite_sent : 1,
						renewal_count : 0,
						bounces : false
					},
				},
				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'PENDINGINVITEACCEPT',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : null,
					purchase_date : new Date().toJSON(),
					emailstatus : {
						invite_sent : 1,
						renewal_count : 0,
						bounces : false
					},
				},
				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'EXPIRED',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					purchase_date : new Date().toJSON(),
					emailstatus : {
						invite_sent : 0,
						renewal_count : 1,
						bounces : false
					},
				},
				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'INVITEIGNORE',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					purchase_date : new Date().toJSON(),
				},

				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : 'IC02',
					connectionstatus : 'INRENEWALWINDOW',
					expiration_date : new Date().toJSON(),
					expiration_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					purchase_date : new Date().toJSON(),
					historyrecords : [
						{
							id : 865,
							eventname: 'User:DayOfRenewalReminder',
							label: 'Day of Expiration Reminder Sent',
							status: 'complete',
							date: "2024-10-10T10:00:00.000Z"
						},
					],
				},
				{
					id : 1001,
					keyid : 1001,
					firstname : 'John',
					lastname : 'Smith',
					fullname : 'Smith, John',
					businessname : 'Business Name',
					emailaddress : 'test@email.com',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()),'MM/dd/yyyy'),
					abcnumber : '',
					currenticstatus : '',
					connectionstatus : 'INVITEBOUNCE',
					expiration_date : null,
					expiration_dts_formatted : null,
					purchase_date : null,
					emailstatus : {
						invite_sent : 0,
						invite_open_count : 0,
						invite_click_count : 0,
						bounces : false
					},
				},
			]);

			setSharedConnections([
				{
					id : 2000,
					keyid : 5200,
					firstname : 'Jane',
					lastname : 'Doe',
					fullname : 'Doe, Jane',
					businessname : 'Business Name',
					share_dts : new Date().toJSON(),
					share_dts_formatted : dateFormat(dateParse( new Date().toJSON()), 'MM/dd/yyyy'),
					active : 'NO',
				}
			]);

			await RefreshProfile();
		}
		else
		{

		}
	}

	async function ToggleActive( connectionid )
	{
		let connections = sharedconnections;
		let active = '';

		connections = connections.map( (row) => {

			if(row.id === connectionid)
			{
				//Toggle 
				row.active = ( row.active === 'YES' ? 'NO' : 'YES');

				active = row.active;
			}
			return row;
		});

		setSharedConnections(connections);

		await api.setconnectionactive( connectionid , active );

	}

	async function TogglePDFShared( connectionid )
	{
		let connections = sharedconnections;
		let shared = '';

		connections = connections.map( (row) => {

			if(row.id === connectionid)
			{
				//Toggle
				row.reportshared = ( row.reportshared === 'YES' ? 'NO' : 'YES');

				shared = row.reportshared;
			}
			return row;
		});
		
		setSharedConnections(connections);

		await api.setsharedetails( connectionid , shared );
	}

	async function ResendInvite( inviteid )
	{
		let response = await api.resendinvite( inviteid );

		if(response.success)
		{
			setInviteResent(inviteid);
			setTimeout( () => {
				setInviteResent(false);
			},5000);
		}
	}

	async function CancelInvite( inviteid )
	{
		let response = await api.cancelinvite( inviteid );

		if(response.success)
		{
			setTimeout( () => {
				RefreshConnections();
			},500);
		}
	}

	async function DeleteSetConnection( )
	{
		if(deleteConnection.type === 'myshares')
		{
			let connections = sharedconnections;

			connections = connections.filter( (row) => {

				return !(row.id === deleteConnection.connectionid);
			});

			setSharedConnections(connections);

			await api.deleteconnection( deleteConnection.connectionid , deleteConnection.type );
		}
		else if(deleteConnection.type === 'sharedwithme')
		{
			let connections = connectionssharedwithme;

			connections = connections.filter( (row) => {

				return !(row.id === deleteConnection.connectionid);
			});

			setConnSharedWithMe(connections);

			await api.deleteconnection( deleteConnection.connectionid , deleteConnection.type );
		}

		setDeleteConnection({});
	}

	async function DownloadReport( connectionid )
	{
		setDownloadModal(true);

		let response = await api.downloadreport( connectionid );
		if(response.success)
		{
			setDownloadlink(response.downloadlink);
			setDownloadname('background_check_details.pdf');			
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setDownloadlink('https://www.google.com');
			setDownloadname('background_check_details.pdf');
		}
		else
		{
			setDownloaderror('Error: Report failure');
		}
	}

	async function RequestRenewal( )
	{
		setRequestRenewalStatus('inprogress');
		let response = await api.requestrenewal( RequestRenewalModal );
		if(response.success)
		{
			setRequestRenewalModal(false);
			setRequestRenewalStatus(false);
		}
	}

	async function CopyInviteLink()
	{
		await navigator.clipboard.writeText(profile.network_invite_url);

		setInviteLinkCopied(true);

		setTimeout( () => {
			setInviteLinkCopied(false);
		},5000);
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		( async () => {
			await RefreshConnections();
		})();

	},[]);

	React.useEffect( () => {

		// Watch for events to trigger refresh methods
		if( ['networkupdate','globalrefresh'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await RefreshConnections();
			})();
		}		

	},[emitter.eventname,emitter.data]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<>
			<Card className="p-4 my-network">
				<Row>
					<Col sm={12}>
						<h4>My Network</h4>
					</Col>
					<Col xs={12} sm={8} lg={9} md={9} xl={10}>
						<p className="small mb-3">Use the tabs below to manage your trusted network. Use the <b>Shared With Me</b> tab to view and monitor your independent contractor network. Use the <b>Shared With Others</b> tab manage who you are sharing your ShieldScore<sup>TM</sup> with.  Click the <b>Network Actions Button</b> to send an invitation to join your network or share your ShieldScore.</p>
					</Col>
					
					<Col xs={2} sm={2} lg={2} className="float-end">
						<Dropdown>
							<Dropdown.Toggle variant="primary" className="network-actions" id="dropdown-basic">
								<Diagram3Fill /> Network Actions
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item href="#addtomynetwork"><PersonFillAdd/> Invite User</Dropdown.Item>
								<Dropdown.Item 
									href="#sharemyshieldid"
									disabled={ ! ( auth?.user?.certification_number && auth?.user?.certification_number?.length > 0 && profile.network_sharing_master === 'YES' && profile.network_searchresults === 'YES') }
								><ShareFill /> Share My ShieldScore</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>

						{/* <Button style={{float:"left", whiteSpace:"nowrap"}} className="mt-2" href="#addtomynetwork"><PersonFillAdd/> Invite User</Button> */}
					</Col>
				</Row>
				<Row style={{marginTop:"20px",overflow:'clip'}} className="network-tabs">
					<Col sm={12}>
						<Tab.Container id="left-tabs-example" defaultActiveKey="sharedwithme">
							<Nav variant="tabs">
								<Nav.Item xs={6}>
									<Nav.Link eventKey="sharedwithme" className="sharedwithme">Shared with Me</Nav.Link>
								</Nav.Item>
								<Nav.Item xs={6}>
									<Nav.Link eventKey="shared" className="sharedwithothers">Shared with Others</Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content style={{backgroundColor:"#FFFFFF"}}>
								<Tab.Pane eventKey="shared">
									<div id="network-sharewithothers" className="ag-theme-quartz" style={{height:'650px',minWidth:'1100px'}} >
										<AgGridReact 
											ref={sharedwithothersgridref}
											rowData={sharedconnections} 
											columnDefs={sharedwithothersColDef}
											pagination={true}
											paginationPageSize={rows_per_page}
											paginationPageSizeSelector={[10 , 20, 50, 100]}
											autoSizeStrategy={sharedwithothersAutosizeStrategy}
											rowHeight={80}
											noRowsOverlayComponent={()=> {
												return <>Start building your trusted network. Add independent contractors today</>;
											}}
										/>
									</div>
								</Tab.Pane>
								<Tab.Pane eventKey="sharedwithme">
									<div >
										<Row>
											<Col xs={11}>
												<InputGroup className="mb-1 network-sharedwithme-quickfilter">
													<Form.Control 
														ref={shwme_qf_ref}
														type='text' 
														name="sharedwithmequickfilter" 
														placeholder="Quick Filter"
														onInput={(e) => {
															setshwme_qf_text(e.target.value);
														}}
													/>
													<InputGroup.Text>
														<Search className="searchbutton" size={12} />
														<Button className="clearbutton p-0" variant="link" onClick={() => { shwme_qf_ref.current.value = '';  }}><XCircleFill style={{pointer:'cursor'}} color="grey"/></Button>
													</InputGroup.Text>
												</InputGroup>
											</Col>											
											<Col xs={1} className="text-end">
												<a href="https://support.shieldhub.com/support/solutions/articles/154000204464-managing-your-network" target="_blank"><QuestionCircleFill className="mt-2" style={{fontSize:'20px'}} /></a>
											</Col>
										</Row>
									</div>
									<div id="network-sharedwithme" className="ag-theme-quartz" style={{height:'650px',minWidth:'1100px'}} >
										<AgGridReact 
											ref={sharedwithmegridref}
											rowData={connectionssharedwithme} 
											columnDefs={sharedwithmeColDef}
											pagination={true}
											paginationPageSize={rows_per_page}
											paginationPageSizeSelector={[10 , 20, 50, 100]}
											quickFilterText={shwme_qf_text}
											autoSizeStrategy={sharedwithmeAutosizeStrategy}
											tooltipShowDelay={0}
											tooltipHideDelay={120000}
											rowHeight={55}
											noRowsOverlayComponent={()=> {
												return <>Start building your trusted network. Add independent contractors today</>;
											}}
										/>
									</div>
									<Row className="p-0">
										<Col xs={{span:4,offset:8}} className="content-end">
											<Button variant="link" style={{color:branding.PrimaryButtonBackground,float:"right",textDecorationLine:"none"}} onClick={() => {

												sharedwithmegridref.current.api.exportDataAsCsv(csvexportdefaults);
												/*
												saveAsCsv({
													data : csvstruct.data,
													fields : csvstruct.fields,
													filename : csvstruct.filename
												})
												*/
											}}>Export as CSV <BoxArrowUpRight size={12} style={{marginTop:'-5px'}}/></Button>
										</Col>
									</Row>
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</Col>
				</Row>
			</Card>
			<Modal show={(parseInt(deleteConnection.connectionid) > 0)} centered={true} size="sm">
				<Modal.Body style={{textAlign:'center'}}>
					<h5 style={{clear:'both'}}>Are you sure?</h5>
					<br />
					<div style={{float:'left'}}>
						<Button variant="outline-secondary" onClick={() => { setDeleteConnection({}); }}>Nevermind</Button>
					</div>
					<div style={{float:'right'}}>
						<Button variant="primary" onClick={DeleteSetConnection}>Yes</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={RequestRenewalModal !== false} centered={true} size='md' onHide={() => { setRequestRenewalModal(false); setRequestRenewalStatus(false)} }>
				<Modal.Header closeButton={true}>
					<Modal.Title>Request Renewal</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">
					<Image src='/images/RefreshShield.svg' style={{width:'50%'}} />
					<h4>Send Renewal Request</h4>
					<p>This will add the user to the expiration message queue.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={RequestRenewal} disabled={RequestRenewalStatus !== false}> {RequestRenewalStatus !== false && <Spinner size='sm' />}  Request Renewal</Button>
				</Modal.Footer>
			</Modal>
				
			<Modal show={InviteLinkModal} centered={true} onHide={() => { setInviteLinkModal(false); }}>
				<Modal.Header closeButton={true}>
					<Modal.Title>Invitation Link</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Provide the following link to invite users as an alternative to resending a system invite:<br />
					<br />
					<InputGroup>
						<FormControl
							className="shadow-none"
							value={profile.network_invite_url}
						/>
						{InviteLinkCopied === false && <Button onClick={CopyInviteLink}><Copy /> Copy Link</Button>}
						{InviteLinkCopied === true && <Button onClick={CopyInviteLink} variant="success"><Clipboard /> Link Copied</Button>}
					</InputGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => { setInviteLinkModal(false); }}>Done</Button>
				</Modal.Footer>
			</Modal>
			<ShareMyShieldScore />
			<FileDownloadModal
				show={downloadModal}
				downloadlink={ (downloadlink !== false && downloadlink.length > 10 ? downloadlink : false ) }
				downloadtext={ (downloadlink !== false && downloadlink.length > 10 ? 'Download Report' : null ) }
				downloaderror={downloaderror}
				downloadname={downloadname}
				CancelMethod={() => {
					setDownloadModal(false);
					setDownloadlink(false);
					setDownloaderror(false);
				}}
			/>			
		</>
	);
}

export default MyNetwork;