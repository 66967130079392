import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Stack , Button, Form, Alert , Row , Col } from 'react-bootstrap';
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import { useAuth } from "../components/Auth";
import Logo from '../assets/shieldhub-logo.png';
import api from "../io/user";
import { useBranding } from "../components/Branding";


function RegisterVerification(){

	const auth = useAuth();
	const branding = useBranding();
	const [ verificationcode , setVerificationCode ] = React.useState('');
	const [ submitdisable , setSubmitdisable ] = React.useState(true);
	const [ errorbanner, setErrorbanner ] = React.useState(false);
	const [ errormessage , setErrormessage ] = React.useState(false);
	const navigate = useNavigate();
	const { search } = useLocation();

	const querystring = new URLSearchParams(search, [search]);

	const CodeFieldStyle = {
		paddingLeft:"10px",
		paddingRight:"10px",
		width:"41px",
		textTransform: "uppercase",
		textAlign:"center"
	};

	let CodeFields = {};

	CodeFields.one = React.createRef(null);
	CodeFields.two = React.createRef(null);
	CodeFields.three = React.createRef(null);
	CodeFields.four = React.createRef(null);
	CodeFields.five = React.createRef(null);
	CodeFields.six = React.createRef(null);

	function handleControlCodes(event)
	{
		if(event.keyCode === 8)
		{
			let code = verificationcode;

			code = code.substring(0,code.length-1);

			setVerificationCode(code);

			if(code.length === 0)
			{
				CodeFields.one.current.focus();
			}
			else if(code.length === 1)
			{
				CodeFields.two.current.focus();
			}
			else if(code.length === 2)
			{
				CodeFields.three.current.focus();
			}
			else if(code.length === 3)
			{
				CodeFields.four.current.focus();
			}
			else if(code.length === 4)
			{
				CodeFields.five.current.focus();
			}
			else if(code.length === 5)
			{
				CodeFields.six.current.focus();
			}

		}
	}

	async function handleCodePasteEvent(event)
	{
		var clipboardData = event.clipboardData || event.originalEvent.clipboardData || window.clipboardData;
		var pastedData = clipboardData.getData('text/plain');

		pastedData = pastedData.toUpperCase();

		if(pastedData.match(/^[0-9A-Z]{1,6}/))
		{
			setVerificationCode(pastedData);

			if(pastedData.substring(1,2) !== '')
			{
				CodeFields.two.current.value = pastedData.substring(1,2);
			}
			if(pastedData.substring(2,3) !== '')
			{
				CodeFields.three.current.value = pastedData.substring(2,3);
			}
			if(pastedData.substring(3,4) !== '')
			{
				CodeFields.four.current.value = pastedData.substring(3,4);
			}
			if(pastedData.substring(4,5) !== '')
			{
				CodeFields.five.current.value = pastedData.substring(4,5);
			}
			if(pastedData.substring(5,6) !== '')
			{
				CodeFields.six.current.value = pastedData.substring(5,6);
			}

		}
	}

	function handleVerficationCode(event)
	{
		if(verificationcode.length === 0 && event?.currentTarget?.value?.length > 0)
		{
			setVerificationCode(event.currentTarget.value.toUpperCase());
			CodeFields.two.current.focus();
		}
		else
		{
			let code = verificationcode;

			code = CodeFields.one.current.value;
			if(CodeFields.two.current.value.length === 1)
			{
				code += CodeFields.two.current.value;
			}
			if(CodeFields.three.current.value.length === 1)
			{
				code += CodeFields.three.current.value;
			}
			if(CodeFields.four.current.value.length === 1)
			{
				code += CodeFields.four.current.value;
			}
			if(CodeFields.five.current.value.length === 1)
			{
				code += CodeFields.five.current.value;
			}
			if(CodeFields.six.current.value.length === 1)
			{
				code += CodeFields.six.current.value;
			}

			if(code.length < 6)
			{
				setSubmitdisable(true);
			}

			if(code.length === 2)
			{
				CodeFields.three.current.focus();
			}
			else if(code.length === 3)
			{
				CodeFields.four.current.focus();
			}
			else if(code.length === 4)
			{
				CodeFields.five.current.focus();
			}
			else if(code.length === 5)
			{
				CodeFields.six.current.focus();
			}
			else if(code.length === 6)
			{
				setSubmitdisable(false);
			}

			code = code.toUpperCase();

			setVerificationCode(code);
		}
	}

	async function handleSubmitCode(event){

		if(event)
		{
			event.preventDefault();
		}

		var code;

		if(querystring.get('code') !== null && querystring.get('code') !== undefined)
		{
			code = querystring.get('code');
		}
		else if(verificationcode.length !== 6)
		{
			setErrorbanner(true);
			setErrormessage("Error : Verification code is 6 charaters ");
			return;
		}
		else
		{
			code = verificationcode;
		}

		let response = await api.registerverification(code);

		if(response.success)
		{
			navigate("/user?gettingstarted");
		}

	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		if(querystring.get('code') !== null && querystring.get('code') !== undefined)
		{
			handleSubmitCode();
			return;
		}
		else
		{
			CodeFields.one.current.focus();
		}

		if(window.clarity)
		{
			window.clarity('set','userid',auth?.user?.userid);
		}	

	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<div className="login-position container">
			<Stack gap={2}>
				<Row className="justify-content-center login-portal">
					<Col xs="auto" style={{height:'90px'}}>
						{process.env.REACT_APP_LOCALDEBUG ? <img src={Logo} alt="ShieldHub Logo" className="branding"/> : <img src={branding.Company.branding_logo_path} alt="ShieldHub Logo" className="branding"/>}
					</Col>
				</Row>
				<Row className="justify-content-center login-portal">
					<Col xs={12} sm={10} md={8} lg={6}>
						<Form onSubmit={handleSubmitCode} autoComplete="off" className="login-container">
							<h2>Verify Your Email</h2>
							<h6>A verification code and link were sent to your email {auth?.user?.emailaddress}</h6>
							<Stack direction="horizontal" gap={1} style={{paddingTop:"50px",paddingBottom:"50px"}} className="justify-content-center login-portal">
								<div>
									<Form.Control 
										type="text"
										size="lg"
										maxLength={1}
										htmlSize={1}
										style={CodeFieldStyle}
										ref={CodeFields.one}
										onChange={handleVerficationCode}
										onKeyDown={handleControlCodes}
										onPaste={handleCodePasteEvent}
									/>
								</div>
								<div>
									<Form.Control
										type="text"
										size="lg"
										maxLength={1}
										htmlSize={1}
										style={CodeFieldStyle}
										ref={CodeFields.two}
										onChange={handleVerficationCode}
										onKeyDown={handleControlCodes}
									/>
								</div>
								<div>
									<Form.Control
										type="text"
										size="lg"
										maxLength={1}
										htmlSize={1}
										ref={CodeFields.three}
										style={CodeFieldStyle}
										onChange={handleVerficationCode}
										onKeyDown={handleControlCodes}
									/>
								</div>
								<div>
									<Form.Control
										type="text"
										size="lg"
										maxLength={1}
										htmlSize={1}
										ref={CodeFields.four}
										style={CodeFieldStyle}
										onChange={handleVerficationCode}
										onKeyDown={handleControlCodes}
									/>
								</div>
								<div>
									<Form.Control
										type="text"
										size="lg"
										maxLength={1}
										htmlSize={1}
										ref={CodeFields.five}
										style={CodeFieldStyle}
										onChange={handleVerficationCode}
										onKeyDown={handleControlCodes}
									/>
								</div>
								<div>
									<Form.Control
										type="text"
										size="lg"
										maxLength={1}
										htmlSize={1}
										ref={CodeFields.six}
										style={CodeFieldStyle}
										onChange={handleVerficationCode}
										onKeyDown={handleControlCodes}
									/>
								</div>
							</Stack>
							<Row>
								<Col sm={12} style={{textAlign:"center",marginTop:"20px"}}>
									<Button type="submit" disabled={submitdisable} style={{backgroundColor:branding.PrimaryButtonBackground,height:"58px",border:branding.PrimaryButtonBackground, width: "100%"}}>Create Account</Button>
								</Col>
							</Row>
							<Alert variant="danger" show={errorbanner}> <ExclamationTriangleFill size={20} />{errormessage}</Alert>
							{/* <Row style={{paddingTop:"50px"}}>
								<Col>
									<div style={{"color":"#6C757D",textAlign:"center"}}>Don't have access to email? <Button variant="link" href="/register">Verify Using Another Method</Button></div>					
								</Col>
							</Row> */}
						</Form>
					</Col>
				</Row>
			</Stack>
		</div>
	);

}

export default RegisterVerification;