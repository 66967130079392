import * as React from "react";
import '@aws-amplify/ui-react/styles.css';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import api from '../../../io/workflow';
import { Container , Row , Col, Button, Image } from "react-bootstrap";
import { CaretRightFill, CheckCircleFill } from "react-bootstrap-icons";

function CaptureUserSelfie( props ) {

	const [ userReady , setUserReady ] = React.useState(false);
	const [ selfiesessionid , setSelfiesessionid ] = React.useState(null);
	const [ liveselfievalues , setSelfieValues ] = React.useState(null);

	async function StartSessionAuth()
	{
		let response = await api.startlivefacecapture('getauth');
		if(response.success)
		{
			return Promise.resolve({
				accessKeyId : response.credentials.accessKeyId,
				secretAccessKey : response.credentials.secretAccessKey,
				sessionToken : response.credentials.sessionToken,
			});
		}
	}

	async function SessionComplete()
	{
		let response = await api.completelivefacecapture();
		if(response.success)
		{
			setSelfieValues(response.liveness);
			props.CompleteStep();
		}

	}

	async function GetSession()
	{
		let response = await api.startlivefacecapture('getsession');
		if(response.success)
		{
			setSelfiesessionid(response.sessionid);
		}
	}

	return (
		<>

			{userReady === true && selfiesessionid && liveselfievalues === null && props.isMobile === false && 
				<div style={{height:'600px'}}>
					<FaceLivenessDetector
						config={{ credentialProvider : async () => { return await StartSessionAuth() }, binaryPath : `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/` , faceModelUrl : `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/model.json` }}
						region='us-east-1'
						sessionId={selfiesessionid}
						onAnalysisComplete={SessionComplete}
						onError={(error) => {
							console.log(error);
						}}
						
					/>
				</div>
			}
			{userReady === true && selfiesessionid && liveselfievalues === null && props.isMobile === true && props.screenOrientation === 'portrait' && 
				<div style={{position:'absolute',top:'0px',left:'0px',width:'100%',height:'100%',zIndex:'1000',margin:'5px'}}>
					<FaceLivenessDetector
						config={{ credentialProvider : async () => { return await StartSessionAuth() }, binaryPath : `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/` , faceModelUrl : `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/model.json` }}
						region='us-east-1'
						sessionId={selfiesessionid}
						onAnalysisComplete={SessionComplete}
						onError={(error) => {
							console.log(error);
						}}
					/>
				</div>
			}


			{userReady === true && liveselfievalues !== null && 
				<Container>
					<Row>
						<CheckCircleFill color="green" />
						<h1>Thank You</h1>
						<p>Your video has been recorded</p>
					</Row>
					<Row className="justify-content-center">
						<Col xs={12} md={8} lg={4}>
							<Button variant="outline-primary" style={{width:'100%'}} onClick={ async () => { 
								await GetSession();
								setSelfieValues(null);
							}}>Retake video</Button>
						</Col>
					</Row>
					<Row className="mt-1 justify-content-center" >
						<Col xs={12} md={8} lg={4}>
							<Button variant="primary" style={{width:'100%'}} >Continue</Button>
						</Col>
					</Row>
				</Container>
			}

			{props.isMobile === true && props.screenOrientation === 'landscape' &&
			<>
				<Row>
					<Col className="text-center" xs={12}>
						<h2>For best results, please rotate your device to portrait mode</h2>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xs={8}>
						<Image src={`${process.env.PUBLIC_URL}/images/landscape_rotate_istockphoto-1144546554-1024x1024.jpg`} style={{width:'100%'}}/>
					</Col>
				</Row>
			</>
			}
			{userReady === false && (props.isMobile === false || ( props.isMobile === true && props.screenOrientation === 'portrait' )) &&
			<>
				<Row className="justify-content-center">
					<Col xs={4} className="text-center">
						<h3>Record a video</h3>
						<p>For security, we capture a live video to verify your identity</p>
						<Button onClick={ async () => { 
							await GetSession();
							setUserReady(true); 
						}} >I&apos;m ready <CaretRightFill /></Button>
					</Col>
				</Row>
			</>
			}
		</>
	);
}
export default CaptureUserSelfie;

/*

https://aws.amazon.com/rekognition/identity-verification/

https://ui.docs.amplify.aws/react/connected-components/liveness

https://github.com/aws-amplify/amplify-ui/blob/main/packages/react-liveness/src/components/FaceLivenessDetector/FaceLivenessDetector.tsx

https://docs.aws.amazon.com/rekognition/latest/dg/face-liveness-prerequisites.html

*/