import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Card, Form, Stack , Container, Col , Row , Button , Image , Alert } from "react-bootstrap";
import { useBranding } from "../../Branding";
import api from '../../../io/workflow';
import generalapi from "../../../io/general";
import ClearStarLogo from "../../../assets/cra_logos/clearstar.png";
import { ExclamationTriangleFill } from "react-bootstrap-icons";


function CompletePurchaseForm({ pkgobject })
{
	const branding = useBranding();
	const stripe = useStripe();
	const elements = useElements();
	const navigate = useNavigate();

	const [ purchaseinprogress , setPurchaseinprogress ] = React.useState(false);
	const [ transactionerror , setTransactionError ] = React.useState(false);
	const [ transactionerrormsg , setTransactionErrormsg ] = React.useState(null);
	const [ showtermscondition , setShowTermsConditions ] = React.useState(false);
	const [ TermsAndConditions , setTermsAndConditions ] = React.useState({ __html : '<div></div>'});

	const PaymentOptions = {
		layout : {
			type : 'tabs',
			defaultCollapsed : false
		},
		wallets : {
			applePay : 'auto',
			googlePay : 'auto',
		},
	};

	const [ accept_cc_terms , setAcceptCCTerms ] = React.useState(true);

	async function handleSubmit(event)
	{
		setPurchaseinprogress(true);
		setTransactionError(false);
		event.preventDefault();

		let confirmResponse = await stripe.confirmPayment({ 
			elements,
			confirmParams: {
				return_url: `${window.location.origin}/user/purchase/purchasereceipt`,
			},
			redirect : 'if_required'
		});

		if(confirmResponse.paymentIntent && confirmResponse.paymentIntent.status === 'succeeded')
		{

			let response = await api.setpurchaseconfirmed( confirmResponse.paymentIntent.id );
			if(response.success)
			{
				navigate(`/user/purchase/purchasereceipt#purchaseid=${response.purchaseid}`);
				return;
			}
		}
		else if(confirmResponse.error && confirmResponse.error.code)
		{
			if(confirmResponse.error.message && confirmResponse.error.message.length > 0)
			{
				setTransactionErrormsg(confirmResponse.error.message);
			}
			else
			{
				setTransactionErrormsg('The transaction was declined.');
			}

			setTransactionError(true);
		}
		else
		{
			console.log(confirmResponse);
		}

		setPurchaseinprogress(false);
	}

	async function getTermsAndConditions()
	{

		let termsandconditions = await generalapi.branding();

		if(termsandconditions.success)
		{
			setTermsAndConditions({__html : termsandconditions.branding.credit_card_terms});
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		( async () => {
			await getTermsAndConditions();
		})();

	},[]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (
		<>
			<Form onSubmit={handleSubmit}>
				<Stack gap={2} className="pb-5 col-xs-12 col-sm-8 col-md-6 mx-auto" style={{position:'relative'}}>
					{ showtermscondition && <Container className="mx-auto" style={{zIndex:'1000',height:'600px',position:'absolute',maxWidth:'550px'}}>
						<div style={{border:'1px solid black',height:'100%',backgroundColor:'#FFFFFF',overflowY:'scroll'}} className="p-3 shadow-sm rounded-3">
							<p dangerouslySetInnerHTML={TermsAndConditions}></p>
							<br />
							<br />
						</div>
						<div className="rounded-3" style={{textAlign:'center',position:'relative',bottom:'45px',padding:'3px',backgroundColor:'#FFFFFF',marginLeft:'3px',marginRight:'3px'}}>
							<Button onClick={() => {
								setShowTermsConditions(false);
							}}>Close</Button>
						</div>
					</Container>}
					<Card className="rounded-0">
						<Card.Header className="rounded-0" style={{backgroundColor:branding.PrimaryButtonBackground,color:'#FFFFFF'}}>
							<Card.Title className="text-center mb-0">YOU SELECTED</Card.Title>
						</Card.Header>
						<Card.Body className="text-center">
							<img src={ClearStarLogo} alt="ClearStar CRA" style={{width:'190px'}}/>
							<p>{pkgobject.package_descrip}</p>
							<h4>Pay ShieldHub, Inc.</h4>
							<h1 style={{fontWeight:'bolder',fontSize:'45px'}}>${pkgobject.base_price}</h1>						
						</Card.Body>
					</Card>
					<Container className="p-0 m-0">
						<PaymentElement options={PaymentOptions}/>
					</Container>
					<Container>
						<Form.Check id='accept_cc_terms' >
							<Form.Check.Input type='checkbox' onChange={(event) => { setAcceptCCTerms(!event.currentTarget.checked) }}/>
							<Form.Check.Label>I agree to the <a href="javascript:void(0);" onClick={() => {
								setShowTermsConditions(true);
							}} >purchase terms and conditions</a> and authorize ShieldHub Inc. to charge my credit card.</Form.Check.Label>
						</Form.Check>
					</Container>
					<Alert variant='danger' show={transactionerror}><ExclamationTriangleFill size={25} style={{marginTop:'-3px'}}/> Error : {transactionerrormsg}</Alert>
					<Container>
						<Row>
							<Col>
								<Button variant="link" href="/user" style={{textDecoration:'none',color:branding.PrimaryColor}}>Cancel</Button>
							</Col>
							<Col xs={{offset:2,span:4}}>
								<Button 
									type="submit" 
									style={{width:'100%'}} 
									disabled={ ( accept_cc_terms || purchaseinprogress )}
								>{ ( purchaseinprogress ? <>Processing...</> : <>Pay ${pkgobject.base_price}</>) }</Button>
							</Col>
						</Row>
						<Row >
							<Image src='/images/poweredbystripeblurple.svg' style={{width:'125px',marginLeft:'auto',marginRight:'auto'}}/>
						</Row>
					</Container>
				</Stack>
			</Form>
		</>
	);
}

function CompletePurchase()
{
	const branding = useBranding();
	const { hash } = useLocation();
	const [ stripePromise , setStripePromise ] = React.useState(null);
	const [ loaded , setLoaded ] = React.useState(false);
	const [ pkgobject , setPackageObject ] = React.useState(false);
	const [ stripeOptions , setStripeOptions ] = React.useState({
		appearance : {
			theme : 'stripe',
			rules : {
				'.Label' : {
					color : branding.PrimaryColor,
					textTransform : 'uppercase'
				}
			}
		},
	});

	async function LoadSelectedPackage(pkgid)
	{
		var response = await api.startpurchase(pkgid);
		if(response.success)
		{
			setPackageObject(response.packagedetails);

			let updatedStripeOptions = { ...stripeOptions };

			updatedStripeOptions.clientSecret = response.client_secret;

			setStripeOptions(updatedStripeOptions);
			setLoaded(true);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setPackageObject({
				crapackageid : 'SLRT_16412',
				base_price : 50,
				package_descrip : 'Non New York State 1-Year Package with Continuous Monitoring',
				selection_criteria : null,
				exclusion_criteria : 'state!=NY'
			});

			let updatedStripeOptions = { ...stripeOptions };

			updatedStripeOptions.mode = 'payment';
			updatedStripeOptions.amount = 5000;
			updatedStripeOptions.currency = 'usd';

			setStripeOptions(updatedStripeOptions);
			setLoaded(true);
		}
	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect( () => {

		if(hash.match(/#pkgid=/))
		{
			if(hash.match(/#pkgid=.+/) && branding?.Company?.stripepublishablekey?.match(/^pk_/))
			{
				var hashvalues = hash.match(/#pkgid=(.+)/);

				( async () => {
					let stripe = await loadStripe(branding.Company.stripepublishablekey);
					setStripePromise(stripe);
					await LoadSelectedPackage(hashvalues[1]);
				})();
			}
		}
		else
		{
			//reset values here
			// setRegisterlink(false);
		}
	},[ hash , branding.Company.stripepublishablekey ]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<>
			{ loaded && <Elements stripe={stripePromise} options={stripeOptions}>
				<CompletePurchaseForm pkgobject={pkgobject} />
			</Elements>}
		</>
	);
}
export default CompletePurchase;