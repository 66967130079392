import * as React from "react";
import { Stack , Row , Col, Button } from "react-bootstrap";
import { ArrowRightCircle, CarFrontFill, Globe } from "react-bootstrap-icons";
import { useBranding } from "../../Branding";
import api from '../../../io/workflow';
import { useNavigate } from 'react-router-dom';


function ChooseIdDocument()
{
	const branding = useBranding();
	const navigate = useNavigate();

	const [ selectedDocumentType , setSelectedDocumentType ] = React.useState(false);

	async function SelectDocument( documentType )
	{
		setSelectedDocumentType(documentType);

		let response = await api.setselecteddocument( documentType );
		if(response.success)
		{
			navigate(`/user/idverify/checkpermissions`);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			navigate(`/user/idverify/checkpermissions`);
		}
	}

	return (
		<>
			<Row className="justify-content-center">
				<Col xs={8}>
					<h1>Choose your document</h1>
					<p>You must provide an official photo ID</p>
				</Col>
			</Row>
			<Row className="choose-id-document justify-content-center">
				<Col xs={10} md={8}>
					<Stack gap={2}>
						<Row className={selectedDocumentType === 'driverslicense' ? 'document-choice document-picked' : 'document-choice' } onClick={() => { SelectDocument('driverslicense'); }}>
							<Col xs={2} lg={1}><Button style={{cursor:'arrow',pointerEvents:'none'}}><CarFrontFill /></Button></Col>
							<Col xs={8} lg={10} className="mt-2"><b>USA State Driver&apos;s License</b></Col>
							<Col xs={2} lg={1} className="mt-2"><ArrowRightCircle color={branding.PrimaryColor} size={20} /></Col>
						</Row>
						<Row className={selectedDocumentType === 'passport' ? 'document-choice document-picked' : 'document-choice' } onClick={() => { SelectDocument('passport'); }}>
							<Col xs={2} lg={1} ><Button style={{cursor:'arrow',pointerEvents:'none'}}><Globe /></Button></Col>
							<Col xs={8} lg={10} className="mt-2"><b>USA Passport</b></Col>
							<Col xs={2} lg={1} className="mt-2"><ArrowRightCircle color={branding.PrimaryColor} size={20} /></Col>
						</Row>
					</Stack>
				</Col>
			</Row>
		</>

	);

}
export default ChooseIdDocument;