import * as React from "react";
import { Card, Collapse , Button , Table } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import { useRefreshEmitter } from "../../RefreshEmitter.js";
import { format as dateFormat , parseISO as dateParseISO , isValid as dateValid } from 'date-fns';
import api from '../../../io/admin';


function UserMetadata()
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ userprofile , setUserProfile ] = React.useState({});
	const [ panelshow , setPanelShow ] = React.useState(true);

	async function LoadProfile( userid )
	{
		let response = await api.getuser( userid );

		if(response.success === true)
		{

			if(response.profile.last_auth_dts && !dateValid(response.profile.last_auth_dts))
			{
				try{
					response.profile.last_auth_dts = dateParseISO(response.profile.last_auth_dts);
				}
				catch(e){
					console.warn(e);
					response.profile.last_auth_dts = null;
				}
			}
			if(response.profile.register_dts && !dateValid(response.profile.register_dts))
			{
				try{
					response.profile.register_dts = dateParseISO(response.profile.register_dts);
				}
				catch(e){
					console.warn(e);
					response.profile.register_dts = null;
				}
			}

			setUserProfile(response.profile);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setUserProfile({
				userid : 1000,
				last_auth_dts : new Date(),
				last_auth_ip : '127.0.0.1',
				register_dts : new Date(),
				last_auth_useragent : 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_2) AppleWebKit/601.3.9 (KHTML, like Gecko) Version/9.0.2 Safari/601.3.9'
			});
		}
		else
		{
			console.warn(response);
			setUserProfile({});
		}

	}
	
	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setUserProfile({});

		( async () => {
			await LoadProfile( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadProfile( userid );
			})();
		}
	},[emitter.eventname,hash]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;User Metadata</Button>


			<Collapse in={panelshow}>
				<>
					<h6>Last Login</h6>
					<Table>
						<thead>
							<tr>
								<th>Date/Time</th>
								<th>IP Address</th>
								<th>User Agent</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{ (userprofile.last_auth_dts && dateValid(userprofile.last_auth_dts) ? dateFormat(userprofile.last_auth_dts,"MM/dd/yyyy hh:mm a") : null )}</td>
								<td>{userprofile.last_auth_ip}</td>
								<td>{userprofile.last_auth_useragent}</td>
							</tr>
						</tbody>
					</Table>
					<br />
					<h6>Registered Date/Time</h6>
					<p>{ (userprofile.register_dts && dateValid(userprofile.register_dts) ? dateFormat(userprofile.register_dts,"MM/dd/yyyy hh:mm a") : null )}</p>
				</>
			</Collapse>
		</Card>

	);

}
export default UserMetadata;