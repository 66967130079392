import * as React from "react";
import { Button, Row , Col, Spinner } from "react-bootstrap";
import { LicenseManager } from 'dynamsoft-license';
import { CoreModule } from "dynamsoft-core";
import "dynamsoft-document-normalizer";
import 'dynamsoft-label-recognizer';
import { CameraEnhancer, CameraView , DrawingStyleManager } from "dynamsoft-camera-enhancer";
import { CaptureVisionRouter } from "dynamsoft-capture-vision-router";
import { MultiFrameResultCrossFilter , ImageManager } from "dynamsoft-utility";

import api from '../../../io/workflow';
import { useGeolocated } from "react-geolocated";

LicenseManager.initLicense('DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAzMDcxNjUzLVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAzMDcxNjUzIiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2Rscy5keW5hbXNvZnRvbmxpbmUuY29tIiwiY2hlY2tDb2RlIjotODgzMjQxOTA4fQ==');

// function dataURLtoBlob(dataurl)
// {
// 	//https://gist.github.com/wuchengwei/b7e1820d39445f431aeaa9c786753d8e
// 	var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
// 		bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
// 	while(n--){
// 		u8arr[n] = bstr.charCodeAt(n);
// 	}
// 	return new Blob([u8arr], {type:mime});
// }

function CaptureID( props )
{
	if(process.env.REACT_APP_SAMPLEDATA ? true : false)
	{
		CoreModule.engineResourcePaths = {
			core: 'https://cdn.jsdelivr.net/npm/dynamsoft-core@3.2.30/dist/',
			license: 'https://cdn.jsdelivr.net/npm/dynamsoft-license@3.2.21/dist/',
			ddn: "https://cdn.jsdelivr.net/npm/dynamsoft-document-normalizer@2.2.10/dist/",
			cvr: 'https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router@2.2.30/dist/',
			dce: 'http://localhost:3000/dynamsoft/dynamsoft-camera-enhancer/dist/',
			std: 'https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-std@1.2.10/dist/',
			dip: 'https://cdn.jsdelivr.net/npm/dynamsoft-image-processing@2.2.30/dist/',
			dbr: 'https://cdn.jsdelivr.net/npm/dynamsoft-barcode-reader@10.2.10/dist/',
			dlr: "https://cdn.jsdelivr.net/npm/dynamsoft-label-recognizer@3.2.30/dist/",
			dnn: 'https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-dnn@1.0.20/dist/',
			dlrData: 'https://cdn.jsdelivr.net/npm/dynamsoft-label-recognizer-data@1.0.10/dist/',
		};
	}
	else if(process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN)
	{
		CoreModule.engineResourcePaths = {
			core : `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-core/dist/`,
			license: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-license/dist/`,
			ddn: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-document-normalizer/dist/`,
			cvr: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-capture-vision-router/dist/`,
			dce: '/dynamsoft/dynamsoft-camera-enhancer/dist/',
			std: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-capture-vision-std/dist/`,
			dip: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-image-processing/dist/`,
			dbr: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-barcode-reader/dist/`,
			dlr: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-label-recognizer/dist/`,
			dnn: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-capture-vision-dnn/dist/`,
			dlrData: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-label-recognizer-data/dist/`,
		};		
	}
	else
	{
		CoreModule.engineResourcePaths = {
			core : 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-core/dist/',
			license: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-license/dist/',
			ddn: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-document-normalizer/dist/',
			cvr: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-capture-vision-router/dist/',
			dce: '/dynamsoft/dynamsoft-camera-enhancer/dist/',
			std: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-capture-vision-std/dist/',
			dip: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-image-processing/dist/',
			dbr: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-barcode-reader/dist/',
			dlr: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-label-recognizer/dist/',
			dnn: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-capture-vision-dnn/dist/',
			dlrData: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-label-recognizer-data/dist/',
		};
	}

	const capturecontainer = React.createRef(null);

	var CameraViewInstance, CameraEnhancerInstance, CVRouter , ImageManagerInstance;

	// const { GeoCoords, isGeolocationAvailable, isGeolocationEnabled }
	const { GeoCoords } = useGeolocated({positionOptions:{ enableHighAccuracy: true, },userDecisionTimeout: 5000, isOptimisticGeolocationEnabled : false });

	const [ capturedImage , setCapturedImage ] = React.useState(false);
	const [ capturedImageUrl , setCapturedImageUrl ] = React.useState(false);
	const [ stepComplete , setStepComplete ] = React.useState(false);
	const [ idvalues , setIdValues ] = React.useState(null);

	const [ captureInProgress , setCaptureInProgress ] = React.useState(false);

	async function verify()
	{
		setCaptureInProgress(true);

		let presignedurl = await api.startcaptureid(props.side);

		if(presignedurl.success)
		{
			// let imagecontent = dataURLtoBlob(capturedImage);
			let s3uploadresponse = await api.uploadcapture( presignedurl.posturl , capturedImage );
			if(s3uploadresponse.success)
			{
				let verifyresponse = await api.completecapture(props.side,'success' , GeoCoords );
				if(verifyresponse.success)
				{
					let metadata = {
						idvalues : verifyresponse.idvalues,
						labels : verifyresponse.labels,
						face : verifyresponse.face,
					}

					setIdValues(metadata);
					setStepComplete(true);
					props.CompleteStep();

					setCaptureInProgress(false);
				}
			}
		}

	}

	async function DocumentCapture( result )
	{
		if(result.detectedQuadResultItems.length === 0)
		{
			if(parseInt(result.errorCode) !== 0)
			{
				console.log({ errorString : result.errorString , errorCode : result.errorCode });
			}

			return;
		}

		let total_area = parseInt(result.originalImageTag.currentHeight) * parseInt(result.originalImageTag.currentWidth);
		let mostly_covered_area = parseInt(total_area * 0.15);

		// console.log(result);

		if( [8,16].indexOf(parseInt(result.detectedQuadResultItems[0].type)) !== -1 )
		{
			if(parseInt(result.detectedQuadResultItems[0].location.area) < mostly_covered_area )
			{
				// console.log(result.items[0].location.area,100000);
				return;
			}
		}
		else
		{
			console.log('wrong result type');
			console.log(result.detectedQuadResultItems[0].type);
			return;
		}

		// console.log(result);

		if(capturedImage === false)
		{
			CameraEnhancerInstance.setPixelFormat( 7 | 11);
			CameraEnhancerInstance.clearBuffer();

			let current_image = CameraEnhancerInstance.fetchImage();

			let textlineresults = await CVRouter.capture(current_image,'RecognizeTextLines_Default');

			console.log(textlineresults);

			if(textlineresults.textLineResultItems.length > 3)
			{
				let imagedata = await ImageManagerInstance.saveToFile(current_image,'download');

				setCapturedImageUrl(URL.createObjectURL(imagedata));
				setCapturedImage(imagedata);

				CameraEnhancerInstance.clearBuffer();				
	
				CVRouter.stopCapturing('DetectDocumentBoundaries_Default');

				CVRouter.stopCapturing();
	
			}
			else
			{
				CameraViewInstance.setTipVisible(true);
				CameraViewInstance.updateTipMessage('Image does not appear clear enough, please retry');

				CameraEnhancerInstance.clearBuffer();
				return;
			}
		}
	
	}


	async function Init()
	{
		if( !(CameraViewInstance && !CameraViewInstance?.getUIElement) )
		{
			CameraViewInstance = await CameraView.createInstance();
		}
		if(!CameraEnhancerInstance || !CVRouter)
		{
			CameraEnhancerInstance = await CameraEnhancer.createInstance(CameraViewInstance);
			CameraViewInstance.setTipConfig({
				topLeftPoint: {x: 50, y:50},
				width: 200,
				duration: 3000
			});
			CameraViewInstance.setTipVisible(false);
			CameraEnhancerInstance.setMaxImageCount(1);
			DrawingStyleManager.updateDrawingStyle(1,{
				fillStyle: "rgba(0, 0, 0, 0)",
				lineWidth: 1,
				paintMode : 'stroke',
				strokeStyle: "rgba(0, 0, 0, 0)"
			});
			// DrawingStyleManager.updateDrawingStyle(9,{
			// 	fillStyle: "rgba(0, 0, 0, 0)",
			// 	lineWidth: 1,
			// 	paintMode : 'stroke',
			// 	strokeStyle: "rgba(0, 0, 0, 0)"
			// });

			CVRouter = await CaptureVisionRouter.createInstance();
			CVRouter.setInput(CameraEnhancerInstance);
			CVRouter.addResultReceiver({
				onDetectedQuadsReceived : DocumentCapture,
			});
	
			let filter = new MultiFrameResultCrossFilter();
			filter.enableResultCrossVerification('barcode',false);
			filter.enableResultCrossVerification('detected_quad',true);
			await CVRouter.addResultFilter(filter);
		}

		if(capturecontainer !== null && capturecontainer.current !== null)
		{
			capturecontainer?.current?.replaceChildren( CameraViewInstance.getUIElement() );
			await CameraEnhancerInstance.open();
			let settings = await CVRouter.getSimplifiedSettings("DetectDocumentBoundaries_Default");
			settings.minImageCaptureInterval = 30;
			CVRouter.updateSettings('DetectDocumentBoundaries_Default', settings );
			await CVRouter.startCapturing('DetectDocumentBoundaries_Default');

			ImageManagerInstance = new ImageManager();
	
		}		


	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		( async () => {
			await Init();
		} )();


	}, [capturecontainer,capturecontainer.current,CameraViewInstance, CameraViewInstance?.getUIElement ]);
	/* eslint-enable react-hooks/exhaustive-deps */


	return (

		<>
			<h1>{`Scan ID ${props.side}`}</h1>
			{capturedImage !== false && <>
				<Row>
					<Col ><img src={capturedImageUrl} style={{width:'100%'}}/></Col>
					<Col xs={12} md={6}><pre>{JSON.stringify(idvalues,null,3)}</pre></Col>
				</Row>
				{stepComplete === false && <Button onClick={() => { setCapturedImage(false); }}>Retry</Button>}
			</>}
			{ capturedImage === false && <>

				<Row>
					<Col xs={12} >
						<div ref={capturecontainer} className="div-ui-container" style={{height:'540px'}}></div>
					</Col>
				</Row>

			</> }

			{ stepComplete === false && <Button variant="primary" onClick={verify}>Next { captureInProgress === true && <Spinner size="sm" /> }</Button>}
		</>


	);

}
export default CaptureID;
