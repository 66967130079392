import * as React from "react";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import { Download, FiletypePdf } from "react-bootstrap-icons";
import { useBranding } from "./Branding";



function FileDownloadModal( props )
{
	const branding = useBranding();

	return (
		<Modal show={props.show} size="sm" centered={true}>
			<Modal.Body style={{textAlign:"center"}}>
				{ (props.downloadlink === false && props.downloaderror === false) && <Spinner style={{width:'75px',height:'75px'}} />}

				{props.downloaderror && <Alert variant="danger">{props.downloaderror}</Alert>}

				{ ( props.downloaderror === false && props.downloadlink !== false && props.downloadlink?.length > 10 ) && <FiletypePdf size={100} /> }

			</Modal.Body>
			<Modal.Footer className="justify-content-between">
				<Button variant="link" onClick={props.CancelMethod} style={{color:branding.PrimaryColor,textDecoration:'none'}} >Close</Button>
				<Button 
					variant="primary" 
					href={props.downloadlink} 
					download={props.downloadname} 
					target={props.target} 
					disabled={ (props.downloadlink === false || props.downloadlink?.length < 10) } 
				><Download /> {props.downloadtext}</Button>
			</Modal.Footer>
		</Modal>
	)

}
export default FileDownloadModal;