import * as React from "react";
import { Button, Row , Col, Spinner } from "react-bootstrap";
import { CoreModule } from 'dynamsoft-core';
import { LicenseManager } from 'dynamsoft-license';
import "dynamsoft-barcode-reader";
import { CameraEnhancer, CameraView } from "dynamsoft-camera-enhancer";
import { CaptureVisionRouter } from "dynamsoft-capture-vision-router";
import { MultiFrameResultCrossFilter , ImageManager } from "dynamsoft-utility";
import api from '../../../io/workflow';
import { useGeolocated } from "react-geolocated";


LicenseManager.initLicense('DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAzMDcxNjUzLVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAzMDcxNjUzIiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2Rscy5keW5hbXNvZnRvbmxpbmUuY29tIiwiY2hlY2tDb2RlIjotODgzMjQxOTA4fQ==');

// function dataURLtoBlob(dataurl)
// {
// 	//https://gist.github.com/wuchengwei/b7e1820d39445f431aeaa9c786753d8e
// 	var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
// 		bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
// 	while(n--){
// 		u8arr[n] = bstr.charCodeAt(n);
// 	}
// 	return new Blob([u8arr], {type:mime});
// }
// async function blobToDataURL(blob)
// {
// 	return new Promise( ( resolve ) => {
// 		var a = new FileReader();
// 		a.onload = (e) => {
// 			resolve(e.target.result);
// 		}
// 		a.readAsDataURL(blob);
// 	});
// }
// function _arrayBufferToBase64( buffer )
// {
// 	var binary = '';
// 	var bytes = new Uint8Array( buffer );
// 	var len = bytes.byteLength;
// 	for (var i = 0; i < len; i++)
// 	{
// 		binary += String.fromCharCode( bytes[ i ] );
// 	}
// 	return window.btoa( binary );
// }

function CaptureID( props )
{
	if(process.env.REACT_APP_SAMPLEDATA ? true : false)
	{
		CoreModule.engineResourcePaths = {
			std: 'https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-std@1.2.0/dist/',
			dip: 'https://cdn.jsdelivr.net/npm/dynamsoft-image-processing@2.2.10/dist/',
			core: 'https://cdn.jsdelivr.net/npm/dynamsoft-core@3.2.10/dist/',
			license: 'https://cdn.jsdelivr.net/npm/dynamsoft-license@3.2.10/dist/',
			cvr: 'https://cdn.jsdelivr.net/npm/dynamsoft-capture-vision-router@2.2.10/dist/',
			dbr: 'https://cdn.jsdelivr.net/npm/dynamsoft-barcode-reader@10.2.10/dist/',
			dce: 'https://cdn.jsdelivr.net/npm/dynamsoft-camera-enhancer@4.0.2/dist/'
		};
	}
	else if(process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN)
	{
		CoreModule.engineResourcePaths = {
			std: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-capture-vision-std/dist/`,
			dip: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-image-processing/dist/`,
			core : `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-core/dist/`,
			license: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-license/dist/`,
			cvr: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-capture-vision-router/dist/`,
			dbr: `https://static.${process.env.REACT_APP_DYNAMSOFT_MODULE_DOMAIN}/dynamsoft/dynamsoft-barcode-reader/dist/`,
			dce: '/dynamsoft/dynamsoft-camera-enhancer/dist/',
		};		
	}
	else
	{
		CoreModule.engineResourcePaths = {
			std: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-capture-vision-std/dist/',
			dip: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-image-processing/dist/',
			core : 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-core/dist/',
			license: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-license/dist/',
			cvr: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-capture-vision-router/dist/',
			dbr: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-barcode-reader/dist/',
			dce: 'https://static.shieldhublabs.com/dynamsoft/dynamsoft-camera-enhancer/dist/'
		};
	}

	const capturecontainer = React.createRef(null);

	var CameraViewInstance, CameraEnhancerInstance, CVRouter , ImageManagerInstance;

	// const { GeoCoords, isGeolocationAvailable, isGeolocationEnabled }
	const { GeoCoords } = useGeolocated({positionOptions:{ enableHighAccuracy: true, },userDecisionTimeout: 5000, isOptimisticGeolocationEnabled : false });

	const [ capturedImage , setCapturedImage ] = React.useState(false);
	const [ capturedImageUrl , setCapturedImageUrl ] = React.useState(false);
	const [ capturedBarCode , setCapturedBarcode ] = React.useState(false);
	const [ stepComplete , setStepComplete ] = React.useState(false);
	const [ idvalues , setIdValues ] = React.useState(null);

	const [ captureInProgress , setCaptureInProgress ] = React.useState(false);

	async function verify()
	{
		let presignedurl = await api.startcaptureid(props.side);

		if(presignedurl.success)
		{
			// let imagecontent = dataURLtoBlob(capturedImage);
			let s3uploadresponse = await api.uploadcapture( presignedurl.posturl , capturedImage );
			if(s3uploadresponse.success)
			{
				let verifyresponse = await api.completecapture(props.side,'success' , capturedBarCode , GeoCoords );
				if(verifyresponse.success)
				{
					let metadata = {
						idvalues : verifyresponse.idvalues,
						labels : verifyresponse.labels,
						face : verifyresponse.face,
					}

					setIdValues(metadata);
					setStepComplete(true);
					props.CompleteStep();

					setCaptureInProgress(false);
				}
			}
		}

	}

	async function BarcodeCapture( result )
	{

		if(!result?.barcodeResultItems || result?.barcodeResultItems.length === 0)
		{
			if(parseInt(result.errorCode) !== 0)
			{
				console.log({ errorString : result.errorString , errorCode : result.errorCode });
			}

			return;
		}

		let pdf417_barcode_results = result.barcodeResultItems.filter( (item) => { return item.formatString === 'PDF417' } );

		if(pdf417_barcode_results.length === 0)
		{
			return;
		}

		if(capturedBarCode === false)
		{
			setCapturedBarcode(pdf417_barcode_results[0].text);
		}

		if(capturedImage === false)
		{
			CameraEnhancerInstance.setPixelFormat( 7 | 11);
			CameraEnhancerInstance.clearBuffer();

			let current_image = CameraEnhancerInstance.fetchImage();

			let imagedata = await ImageManagerInstance.saveToFile(current_image,'download');

			setCapturedImageUrl(URL.createObjectURL(imagedata));
			setCapturedImage(imagedata);
			CVRouter.stopCapturing('ReadSingleBarcode');
		}

		return;
	}

	async function Init()
	{
		await CoreModule.loadWasm(['DBR']);

		if( !(CameraViewInstance && !CameraViewInstance?.getUIElement) )
		{
			CameraViewInstance = await CameraView.createInstance();
			CameraViewInstance.setScanLaserVisible(false);
		}
		if(!CameraEnhancerInstance || !CVRouter)
		{
			CameraEnhancerInstance = await CameraEnhancer.createInstance(CameraViewInstance);
			CameraEnhancerInstance.setMaxImageCount(1);
			CameraEnhancerInstance.setPixelFormat( 7 | 11);

			CVRouter = await CaptureVisionRouter.createInstance();
			CVRouter.setInput(CameraEnhancerInstance);
			CVRouter.addResultReceiver({ 
				onCapturedResultReceived : BarcodeCapture,
			});
	
			let filter = new MultiFrameResultCrossFilter();
			filter.enableResultCrossVerification('barcode',true);
			filter.enableResultDeduplication('barcode', true);
			filter.setDuplicateForgetTime('barcode',1000);
			await CVRouter.addResultFilter(filter);
		}

		if(capturecontainer !== null && capturecontainer.current !== null)
		{
			capturecontainer?.current?.replaceChildren( CameraViewInstance.getUIElement() );

			CameraEnhancerInstance.setPixelFormat( 7 | 11);
			await CameraEnhancerInstance.open();
			let settings = await CVRouter.getSimplifiedSettings("ReadSingleBarcode");
			settings.barcodeSettings.barcodeFormatIds = 0x2000000;
			settings.capturedResultItemTypes = 0x2000000 | 2 | 1 ;
			settings.minImageCaptureInterval = 30;
			CVRouter.updateSettings('ReadSingleBarcode', settings );
			CVRouter.startCapturing('ReadSingleBarcode');

			ImageManagerInstance = new ImageManager();
	
		}
	}
	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		( async () => {
			await Init();
		} )();

	}, [capturecontainer,capturecontainer.current,CameraViewInstance, CameraViewInstance?.getUIElement ]);

	/* eslint-enable react-hooks/exhaustive-deps */


	return (

		<>
			<h1>{`Scan ID ${props.side}`}</h1>
			{capturedImage !== false && <>
				<Row>
					<Col xs={12} md={6}><img src={capturedImageUrl} height={350} width={600}/></Col>
					<Col xs={12} md={6}><pre>{JSON.stringify({idvalues , capturedBarCode},null,3)}</pre></Col>
				</Row>
				{stepComplete === false && <Button onClick={() => { setCapturedImage(false); setCapturedImageUrl(false); }}>Retry</Button>}
			</>}
			{ capturedImage === false && <>
				<Row>
					<Col xs={12} >
						<div ref={capturecontainer} className="div-ui-container" style={{height:'540px'}}></div>
					</Col>
				</Row>

			</> }

			{ stepComplete === false && <Button variant="primary" onClick={verify}>Next { captureInProgress === true && <Spinner size="sm" /> }</Button>}
		</>


	);

}
export default CaptureID;
