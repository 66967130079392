import axios from "axios";

const base_api_endpoint = process.env.REACT_APP_API_ENDPOINT;

async function search( searchterm )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/profile/search", { searchterm },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.freesearchexceeded = response.data.freesearchexceeded;
			apiresponse.searchresults = response.data.searchresults;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function getmyprofile()
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.get(base_api_endpoint+"/myprofile",{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.profile = response.data.profile;
		}
	}
	catch (e){
		console.log(e);
	}


	return apiresponse;	
}

async function saveprofileinfo( profileobject )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/profile/update", { profileobject },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}


	return apiresponse;
}

async function updatepassword( newpassword )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/profile/updatepassword", { newpassword },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}


	return apiresponse;
}

async function getprofileimageurl()
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.get(base_api_endpoint+"/profile/uploadimage",{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.posturl = response.data.posturl;
		}
	}
	catch (e){
		console.log(e);
	}


	return apiresponse;		
}

async function uploadprofileimagetos3( posturl , imagecontent )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.put(posturl,imagecontent);

		if(response.status === 200)
		{
			apiresponse.success = true;
		}
	}
	catch (e){
		console.log(e);
	}


	return apiresponse;	
}

async function uploadprofileimage( uploadstatus )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/profile/uploadimage",{ uploadstatus },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.profileimage = response.data.profileimage;
		}
	}
	catch (e){
		console.log(e);
	}


	return apiresponse;	
}

async function listpackages()
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.get(base_api_endpoint+"/profile/listpackages",{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.packages = response.data.packages;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function startapplication( packageid , applicationid = null )
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.post(base_api_endpoint+"/profile/newapplicant",{ packageid , applicationid },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.registrationlink = response.data.registrationlink;
			apiresponse.package_descrip = response.data.package_descrip;
			apiresponse.crapackageid = response.data.crapackageid;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;	
}

async function listapplications()
{
	let apiresponse = {
		success : false
	};

	try{
		let response = await axios.get(base_api_endpoint+"/application/listapplications",{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.applications = response.data.applications;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function getDownloadLink( appid )
{
	let apiresponse = {
		success : false
	};
	try{
		let response = await axios.get(base_api_endpoint+`/application/downloadreport?appid=${appid}`,{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.downloadlink = response.data.downloadlink;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function updatetwofactor( step , method , code )
{
	let apiresponse = {
		success : false
	};

	let parameters = {};

	if(step === 'start')
	{
		parameters.startmethod = method;
	}
	else if(step === 'verify')
	{
		parameters.verifymethod = method;
		parameters.code = code;
	}
	else if(step === 'remove')
	{
		parameters.removemethod = method;
	}
	else if(step === 'primary')
	{
		parameters.primarymethod = method;
	}

	try{
		let response = await axios.post(base_api_endpoint+"/profile/twofactor",parameters,{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.otpurl = response.data.otpurl;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;

}

async function getpurchases()
{
	let apiresponse = {
		success : false
	};
	try{
		let response = await axios.get(base_api_endpoint+`/profile/purchases`,{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.purchases = response.data.purchases;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function getpendingapplication( applicationid )
{
	let apiresponse = {
		success : false
	};
	try{
		let response = await axios.post(base_api_endpoint+`/profile/getapplication`,{ applicationid },{withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.profile = response.data.profile;
			apiresponse.application = response.data.application;
			apiresponse.pendinginvites = response.data.pendinginvites;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function saveacceptdetails( request )
{
	let apiresponse = {
		success : false
	};
	try{
		let response = await axios.post(base_api_endpoint+`/profile/saveaccept`, request, {withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

async function lookuparchivedshieldid( request )
{
	let apiresponse = {
		success : false
	};
	try{
		let response = await axios.post(base_api_endpoint+`/profile/lookuparchivedshieldid`, request, {withCredentials:true});

		if(response.data.success)
		{
			apiresponse.success = response.data.success;
			apiresponse.matchfound = response.data.matchfound;
			apiresponse.shieldid = response.data.shieldid;
		}
	}
	catch (e){
		console.log(e);
	}

	return apiresponse;
}

var methods = { 
	search, 
	saveprofileinfo, 
	getmyprofile,
	getprofileimageurl,
	uploadprofileimagetos3,
	uploadprofileimage, 
	startapplication, 
	updatepassword, 
	listapplications, 
	getDownloadLink, 
	listpackages,
	updatetwofactor,
	getpurchases,
	getpendingapplication,
	saveacceptdetails,
	lookuparchivedshieldid,
};

export default methods;