import * as React from "react";
import { Card, Collapse , Button , Modal , Form} from "react-bootstrap";
import { ChevronDown, ChevronRight, JournalPlus } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import api from '../../../io/admin';
import { format as dateFormat , parseISO as dateParseISO } from 'date-fns';
import { AgGridReact } from "ag-grid-react";
import { useRefreshEmitter } from "../../RefreshEmitter.js";


function AdminNotes()
{
	const { hash } = useLocation();
	const emitter = useRefreshEmitter();
	const [ panelshow , setPanelShow ] = React.useState(true);
	const [ modalShow , setModalShow ] = React.useState(false);
	const [ adminNoteList  , setAdminNoteList ] = React.useState([]);

	const modalformref = React.createRef(null);

	const adminNoteColDef = [
		{ field : 'authorname' , headerName : 'Author' , filter : true , filterParams : { buttons : [ 'reset' ] } , cellRenderer : (props) => {
			return <>
				<b>{props.data.authorname}</b><br />
				<b>{props.data.authoremailaddress}</b>
			</>;
		} },
		{ field : 'dts' , headerName : 'Date Time' , filter : 'agDateColumnFilter' , filterParams : { buttons : [ 'reset' ] } , cellRenderer : (props) => {
			return <>
				{props.data.dts_formatted}
			</>;
		}},
		{ field : 'note' , headerName : 'Note' , wrapText : true, autoHeight : true, initialWidth : 600 },
	];


	async function LoadAdminNotes( userid )
	{
		let response = await api.getadminnotes( userid );

		if(response.notes)
		{
			let notes = response.notes.map( (note) => {
				if(note.dts)
				{
					try{
						note.dts = dateParseISO(note.dts);
					}
					catch(e){
						console.warn(e);
						note.dts = null;
					}
					note.dts_formatted = dateFormat(note.dts, 'MM/dd/yyyy hh:mm a');
				}
				return note;
			});

			setAdminNoteList(notes);
		}
		else if(process.env.REACT_APP_SAMPLEDATA ? true : false)
		{
			setAdminNoteList([
				{
					noteid : 1000,
					authorid : 5000,
					authoremailaddress : 'admin@example.com',
					authorname : 'Admin Name',
					dts : new Date(),
					dts_formatted : dateFormat(new Date(), 'MM/dd/yyyy hh:mm a'),
					note : 'Lorem ipsum dolor sit amet,\nconsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqu',
				},
				{
					noteid : 1001,
					authorid : 5000,
					authoremailaddress : 'zdmin@example.com',
					authorname : 'Zdmin Name',
					dts : new Date(2020,1,1),
					dts_formatted : dateFormat(new Date(2020,1,1), 'MM/dd/yyyy hh:mm a'),
					note : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqu',
				}
			]);
		}
		else
		{
			console.warn(response);
			setAdminNoteList([]);
		}
	}

	async function SaveNewAdminNote()
	{

		let formData = new FormData(modalformref.current);

		let response = await api.saveadminnote( hash.replace(/#([0-9]+)/,'$1') , formData.get('adminnote') );

		if(response.success === true)
		{
			setModalShow(false);
			await LoadAdminNotes( hash.replace(/#([0-9]+)/,'$1') );
		}
		else
		{
			console.warn(response);
		}

	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		let userid = hash.replace(/#([0-9]+)/,'$1');
		setAdminNoteList([]);

		( async () => {
			await LoadAdminNotes( userid );
		})();

	},[hash]);
	React.useEffect( () => {

		let userid = hash.replace(/#([0-9]+)/,'$1');

		if( ['admin:refreshuser'].indexOf(emitter.eventname) !== -1 )
		{
			( async () => {
				await LoadAdminNotes( userid );
			})();
		}
	},[emitter.eventname,hash]);
	/* eslint-enable react-hooks/exhaustive-deps */		

	return (
		<Card className="p-4 d-inline-block">
			<Button variant="link" className="accordian-title" onClick={() => { setPanelShow( !panelshow ) }}>
				{panelshow && <ChevronDown/>}
				{!panelshow && <ChevronRight />}
				&nbsp;Administrative Notes</Button>
			<Collapse in={panelshow}>
				<>
					<div className="ag-theme-quartz" style={{height:'500px'}} >
						<AgGridReact
							rowData={adminNoteList}
							columnDefs={adminNoteColDef}
							pagination={true}
							paginationPageSize="10"
							rowHeight={100}
						/>						
					</div>
					<Button variant="primary" className="d-block" onClick={() => {
						setModalShow(true);
					}}><JournalPlus size={18} /> Add Note</Button>
				</>
			</Collapse>
			<Modal show={modalShow} centered>
				<Form ref={modalformref}>
					<Modal.Header>
						<Modal.Title>Add Admin Note</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Control as="textarea" name="adminnote"></Form.Control>
					</Modal.Body>
					<Modal.Footer className="justify-content-between">
						<Button variant="secondary" onClick={() => {
							setModalShow(false);
						}}>Nevermind</Button>
						<Button variant="primary" onClick={SaveNewAdminNote}>Save</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Card>

	);

}
export default AdminNotes;