import * as React from "react";
import { Button, Container , Image , Row , Col } from "react-bootstrap";
import CameraPermissions from '../../../assets/cam-permissions.svg';
import { Link, useNavigate } from "react-router-dom";

function CheckPermissions(props)
{

	const navigate = useNavigate();

	const [ camerapermission , setCameraPermission ] = React.useState(-1);
	const [ locationpermission , setLocationPermission ] = React.useState(-1);

	async function TriggerCameraPermission()
	{
		try
		{
			let stream = await navigator.mediaDevices.getUserMedia({ video: true });

			let devices = await navigator.mediaDevices.enumerateDevices();

			let videoDevices = devices.filter(device => device.kind === 'videoinput');

			if(videoDevices.length > 0)
			{
				setCameraPermission(1);
			}
			else
			{
				setCameraPermission(2);
			}

			stream.getTracks().forEach(track => track.stop());
		}
		catch (error)
		{
			console.log(error);
			setCameraPermission(0);
		}
	}

	function TriggerLocationPermission()
	{
		try
		{
			navigator.geolocation.getCurrentPosition((result) => {

				console.log(result);

				setLocationPermission(1);

			} , (error) => {

				console.log(error);

				setLocationPermission(2);

			} , { maximumAge : 60000 , timeout : 3000 , enableHighAccuracy : props.isMobile });

		}
		catch (error)
		{
			console.log(error);
			setLocationPermission(0);
		}
	}

	/*

		Permission flag values
		-1 : Not checked yet
		 0 : Denied permission
		 1 : Granted permission
		 2 : Camera or Location does not exist (on a desktop without webcam and without location)

	*/

	React.useEffect(() => {

		if(camerapermission >= 1 && locationpermission >= 1)
		{
			navigate('/user/idverify/capturefront');
		}

	},[navigate,camerapermission,locationpermission]);

	return (

		<Container>
			<h1>Verify your permissions</h1>
			{ camerapermission === -1 && locationpermission === -1 && 
				<Row className="justify-content-center">
					<Col xs={12} lg={4} className="text-center">
						<h3>Allow Camera Access</h3>
						<p>When prompted, you must enable camera access</p>
						<Container style={{height:'200px',marginTop:'70px'}} className="text-center">
							<Image src={CameraPermissions} />
						</Container>
						<p>We can&apos;t verify you without your camera</p>
						<Button variant="primary" onClick={TriggerCameraPermission}>Enable Camera Access</Button>
					</Col>
				</Row>
			}
			{camerapermission === 0 &&
				<Container>
					<h3>Camera Access Denied</h3>
					<p>We can&apos;t verify you without verifying your camera</p>
				</Container>
			}
			{camerapermission === 2 &&
				<Container>
					<h3>No cameras detected</h3>
					<p>To complete this workflow, you will need a device with a camera.</p>
					<Button as={Link} to='/user/idverify/idready'>Back</Button>
				</Container>
			}
			{camerapermission === 1 && locationpermission === -1 &&
				<Row className="justify-content-center">
					<Col xs={12} lg={4} className="text-center">
						<h3>Allow Location Access</h3>
						<p>When prompted, you must enable location access</p>
						<Container style={{height:'200px',paddingTop:'10px',paddingBottom:'10px'}} className="text-center">
							<Image src={`${process.env.PUBLIC_URL}/images/istockphoto-1453860167-612x612.jpg`} style={{height:'100%'}} />
						</Container>
						<p>We can&apos;t verify you without verifying your location</p>
						<Button variant="primary" onClick={TriggerLocationPermission}>Enable Location Access</Button>
					</Col>
				</Row>
			}
			{locationpermission === 0 &&
				<Container>
					<h3>Location Access Denied</h3>
					<p>We can&apos;t verify you without verifying your location</p>
				</Container>
			}


		</Container>

	);

}
export default CheckPermissions;